import isEmail from 'validator/lib/isEmail';
import PhoneNumber from 'awesome-phonenumber';

import searchCustomerProfiles from 'lib/common/api/customerProfiles/searchCustomerProfiles';
import TProfile from 'lib/common/types/CustomerProfile';

import { SEARCH_TYPES } from '../constants/searchTypes';
import { VALIDATION_ERROR_STATES } from '../constants/validationErrorStates';

import { ProfileActions, SEARCHABLE_FIELDS, TErrors, TParams } from '../types/CustomerProfileForm';

const isFieldValid = ({ phoneNumber, emailAddress, accountNumber }: TProfile, fieldName: string) => {
  if (fieldName === SEARCHABLE_FIELDS.PHONE_NUMBER) {
    return phoneNumber ? PhoneNumber(phoneNumber).isValid() : true;
  }

  if (fieldName === SEARCHABLE_FIELDS.EMAIL_ADDRESS) {
    return emailAddress ? isEmail(emailAddress) : true;
  }

  return accountNumber ? Boolean(accountNumber) : true;
};

export const validateProfile = async (
  { phoneNumber, emailAddress, accountNumber }: TProfile,
  params: TParams
): Promise<{ errors: TErrors }> => {
  const { config, fetch_, fieldName, value, action } = params;

  if (SEARCH_TYPES[fieldName] && value && isFieldValid({ phoneNumber, emailAddress, accountNumber }, fieldName)) {
    const searchedProfiles = await searchCustomerProfiles({
      config,
      fetch_,
      type: SEARCH_TYPES[fieldName],
      value,
      maxResults: 30
    });

    const matchedProfiles = action === ProfileActions.CREATE ? searchedProfiles.length : searchedProfiles.length > 1;

    return {
      errors: {
        [fieldName]: matchedProfiles ? VALIDATION_ERROR_STATES[fieldName].duplicate : void 0
      }
    };
  }

  return {
    errors: {
      [fieldName]:
        value && !isFieldValid({ phoneNumber, emailAddress, accountNumber }, fieldName)
          ? VALIDATION_ERROR_STATES[fieldName]?.invalid
          : void 0
    }
  };
};
