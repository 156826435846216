import { createContext, useContext } from 'react';

import TUser from 'lib/common/types/User';

type TAuthContext = {
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;
  loaded?: boolean;
  tokens?: any;
  email?: string;
  userData?: TUser;
};

const defaultAuth = {
  fetch_: () => Promise.resolve(),
  config: {}
};

const context = createContext<TAuthContext>(defaultAuth);

export const useIsolatedAuthContext = () => useContext(context);

export default context;
