export default async function storeAgentStatus({ status, fetch, tokens, config }) {
  if (!tokens || !config || !Object.keys(config).length || !fetch) {
    return;
  }

  try {
    await fetch(
      `${config.AGENT_SERVICE_URL}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/status`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ status })
      },
      tokens
    );
  } catch (e) {
    console.error('Error saving agent status', e);
  }
}
