import { useRef, useState } from 'react';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import _omitBy from 'lodash.omitby';

import IUploadedFile from 'lib/common/types/UploadedFile';
import TFetch from 'lib/common/types/Fetch';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useConfigContext } from 'lib/core/config';
import { TConfig } from 'lib/common/types/Config';
import UploadedFileType from 'lib/common/constants/files/UploadedFileType';
import UploadedFileStatus from 'lib/common/constants/files/UploadedFileStatus';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import { LogEvents, logger } from '../../components/LoggerController';
import createEmailAttachmentUploadUrl from './api/createEmailAttachmentUploadUrl';
import deleteAttachment from './api/deleteAttachment';
import Context, { IFileUploadMetadata } from './Context';

type TUploadCreateUrlMap = {
  [key in UploadedFileType]: (fetch_: TFetch, config: TConfig, files: File[]) => Promise<IFileUploadMetadata[]>;
};

type TDeleteFileMap = {
  [key in UploadedFileType]: (fetch_: TFetch, config: TConfig, key: string) => Promise<void>;
};

const STORAGE_KEY = 'uploaded-files';

const getInitialFile = (file: File, contextId: string) => ({
  uploadPercent: 0,
  name: file.name,
  status: UploadedFileStatus.UPLOADING,
  size: file.size,
  type: file.type,
  cancelSource: null,
  contextId
});

const TYPE_CREATE_URL_MAP: TUploadCreateUrlMap = {
  [UploadedFileType.EMAIL_ATTACHMENT]: createEmailAttachmentUploadUrl
};

const TYPE_DELETE_MAP: TDeleteFileMap = {
  [UploadedFileType.EMAIL_ATTACHMENT]: deleteAttachment
};

const UPLOAD_CANCELLED_CODE = 'ERR_CANCELED';

function getInitialFileMetadata(getStorageItem) {
  try {
    return JSON.parse(getStorageItem(STORAGE_KEY)) || {};
  } catch {
    return {};
  }
}

function EmailUploadProvider({ children }) {
  const { getStorageItem, setStorageItem } = useLocalStorage();

  // Multiple files in parallel all changing upload progress can lead to race conditions where updates get skipped
  // We use a ref here to ensure that the latest state is always used when updating the object
  const latestFiles = useRef<Record<string, IUploadedFile>>(getInitialFileMetadata(getStorageItem));

  const [files, setFiles] = useState<Record<string, IUploadedFile>>(getInitialFileMetadata(getStorageItem));

  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const setLatestFiles = (files: Record<string, IUploadedFile>) => {
    latestFiles.current = files;
    setFiles(files);
  };

  const updateAttachment = (key: string, update: Partial<IUploadedFile>) => {
    const newAttachments = {
      ...latestFiles.current,
      [key]: {
        ...(latestFiles.current[key] || {}),
        ...update
      }
    };

    setLatestFiles(newAttachments);
  };

  const clearFiles = (contextId: string) => {
    setLatestFiles(_omitBy(latestFiles.current, (file) => file.contextId === contextId));
    storeCompleteFileMetadataInStorage();
  };

  const deleteFile = async (type: UploadedFileType, key: string) => {
    const cancelSource = latestFiles.current[key]?.cancelSource;

    try {
      cancelSource?.cancel();
    } catch {}

    const { [key]: _, ...files } = latestFiles.current;

    setLatestFiles(files);
    storeCompleteFileMetadataInStorage();

    await TYPE_DELETE_MAP[type](fetch_, config, key);
  };

  const storeCompleteFileMetadataInStorage = () => {
    const completeFiles = Object.entries(latestFiles.current).reduce((currentFiles, [key, file]) => {
      if (file.status === UploadedFileStatus.COMPLETE) {
        return { ...currentFiles, [key]: file };
      }

      return currentFiles;
    }, {});

    setStorageItem(STORAGE_KEY, JSON.stringify(completeFiles));
  };

  const onUploadProgress = (key: string, progressEvent, cancelSource) => {
    updateAttachment(key, {
      cancelSource,
      uploadPercent: Math.round((progressEvent.loaded * 100) / (progressEvent.total || progressEvent.loaded))
    });
  };

  const uploadFileToUrl = async (file: File, fileMetadata: IFileUploadMetadata) => {
    try {
      const cancelSource = axios.CancelToken.source();

      const options: AxiosRequestConfig = {
        cancelToken: cancelSource.token,
        onUploadProgress: (progressEvent) => {
          onUploadProgress(fileMetadata.key, progressEvent, cancelSource);
        }
      };

      await axios.put(fileMetadata.url, file, options);

      updateAttachment(fileMetadata.key, {
        status: UploadedFileStatus.COMPLETE,
        uploadPercent: 100,
        cancelSource: null
      });

      storeCompleteFileMetadataInStorage();
    } catch (error: unknown) {
      if ((error as AxiosError)?.code === UPLOAD_CANCELLED_CODE) {
        return;
      }

      logger.error(LogEvents.ATTACHMENT_UPLOAD_FAILED, { error });

      updateAttachment(fileMetadata.key, {
        status: UploadedFileStatus.ERROR,
        cancelSource: null
      });
    }
  };

  const uploadFiles = async (type: UploadedFileType, files: File[], contextId: string) => {
    const filesMetadata = await TYPE_CREATE_URL_MAP[type](fetch_, config, files);

    if (!filesMetadata || !filesMetadata.length) {
      return [];
    }

    setLatestFiles({
      ...latestFiles.current,
      ...filesMetadata.reduce(
        (currentFiles, { key }, index) => ({
          ...currentFiles,
          [key]: getInitialFile(files[index], contextId)
        }),
        {}
      )
    });

    filesMetadata.forEach((fileMetadata, index) => {
      uploadFileToUrl(files[index], fileMetadata);
    });

    return Promise.resolve(filesMetadata);
  };

  const getFilesForContext = (contextId: string) =>
    _omitBy(latestFiles.current, (file) => file.contextId !== contextId);

  const addFiles = (files: Record<string, IUploadedFile>) => {
    setLatestFiles({
      ...latestFiles.current,
      ...files
    });
    storeCompleteFileMetadataInStorage();
  };

  return (
    <Context.Provider
      value={{
        state: { files },
        actions: {
          uploadFiles,
          deleteFile,
          clearFiles,
          getFilesForContext,
          addFiles
        }
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default EmailUploadProvider;
