import startCase from 'lodash.startcase';

export default function transformLogs(object: any, keys: string[] = []) {
  Object.keys(object).forEach((key) => {
    if (typeof object[key] === 'string' && object[key].length) {
      return;
    }

    if (typeof object[key] === 'string' && !object[key].length) {
      object[key] = [...keys, key].map((k) => startCase(k.toLowerCase())).join(' ');
      return;
    }

    transformLogs(object[key], [...keys, key]);
  });

  return object;
}
