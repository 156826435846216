import axios from 'axios';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';

export default async function sendSms(config, data) {
  try {
    const { endpointUrl, customHeader, customHeaderValue } = config;

    await axios.post(
      `${endpointUrl}`,
      data,
      (customHeader &&
        customHeaderValue && {
          headers: {
            [customHeader]: customHeaderValue
          }
        }) ||
        {}
    );

    logger.info(LogEvents.OUTBOUND_SMS_SEND.SUCCESS);
  } catch (error: any) {
    logger.error(LogEvents.OUTBOUND_SMS_SEND.FAIL, { error });

    requestErrorToast({ errorReference: error.response?.data?.errorCode, pointOfContact: 'admin' });
  }
}
