import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';

export default function isActionableCallTask({ type, status }) {
  return Boolean(
    ((type === CONTACT_TYPES.CALL || type === CONTACT_TYPES.QUEUE_CALLBACK) && status === CONTACT_STATES.CONNECTED) ||
      (type === CONTACT_TYPES.CONFERENCE_CALL && status === CONTACT_STATES.CONNECTED) ||
      (type === CONTACT_TYPES.CONFERENCE_CALL && status === CONTACT_STATES.CONNECTING)
  );
}
