import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { faCommentAlt, faPhoneAlt } from '@fortawesome/pro-regular-svg-icons';
import { format } from 'date-fns';

import Text from 'lib/common/components/Text';
import Icon from 'lib/common/components/Icon';

import getHistoryDataSet from 'lib/common/utils/customerProfiles/getHistoryDataSet';
import IInteractionHistory from 'lib/common/types/InteractionHistory';
import getDateFormat from 'lib/common/utils/getDateFormat';

import COLOURS from 'css/export-vars.module.scss';

import './interaction-history-table.scss';

interface IInteractionHistoryTable {
  interactionHistory: IInteractionHistory[];
  currentContactIsViewed?: boolean;
}

const INTERACTION_TYPE_ICON_SIZE = 15;

const COLUMNS = [
  { id: 'timestamp', numeric: false, label: 'Date' },
  { id: 'type', numeric: false, label: 'Type' },
  { id: 'queueSort', numeric: false, label: 'Queue Duration' },
  { id: 'callSort', numeric: false, label: 'Call Duration' }
];

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      maxHeight: 600
    },
    table: {
      minWidth: 400
    },
    tableCellHead: {
      backgroundColor: COLOURS.white
    }
  })
);

export default function InteractionHistoryTable({
  interactionHistory,
  currentContactIsViewed
}: IInteractionHistoryTable) {
  const [order, setOrder] = useState<'asc' | 'desc' | undefined>('desc');
  const [orderBy, setOrderBy] = useState('name');

  const classes = useStyles();

  if (!interactionHistory || !interactionHistory.length) {
    return (
      <Text>
        {currentContactIsViewed
          ? 'This is the first time this contact has called.'
          : 'This contact has no previous interactions.'}
      </Text>
    );
  }

  const handleRequestSort = (property) => () => {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  return (
    <div className="interaction-history-table">
      <TableContainer className={classes.container}>
        <Table stickyHeader className={classes.table} size="medium">
          <TableHead>
            <TableRow>
              {COLUMNS.map((column) => (
                <TableCell
                  key={column.id}
                  align="left"
                  sortDirection={orderBy === column.id ? order : false}
                  className={classes.tableCellHead}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={order}
                    onClick={handleRequestSort(column.id)}
                  >
                    {column.label}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {getHistoryDataSet({ data: interactionHistory, orderBy, order }).map(
              ({ id, timestamp, type, callDuration, queueDuration }) => (
                <TableRow key={id} classes={{ root: 'tbl-row' }} hover={false}>
                  <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                    <span className="table-record">
                      {timestamp ? format(new Date(timestamp), getDateFormat()) : 'Unknown'}
                    </span>
                  </TableCell>
                  <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                    <span className="table-record">
                      <Icon size={INTERACTION_TYPE_ICON_SIZE} icon={type !== 'VOICE' ? faCommentAlt : faPhoneAlt} />
                    </span>
                  </TableCell>
                  <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                    <span className="table-record">{queueDuration}</span>
                  </TableCell>
                  <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                    <span className="table-record">{callDuration}</span>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
