import { Route, RouteProps } from 'react-router-dom';
import { TPermissions } from '@cloud-wave/neon-common-lib';

import { TConnectPermissions, TPermissionTypes } from '../types/Permissions';
import RequiresPermission from './RequiresPermission/RequiresPermission';
import NotFound from './NotFound';

type AuthorisedRouteProps = {
  permission?: TPermissions | TConnectPermissions;
  permissionType?: TPermissionTypes;
  requiresAdmin?: boolean;
} & RouteProps;

export default function AuthorisedRoute({
  permission,
  permissionType,
  children,
  requiresAdmin,
  ...props
}: AuthorisedRouteProps) {
  return (
    <RequiresPermission
      permission={permission}
      permissionType={permissionType}
      fallback={<Route component={NotFound} />}
      requiresAdmin={requiresAdmin}
    >
      <Route {...props}>{children}</Route>
    </RequiresPermission>
  );
}
