import * as Profile from 'lib/common/components/Profile';
import RequiresPermission from 'lib/common/components/RequiresPermission';
import Loader from 'lib/common/components/Loader';
import useCustomerProfileActions from 'lib/common/hooks/useCustomerProfileActions';
import getContactDisplayName from 'lib/common/utils/customerProfiles/getContactDisplayName';
import { Permissions } from '@cloud-wave/neon-common-lib';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import TProfile from 'lib/common/types/CustomerProfile';
import ChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import getMatchableAttribute from 'lib/common/utils/getMatchableAttribute';

import Header from './Header';
import NotFound from './NotFound';

import '../styles/customer-profile.scss';

const CustomerProfile = ({ profileId, task }: { profileId?: string; task?: ChatTask | Task }) => {
  const {
    actions: { matchExistingCustomerProfile, deleteExistingCustomerProfile }
  } = useContactContext();

  const onDeleteProfile = () => {
    if (!task) {
      return;
    }

    deleteExistingCustomerProfile(task.taskId);
  };

  const {
    profile,
    profileHistory,
    profileNotes,
    showEditModal,
    showDeleteModal,
    profileDoesNotExist,
    profileLoading,
    showCreateModal,
    actions: { onCreate, deleteProfile, onEdit, onSaveNotes, onDeleteProfileSuccess },
    modalActions: {
      open: { openEditModal, openDeleteModal, openCreateModal },
      close: { closeEditModal, closeDeleteModal, closeCreateModal }
    }
  } = useCustomerProfileActions({ profileId, task, onDeleteProfile });

  const onCreateSuccess = (profile: TProfile) => {
    if (task) {
      matchExistingCustomerProfile({
        profile,
        taskId: task.taskId
      });
    }

    closeCreateModal();
  };

  return (
    <>
      <div className="customer-profile">
        {!profileLoading && profileDoesNotExist && (
          <>
            <NotFound onCreate={openCreateModal} task={task} />
            <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
              <Profile.CreateProfileModal
                existingAttribute={getMatchableAttribute(task?.contact)}
                open={showCreateModal}
                onSave={onCreate}
                onClose={closeCreateModal}
                onSuccess={onCreateSuccess}
              />
            </RequiresPermission>
          </>
        )}
        {!profileDoesNotExist && (
          <Header profile={profile} profileNotes={profileNotes} onDelete={openDeleteModal} onEdit={openEditModal} />
        )}
        {!profileDoesNotExist && profileLoading && <Loader small relative className="ml-auto mr-auto" />}
        {!profileDoesNotExist && !profileLoading && (
          <>
            <Profile.Information profile={profile} />
            <hr />
            <Profile.Notes
              contactDisplayName={getContactDisplayName(profile, { capitalised: true })}
              notes={profileNotes}
              onSave={onSaveNotes}
            />
            <hr />
            <Profile.History
              className="customer-profile__history"
              profileHistory={profileHistory}
              currentContactIsViewed
              emptyMessage={`This is the first time ${getContactDisplayName(profile)} has called.`}
            />
          </>
        )}
      </div>
      {profile && (
        <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
          <Profile.EditProfileModal
            profile={profile}
            open={showEditModal}
            onSave={onEdit}
            onClose={closeEditModal}
            onSuccess={closeEditModal}
          />
        </RequiresPermission>
      )}
      <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
        <Profile.DeleteProfileDialog
          open={showDeleteModal}
          onClose={closeDeleteModal}
          onConfirm={deleteProfile}
          onSuccess={onDeleteProfileSuccess}
        />
      </RequiresPermission>
    </>
  );
};

export default CustomerProfile;
