import { faChevronDown, faPlug } from '@fortawesome/pro-regular-svg-icons';
import { useLocation } from 'react-router-dom';
import { usePrevious } from 'webrix/hooks';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';

import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import Popover from 'lib/common/components/molecules/Popover';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import HeaderTaskBanner from './components/HeaderTaskBanner';

import './tasks.scss';

const SMALL_WINDOW_PX = 1100;

export default function Tasks() {
  const isBigScreen = useMediaQuery({ query: `(min-width: ${SMALL_WINDOW_PX}px)` });
  const {
    state: { tasks }
  } = useContactContext();
  const { pathname } = useLocation();

  const isOnWorkspace = pathname === PAGE_ROUTES.WORKSPACE;

  const filteredTasks = tasks.filter((task) => task.status !== CONTACT_STATES.CONNECTING);
  const small = !isBigScreen || filteredTasks.length > 1;
  const show = filteredTasks.length && !isOnWorkspace;
  const previousOnWorkspace = usePrevious(isOnWorkspace);
  const hasPreviousTasks = Boolean(usePrevious(filteredTasks).length);
  const outAnimation = hasPreviousTasks ? 'flipOutX' : '';

  const menuEl = (
    <button aria-label="Open tasks menu" className="header-tasks__button">
      <Icon icon={faPlug} />
      <Text>
        {filteredTasks.length} Task{filteredTasks.length === 1 ? '' : 's'}
      </Text>
      <Icon className="ml-10" icon={faChevronDown} />
    </button>
  );

  return (
    <div
      style={{
        display: (previousOnWorkspace && isOnWorkspace) || (!show && !hasPreviousTasks) ? 'none' : void 0
      }}
      className={cx(
        'header-tasks',
        'animate__animated',
        `animate__${show ? 'flipInX' : outAnimation}`,
        'animate__fast',
        { 'header-tasks--small': small }
      )}
    >
      {!small ? (
        <HeaderTaskBanner task={filteredTasks[0]} hideSubtitle />
      ) : (
        <Popover anchor={!show || tasks.length === 0 ? null : menuEl}>
          {tasks.map((task) => (
            <div className="header-tasks__task" key={task.taskId}>
              <HeaderTaskBanner task={task} />
            </div>
          ))}
        </Popover>
      )}
    </div>
  );
}
