import cx from 'classnames';
import DescriptivePill, { DescriptivePillProps } from './components/DescriptivePill';

export default function DescriptivePills({
  pills,
  id,
  className,
  ariaLabel
}: {
  className?: string;
  pills: DescriptivePillProps[];
  id?: string;
  ariaLabel?: string;
}) {
  return (
    <div
      role="tablist"
      aria-label={ariaLabel}
      data-testid={id || 'descriptive-pills'}
      className={cx('next-to-20', 'full-width', 'stretch-children', 'wrap', className)}
    >
      {pills.map((pill) => (
        <DescriptivePill key={pill.heading} {...pill} />
      ))}
    </div>
  );
}
