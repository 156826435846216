import { useState } from 'react';
import { isNumberValid } from '@cloud-wave/neon-common-lib';
import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import getUserName from 'lib/common/utils/getUserName';
import { useRequirementsContext } from 'lib/common/contexts/RequirementsContext';
import { isActiveConference } from 'lib/common/utils/conferenceConnections';

import { faPhone } from '@fortawesome/pro-regular-svg-icons';
import { useContactContext } from '../../contexts/ContactContext';
import { ITextProps } from '../Text/types';
import HangupModal from '../molecules/Directory/components/HangupModal';

import styles from './click-to-call.module.scss';

export default function ClickToCall({
  children,
  beforeOnClick,
  onClick,
  queueArn,
  button,
  textProps,
  showIcon,
  iconSize,
  isTransfer
}: {
  children: string;
  beforeOnClick?: () => void;
  onClick?: () => void;
  queueArn?: string;
  button?: boolean;
  textProps?: Partial<ITextProps>;
  showIcon?: boolean;
  iconSize?: number;
  isTransfer?: boolean;
}) {
  const {
    actions: { makeOutboundCall, getActiveCallTask },
    state: { onActiveCall, isCallInACW }
  } = useContactContext();

  const { isPermissionGranted } = useRequirementsContext();

  const [hangupModalOpen, setHangupModalOpen] = useState(false);

  const isValidNumber = isNumberValid(children);

  const isDisabled = !isValidNumber || !isPermissionGranted('microphone');

  const { profile, connectionValue, contact } = getActiveCallTask() || {};

  const activeCallName = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const handleOnNumberClick = () => {
    if (isDisabled) {
      return;
    }

    if (!isTransfer && (onActiveCall || isCallInACW) && !hangupModalOpen) {
      setHangupModalOpen(true);
      return;
    }

    beforeOnClick?.();

    return onClick ? onClick() : makeOutboundCall(children, queueArn);
  };

  const clickToCallElement = button ? (
    <Button
      asyncAction
      className={styles['click-to-call__button']}
      styleType="SECONDARY"
      icon="faPhone"
      onClick={!isDisabled ? handleOnNumberClick : void 0}
      disabled={isDisabled}
      tooltip={!isValidNumber ? "This phone number can't be dialled because it's not valid" : void 0}
    >
      Call {children}
    </Button>
  ) : (
    <Text
      ariaTitle={`Call ${children}`}
      {...(textProps || {})}
      onClick={!isDisabled ? handleOnNumberClick : void 0}
      className={styles['click-to-call__link']}
    >
      {showIcon && (
        <Icon
          tooltip="Phone number"
          color={isValidNumber ? 'primary' : void 0}
          className="mr-10"
          icon={faPhone}
          size={iconSize || 15}
        />
      )}
      {children}
    </Text>
  );

  const currentlyInConference = contact ? isActiveConference(contact) : false;

  function getModalText() {
    if (currentlyInConference) {
      return { primaryText: 'Dialling this number will remove you from the currently active conference call.' };
    }

    if (isCallInACW) {
      return { primaryText: 'Dialling this number will clear your current call ACW.' };
    }

    return {
      primaryText: `Dialling this contact will hang up the current active call${
        activeCallName ? ` with ${activeCallName}` : ''
      }.`,
      secondaryText:
        'Use the call control button if you want to transfer to an external number or dial numbers in your current call.'
    };
  }

  return (
    <>
      {hangupModalOpen && (
        <HangupModal
          open={hangupModalOpen}
          onClose={() => {
            setHangupModalOpen(false);
          }}
          onSave={handleOnNumberClick}
          {...getModalText()}
        />
      )}
      {clickToCallElement}
    </>
  );
}
