import cx from 'classnames';
import Text from 'lib/common/components/Text';

import styles from './conference-agent-name.module.scss';

export default function ConferenceAgentName({ agentName, isSoftphone }: { agentName: string; isSoftphone?: boolean }) {
  return (
    <div className={styles['conference-agent-name']}>
      <Text
        color="darkBlue"
        bold
        ellipsis
        className={cx(styles['conference-agent-name__title'], {
          [styles['conference-agent-name__title--softphone']]: isSoftphone
        })}
      >
        {agentName}
      </Text>
      <Text type="extraSmall" color="darkGrey" className={styles['conference-agent-name__sub-title']}>
        You
      </Text>
    </div>
  );
}
