import { useEffect, useState } from 'react';
import { usePrevious } from 'webrix/hooks';
import { faCheck, faSpinnerThird, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import useTimeout from './useTimeout';

const LOADING_STATES = {
  NONE: 'NONE',
  BUSY: 'BUSY',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

interface Props {
  onAction?: (any) => any;
  onSuccess?: (response: any) => void;
  onFailure?: () => void;
  onFinally?: () => void;
  loading?: boolean;
  successTimeoutSeconds?: number;
}

const ICON_TIMEOUT_S = 1;
const BUSY_TIMEOUT_S = 0.3;

const BUSY_ICON = {
  name: 'faSpinnerThird',
  el: faSpinnerThird
};

const SUCCESS_ICON = {
  name: 'faCheck',
  el: faCheck
};

const ERROR_ICON = {
  name: 'faXmark',
  el: faXmark
};

const stateIcons: { [key: string]: null | { name: string; el: IconDefinition } } = {
  [LOADING_STATES.NONE]: null,
  [LOADING_STATES.BUSY]: BUSY_ICON,
  [LOADING_STATES.SUCCESS]: SUCCESS_ICON,
  [LOADING_STATES.ERROR]: ERROR_ICON
};
export default function useActionState({
  onSuccess,
  onFailure,
  onFinally,
  loading,
  onAction,
  successTimeoutSeconds = ICON_TIMEOUT_S
}: Props) {
  const [loadingState, setLoadingState] = useState<string>(LOADING_STATES[loading ? 'BUSY' : 'NONE']);
  const previousLoadingState = usePrevious(loadingState);

  const { setTimeoutRef, clearTimeout } = useTimeout();

  const busy = loadingState !== LOADING_STATES.NONE;
  const prefixIcon = !busy ? stateIcons[LOADING_STATES.NONE] : stateIcons[loadingState];

  useEffect(() => {
    const newState = loading ? LOADING_STATES.BUSY : LOADING_STATES.NONE;

    setLoadingState(newState);
  }, [loading]);

  // These need to be handled in this hook and not in handleInteraction, because the parent
  // might change the functions and we need to call the most recent version, not the original reference
  useEffect(() => {
    const loadingStateHasReset = loadingState === LOADING_STATES.NONE && previousLoadingState !== LOADING_STATES.NONE;

    if (!loadingStateHasReset && loadingState !== LOADING_STATES.ERROR) {
      return;
    }

    if (loadingStateHasReset) {
      return void onFinally?.();
    }

    onFailure?.();
  }, [loadingState]);

  const handleInteraction = async (event: any) => {
    clearTimeout();

    setTimeoutRef(() => {
      setLoadingState(LOADING_STATES.BUSY);
    }, BUSY_TIMEOUT_S * 1000);

    try {
      const result = await onAction?.(event);

      if (successTimeoutSeconds) {
        setLoadingState(LOADING_STATES.SUCCESS);
      }

      clearTimeout();

      setTimeoutRef(() => {
        setLoadingState(LOADING_STATES.NONE);
        onSuccess?.(result);
        onFinally?.();
      }, successTimeoutSeconds * 1000);
    } catch (e) {
      setLoadingState(LOADING_STATES.ERROR);

      clearTimeout();

      setTimeoutRef(() => {
        setLoadingState(LOADING_STATES.NONE);
      }, ICON_TIMEOUT_S * 1000);
    }
  };

  return {
    handleInteraction,
    busy,
    icon: prefixIcon,
    icons: {
      busy: BUSY_ICON as IconType,
      success: SUCCESS_ICON as IconType,
      error: ERROR_ICON as IconType
    }
  };
}

export type IconType = typeof BUSY_ICON | typeof SUCCESS_ICON | typeof ERROR_ICON;

export type ActionState = {
  handleInteraction: (event: any) => Promise<void>;
  busy: boolean;
  icon: null | IconType;
  icons: {
    busy: IconType;
    success: IconType;
    error: IconType;
  };
};
