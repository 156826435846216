import { getAllActiveConnections } from 'lib/common/utils/conferenceConnections';

import CONTACT_TYPE from 'lib/common/constants/contactTypes';

export default function getCallContactType(contact: connect.Contact) {
  const activeConnections = getAllActiveConnections(contact);

  if (activeConnections.length > 1) {
    return CONTACT_TYPE.CONFERENCE_CALL;
  }

  return CONTACT_TYPE.CALL;
}
