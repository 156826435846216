import { formatDistance } from 'date-fns';
import _sortBy from 'lodash.sortby';

const LESS_THAN_A_MINUTE = 'less than a minute';

function getTimeBetweenTimestamps(firstTime, secondTime) {
  if (!firstTime || !secondTime) {
    return LESS_THAN_A_MINUTE;
  }

  try {
    return formatDistance(firstTime, secondTime);
  } catch {
    return LESS_THAN_A_MINUTE;
  }
}

function getSortValue(firstTime, secondTime) {
  if (!firstTime || !secondTime) {
    return 0;
  }

  const value = Math.abs(firstTime - secondTime);

  if (isNaN(value)) {
    return 0;
  }

  return value;
}

export default function getHistoryDataSet({ data, order = 'timestamp', orderBy = 'asc' }) {
  const dataSet = data.map(({ contactId, initiationTimestamp, channel, queue, connectedToSystemTimestamp }) => ({
    id: contactId,
    timestamp: initiationTimestamp,
    type: channel,
    call: getSortValue(queue?.enqueueTimestamp, queue?.dequeueTimestamp),
    queue: getSortValue(connectedToSystemTimestamp, initiationTimestamp),
    callDuration: getTimeBetweenTimestamps(connectedToSystemTimestamp, initiationTimestamp),
    queueDuration: getTimeBetweenTimestamps(queue?.enqueueTimestamp, queue?.dequeueTimestamp)
  }));

  const sorted = _sortBy(dataSet, orderBy);

  if (order === 'asc') {
    return sorted.reverse();
  }

  return sorted;
}
