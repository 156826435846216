import cx from 'classnames';
import sizeMe from 'react-sizeme';
import { memo } from 'react';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import TaskStatusBadge from 'lib/common/components/atoms/TaskStatusBadge';
import PORTAL_IDS from 'lib/common/constants/portalIds';
import { InfoPanelRow } from '../../types/InfoPanelRow';

import styles from './row.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const ROW_SMALL_WIDTH_PX = 300;

function Row({ icon, text, badgeProps, suffix, actions, size: { width }, showBadgeText = true, testId }: InfoPanelRow) {
  return (
    <div className={styles['info-panel-row']} id={PORTAL_IDS.SOFTPHONE_ATTACHMENT_PORTAL_ID} data-testid={testId}>
      {icon && <Icon className="mr-5" icon={icon} size={15} />}
      {typeof text === 'string' ? <Text>{text}</Text> : text}
      {badgeProps && (
        <TaskStatusBadge
          {...badgeProps}
          className="ml-10"
          showBadgeText={width > ROW_SMALL_WIDTH_PX && showBadgeText}
          isRow
        />
      )}
      {actions}
      {suffix && (
        <div className={cx(styles['info-panel-row__suffix'], { 'ml-10': badgeProps || actions })}>{suffix}</div>
      )}
    </div>
  );
}

export default memo(sizeMeHOC(Row));
