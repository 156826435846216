import { useEffect, useState } from 'react';

import Button from 'lib/common/components/Button';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import connectGetter from 'lib/common/utils/connectGetter';
import {
  areAllThirdPartyConnectionsOnHold,
  getAgentVoiceConnection,
  getAllActiveConnections,
  isAnyConnectionConnecting,
  isMultiPartyConferenceEnabled
} from 'lib/common/utils/conferenceConnections';
import agentIsMonitoring from 'lib/common/utils/connect/agentIsMonitoring';
import { ACTIONS_VISIBILITY_TIMEOUT } from 'lib/common/constants/conference';

import TaskFooterRow from '../../../TaskFooterRow';
import MuteButton from '../MuteButton';
import TransferFooterButtons from '../../../TransferFooterButtons';

export default function Conference({ taskId, initialConnectionDisconnected, isSmallWidth, connectionValue, contact }) {
  const { agent } = useAgentContext();
  const {
    actions: { onJoinConference, onHoldAll, hangupAgent }
  } = useContactContext();
  const [resumeOthers, setResumeOthers] = useState(false);

  const hasMaxConferenceConnections = getAllActiveConnections(contact).length === 5;
  const isMultiPartyConference = isMultiPartyConferenceEnabled(contact);
  const isMonitoring = agentIsMonitoring(contact);
  const agentConnection = getAgentVoiceConnection(contact);
  const isAgentOnHold = connectGetter(agentConnection, 'getState')?.type === connect.ConnectionStateType.HOLD;
  const isConnecting = isAnyConnectionConnecting(contact);
  const allThirdPartyConnectionsOnHold = areAllThirdPartyConnectionsOnHold(contact);

  useEffect(() => {
    if (!allThirdPartyConnectionsOnHold) {
      setResumeOthers(false);
    }

    const holdResumeOthersTimeout = setTimeout(
      () => setResumeOthers(allThirdPartyConnectionsOnHold),
      ACTIONS_VISIBILITY_TIMEOUT
    );

    return () => clearTimeout(holdResumeOthersTimeout);
  }, [allThirdPartyConnectionsOnHold]);

  // When the agent is on a desk phone, they can only mute/unmute the call on their phone (not in NEON)
  const isOnDeskPhone = !connectGetter(agent, 'isSoftphoneEnabled');

  if (isMonitoring) {
    return (
      <TaskFooterRow>
        <Button
          size="medium"
          icon="faPhoneHangup"
          onClick={() => hangupAgent(taskId)}
          tooltip="End monitoring"
          styleType="DANGER"
          round
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
      </TaskFooterRow>
    );
  }

  return (
    <>
      <TaskFooterRow isSmallWidth={isSmallWidth}>
        <TransferFooterButtons
          taskId={taskId}
          connectionValue={connectionValue}
          showDialpadTransfer
          showAgentAndQueueTransfer={isMultiPartyConference && !hasMaxConferenceConnections}
          disableTransfer={initialConnectionDisconnected || hasMaxConferenceConnections}
        />
      </TaskFooterRow>
      <TaskFooterRow primaryButtonIndex={1} isSmallWidth={isSmallWidth}>
        <Button
          onClick={() => (resumeOthers ? onJoinConference({ taskId }) : onHoldAll(taskId))}
          tooltip={resumeOthers ? 'Take others off hold' : 'Put others on hold'}
          icon={resumeOthers ? 'faPlay' : 'faPause'}
          styleType="NEUTRAL"
          round
          size="small"
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
          disabled={isConnecting}
        />

        <Button
          size="medium"
          icon="faRightFromBracket"
          tooltip="Leave the conference"
          styleType="DANGER"
          round
          onClick={() => hangupAgent(taskId)}
          asyncAction
          successTimeoutSeconds={0}
          preventDoubleClick
        />
        {!isOnDeskPhone && (
          <MuteButton disabled={isAgentOnHold || isConnecting} agentConnection={agentConnection} taskId={taskId} />
        )}
      </TaskFooterRow>
    </>
  );
}
