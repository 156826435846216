import connectGetter from 'lib/common/utils/connectGetter';
import toast from 'lib/common/utils/toast';
import connectAction from 'lib/common/utils/connectAction';
import { AGENT_TASK_TRANSFER_FAILURE } from 'lib/common/constants/connectExceptions';
import {
  getAgentConnection,
  getAllActiveConnections,
  getAllActiveThirdPartyConnections,
  isMultiPartyConferenceEnabled
} from 'lib/common/utils/conferenceConnections';
import { LogEvents, logger } from 'lib/common/components/LoggerController';

import TTask from '../../../types/Task';
import useMoveTaskToACW from './useMoveTaskToACW';

const POLL_TRANSFER_COMPLETE_TRIES = 10;
const TIME_MS_BETWEEN_TRANSFER_POLL = 1000;
const TIME_WAIT_FOR_TRANSFER_FAIL = 5000;

function getTaskContact(tasks: TTask[], taskId: string) {
  return tasks.find((task) => task.taskId === taskId)?.contact;
}

async function waitForContactEnded(contact: connect.Contact, retries = POLL_TRANSFER_COMPLETE_TRIES) {
  const contactType = connectGetter(contact, 'getState')?.type;

  // If no contact type it's because the contact no longer exists, let it be destroyed
  if (!contactType || contactType === connect.ContactStateType.ENDED) {
    return Promise.resolve();
  }

  if (contactType === connect.ContactStateType.ERROR || retries === 0) {
    return Promise.reject();
  }

  await new Promise((resolve) => setTimeout(resolve, TIME_MS_BETWEEN_TRANSFER_POLL));
  return waitForContactEnded(contact, retries - 1);
}

function waitForContactTransfer(contact: connect.Contact) {
  return new Promise(async (resolve, reject) => {
    const agentConnection = getAgentConnection(contact);
    // @ts-expect-error getMediaController is accessible on baseConnection, typed incorrectly by streams
    const taskSession = await connectGetter(agentConnection, 'getMediaController');

    if (taskSession) {
      // @ts-expect-error as above
      taskSession.onTransferFailed?.((message) => {
        toast('error', AGENT_TASK_TRANSFER_FAILURE);
        reject(message);
      });

      // Transfer might fail after 5 seconds
      await new Promise((resolve) => setTimeout(resolve, TIME_WAIT_FOR_TRANSFER_FAIL));
    }

    await waitForContactEnded(contact);

    resolve(undefined);
  });
}

export default function useCallActions({ tasks, handleContactChange }) {
  const moveTaskToACW = useMoveTaskToACW({ handleContactChange });

  const holdCall = async ({ taskId }: { taskId: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      // When the initial connection has disconnected we need to fall back to holding the third party connection
      const activeConnection =
        connectGetter(contact, 'getActiveInitialConnection') ||
        connectGetter(contact, 'getSingleActiveThirdPartyConnection');

      if (!activeConnection) {
        throw new Error('No active connection found');
      }

      await connectAction('hold', activeConnection);

      logger.info(LogEvents.CALL_ACTIONS.HOLD.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CALL_ACTIONS.HOLD.FAIL, { contactId: taskId, error });

      return Promise.reject(error);
    }
  };

  const resumeCall = async ({ taskId }: { taskId: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    try {
      // When the initial connection has disconnected we need to fall back to holding the third party connection
      const activeConnection =
        connectGetter(contact, 'getActiveInitialConnection') ||
        connectGetter(contact, 'getSingleActiveThirdPartyConnection');

      if (!activeConnection) {
        throw new Error('No active connection found');
      }

      await connectAction('resume', activeConnection);

      logger.info(LogEvents.CALL_ACTIONS.RESUME.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CALL_ACTIONS.RESUME.FAIL, { contactId: taskId, error });

      return Promise.reject(error);
    }
  };

  const resumeConferenceConnection = async ({ taskId, connectionId }: { taskId: string; connectionId?: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact || !connectionId) {
      return Promise.resolve();
    }

    try {
      // This is a workaround for the connect streams not providing a way to resume a conference connection when
      // isMultiPartyConferenceEnabled is not enabled and the scenario is as follows:
      // 1. Agent is in a conference with 2 participants and one of them is on hold
      // 2. When resuming the connection, it will through an error therefore we need to join the conference instead
      const activeConnections = getAllActiveConnections(contact);
      const isTwoParticipantsConference = activeConnections.length === 2;
      const isOneParticipantOnHold =
        activeConnections.filter(
          (connection) => connectGetter(connection, 'getState')?.type === connect.ConnectionStateType.HOLD
        ).length === 1;
      const isJoinAction = isTwoParticipantsConference && isOneParticipantOnHold;
      const isMultiPartyConference = isMultiPartyConferenceEnabled(contact);

      if (isJoinAction && !isMultiPartyConference) {
        await connectAction('conferenceConnections', contact);

        return logger.info(LogEvents.CONFERENCE_ACTIONS.RESUME.SUCCESS, { contactId: taskId });
      }

      const selectedConnection = getAllActiveConnections(contact, { includeAgent: true }).find(
        (connection) => connection.connectionId === connectionId
      );

      if (!selectedConnection) {
        throw new Error('Something went wrong while trying to resume the call, please try again');
      }

      await connectAction('resume', selectedConnection);

      return logger.info(LogEvents.CONFERENCE_ACTIONS.RESUME.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CONFERENCE_ACTIONS.RESUME.FAIL, { contactId: taskId, error });
    }
  };

  const holdConferenceConnection = async ({ taskId, connectionId }: { taskId: string; connectionId?: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact || !connectionId) {
      return Promise.resolve();
    }

    try {
      const selectedConnection = getAllActiveConnections(contact, { includeAgent: true }).find(
        (connection) => connection.connectionId === connectionId
      );

      if (!selectedConnection) {
        throw new Error('Something went wrong while trying to hold the call, please try again');
      }

      await connectAction('hold', selectedConnection);
      logger.info(LogEvents.CONFERENCE_ACTIONS.HOLD.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CONFERENCE_ACTIONS.HOLD.FAIL, { contactId: taskId, error });
    }
  };

  const endConferenceConnection = async ({ taskId, connectionId }: { taskId: string; connectionId?: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact || !connectionId) {
      return Promise.resolve();
    }

    try {
      const selectedConnection = getAllActiveThirdPartyConnections(contact).find(
        (connection) => connection.connectionId === connectionId
      );

      if (!selectedConnection) {
        throw new Error('Something went wrong while trying to disconnect the participant, please try again');
      }

      await connectAction('destroy', selectedConnection);

      logger.info(LogEvents.CONFERENCE_ACTIONS.END.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CONFERENCE_ACTIONS.END.FAIL, { contactId: taskId, error });
    }
  };

  const onTransferToQueueOrAgent = async (endpoint: connect.Endpoint, taskId: string) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact) {
      return Promise.resolve();
    }

    const contactType = connectGetter(contact, 'getType');

    if (!contactType) {
      return Promise.resolve();
    }

    await connectAction('addConnection', contact, endpoint, {
      ignoreError: () => connectGetter(contact, 'getState')?.type === connect.ContactStateType.ENDED
    });

    if (contactType !== connect.ContactType.TASK && contactType !== connect.ContactType.CHAT) {
      return;
    }

    await waitForContactTransfer(contact);

    return moveTaskToACW(contact);
  };

  const muteConnection = async ({ taskId, connectionId }: { taskId: string; connectionId?: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact || !connectionId) {
      return Promise.resolve();
    }

    try {
      const selectedConnection = getAllActiveConnections(contact, { includeAgent: true }).find(
        (connection) => connection.connectionId === connectionId
      );

      if (!selectedConnection) {
        throw new Error('Something went wrong while trying to mute the call, please try again');
      }

      await connectAction('muteParticipant', selectedConnection);
      logger.info(LogEvents.CALL_ACTIONS.MUTE.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CALL_ACTIONS.MUTE.FAIL, { contactId: taskId, error });
    }
  };

  const unmuteConnection = async ({ taskId, connectionId }: { taskId: string; connectionId?: string }) => {
    const contact = getTaskContact(tasks, taskId);

    if (!contact || !connectionId) {
      return Promise.resolve();
    }

    try {
      const selectedConnection = getAllActiveConnections(contact, { includeAgent: true }).find(
        (connection) => connection.connectionId === connectionId
      );

      if (!selectedConnection) {
        throw new Error('Something went wrong while trying to unmute the call, please try again');
      }

      await connectAction('unmuteParticipant', selectedConnection);
      logger.info(LogEvents.CALL_ACTIONS.UNMUTE.SUCCESS, { contactId: taskId });
    } catch (error) {
      logger.error(LogEvents.CALL_ACTIONS.UNMUTE.FAIL, { contactId: taskId, error });
    }
  };

  return {
    holdCall,
    resumeCall,
    onTransferToQueueOrAgent,
    resumeConferenceConnection,
    holdConferenceConnection,
    endConferenceConnection,
    muteConnection,
    unmuteConnection
  };
}
