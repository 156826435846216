import { useEffect } from 'react';
import { Permissions } from '@cloud-wave/neon-common-lib';

import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import connectGetter from 'lib/common/utils/connectGetter';
import ChatTask from 'lib/common/types/ChatTask';
import Task from 'lib/common/types/Task';
import { ImageType } from 'lib/common/constants/imageBlockFields';
import { useConfigContext } from 'lib/core/config';
import { getDefaultBrandImageUrl, getFileUrl } from 'lib/core/utils';
import getTaskType from 'lib/common/contexts/ContactContext/utils/getTaskType';
import resolveCustomerProfile from 'lib/common/contexts/ContactContext/utils/resolveCustomerProfile';
import { useAuthContext } from 'lib/core/context/AuthProvider';

import registerChatHandlers from '../utils/registerChatHandlers';
import getInitialChatMessages from '../utils/getInitialChatMessages';
import { TAnyTask } from '../types/ContactContext';
import { getDraftTasks } from '../utils/draftTasksStorage';
import THandleContactChange from '../types/HandleContactChange';
import { usePermissionsContext } from '../../PermissionsContext';
import hangupAgentHelper from '../utils/hangupAgentHelper';
import taskBuilder from '../utils/taskBuilder';
import useClearContact from './useClearContact';

interface IInitialSetup {
  setTasks: (tasks: TAnyTask[]) => void;
  handleContactChange: THandleContactChange;
  getSelectedTaskId: () => string | undefined;
}

export default function useInitialSetup({ setTasks, handleContactChange, getSelectedTaskId }: IInitialSetup) {
  const clearContact = useClearContact({ handleContactChange });
  const { config } = useConfigContext();
  const { fetch_ } = useAuthContext();
  const { hasPermission } = usePermissionsContext();

  const hasACWPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW
  });

  const hasCustomerProfilesPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.CUSTOMER_PROFILES
  });

  const appLogo =
    config && config.BRAND && config.BRAND.favicon && config.BRAND.favicon.fileKey
      ? getFileUrl(config.BRAND?.favicon.fileKey, config)
      : getDefaultBrandImageUrl(ImageType.FAVICON);

  useEffect(() => {
    const connect = (window as any).getConnect();

    connect.agent(async (agent: connect.Agent) => {
      const draftTasks = await getDraftTasks();

      const existingContacts: connect.Contact[] = connectGetter(agent, 'getContacts') || [];

      if (!existingContacts?.length) {
        setTasks(draftTasks.withoutContact);

        return;
      }

      const tasks = (
        await Promise.all(
          existingContacts.map(async (contact): Promise<Task | ChatTask | null> => {
            const taskType = getTaskType(contact);
            if (!taskType) {
              return null;
            }

            const profile = await resolveCustomerProfile({
              contact,
              config,
              fetch_,
              hasCustomerProfilesPermission
            });

            if (taskType === CONTACT_TYPES.CHAT) {
              const chatTask = await taskBuilder.newChatTask({ contact, hasPermission });

              if (!chatTask) {
                return null;
              }

              registerChatHandlers({
                session: chatTask.chatSession,
                taskId: chatTask.taskId,
                handleContactChange,
                getSelectedTaskId,
                profile: chatTask.profile,
                icon: appLogo
              });

              return {
                ...chatTask,
                ...(await getInitialChatMessages(chatTask.chatSession)),
                profile
              } as ChatTask;
            }

            const existingDraftTask = draftTasks.withContact.find((task) => task.taskId === contact.contactId);
            const taskFromContact = taskBuilder.newBaseTask(contact);
            if (!taskFromContact) {
              return null;
            }

            const task = {
              ...(existingDraftTask || {}),
              ...taskFromContact,
              profile,
              status: existingDraftTask ? CONTACT_STATES.DRAFT : taskFromContact.status
            };

            /**
             * If call is connected, on page refresh set to a disconnected connection state
             */
            if (
              (task.type === CONTACT_TYPES.CALL || task.type === CONTACT_TYPES.QUEUE_CALLBACK) &&
              task.status === CONTACT_STATES.CONNECTED
            ) {
              try {
                await hangupAgentHelper({ contact });

                if (!hasACWPermission) {
                  await clearContact(contact, { skipDestroy: true });

                  return null;
                }

                return {
                  ...task,
                  status: CONTACT_STATES.ACW,
                  connectionState: CONNECTION_STATES.DISCONNECTED
                };
              } catch {
                return null;
              }
            }

            return task as Task;
          })
        )
      ).filter(Boolean) as TAnyTask[];

      setTasks([...draftTasks.withoutContact, ...tasks.filter(Boolean)]);
    });
  }, []);
}
