import { useEffect, useState } from 'react';
import { EditorState, convertToRaw } from 'draft-js';
// @ts-ignore
import { Editor } from 'react-draft-wysiwyg';
import { useBooleanState } from 'webrix/hooks';
import { faCircleNotch, faEdit, faMemo, faSave, faXmark } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Permissions } from '@cloud-wave/neon-common-lib';
import toast from 'lib/common/utils/toast';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import RequiresPermission from 'lib/common/components/RequiresPermission';

import profileFieldParsers from 'lib/common/utils/customerProfiles/profileFieldParsers';

import ContentPanel from '../ContentPanel';
import './notes.scss';

type TNotes = {
  className?: string;
  notes?: string | null;
  contactDisplayName: string;
  onSave: (notes: string) => Promise<void>;
};

const SAVE_ERROR_MESSAGE = 'Oops, something went wrong. Give it another try.';

function Actions({ editing, saving, setEditing, handleCancel, handleSave }) {
  if (!editing) {
    return (
      <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
        <ClickableIcon aria-label="Edit notes" data-testid="edit-notes" icon={faEdit} onClick={setEditing} />
      </RequiresPermission>
    );
  }

  return saving ? (
    <Icon icon={faCircleNotch} spin />
  ) : (
    <RequiresPermission permission={Permissions.CUSTOMER_PROFILES_WRITE}>
      <>
        <ClickableIcon
          aria-label="Cancel"
          data-testid="cancel-edit-notes"
          icon={faXmark}
          onClick={handleCancel}
          size={25}
        />
        <ClickableIcon aria-label="Save" data-testid="save-edit-notes" icon={faSave} onClick={handleSave} />
      </>
    </RequiresPermission>
  );
}

function getEditorStateFromNotes(notes) {
  const state = profileFieldParsers.notes(notes);

  if (!state) {
    return EditorState.createEmpty();
  }

  return state;
}

export default function Notes({ className, notes: profileNotes, contactDisplayName, onSave }: TNotes) {
  const { value: editing, setTrue: setEditing, setFalse: setNotEditing } = useBooleanState();
  const { value: saving, setTrue: setSaving, setFalse: setNotSaving } = useBooleanState();

  const [notes, setNotes] = useState(EditorState.createEmpty());

  const fetchingNotes = profileNotes === void 0;
  const isEmpty = !editing && !notes.getCurrentContent?.()?.hasText?.();

  useEffect(() => {
    setNotes(getEditorStateFromNotes(profileNotes));
  }, [profileNotes]);

  const handleCancel = () => {
    setNotes(getEditorStateFromNotes(profileNotes));
    setNotEditing();
  };

  const handleSave = async () => {
    setSaving();

    try {
      // Check if there's actual text content, because even if there isn't there will be content state which we don't
      // want to save

      await onSave(
        // getPlainText will always create a single space between blocks, so you need to send \u0001 as a parameter
        notes.getCurrentContent().getPlainText('\u0001') ? JSON.stringify(convertToRaw(notes.getCurrentContent())) : ''
      );

      setNotEditing();
    } catch (e) {
      toast('error', SAVE_ERROR_MESSAGE);
    } finally {
      setNotSaving();
    }
  };

  return (
    <ContentPanel
      className={cx('profile-notes', className)}
      title="Notes"
      isEmpty={!fetchingNotes && !editing && !profileNotes}
      emptyMessage={`${contactDisplayName} doesn't have any notes yet.`}
      emptyIcon={faMemo}
      alwaysShowChildren={!fetchingNotes}
      actions={
        !fetchingNotes && (
          <div className="profile-notes__actions">
            <Actions
              editing={editing}
              setEditing={setEditing}
              handleCancel={handleCancel}
              handleSave={handleSave}
              saving={saving}
            />
          </div>
        )
      }
    >
      {fetchingNotes && <Loader relative small />}
      {!editing && !isEmpty && <Editor readOnly editorState={notes} toolbarHidden />}
      {editing && (
        <Editor
          readOnly={saving}
          editorClassName={cx('profile-notes__main-editor', {
            'profile-notes__main-editor__saving': saving
          })}
          editorState={notes}
          onEditorStateChange={setNotes}
          toolbar={{
            options: ['list', 'link']
          }}
          {...(saving ? { toolbarClassName: 'profile-notes__toolbar__saving' } : {})}
        />
      )}
    </ContentPanel>
  );
}
