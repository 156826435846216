import React, { FunctionComponent, ReactNode, SVGProps } from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as ErrorImage } from 'assets/vectors/error.svg';
import Button from 'lib/common/components/Button';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import useIsSoftphoneQuery from 'lib/common/hooks/useIsSoftphoneQuery';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

import './error.scss';
import DownloadLogs from '../DownloadLogs';

type ErrorProps = {
  text?: string | ReactNode;
  title?: string;
  Image?: FunctionComponent<SVGProps<SVGSVGElement>>;
  icon?: IconDefinition;
  relative?: boolean;
  hidePrimaryAction?: boolean;
};

function Error({ text, Image, icon, title, relative, hidePrimaryAction }: ErrorProps) {
  // Error can be used inside or outside the layout context, so this hook will only work if this is a child of that context
  const { isSoftphone: isSoftphoneLayout } = useLayout();

  // Fallback to the plain softphone media query in case we're rendered from the error boundary. ! This will ignore softphone forced mode !
  const isSoftphoneSize = useIsSoftphoneQuery();

  const history = useHistory();

  const isSoftphone = isSoftphoneLayout || isSoftphoneSize;
  const useDefaults = !text;

  const onClick = () => {
    if (useDefaults) {
      return void window.location.reload();
    }

    history.push('/');
  };

  const imageClass = cx('error__page__image', { 'error__page__image--softphone': isSoftphone });

  return (
    <>
      <div className={cx('error', { 'error--relative': relative })} data-testid="error-page">
        <div className="error__page">
          <div className="error__content">
            <Text
              type="heading2"
              className={cx('error__content__title', {
                'error__content__title--softphone': isSoftphone
              })}
            >
              {title || 'Oops'}
            </Text>
            <Text className="error__content__text mt-10">
              {text || 'Something went wrong. Try reloading the page.'}
            </Text>
            {!hidePrimaryAction && <Button onClick={onClick}>{useDefaults ? 'Reload' : 'Take Me Home'}</Button>}
          </div>
          {!icon && (Image ? <Image className={imageClass} /> : <ErrorImage className={imageClass} />)}
          {icon && <Icon icon={icon} size={80} color="darkGrey" />}
        </div>
      </div>
      <DownloadLogs alwaysShown />
    </>
  );
}

export default Error;
