import { parsePhoneNumber } from 'awesome-phonenumber';

import connectGetter from './connectGetter';

const DEFAULT_USER_NAME = 'Agent';

function assertVoiceConnection(connection: connect.BaseConnection): asserts connection is connect.VoiceConnection {
  try {
    if (!(connection instanceof connect.VoiceConnection)) {
      throw 'Agent not connected to call';
    }
  } catch (e) {
    if (e instanceof TypeError && process.env.NODE_ENV === 'test') {
    } else {
      throw e;
    }
  }
}

function assertVoiceConnections(
  connections: connect.BaseConnection[]
): asserts connections is connect.VoiceConnection[] {
  connections.forEach((connection) => {
    try {
      if (!(connection instanceof connect.VoiceConnection)) {
        throw 'Agent not connected to call';
      }
    } catch (e) {
      if (e instanceof TypeError && process.env.NODE_ENV === 'test') {
      } else {
        throw e;
      }
    }
  });
}

export const isConference = (contact: connect.Contact) => (connectGetter(contact, 'getConnections') || []).length > 2;

export const isActiveConference = (contact: connect.Contact) => {
  return getAllActiveConnections(contact).length > 1;
};

export const isMultiPartyConferenceEnabled = (contact: connect.Contact) => {
  return Boolean(connectGetter(contact, 'isMultiPartyConferenceEnabled'));
};

export const getAgentConnection = (contact: connect.Contact | undefined): connect.BaseConnection | undefined => {
  return connectGetter(contact, 'getAgentConnection');
};

export const getAgentVoiceConnection = (contact?: connect.Contact): connect.VoiceConnection | undefined => {
  const connection = connectGetter(contact, 'getAgentConnection');
  if (!connection) {
    return;
  }

  assertVoiceConnection(connection);

  return connection;
};

export const getAllActiveThirdPartyConnections = (contact: connect.Contact): connect.BaseConnection[] => {
  return (
    connectGetter(contact, 'getThirdPartyConnections')?.filter(
      (connection) => connectGetter(connection, 'getState')?.type !== connect.ConnectionStateType.DISCONNECTED
    ) ?? []
  );
};

export const getAllActiveThirdPartyVoiceConnections = (contact: connect.Contact): connect.VoiceConnection[] => {
  const connections = connectGetter(contact, 'getThirdPartyConnections');
  if (!connections) {
    return [];
  }
  assertVoiceConnections(connections);

  return (
    connections.filter(
      (connection) => connectGetter(connection, 'getState')?.type !== connect.ConnectionStateType.DISCONNECTED
    ) ?? []
  );
};

export const getAllActiveConnections = (
  contact: connect.Contact,
  { includeAgent }: { includeAgent?: boolean } = {}
): connect.BaseConnection[] => {
  return (
    connectGetter(contact, 'getConnections')?.filter(
      (connection) =>
        connectGetter(connection, 'getState')?.type !== connect.ConnectionStateType.DISCONNECTED &&
        (includeAgent || connectGetter(connection, 'getType') !== connect.ConnectionType.AGENT)
    ) ?? []
  );
};

export const getAllActiveVoiceConnections = (
  contact: connect.Contact,
  { includeAgent }: { includeAgent?: boolean } = {}
): connect.VoiceConnection[] => {
  const connections = connectGetter(contact, 'getConnections');
  if (!connections) {
    return [];
  }
  assertVoiceConnections(connections);

  return (
    connections.filter(
      (connection) =>
        connectGetter(connection, 'getState')?.type !== connect.ConnectionStateType.DISCONNECTED &&
        (includeAgent || connectGetter(connection, 'getType') !== connect.ConnectionType.AGENT)
    ) ?? []
  );
};

export const getConnectionName = (connection: connect.VoiceConnection) => {
  const phoneNumber = connectGetter(connection, 'getEndpoint')?.phoneNumber ?? '';

  return parsePhoneNumber(phoneNumber).isValid() ? phoneNumber : DEFAULT_USER_NAME;
};

export const isConnectionOnHold = (connection: connect.BaseConnection) => {
  const isOnHold = connectGetter(connection, 'getState')?.type === connect.ConnectionStateType.HOLD;

  return Boolean(isOnHold);
};

export const isConnectionMuted = (connection: connect.VoiceConnection) => {
  const isMuted = connectGetter(connection, 'isMute');

  return Boolean(isMuted);
};

export const getConferenceConnectionStates = (
  contact: connect.Contact,
  { includeAgent }: { includeAgent?: boolean } = {}
) => {
  const allActiveConnections = getAllActiveConnections(contact, { includeAgent });

  return allActiveConnections.map((connection) => connectGetter(connection, 'getState')?.type).filter(Boolean);
};

// All third party connections on hold
export const areAllThirdPartyConnectionsOnHold = (contact: connect.Contact) => {
  const conferenceStates = getConferenceConnectionStates(contact);

  if (!conferenceStates?.length) {
    return false;
  }

  return conferenceStates.every((connectionState) => connectionState === connect.ConnectionStateType.HOLD);
};

// All third party connections connected
export const areAllThirdPartyConnectionsConnected = (contact: connect.Contact) => {
  const conferenceStates = getConferenceConnectionStates(contact);

  if (!conferenceStates?.length) {
    return false;
  }

  return conferenceStates.every((connectionState) => connectionState === connect.ConnectionStateType.CONNECTED);
};

// Customer connection is disconnected
export const isInitialConnectionDisconnected = (contact: connect.Contact) => {
  const initialConnection = connectGetter(contact, 'getInitialConnection');

  return connectGetter(initialConnection, 'getState')?.type === connect.ConnectionStateType.DISCONNECTED;
};

export const isAnyConnectionConnecting = (contact: connect.Contact) => {
  const conferenceStates = getConferenceConnectionStates(contact);

  return conferenceStates.some((connectionState) => connectionState === connect.ConnectionStateType.CONNECTING);
};

export const isAnyConnectionOnHold = (contact: connect.Contact) => {
  const conferenceStates = getConferenceConnectionStates(contact, { includeAgent: true });

  return conferenceStates.some((connectionState) => connectionState === connect.ConnectionStateType.HOLD);
};
