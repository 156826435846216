import { ReactNode, useEffect, useRef, useState, cloneElement, ReactElement } from 'react';
import { createPortal } from 'react-dom';
import { useBooleanState } from 'webrix/hooks';
import cx from 'classnames';
import Draggable from 'react-draggable';
import { faXmark, faWindowRestore } from '@fortawesome/pro-regular-svg-icons';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';
import VARS from 'css/export-vars.module.scss';
import parseJSON from '../../utils/parseJSON';
import ClickableIcon from '../ClickableIcon';
import Text from '../Text';
import Loader from '../Loader';
import DraggableOverlaySize from './DraggableOverlaySize';

import styles from './styles/draggable-overlay.module.scss';

const ASSUMED_MIN_HEIGHT = 300;

interface DraggableOverlayProps {
  id: string;
  open: boolean;
  title?: string;
  size?: DraggableOverlaySize;
  height?: string;
  className?: string;
  onClose: () => void;
  triggerSelector: string;
  children: ReactNode;
  canShowLoading?: boolean;
}

function getInitialPosition({
  triggerSelector,
  size = DraggableOverlaySize.SMALL,
  ignoreStored = false,
  storedPosition
}) {
  const width =
    size === DraggableOverlaySize.SMALL ? parseInt(VARS.overlayWidthSmall, 10) : parseInt(VARS.overlayWidthLarge, 10);

  const fallback = { x: document.body.getBoundingClientRect().width / 2 - width / 2, y: 75 };

  if (
    !ignoreStored &&
    storedPosition &&
    storedPosition.x &&
    storedPosition.y &&
    storedPosition.x + width < document.body.getBoundingClientRect().width &&
    storedPosition.y + ASSUMED_MIN_HEIGHT < document.body.getBoundingClientRect().height
  ) {
    return storedPosition;
  }

  const trigger = document.querySelector(triggerSelector);

  if (!trigger) {
    return fallback;
  }

  const { x: triggerX, width: triggerWidth } = trigger.getBoundingClientRect();
  const xPos = triggerX + triggerWidth / 2;

  return { x: xPos - width / 2, y: 75 };
}

export default function DraggableOverlay({
  id,
  triggerSelector,
  size = DraggableOverlaySize.SMALL,
  className,
  onClose,
  title,
  children,
  open,
  canShowLoading = false
}: DraggableOverlayProps) {
  const draggableRef = useRef(null);

  const { getStorageItem, setStorageItem } = useLocalStorage();

  const storedPosition = parseJSON(getStorageItem(id) || '');
  const [position, setPosition] = useState(getInitialPosition({ triggerSelector, size, storedPosition }));
  const [hasMaxHeight, setHasMaxHeight] = useState(false);
  const { value: isLoaderLoading, setTrue: setLoaderLoading, setFalse: setLoaderNotLoading } = useBooleanState();

  const onFinishMove = (_: any, { x, y }) => {
    setStorageItem(id, JSON.stringify({ x, y }));
  };

  const onMove = (_: any, coords) => {
    setPosition(coords);
  };

  const onCloseClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    onClose();
  };

  const onResetPosition = () => {
    const initialPosition = getInitialPosition({ triggerSelector, size, ignoreStored: true, storedPosition });

    setPosition(initialPosition);

    onFinishMove(null, initialPosition);
  };

  useEffect(() => {
    setPosition(getInitialPosition({ triggerSelector, size, storedPosition }));

    const { height } = document.body.getBoundingClientRect();

    // 600 is the smallest height for desktops
    if (height <= 600) {
      return void setHasMaxHeight(true);
    }

    setHasMaxHeight(false);
  }, [open]);

  return createPortal(
    <div data-testid="draggable-overlay-testid">
      <Draggable
        ref={draggableRef}
        handle=".draggable-overlay__header"
        position={position}
        bounds="body"
        onStop={onFinishMove}
        onDrag={onMove}
      >
        <div
          className={cx(styles['draggable-overlay'], className, {
            [styles['draggable-overlay--hidden']]: !open,
            [styles['draggable-overlay--large']]: size !== DraggableOverlaySize.SMALL
          })}
          data-testid="draggable-overlay-container"
        >
          <div
            // need to add a selector here for the drag handle to work
            className={cx([styles['draggable-overlay__header']], 'draggable-overlay__header')}
            data-testid="draggable-overlay-drag-handle"
          >
            <div className={styles['draggable-overlay__header__title']}>
              <Text type="heading2" data-testid="draggable-overlay-drag-title">
                {title}
              </Text>
              <Loader
                size={20}
                minimised
                className={cx(styles['draggable-overlay__header__title__loader'], {
                  [styles['draggable-overlay__header__title__loader--visible']]: isLoaderLoading
                })}
              />
            </div>
            <ClickableIcon
              onClick={onResetPosition}
              icon={faWindowRestore}
              className={styles['draggable-overlay__header__reset']}
              tooltip="Reset window position"
              size={15}
            />
            <ClickableIcon
              aria-label="Close"
              onClick={onCloseClick}
              icon={faXmark}
              data-testid="draggable-overlay-drag-close"
              size={20}
            />
          </div>
          <div
            className={cx(styles['draggable-overlay__content'], {
              [styles['draggable-overlay__content--large']]: size == DraggableOverlaySize.LARGE,
              [styles['draggable-overlay__content--max-height']]: hasMaxHeight
            })}
          >
            {canShowLoading
              ? cloneElement(children as ReactElement, { setLoaderLoading, setLoaderNotLoading })
              : children}
          </div>
        </div>
      </Draggable>
    </div>,
    document.body
  );
}
