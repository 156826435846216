import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faThumbtackSlash: IconDefinition = {
  prefix: 'far' as IconPrefix,
  iconName: 'thumbtack-slash' as IconName,
  icon: [
    19,
    15,
    [],
    'f0000',
    'M7.74734 8.90538L10.0764 10.7309V14.2926C10.0764 14.682 9.76316 14.9953 9.37376 14.9953C8.98435 14.9953 8.67108 14.682 8.67108 14.2926V10.3107H4.68921C4.39936 10.3107 4.12707 10.179 3.9514 9.95061C3.77573 9.72224 3.71132 9.42653 3.78158 9.14546L3.96311 8.4135C4.13 7.74478 4.42922 7.13813 4.82829 6.61756L5.93472 7.48479C5.65423 7.85457 5.4443 8.2838 5.32748 8.75635L5.28942 8.90567H7.74734V8.90538ZM17.6054 14.846L0.272569 1.26079C-0.449436 0.672586 0.410763 -0.399589 1.13921 0.154065L6.09077 4.03492L6.18534 2.81547L6.29367 1.41011H5.3919C5.26307 1.41011 5.1401 1.37497 5.0347 1.31349C4.82975 1.19052 4.68921 0.965077 4.68921 0.707427C4.68921 0.318024 5.00249 0.00474549 5.3919 0.00474549H13.3556C13.745 0.00474549 14.0583 0.318024 14.0583 0.707427C14.0583 0.965077 13.9178 1.19052 13.7128 1.31349C13.6074 1.37497 13.4874 1.41011 13.3556 1.41011H12.4538L12.5622 2.81547L12.7701 5.53543C13.745 6.19712 14.4799 7.20723 14.7815 8.4135L14.963 9.14546C15.0333 9.42653 14.9689 9.72224 14.7932 9.95061C14.626 10.1682 14.3684 10.2976 14.0964 10.3096L18.472 13.7392C19.1943 14.3271 18.3335 15.3996 17.6054 14.846ZM13.4581 8.90538L13.42 8.75606C13.2034 7.89234 12.6822 7.1721 11.9825 6.70071C11.6282 6.46063 11.4027 6.07123 11.3705 5.64669L11.0543 1.51844C11.0514 1.4833 11.0514 1.44524 11.0514 1.41011H7.69903C7.69903 1.44524 7.69903 1.4833 7.6961 1.51844H7.69318L7.4203 5.07723L8.71236 6.08997C8.80986 5.81885 9.06809 5.6262 9.37346 5.6262C9.76287 5.6262 10.0761 5.93948 10.0761 6.32888V7.15892L12.3045 8.90538H13.4578H13.4581Z'
  ]
};

export default faThumbtackSlash;
