import ClickableIcon from 'lib/common/components/ClickableIcon';
import ReactSelect from 'lib/common/components/ReactSelect';
import { faFilter } from '@fortawesome/pro-regular-svg-icons';
import Popover from 'lib/common/components/molecules/Popover';
import Text from 'lib/common/components/Text';
import COLOURS from 'css/export-vars.module.scss';

import './tasks-filter-menu.scss';

interface ITasksFilterMenuProps {
  selectedFilter: { label: string; value: string | null };
  filterOptions: { label: string; value: string | null }[];
  onFilter: (value: any) => void;
}

const SELECTED_COLOUR = COLOURS.blue;
const PRIMARY_COLOUR = COLOURS.darkBlue;

const TasksFilterMenu = ({ selectedFilter, filterOptions, onFilter }: ITasksFilterMenuProps) => {
  const onSelectItem = (onClose) => (option) => {
    onFilter(option);
    onClose();
  };

  return (
    <Popover
      anchor={
        <div className="tasks-filter-menu">
          <ClickableIcon
            icon={faFilter}
            tooltip="Filter tasks"
            className="tasks-filter-menu__icon"
            style={{ color: selectedFilter.value ? SELECTED_COLOUR : PRIMARY_COLOUR }}
          />
          <span
            className="tasks-filter-menu__dot"
            style={{ display: selectedFilter.value ? 'inherit' : 'none' }}
          ></span>
        </div>
      }
      reverse={false}
      offset={20}
    >
      {({ onClose }) => (
        <div className="tasks-filter-menu__body">
          <Text type="heading4" className="tasks-filter-menu__body__header">
            Filter Tasks
          </Text>
          <ReactSelect
            value={selectedFilter}
            options={filterOptions}
            onChange={onSelectItem(onClose)}
            isSearchable={false}
            aria-label="Filter tasks"
            placeholder="All Tasks"
          />
        </div>
      )}
    </Popover>
  );
};

export default TasksFilterMenu;
