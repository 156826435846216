import cx from 'classnames';

import HeadingLabel from 'lib/common/components/HeadingLabel';
import Modal from 'lib/common/components/Modal';
import useCreateAgentTask from 'lib/common/components/molecules/CreateAgentTask/useCreateAgentTask';

export default function CreateAgentTaskModal({
  setCreateAgentTaskOpen = (_: boolean) => {},
  name: defaultName = '',
  description: defaultDescription = '',
  open = false
}) {
  const closeModal = () => {
    setCreateAgentTaskOpen(false);
  };

  const { blocks, saveDisabled, createTask } = useCreateAgentTask({
    defaultName,
    defaultDescription,
    open
  });

  return (
    <Modal
      small
      saveDisabled={saveDisabled}
      title="Add A New Agent Task"
      onClose={closeModal}
      open={open}
      onSave={createTask}
      onSuccess={closeModal}
    >
      {blocks.map(({ content, className, ...block }, index) => (
        <div className={cx(className, { 'mt-40': index !== 0 })} key={index}>
          <HeadingLabel smallTitle {...block} primary={block.title} secondary={block.summary} />
          {content}
        </div>
      ))}
    </Modal>
  );
}
