import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import Avatar from 'lib/common/components/Avatar';
import { Information } from 'lib/common/components/Profile';
import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';
import Text from 'lib/common/components/Text';
import getUserName from 'lib/common/utils/getUserName';
import TProfile from 'lib/common/types/CustomerProfile';

type TSummary = {
  profile?: TProfile | null;
  currentContactIsViewed: boolean;
};

export default function Summary({ profile }: TSummary) {
  if (!profile) {
    return null;
  }

  const name = getUserName({ ...profile, fallback: 'Customer' });

  return (
    <>
      <div className="customer-profile__content__profile__summary">
        <Avatar
          size={60}
          name={name}
          fallbackIcon={profile?.partyType === PROFILE_TYPES.INDIVIDUAL ? void 0 : faBuilding}
        />
        <Text type="heading1" htmlElement="h3" className="ml-10">
          {name}
        </Text>
      </div>
      <div className="customer-profile__content__profile__info">
        <Information profile={profile} />
      </div>
    </>
  );
}
