/**
 * @param attributeKey
 * @returns string
 * Example Returns
 * "yourStringHere" -> "Your String Here"
 * "YourStringHere" -> "Your String Here"
 * "your_string_here" -> "Your String Here"
 * "Your-String-Here" -> "Your String Here"
 * "yourSTRINGHere" -> "Your STRING Here"
 */

export default function normaliseAttributeKey(attributeKey: string) {
  return attributeKey
    .replace(/(_|-)/g, ' ')
    .trim()
    .replace(/\w\S*/g, (str) => `${str.charAt(0).toUpperCase()}${str.substring(1)}`)
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2');
}
