import cx from 'classnames';
import md5 from 'js-md5';
import { ReactNode } from 'react';
import { faCircleInfo, faUser, IconDefinition } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import getNameInitials from 'lib/common/utils/getNameInitials';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import COLOURS from 'css/export-vars.module.scss';
import styles from './avatar.module.scss';

const BACKGROUND_BRIGHTNESS = 90;

const STATUS_DOT_SIZE_MULTIPLIER = 0.4;
const SIZE_MULTIPLIER = 2;

const AvatarSizes = {
  XLARGE: 75,
  LARGE: 60,
  MEDIUM: 40,
  SMALL: 30
} as const;

type TAvatarSizes = typeof AvatarSizes[keyof typeof AvatarSizes];

interface IAvatarProps {
  size?: TAvatarSizes;
  name?: string;
  fallbackIcon?: IconDefinition;
  statusColour?: string;
  tooltip?: ReactNode;
  onClick?: () => void;
  testId?: string;
  statusBackgroundColor?: string;
  iconHashString?: string;
  className?: string;
  ariaLabel?: string;
}

function stringToHslColour(string: string) {
  const hash = parseInt(md5(string), 16) % 360;

  return `hsl(${hash}, 50%, ${BACKGROUND_BRIGHTNESS}%)`;
}

function Avatar({
  size = AvatarSizes.MEDIUM,
  fallbackIcon = faUser,
  name,
  iconHashString,
  statusColour,
  tooltip,
  onClick,
  testId,
  statusBackgroundColor,
  className,
  ariaLabel
}: IAvatarProps) {
  const dotSize = size * STATUS_DOT_SIZE_MULTIPLIER;

  const content = (
    <>
      {!name ? (
        <Icon icon={fallbackIcon} size={size / SIZE_MULTIPLIER} />
      ) : (
        <Text
          inline
          className={cx(styles['avatar__initials'], {
            [styles['avatar__initials--small']]: size === AvatarSizes.SMALL,
            [styles['avatar__initials--medium']]: size === AvatarSizes.MEDIUM,
            [styles['avatar__initials--large']]: size === AvatarSizes.LARGE,
            [styles['avatar__initials--xlarge']]: size === AvatarSizes.XLARGE
          })}
        >
          {getNameInitials(name)}
        </Text>
      )}

      {tooltip && (
        <Tooltip
          ariaHidden
          title={tooltip}
          classes={{ arrow: styles['avatar__tooltip__arrow'] }}
          arrow
          placement="left"
        >
          <div
            className={styles['avatar__tooltip']}
            style={{
              height: `${dotSize}px`,
              width: `${dotSize}px`
            }}
          >
            <Icon icon={faCircleInfo} />
          </div>
        </Tooltip>
      )}

      {statusColour && (
        <div
          className={cx(styles['avatar__status-dot'], {
            [styles['avatar__status-dot--small']]: size === AvatarSizes.SMALL,
            [styles['avatar__status-dot--outline']]: statusColour === COLOURS.white
          })}
          data-testid="avatar-profile-status"
          style={{
            background: `${statusColour}`,
            height: `${dotSize}px`,
            width: `${dotSize}px`,
            borderColor: statusBackgroundColor || void 0
          }}
        />
      )}
    </>
  );

  const props = {
    style: {
      backgroundColor: stringToHslColour(name || iconHashString || '')
    },
    className: cx(className, styles['avatar'], {
      [styles['avatar--small']]: size === AvatarSizes.SMALL,
      [styles['avatar--medium']]: size === AvatarSizes.MEDIUM,
      [styles['avatar--large']]: size === AvatarSizes.LARGE,
      [styles['avatar--xlarge']]: size === AvatarSizes.XLARGE,
      [styles['avatar--clickable']]: Boolean(onClick)
    }),
    'data-testid': testId ?? 'avatar'
  };

  if (onClick) {
    return (
      <button onClick={onClick} aria-label={ariaLabel || `${name} avatar`} {...props}>
        {content}
      </button>
    );
  }

  return <div {...props}>{content}</div>;
}

Avatar.Sizes = AvatarSizes;

export default Avatar;
