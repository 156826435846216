import agentIsMonitoring from 'lib/common/utils/connect/agentIsMonitoring';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_TYPES from 'lib/common/constants/connectionType';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import Task from 'lib/common/types/Task';
import connectGetter from 'lib/common/utils/connectGetter';

import TCustomerProfile from '../../types/CustomerProfile';
import { TContactTypes } from '../../../../constants/contactTypes';

export default function updatedBaseTask({
  existingTask,
  updatedContact,
  taskProps,
  type,
  status,
  connectionState,
  profile
}: {
  existingTask: Task;
  updatedContact: connect.Contact;
  taskProps: Partial<Task>;
  type: TContactTypes;
  status?: ValueOf<typeof CONTACT_STATES>;
  connectionState?: ValueOf<typeof CONNECTION_STATES>;
  profile?: TCustomerProfile;
}) {
  const now = new Date();
  const ignoreAcceptedStatus = status === CONTACT_STATES.ACCEPTED && existingTask.status === CONTACT_STATES.CONNECTED;
  const updatedStatus = ignoreAcceptedStatus ? existingTask.status : status || existingTask.status;

  // Connect only sets this state when the contact is connected.
  const isMonitoring = agentIsMonitoring(updatedContact);
  // first time task is set to connected we set timestamp then never again
  const connectedAtTime =
    status === CONTACT_STATES.CONNECTED && !existingTask.connectedAtTime
      ? connectGetter(updatedContact, 'getState')?.timestamp || now
      : existingTask.connectedAtTime;

  return {
    ...existingTask,
    ...taskProps,
    contact: updatedContact,
    status: updatedStatus,
    connectionState: connectionState || existingTask.connectionState,
    // Profile is set to null in some cases
    profile: profile !== void 0 ? profile : existingTask.profile,
    type,
    connectionType: isMonitoring ? CONNECTION_TYPES.MONITORING : existingTask.connectionType,
    // When a task ends, connect resets the connections and we lose the connection timestamp, so need to make our own copy
    connectedAtTime,
    contactSnapshot: updatedContact.toSnapshot(),

    // Set our own time here, because the connect timestamp is unreliable AF (sometimes get negative times/no reset)
    time: (status && existingTask.status !== status) || existingTask.type !== type ? new Date() : existingTask.time
  };
}
