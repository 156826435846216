import { SEARCHABLE_FIELDS } from '../types/CustomerProfileForm';

/**
 * Potentially this should be exported from customerProfileSearchTypes
 * but "emailAddress" and "email" fieldnames are a mismatch causing it to be redefined here
 */
export const SEARCH_TYPES = {
  [SEARCHABLE_FIELDS.PHONE_NUMBER]: '_phone',
  [SEARCHABLE_FIELDS.EMAIL_ADDRESS]: '_email',
  [SEARCHABLE_FIELDS.ACCOUNT_NUMBER]: '_account'
};
