import TProfile from 'lib/common/types/CustomerProfile';
import InformationField from './InformationField';

const Information = ({ profile }: { profile?: TProfile | null }) => (
  <div className="profile-info">
    <InformationField label="Account Number" value={profile?.accountNumber} />
    <InformationField label="Email Address" value={profile?.emailAddress} />
    <InformationField label="Phone Number" value={profile?.phoneNumber} />
    <InformationField label="Address" value={profile?.address} />
  </div>
);

export default Information;
