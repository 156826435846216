export const useLocalStorage = () => {
  const getStorageItem = (key: string): string | null => {
    try {
      return localStorage.getItem(key);
    } catch {
      return null;
    }
  };

  const setStorageItem = (key: string, value: string): void => {
    try {
      return localStorage.setItem(key, value);
    } catch {
      return;
    }
  };

  const removeStorageItem = (key: string): void => {
    try {
      return localStorage.removeItem(key);
    } catch {
      return;
    }
  };

  return { getStorageItem, setStorageItem, removeStorageItem };
};
