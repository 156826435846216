import { useEffect, useState } from 'react';

import { useLocation } from 'react-router-dom';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import { TPermissions } from '@cloud-wave/neon-common-lib';
import cx from 'classnames';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import Icon from 'lib/common/components/Icon';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import { TConnectPermissions, TPermissionTypes } from 'lib/common/types/Permissions';

import SidebarMenuItemComponent from './SidebarMenuComponent';

type SidebarMenuItemPropTypes = {
  name: string;
  link: string;
  icon: any;
  type: string;
  requiresAdmin?: boolean;
  activeClassName: string;
  permission: TPermissions | TConnectPermissions;
  pageHasSubMenu: boolean;
  permissionType: TPermissionTypes;
  isSidebarOpen: boolean;
  small?: boolean;
  external?: boolean;
};

type SidebarMenuItemProps = SidebarMenuItemPropTypes & {
  items?: SidebarMenuItemProps[];
};

const SidebarMenuItem: React.FC<SidebarMenuItemProps> = (props: SidebarMenuItemProps) => {
  const { pathname } = useLocation();
  const {
    name,
    link,
    external,
    icon,
    items = [],
    activeClassName,
    permission,
    pageHasSubMenu,
    permissionType,
    isSidebarOpen,
    requiresAdmin,
    small
  } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(false);
  const { hasAtLeastOnePermission, isAdmin } = usePermissionsContext();

  const hasSubMenuActive = items.some(({ link }) => link === pathname);

  useEffect(() => {
    if (!hasSubMenuActive) {
      return;
    }

    setOpen(true);
  }, []);

  useEffect(() => {
    if (!hasSubMenuActive) {
      setOpen(false);
      return;
    }

    setOpen(true);
  }, [hasSubMenuActive, pathname]);

  const subMenuActiveWithSidebarOpen = hasSubMenuActive && isSidebarOpen;
  const subMenuActiveWithSidebarClosed = hasSubMenuActive && !isSidebarOpen;
  const permissions = Array.isArray(permission) ? permission : [permission].filter(Boolean);
  const satisfiesOptionalAdminRequirement = !requiresAdmin || (requiresAdmin && isAdmin);

  const MenuItemRoot = hasAtLeastOnePermission({ type: permissionType || 'tenant', permissionsToCheck: permissions }) &&
    satisfiesOptionalAdminRequirement &&
    icon && (
      <SidebarMenuItemComponent
        pageHasSubMenu={Boolean(pageHasSubMenu)}
        link={link}
        external={external}
        activeClassName={activeClassName}
        className={cx({
          'sidebar__submenu-active': subMenuActiveWithSidebarOpen,
          sidebar__closed: !isSidebarOpen
        })}
        testId={`sidebar-menu-item-${name.toLowerCase().replaceAll(' ', '-')}`}
      >
        <div
          className={cx('sidebar__menu-items', {
            'sidebar__menu-items__active': subMenuActiveWithSidebarOpen,
            'sidebar__menu-items__active__sidebar-closed': subMenuActiveWithSidebarClosed
          })}
        >
          <Tooltip title={name} aria-label={name} placement="bottom-end" enterDelay={1000} ariaHidden={false}>
            <ListItemIcon data-testid={`list-icon${link}`}>
              <Icon icon={icon} size={small ? 18 : void 0} />
            </ListItemIcon>
          </Tooltip>
          <ListItemText className="nav-text" primary={name} />
        </div>
      </SidebarMenuItemComponent>
    );

  const MenuItemChildren = isExpandable ? (
    <Collapse
      in={open}
      timeout="auto"
      unmountOnExit
      className={cx({
        'sidebar__collapse-active': subMenuActiveWithSidebarOpen,
        'sidebar__collapse-active__sidebar-closed': subMenuActiveWithSidebarClosed
      })}
    >
      {subMenuActiveWithSidebarClosed && <span className="sidebar__collapse-active__menu-separator" />}
      {items.map((item, index) => (
        <SidebarMenuItem {...item} small key={index} isSidebarOpen={isSidebarOpen} />
      ))}
    </Collapse>
  ) : null;

  return (
    <>
      <List
        component="div"
        disablePadding
        className={cx('sidebar__parent-menu', {
          'sidebar__parent-menu__submenu-active__sidebar-closed': subMenuActiveWithSidebarClosed
        })}
      >
        {MenuItemRoot}
      </List>
      {MenuItemChildren}
    </>
  );
};

export default SidebarMenuItem;
