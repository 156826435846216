import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { useConfigContext } from 'lib/core/config';

export const useMonitorLayoutState = (isSoftphone: boolean) => {
  const [layoutState, setLayoutState] = useState<'softphone' | 'desktop' | null>(null);

  const {
    config: { TENANT_ID }
  } = useConfigContext();

  useEffect(() => {
    const oldState = layoutState;
    const newState = isSoftphone ? 'softphone' : 'desktop';
    setLayoutState(newState);

    if (!oldState || oldState == newState) {
      return;
    }

    try {
      Sentry.metrics.increment(`layout_change`, 1, {
        tags: {
          tenant: TENANT_ID,
          layoutChange: `${oldState}_to_${newState}`
        }
      });
    } catch {}
  }, [isSoftphone]);
};
