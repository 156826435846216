export enum ContactAttributes {
  INTENTS = 'intents',
  TRANSCRIPT = 'transcript',
  PHONE_NUMBER = 'phonenumber',
  OUTBOUND_CAMPAIGN = 'cwneonoutboundcampaign',
  OUTBOUND_CAMPAIGN_DESCRIPTION = 'cwneonoutboundcampaigndescription'
}

//When nothing is set, this means we want to display this in both incomming and active
export const DYNAMIC_CW_NEON = /^CW-NEON(.[^_]*)?_/;
export const DYNAMIC_CW_NEON_ACTIVE = /^CW-NEON-ACTIVE(.[^_]*)?_/;
export const DYNAMIC_CW_NEON_INCOMING = /^CW-NEON-INC(.[^_]*)?_/;

export const OUTBOUND_CAMPAIGN_ATTRIBUTE = 'CW-NEON-OUTBOUND-CAMPAIGN';
export const OUTBOUND_CAMPAIGN_DESCRIPTION_ATTRIBUTE = 'CW-NEON-OUTBOUND-CAMPAIGN-DESCRIPTION';
export const OUTBOUND_CAMPAIGN_QUEUE_ARN = 'CW-NEON-OUTBOUND-CAMPAIGN-QUEUE-ARN';
export const AVAILABLE_TRANSFER_QUEUE_ARNS = 'NEONNOW_AVAILABLE_TRANSFER_QUEUE_ARNS';
