import ParticipantRole from 'lib/common/constants/chat/ParticipantRole';
import chatEvents from 'lib/common/constants/chatEvents';
import filterChatTranscript from 'lib/common/utils/chat/filterChatTranscript';
import connectGetter from 'lib/common/utils/connectGetter';
import _sortBy from 'lodash.sortby';
import _findLastIndex from 'lodash.findlastindex';
import getUniqueAndSortedChatMessages from './getUniqueAndSortedChatMessages';

const getTranscripts = async ({
  chatSession,
  nextToken,
  retrievedMessages = []
}: {
  chatSession: connect.ChatSession;
  nextToken?: string | boolean;
  retrievedMessages?: connect.ChatTranscriptItem[];
}) => {
  const transcriptResponse = await connectGetter(chatSession, 'getTranscript', {
    maxResults: 100,
    sortOrder: 'ASCENDING',
    metadata: undefined,
    ...(nextToken && typeof nextToken === 'string' ? { nextToken } : {})
  });

  // Sometimes this connect API returns duplicates. Ensure there are no duplicates in retrieved history
  const newMessages = getUniqueAndSortedChatMessages([
    ...(transcriptResponse?.data?.Transcript ?? []),
    ...retrievedMessages
  ]);

  const joinedEventIndex = _findLastIndex(
    newMessages,
    ({ ContentType: contentType, ParticipantRole: participantRole }) =>
      contentType === chatEvents.PARTICIPANT_JOINED && participantRole === ParticipantRole.CUSTOMER
  );

  if (joinedEventIndex >= 0) {
    return {
      messages: [
        newMessages[joinedEventIndex],
        ...filterChatTranscript(newMessages.slice(joinedEventIndex, newMessages.length + 1))
      ],
      nextToken: Boolean(transcriptResponse?.data?.NextToken || joinedEventIndex > 0) ? nextToken || true : false
    };
  }

  return getTranscripts({
    chatSession,
    retrievedMessages: filterChatTranscript(newMessages),
    nextToken: transcriptResponse?.data?.NextToken
  });
};

export default async function getInitialChatMessages(chatSession?: connect.ChatSession) {
  if (!chatSession) {
    return {
      messages: [],
      nextToken: void 0
    };
  }

  try {
    return getTranscripts({ chatSession });
  } catch {
    return {
      messages: [],
      nextToken: void 0
    };
  }
}
