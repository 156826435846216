import { useEffect, useState, createRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useHotkeys } from 'react-hotkeys-hook';
import AnnounceKit from 'announcekit-react';
import {
  faBars,
  faBolt,
  faChartLine,
  faAddressBook,
  faRectangleVerticalHistory
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { getFormattedStackVersion } from '@cloud-wave/neon-common-lib';

import getUserName from 'lib/common/utils/getUserName';
import { useDesktopLayoutContext } from 'lib/common/contexts/layout/DesktopLayoutContext';
import { GLOBAL_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { useOverlayContext } from 'lib/common/contexts/OverlayContext';
import { OverlayType } from 'lib/common/contexts/OverlayContext/Context';
import { DIRECTORY_TAB } from 'lib/common/components/molecules/Directory/constants/directoryTabs';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import DraggableOverlay, { DraggableOverlaySize } from 'lib/common/components/DraggableOverlay';
import UserProfile from 'lib/common/components/UserProfile';
import Drawer from 'lib/common/components/Drawer';

import AgentStats from 'lib/common/components/molecules/AgentStats';
import AgentStatusTimer from 'lib/common/components/molecules/AgentStatusTimer';
import Preferences from 'lib/common/components/molecules/Preferences';
import Dialpad from 'lib/common/components/molecules/Dialpad';
import Directory from 'lib/common/components/molecules/Directory';
import ResponsiveLogo from 'lib/common/components/atoms/ResponsiveLogo';
import TUser from 'lib/common/types/User';
import { useConfigContext } from 'lib/core/config';
import { WidgetsState } from 'lib/common/contexts/ModulesContext/constants/WidgetsState';

import Tasks from './Tasks';

import '../styles/header.scss';

const AGENT_STATS_ID = 'agent-stats-overlay';

const overlayVisibleId = (id) => `${id}-visible`;

function Header({ user }: { user: TUser }) {
  const { toggleIsSidebarOpen, isSidebarOpen } = useDesktopLayoutContext();
  const history = useHistory();
  const [numberOfNewUpdates, setNumberOfNewUpdates] = useState(0);
  const { getStorageItem, setStorageItem, removeStorageItem } = useLocalStorage();

  function overlayWasOpen(id: string) {
    return Boolean(getStorageItem(overlayVisibleId(id)));
  }

  const [isAgentStatsOverlayOpen, setAgentStatsOverlayOpen] = useState(overlayWasOpen(AGENT_STATS_ID));
  const {
    state: {
      widgetModules,
      widgetsConfig: { widgetsState }
    },
    actions: { handleWidgetsConfigChange }
  } = useModulesContext();
  const { config } = useConfigContext();

  const { overlayProps, currentOverlay, overlayOpen, openOverlay, closeOverlay } = useOverlayContext();

  const openDirectory = () => openOverlay(OverlayType.DIRECTORY);
  const openPersonalDirectory = () => openOverlay(OverlayType.DIRECTORY, { initialTab: DIRECTORY_TAB.PERSONAL });
  const openOrganisationDirectory = () =>
    openOverlay(OverlayType.DIRECTORY, { initialTab: DIRECTORY_TAB.ORGANISATION });

  const openDialpad = () => openOverlay(OverlayType.DIALPAD);

  useHotkeys(GLOBAL_HOTKEYS.DIRECTORY_AGENTS, openDirectory, [openDirectory]);
  useHotkeys(GLOBAL_HOTKEYS.DIRECTORY_PERSONAL, openPersonalDirectory, [openDirectory, openPersonalDirectory]);
  useHotkeys(GLOBAL_HOTKEYS.DIRECTORY_ORGANISATION, openOrganisationDirectory, [
    openDirectory,
    openOrganisationDirectory
  ]);

  useHotkeys(GLOBAL_HOTKEYS.DIAL_PAD, openDialpad, [openDialpad]);

  const whatsNewRef = createRef<AnnounceKit>();

  const onCloseWhatsNew = async () => {
    setNumberOfNewUpdates(0);
  };

  const onToggleOverlayOpen = (id, setVisibleFn, isOpen) => () => {
    if (isOpen) {
      return void onCloseOverlay(id, setVisibleFn)();
    }

    setStorageItem(overlayVisibleId(id), 'true');

    setVisibleFn(true);
  };

  const onCloseOverlay = (id, closeFn) => () => {
    removeStorageItem(overlayVisibleId(id));

    closeFn(false);
  };

  useEffect(() => {
    if (!whatsNewRef) {
      return;
    }

    (async () => {
      setNumberOfNewUpdates(((await whatsNewRef?.current?.unread()) as number) || 0);
    })();
  }, [whatsNewRef]);

  const defaultDirectoryTitle =
    currentOverlay === OverlayType.DIRECTORY && overlayProps?.transferTo ? 'Directory Transfer' : 'Directory';

  return (
    <div className="header">
      <AnnounceKit
        onWidgetClose={onCloseWhatsNew}
        ref={whatsNewRef}
        widget="https://announcekit.app/widgets/v2/1sV8Bi"
        user={{
          id: user?.username,
          name: getUserName(user),
          email: user?.email,
          role: user?.role,
          organisation: user?.organization
        }}
      />
      <div className="header__burger">
        <ClickableIcon
          icon={faBars}
          className="header__burger__icon"
          onClick={toggleIsSidebarOpen}
          tooltip={`${isSidebarOpen ? 'Minimise' : 'Expand'} the navigation menu`}
        />
      </div>
      <ResponsiveLogo
        tooltip={`${config.BRAND.productName} version ${getFormattedStackVersion(config.STACK_VERSION).presentation}`}
      />
      <AgentStatusTimer />
      <Tasks />

      <ClickableIcon
        icon={faBolt}
        className={cx('header__whats-new', { 'header__whats-new--unread': Boolean(numberOfNewUpdates) })}
        onClick={() => whatsNewRef?.current?.open()}
        tooltip="What's New"
      />
      <hr className="header__line" />
      {Boolean(widgetModules?.length) && widgetsState === WidgetsState.CLOSED && (
        <ClickableIcon
          className={cx('header__widget-icon', 'ml-25')}
          icon={faRectangleVerticalHistory}
          onClick={() => handleWidgetsConfigChange({ widgetsState: WidgetsState.OPEN })}
          tooltip="Widgets"
        />
      )}
      <ClickableIcon
        className={cx('header__agent-stats-icon', 'ml-25')}
        icon={faChartLine}
        onClick={onToggleOverlayOpen(AGENT_STATS_ID, setAgentStatsOverlayOpen, isAgentStatsOverlayOpen)}
        tooltip="Your Queues"
      />
      <ClickableIcon
        className={cx('header__directory-icon', 'ml-25')}
        icon={faAddressBook}
        onClick={openDirectory}
        tooltip="Directory"
      />
      <ClickableIcon className="ml-25" icon="dialpad" onClick={openDialpad} tooltip="Dialpad" />
      <hr className={cx('header__line', 'mr-0')} />

      <UserProfile user={user} />
      {isAgentStatsOverlayOpen && (
        <DraggableOverlay
          title="Your Queues"
          canShowLoading
          open={isAgentStatsOverlayOpen}
          id={AGENT_STATS_ID}
          onClose={onCloseOverlay(AGENT_STATS_ID, setAgentStatsOverlayOpen)}
          size={DraggableOverlaySize.LARGE}
          triggerSelector=".header__agent-stats-icon"
        >
          <AgentStats />
        </DraggableOverlay>
      )}

      <Drawer
        title={defaultDirectoryTitle}
        open={overlayOpen && currentOverlay === OverlayType.DIRECTORY}
        onClose={closeOverlay}
        headerContent={
          currentOverlay === OverlayType.DIRECTORY && overlayProps?.transferTo ? undefined : (
            <ClickableIcon icon="dialpad" onClick={openDialpad} size={20} tooltip="Switch to dialpad" />
          )
        }
        testId="drawer-slide-out-menu"
      >
        {({ setTitle, resetTitle }) => (
          <Directory
            setTitle={setTitle}
            resetTitle={resetTitle}
            transferTo={currentOverlay === OverlayType.DIRECTORY ? overlayProps?.transferTo : undefined}
            initialSelectedTab={(currentOverlay === OverlayType.DIRECTORY && overlayProps?.initialTab) || undefined}
          />
        )}
      </Drawer>
      <Drawer
        onClose={closeOverlay}
        open={overlayOpen && currentOverlay === OverlayType.PREFERENCES}
        title="Preferences"
      >
        <Preferences />
      </Drawer>
      <Drawer
        onClose={closeOverlay}
        open={overlayOpen && currentOverlay === OverlayType.DIALPAD}
        title={currentOverlay === OverlayType.DIALPAD && overlayProps?.isDialpadTransfer ? 'Dialpad' : 'Call'}
        headerContent={
          currentOverlay === OverlayType.DIALPAD && overlayProps?.isDialpadTransfer ? undefined : (
            <ClickableIcon icon={faAddressBook} onClick={openDirectory} size={20} tooltip="Switch to directory" />
          )
        }
        testId="drawer-slide-out-menu"
      >
        <Dialpad
          closeModal={closeOverlay}
          pushToTask={history.push}
          isDialpadTransfer={currentOverlay === OverlayType.DIALPAD && Boolean(overlayProps?.isDialpadTransfer)}
        />
      </Drawer>
    </div>
  );
}

export default Header;
