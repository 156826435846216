import { TTemplateType } from '@cloud-wave/neon-common-lib';

import { TConfig } from 'lib/common/types/Config';

export async function getTemplatedResponses({
  config,
  queueIds,
  type,
  fetch_
}: {
  config: TConfig;
  queueIds: string[];
  type: TTemplateType;
  fetch_: (url, options?, tokens_?, throwError?) => Promise<any>;
}) {
  const templateEndpoint = `${config.AGENT_SERVICE_V2_URL}/user/templated-responses?queueIds=${queueIds.join(
    ','
  )}&type=${type}`;
  try {
    const response = await fetch_(templateEndpoint);

    return (await response.json()).data;
  } catch (e) {
    return [];
  }
}
