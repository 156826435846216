import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';

import TabsPlaceholderDetails from '../constants/TabsPlaceholderDetails';
import { DIRECTORY_TAB } from '../constants/directoryTabs';

const TabsPlaceholder = ({
  type,
  isAdmin,
  createContact
}: {
  type: DIRECTORY_TAB;
  isAdmin: boolean;
  createContact?: () => void;
}) => (
  <EmptyPlaceholder
    text={TabsPlaceholderDetails[type].text}
    subText={type === DIRECTORY_TAB.ORGANISATION && !isAdmin ? 'Your Administrator can add new ones.' : undefined}
    callToAction={
      createContact &&
      (!((type === DIRECTORY_TAB.ORGANISATION && !isAdmin) || type === DIRECTORY_TAB.AGENTS)
        ? { text: 'Create A New Contact', onClick: () => createContact() }
        : undefined)
    }
    icon={TabsPlaceholderDetails[type].icon}
  />
);

export default TabsPlaceholder;
