import TTask from '../types/Task';
import connectGetter from './connectGetter';

type AttributesSource =
  | {
      task?: TTask;
    }
  | {
      attributes: connect.AttributeDictionary;
    };

type ContactAttributes<T extends string> = Record<T, string | undefined>;

function getAttributeValues<T extends string>(
  attributeNames: string[],
  attributes: connect.AttributeDictionary = {}
): ContactAttributes<T> {
  return attributeNames.reduce(
    (attributeValues, attributeName) => ({
      ...attributeValues,
      [attributeName]: attributes?.[attributeName]?.value
    }),
    {} as ContactAttributes<T>
  );
}

export default function getContactAttributes<T extends string>(
  attributesSource: AttributesSource,
  ...attributeNames: T[]
): ContactAttributes<T> {
  if ('attributes' in attributesSource) {
    return getAttributeValues(attributeNames, attributesSource.attributes);
  }

  if (!attributesSource.task?.contact?.getAttributes) {
    return {} as ContactAttributes<T>;
  }

  return getAttributeValues(attributeNames, connectGetter(attributesSource.task?.contact, 'getAttributes'));
}
