export default {
  actions: {
    getSelectedTask: () => void 0,
    getSelectedTaskId: () => void 0,
    setSelectedTaskId: () => {},
    setLastDialedNumber: () => {},
    matchExistingCustomerProfile: () => {},
    deleteExistingCustomerProfile: () => {},
    rejectTask: () => Promise.resolve(),
    acceptTask: () => Promise.resolve(),
    endInitialConnection: () => Promise.resolve(),
    removeTask: () => Promise.resolve(),
    makeOutboundCall: () => Promise.resolve(),
    onTransfer: () => Promise.resolve(),
    onTransferToQueueOrAgent: () => Promise.resolve(),
    holdCall: () => Promise.resolve(),
    resumeCall: () => Promise.resolve(),
    hangupAgent: () => Promise.resolve(),
    onJoinConference: () => Promise.resolve(),
    sendMessage: () => {},
    sendAttachment: () => {},
    downloadAttachment: () => Promise.resolve(void 0),
    sendTypingEvent: () => {},
    getChatTranscripts: () => Promise.resolve(),
    getActiveCallTask: () => undefined,
    onHoldAll: () => Promise.resolve(),
    onSwapConferenceCall: () => Promise.resolve(),
    holdConferenceConnection: () => Promise.resolve(),
    resumeConferenceConnection: () => Promise.resolve(),
    endConferenceConnection: () => Promise.resolve(),
    muteConnection: () => Promise.resolve(),
    unmuteConnection: () => Promise.resolve(),
    setTaskContent: () => {},
    addDraftTask: () => {},
    removeDraftTask: () => {}
  },
  state: {
    tasks: [],
    selectedTaskId: void 0,
    onActiveCall: false,
    lastDialedNumber: '',
    hasConnectingTask: false,
    hasConnectingInboundTask: false,
    hasConnectingVoiceTask: false,
    hasConnectingInboundVoiceTask: false,
    isCallInACW: false
  }
};
