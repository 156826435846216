import { faBold } from '@fortawesome/pro-regular-svg-icons';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_TEXT_COMMAND } from 'lexical';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import cx from 'classnames';
import { useState } from 'react';
import styles from './text-formatter.module.scss';

export function BoldButton({ size, className }: { size?: number; className?: string }) {
  const [editor] = useLexicalComposerContext();
  const [bold, setBold] = useState(false);

  const ToggleBold = () => {
    setBold(!bold);
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
  };

  return (
    <ClickableIcon
      className={cx(styles['toolbar__action'], className, { [styles['toolbar__action--active']]: bold })}
      onClick={ToggleBold}
      aria-label="Format Bold"
      icon={faBold}
      color="grey"
      size={size || 17}
    />
  );
}
