import { faLayerGroup } from '@fortawesome/pro-regular-svg-icons';
import LayoutColumnsIcon from 'lib/common/components/LayoutColumnsIcon';
import IncomingTaskPlacementIcon from 'lib/common/components/molecules/Preferences/components/IncomingTaskPlacementIcon';
import PreferenceSectionContainer from 'lib/common/components/molecules/Preferences/components/PreferenceSectionContainer';
import SegmentedControls from 'lib/common/components/SegmentedControls/UncontrolledSegmentedControls';
import Text from 'lib/common/components/Text';
import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import WORKSPACE_LAYOUT from 'lib/common/constants/workspaceLayouts';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';

interface IPreferencesAudioDevices {}

const PreferencesWorkspace = ({}: IPreferencesAudioDevices) => {
  const { isSoftphone } = useLayout();

  const {
    state: { defaultWorkspaceLayout, incomingTaskPlacement },
    actions: { setDefaultWorkspaceLayout, setIncomingTaskPlacement }
  } = usePreferencesContext();

  if (isSoftphone) {
    return null;
  }

  return (
    <PreferenceSectionContainer icon={faLayerGroup} title={'Workspace'}>
      <div className="preferences__workspace-container">
        <div>
          <Text type="heading3" data-testid="widget-item-title" className={'mb-10'}>
            Default Workspace Layout
          </Text>
          <SegmentedControls
            ariaLabel="Default workspace layout"
            square
            options={[
              {
                icon: <LayoutColumnsIcon />,
                value: WORKSPACE_LAYOUT.THREE_COLUMNS
              },
              {
                icon: <LayoutColumnsIcon twoColumns />,
                value: WORKSPACE_LAYOUT.TWO_COLUMNS
              }
            ]}
            initialIndices={defaultWorkspaceLayout === WORKSPACE_LAYOUT.TWO_COLUMNS ? 1 : 0}
            onSelect={setDefaultWorkspaceLayout}
          />
        </div>
        <div>
          <Text type="heading3" data-testid="widget-item-title" className={'mb-10'}>
            Incoming Task Placement
          </Text>
          <SegmentedControls
            ariaLabel="Incoming task placement"
            square
            options={[
              {
                icon: <IncomingTaskPlacementIcon />,
                value: INCOMING_TASK_PLACEMENTS.BOTTOM
              },
              {
                icon: <IncomingTaskPlacementIcon top />,
                value: INCOMING_TASK_PLACEMENTS.TOP
              }
            ]}
            initialIndices={incomingTaskPlacement === INCOMING_TASK_PLACEMENTS.TOP ? 1 : 0}
            onSelect={setIncomingTaskPlacement}
          />
        </div>
      </div>
    </PreferenceSectionContainer>
  );
};

export default PreferencesWorkspace;
