export const HELP_LINKS = {
  AGENT_GUIDANCE: `/docs/category/agent`,
  REQUIREMENTS: {
    MICROPHONE_ACCESS: '/docs/agent/requirements#microphone',
    STORAGE_ACCESS: '/docs/agent/requirements#cookies'
  },
  EMAIL: {
    SUPPORTED_ATTACHMENTS: {
      DESKTOP: '/docs/agent/desktop/email#blocked-file-types',
      SOFTPHONE: '/docs/agent/softphone/email#blocked-file-types'
    }
  },
  ENABLE_NOTIFICATIONS: '/docs/faqs/agent#softphone-notifications',
  CONNECT: {
    SIGN_IN: '/docs/faqs/agent#sign-in-connect',
    WINDOW_NOT_APPEARING: '/docs/faqs/agent#sign-in-not-appearing'
  }
};

export const EXTERNAL_HELP_LINKS = {
  SITE_DATA: {
    EDGE: 'https://www.microsoft.com/en-us/edge/learning-center/how-to-manage-and-clear-your-cache-and-cookies?form=MA13I2',
    CHROME: 'https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop'
  },
  INCOGNITO: {
    EDGE: 'https://support.microsoft.com/en-us/microsoft-edge/browse-inprivate-in-microsoft-edge-e6f47704-340c-7d4f-b00d-d0cf35aa1fcc',
    CHROME: 'https://support.google.com/chrome/answer/95464?hl=en&co=GENIE.Platform%3DDesktop'
  }
};
