import { RedirectProps, Redirect } from 'react-router';
import { TPermissions } from '@cloud-wave/neon-common-lib';

import { usePermissionsContext } from '../contexts/PermissionsContext';
import { TConnectPermissions, TPermissionTypes } from '../types/Permissions';

interface IPermissionRedirect {
  route: string;
  permission: TPermissions | TConnectPermissions;
  permissionType?: TPermissionTypes;
}

// This is used to redirect to a certain route if the role exists. eg. sub nav routes
export default function PermissionRedirect({
  redirects,
  requiresAdmin,
  ...props
}: Omit<RedirectProps, 'to'> & { redirects: IPermissionRedirect[]; requiresAdmin?: boolean }) {
  const { hasPermission, isAdmin } = usePermissionsContext();

  if (requiresAdmin && !isAdmin) {
    return null;
  }

  const redirect = redirects.find(({ permission, permissionType }) =>
    hasPermission({ type: permissionType, permission })
  );

  if (!redirect) {
    return null;
  }

  return <Redirect {...props} to={redirect.route} />;
}
