import { WidgetConnectAppSuffix } from '@cloud-wave/neon-common-lib/common';

const CONNECT_APP_CONFIG = {
  [WidgetConnectAppSuffix.WISDOM]: {
    containerId: 'wisdom-container',
    initApp: 'wisdom'
  },
  [WidgetConnectAppSuffix.CUSTOMER_PROFILES]: {
    containerId: 'customer-profiles-container',
    initApp: 'customerprofiles'
  },
  [WidgetConnectAppSuffix.CUSTOM_VIEWS]: {
    containerId: 'custom-views-container',
    initApp: 'customviews'
  }
};

export default CONNECT_APP_CONFIG;
