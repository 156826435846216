import { useState } from 'react';
import { format } from 'date-fns';
import { useHotkeys } from 'react-hotkeys-hook';
import { faClock, faComments, faInfoCircle, faPlug, faUser } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import Modal from 'lib/common/components/Modal';
import getUserName from 'lib/common/utils/getUserName';
import ChatTask from 'lib/common/types/ChatTask';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import TUser from 'lib/common/types/User';
import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import getScheduleCallbackDescription from 'lib/common/utils/getScheduleCallbackDescription';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import ChatMessages from 'lib/common/components/molecules/ChatMessages';
import TChatTask from 'lib/common/types/ChatTask';
import { TChatMessage } from 'lib/common/types/chat/ChatMessage';
import { getTaskTimeInfo } from 'lib/common/utils/ACW/getTaskTimeInfo';

import styles from './after-contact-work.module.scss';

type TAfterContactWork = Pick<
  ChatTask,
  'contact' | 'profile' | 'connectionValue' | 'taskId' | 'type' | 'connectedAtTime' | 'time' | 'nextToken' | 'status'
> & {
  messages?: TChatMessage[];
  setCreateAgentTaskOpen: (open: boolean) => void;
  setCreateTaskDefaults: (_: any) => void;
  user: TUser;
  queueName?: string;
  queueId?: string;
};

export default function AfterContactWork(props: TAfterContactWork) {
  const [chatTranscriptionVisible, setChatTranscriptionVisible] = useState<boolean>(false);

  const { setCreateAgentTaskOpen, setCreateTaskDefaults, user, queueName, nextToken, status, ...task } = props;

  const { contact, profile, connectionValue, type, connectedAtTime, time = new Date() } = task;
  const { startedAt, activeDuration } = getTaskTimeInfo({ contact, connectedAtTime });
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const onToggleChatTranscriptionModal = () => {
    setChatTranscriptionVisible(!chatTranscriptionVisible);
  };

  const scheduleCallback = () => {
    setCreateTaskDefaults({
      name: 'Call A Customer Back',
      description:
        type === CONTACT_TYPES.TASK ? '' : getScheduleCallbackDescription(connectionValue, profile, startedAt, user)
    });
    setCreateAgentTaskOpen(true);
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.AFTER_CALL_SCHEDULE_CALLBACK, scheduleCallback, []);

  return (
    <>
      {type === CONTACT_TYPES.CHAT && (
        <Modal
          onClose={onToggleChatTranscriptionModal}
          title="Chat History"
          open={chatTranscriptionVisible}
          secondaryButtonText="Close"
          small
        >
          <ChatMessages
            showEmptyPlaceholder
            messages={(props as unknown as TChatTask).messages}
            customerName={name}
            nextToken={nextToken}
            status={status}
          />
        </Modal>
      )}
      <div className={styles['after-contact-work']}>
        <div className={styles['after-contact-work__info']}>
          <QueueNameAndTimer queueName={queueName} time={time} />
          <div className={styles['after-contact-work__info__row']}>
            <Icon
              icon={type === CONTACT_TYPES.TASK ? faInfoCircle : faUser}
              tooltip={type === CONTACT_TYPES.TASK ? 'Task Name' : 'Customer'}
            />
            <span>{profile && profile.firstName ? getUserName(profile) : connectionValue}</span>
          </div>
          {activeDuration && (
            <>
              <div className={styles['after-contact-work__info__row']}>
                <Icon icon={faPlug} tooltip="The time the interaction started" />
                <span>{format(startedAt, 'h:mm aa')}</span>
              </div>
              <div className={styles['after-contact-work__info__row']}>
                <Icon icon={faClock} tooltip="Interaction duration" />
                <span>{activeDuration}</span>
              </div>
            </>
          )}
          {type === CONTACT_TYPES.CHAT && (
            <div className={cx(styles['after-contact-work__info__row'], styles['after-contact-work__info__row-chat'])}>
              <Icon icon={faComments} />
              <Text type="link" onClick={onToggleChatTranscriptionModal}>
                View Chat History
              </Text>
            </div>
          )}
        </div>
        <ACWOutcome className={styles['after-contact-work__outcome']} withoutAccordion />
      </div>
      <TaskFooter.ACW scheduleCallback={scheduleCallback} />
    </>
  );
}
