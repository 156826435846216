import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Text from 'lib/common/components/Text';
import Icon from 'lib/common/components/Icon';
import IWrapUpCode from 'lib/common/types/WrapUpCode';

import styles from './acw-code-label.module.scss';

interface IACWCodeLabel {
  wrapUpCode: IWrapUpCode;
  small?: boolean;
  isSoftphone?: boolean;
}

export default function ACWCodeLabel({ wrapUpCode: { name, pathname }, small, isSoftphone }: IACWCodeLabel) {
  return (
    <div
      className={cx(styles['acw-code-label'], {
        [styles['acw-code-label--small']]: small,
        [styles['acw-code-label--softphone']]: isSoftphone
      })}
    >
      <Text className={styles['acw-code-label__name']} medium ellipsis>
        {name}
      </Text>
      {pathname && (
        <div className={styles['acw-code-label__path']}>
          <Icon color="darkGrey" size={10} icon={faFolder} />
          <Text color="darkGrey" className={styles['acw-code-label__path__text']} type="extraSmall" ellipsis>
            {pathname}
          </Text>
        </div>
      )}
    </div>
  );
}
