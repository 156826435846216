import { useState } from 'react';
import { WidgetConnectAppSuffix } from '@cloud-wave/neon-common-lib';
import cx from 'classnames';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import CONNECT_APP_CONFIG from 'lib/common/constants/connectAppConfig';

import CustomViewsApp from './components/CustomViewsApp';

import styles from './connect-app.module.scss';

export default function ConnectApp({
  title,
  source,
  instanceUrl
}: {
  title: string;
  source: string;
  instanceUrl: string;
}) {
  const {
    state: { tasks }
  } = useContactContext();

  const customViewsUrl = `${instanceUrl}${WidgetConnectAppSuffix.CUSTOM_VIEWS}`;
  const connectAppSuffix = source.replace(instanceUrl, '');
  const [isConnectAppInitialized, setIsConnectAppInitialized] = useState(false);
  const { containerId, initApp } = CONNECT_APP_CONFIG[connectAppSuffix];

  const onRefChange = (el: HTMLDivElement) => {
    if (!el || source === customViewsUrl || isConnectAppInitialized) {
      return;
    }

    connect.agentApp.initApp(initApp, containerId, source);
    setIsConnectAppInitialized(true);
  };

  if (source === customViewsUrl) {
    return (
      <>
        {tasks.map((task) => (
          <CustomViewsApp key={task.taskId} task={task} source={source} instanceUrl={instanceUrl} />
        ))}
      </>
    );
  }

  return (
    <div
      id={containerId}
      data-testid={containerId}
      title={`${title} module`}
      className={cx(styles['connect-app'])}
      ref={onRefChange}
    />
  );
}
