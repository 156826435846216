import { TextField } from '@material-ui/core';
import { TextFieldProps } from '@material-ui/core/TextField';
import cx from 'classnames';
import { forwardRef } from 'react';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useStyles from './styles';

import './input.scss';

function Input(
  allProps: TextFieldProps & {
    small?: boolean;
    noBorder?: boolean;
    noPadding?: boolean;
    ignoreRows?: boolean;
    ariaRequired?: boolean;
  },
  ref
) {
  const { small, noBorder, noPadding, ignoreRows, ariaRequired, ...props } = allProps;
  const { isSoftphone } = useLayout();

  const classes = useStyles({
    isSoftphone: small || isSoftphone,
    multiline: props.multiline,
    noBorder,
    noPadding,
    ignoreRows
  });

  return (
    <TextField
      variant="outlined"
      {...props}
      ref={ref}
      className={cx(classes.root, props.className)}
      inputProps={{
        ...(props.inputProps || {}),
        className: cx(classes.input, props.inputProps?.className),
        'aria-label': props['aria-label'] || props.label?.toString() || props.placeholder,
        'aria-disabled': props.disabled,
        ...(ariaRequired || props.required ? { 'aria-required': true } : {})
      }}
      InputProps={{
        ...(props.InputProps || {}),
        classes: {
          ...(props.InputProps?.classes || {}),
          root: classes.inputRoot
        },
        ...(ariaRequired || props.required ? { required: true } : {})
      }}
      InputLabelProps={{
        ...(props.InputLabelProps || {}),
        classes: {
          ...(props.InputLabelProps?.classes || {}),
          root: classes.inputLabelRoot
        }
      }}
    />
  );
}

export default forwardRef(Input);
