import cx from 'classnames';
import { ReactNode, useEffect } from 'react';

import { useEmailUploadContext } from 'lib/common/contexts/EmailUploadContext';
import UploadedFileType from 'lib/common/constants/files/UploadedFileType';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import FileCardEmail from './components/FileCardEmail';

import styles from './files-email.module.scss';

interface IFilesProps {
  contextId: string;
  uploadedFileType: UploadedFileType;
  className?: string;
  children?: ReactNode;
}

const SCROLL_SELECTOR_ID = 'files-scroll-to';

export default function FilesEmail({ contextId, uploadedFileType, className, children }: IFilesProps) {
  const {
    actions: { deleteFile, getFilesForContext }
  } = useEmailUploadContext();

  const { isSoftphone } = useLayout();

  const files = getFilesForContext(contextId);

  const onDelete = (fileKey: string) => async () => {
    await deleteFile(uploadedFileType, fileKey);
  };

  useEffect(() => {
    document.querySelector(`#${SCROLL_SELECTOR_ID}`)?.scrollIntoView?.({ behavior: 'smooth' });
  }, [Object.keys(files).length]);

  return (
    <div className={cx(styles.files, className, { [styles['files--softphone']]: isSoftphone })}>
      {children}
      {Object.entries(files).map(([fileKey, file]) => (
        <FileCardEmail
          id={fileKey}
          file={file}
          key={fileKey}
          onDelete={onDelete(fileKey)}
          className={isSoftphone ? styles['files--softphone__file'] : void 0}
        />
      ))}
      <span id={SCROLL_SELECTOR_ID} />
    </div>
  );
}
