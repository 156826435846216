import { Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@material-ui/core';
import { useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { faAddressCard, faBuilding } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';
import getUserName from 'lib/common/utils/getUserName';
import Avatar from 'lib/common/components/Avatar';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import TProfile from 'lib/common/types/CustomerProfile';
import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';

interface IProfilesTable {
  profiles: TProfile[];
}

const COLUMNS = [
  { id: 'type', numeric: false, label: '' },
  { id: 'name', numeric: false, label: 'Name' },
  { id: 'phoneNumber', numeric: false, label: 'Phone Number' },
  { id: 'email', numeric: false, label: 'Email' },
  { id: 'accountNumber', numeric: false, label: 'Account Number' }
];

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: '100%'
    },
    table: {
      minWidth: 550
    },
    tableWrapper: {
      overflowX: 'auto'
    }
  })
);

export default function ProfilesTable({ profiles }: IProfilesTable) {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('name');

  const history = useHistory();

  const classes = useStyles();

  if (!profiles || !profiles.length) {
    return (
      <EmptyPlaceholder
        text="We couldn't find any profiles that match what you're looking for. Make sure your search term is in the full, correct format."
        icon={faAddressCard}
      />
    );
  }

  const handleRequestSort = (property: string) => () => {
    const isDesc = orderBy === property && order === 'desc';

    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const goToCustomerProfile = (id: string) => () => {
    history.push(`/profiles/${id}`);
  };

  return (
    <Table className={cx(classes.table, 'customer-profiles-table')} size="medium" data-testid="customer-profiles-table">
      <TableHead>
        <TableRow>
          {COLUMNS.map((column) => (
            <TableCell key={column.id} align="left" sortDirection={orderBy === column.id ? order : void 0}>
              <TableSortLabel active={orderBy === column.id} direction={order} onClick={handleRequestSort(column.id)}>
                {column.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {profiles.map(({ firstName, lastName, phoneNumber, emailAddress, profileId, accountNumber, partyType }) => (
          <TableRow
            key={profileId}
            classes={{ root: 'tbl-row' }}
            hover
            onClick={profileId ? goToCustomerProfile(profileId) : void 0}
          >
            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
              <Avatar
                size={40}
                name={`${firstName} ${lastName}`}
                fallbackIcon={partyType === PROFILE_TYPES.INDIVIDUAL ? void 0 : faBuilding}
              />
            </TableCell>
            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
              <span className="table-record">{getUserName({ firstName, lastName }) || '-'}</span>
            </TableCell>
            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
              <span className="table-record">{phoneNumber || '-'}</span>
            </TableCell>
            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
              <span className="table-record">{emailAddress || '-'}</span>
            </TableCell>
            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
              <span className="table-record">{accountNumber || '-'}</span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
