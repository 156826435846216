import { useEffect, useState } from 'react';
import cx from 'classnames';
import sizeMe from 'react-sizeme';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import Icon from 'lib/common/components/Icon';
import getMappedConnectState from 'lib/common/contexts/PresenceContext/utils/getMappedConnectState';
import Text from 'lib/common/components/Text';

import { faClock } from '@fortawesome/pro-regular-svg-icons';
import { CSSTransition } from 'react-transition-group';
import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';
import { usePresenceContext } from 'lib/common/contexts/PresenceContext';
import connectGetter from 'lib/common/utils/connectGetter';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import Popover from '../Popover';
import styles from './agent-status-timer.module.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_TIMER_WIDTH = 100;
const WITH_HOUR_TEXT_WIDTH = '58px';
const WITHOUT_HOUR_TEXT_WIDTH = '40px';

interface IAgentStatusTimerProps {
  className?: string;
  size: { width: number };
}

function agentIsAway(presence) {
  return !Object.values(PRESENCE_STATES).includes(getMappedConnectState(presence));
}

function getAgentPresenceTimestamp(agent: connect.Agent | null) {
  if (!agent) {
    return null;
  }

  return new Date(new Date().getTime() - (connectGetter(agent, 'getStateDuration') || 0));
}

const AgentStatusTimer = ({ className, size: { width } }: IAgentStatusTimerProps) => {
  const { isSoftphone } = useLayout();
  const { agent } = useAgentContext();
  const { connectState } = usePresenceContext();

  const [isFirstRender, setIsFirstRender] = useState(true);
  const [startTime, setStartTime] = useState<Date | null>(null);

  const timer = useTaskTimer(startTime || new Date());

  const agentAway = agentIsAway(connectState);

  // Get away timer from connect on first render, in case the user reloads the page while being in an away state.
  useEffect(() => {
    setStartTime(agentAway ? getAgentPresenceTimestamp(agent) : null);
  }, []);

  useEffect(() => {
    if (isFirstRender) {
      setIsFirstRender(false);
      return;
    }

    if (!agentAway) {
      return setStartTime(null);
    }

    setStartTime(new Date());
  }, [connectState]);

  const show = Boolean(agentAway);

  /**
   * 00:00 -> minutes:seconds
   * 00:00:00 -> hours:minutes:seconds
   */
  const timerWidth = timer.split(':').length > 2 ? WITH_HOUR_TEXT_WIDTH : WITHOUT_HOUR_TEXT_WIDTH;
  const isSmallTimer = width < SMALL_TIMER_WIDTH;

  const timerEl = (
    <Text type="body" className={styles['agent-status-timer__container__text']} width={timerWidth}>
      <span className="sr-only">Agent status timer</span>
      {timer}
    </Text>
  );
  const iconEl = <Icon icon={faClock} size={isSmallTimer ? 15 : 17} />;

  return (
    <div className={cx(styles['agent-status-timer'], className)}>
      <CSSTransition
        in={show}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles['agent-status-timer-enter'],
          enterActive: styles['agent-status-timer-enter-active'],
          exit: styles['agent-status-timer-enter'],
          exitActive: styles['agent-status-timer-exit-active']
        }}
      >
        {!isSmallTimer ? (
          <div
            role="timer"
            tabIndex={0}
            className={cx(styles['agent-status-timer__container'], {
              [styles['agent-status-timer__container--softphone']]: isSoftphone
            })}
            data-testid="agent-status-timer"
          >
            {iconEl}
            {timerEl}
          </div>
        ) : (
          <Popover
            anchor={
              <button
                aria-label="Agent status timer"
                className={cx(
                  'no-styles-button',
                  styles['agent-status-timer__container'],
                  styles['agent-status-timer__container-popover'],
                  {
                    [styles['agent-status-timer__container--softphone']]: isSoftphone
                  }
                )}
                data-testid="agent-status-timer"
              >
                {iconEl}
              </button>
            }
          >
            <div role="timer" tabIndex={0} className={styles['agent-status-timer__container__popover-text']}>
              {timerEl}
            </div>
          </Popover>
        )}
      </CSSTransition>
    </div>
  );
};

export default sizeMeHOC(AgentStatusTimer);
