import { format } from 'date-fns';
import getUserName from './getUserName';
import getDateFormat from './getDateFormat';

export default function getScheduleCallbackDescription(connectionValue, profile, connectedAt, user) {
  const userName = getUserName({ ...profile, fallback: '' });
  const displayUserName = userName ? `(${userName})` : '';
  const connectedAtTime = format(connectedAt || new Date(), 'h:mm aa');
  const connectedAtDate = format(connectedAt || new Date(), getDateFormat());
  const agentName = getUserName(user);

  return `${connectionValue} ${displayUserName}.\n\nOriginally contacted us at ${connectedAtTime} on ${connectedAtDate} and talked to Agent ${agentName}.`;
}
