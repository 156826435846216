import Modal from 'lib/common/components/Modal';
import Text from 'lib/common/components/Text';

export default function DeleteProfileDialog({ open, onClose, onConfirm, onSuccess }) {
  return (
    <Modal
      open={open}
      small
      secondaryButtonText="Cancel"
      title="Are You Sure?"
      onClose={onClose}
      onDelete={onConfirm}
      onSuccess={onSuccess}
    >
      <Text>Confirm you'd like to delete this customer profile.</Text>
    </Modal>
  );
}
