import cx from 'classnames';

import withCharacterLimit from 'lib/common/utils/withCharacterLimit';
import { Input } from 'lib/common/components/Input';

import focusInputField from 'lib/common/utils/focusInputField';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import '../styles/create-agent-task.scss';

export default function TaskInformation({ name, setName, description, setDescription }) {
  const { isSoftphone } = useLayout();

  return (
    <div
      className={cx('create-agent-task', {
        'create-agent-task--padding': isSoftphone
      })}
    >
      <div className="create-agent-task__text-field">
        <Input
          label="Name"
          required
          autoFocus
          //Because this is mounting under an accordion, autoFocus works sporadically, which is why adding a setTimeout to autoFocus
          inputRef={focusInputField}
          fullWidth
          value={name}
          data-testid="create-task-name-input"
          onChange={(e) => setName(withCharacterLimit(150, e.target.value))}
        />
      </div>
      <div className="create-agent-task__text-field">
        <Input
          label="Description"
          fullWidth
          value={description}
          multiline
          minRows={2}
          maxRows={4}
          onChange={(e) => setDescription(withCharacterLimit(4096, e.target.value))}
        />
      </div>
    </div>
  );
}
