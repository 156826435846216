import { toast as toastify } from 'react-toastify';
import { isValidElement, ReactNode } from 'react';
import { ToastOptions, TypeOptions } from 'react-toastify/dist/types';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import ToastAlert, { IToastAlertProps } from './ToastAlert';

const getToastContent = (content: string | IToastAlertProps | ReactNode, type: TypeOptions) => {
  if (typeof content === 'string') {
    return <ToastAlert message={content} type={type} />;
  }

  if (isValidElement(content)) {
    return <ToastAlert type={type}>{content}</ToastAlert>;
  }

  return <ToastAlert {...(content as IToastAlertProps)} type={type} />;
};

export default function toast(
  type: TypeOptions,
  content: string | ReactNode | IToastAlertProps,
  options: ToastOptions = {}
) {
  toastify[type](getToastContent(content, type), {
    ...options,
    icon: () => null,
    closeButton: ({ closeToast }) => <Icon size={17} onClick={closeToast} icon={faXmark} />
  });
}
