import { Link } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import { Actions } from 'lib/common/components/Profile';
import TProfile from 'lib/common/types/CustomerProfile';

interface IHeader {
  profile?: TProfile | null;
  profileNotes?: string;
  currentContactIsViewed: boolean;
  onCall: () => void;
  onDelete: () => void;
  onEdit: () => void;
}

export default function Header({ profile, currentContactIsViewed, profileNotes, onCall, onDelete, onEdit }: IHeader) {
  return (
    <div className="customer-profile__content__header">
      <Link to="/profiles" className="customer-profile__content__header__back">
        <Icon icon={faChevronLeft} className="mr-10" size={17} />
        <Text type="heading2">Back To Customer Profiles</Text>
      </Link>
      <Actions
        profile={profile}
        profileNotes={profileNotes}
        showOnCall={!currentContactIsViewed && Boolean(profile?.phoneNumber)}
        onCall={onCall}
        onDelete={onDelete}
        onEdit={onEdit}
      />
    </div>
  );
}
