import { ITabProps } from '../components/Tab/Tab';

export default function getSlicedTabs(
  tabs: ITabProps[],
  totalWidth: number,
  tabWidths: number[],
  TAB_GAP: number
): { visibleTabs: ITabProps[]; overflowTabs: ITabProps[] } {
  if (tabs.length !== tabWidths.length) {
    return { visibleTabs: [], overflowTabs: tabs };
  }

  let currentWidth = 0;
  let currentIndex = 0;

  while (currentWidth + tabWidths[currentIndex] < totalWidth && currentIndex < tabs.length) {
    currentWidth += tabWidths[currentIndex] + TAB_GAP;
    currentIndex++;
  }

  return { visibleTabs: tabs.slice(0, currentIndex), overflowTabs: tabs.slice(currentIndex, tabs.length) };
}
