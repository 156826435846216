import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import cx from 'classnames';

import { TimePicker, DatePicker } from 'lib/common/components/Input';
import Text from 'lib/common/components/Text';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import getDateFormat from 'lib/common/utils/getDateFormat';

import '../styles/create-agent-task.scss';

export default function TaskDateTime({ scheduledDateTime, handleDateTimeChange, invalidDateTimeError }) {
  const { isSoftphone } = useLayout();

  return (
    <>
      <div className={cx('create-agent-task__date-time', { 'create-agent-task--padding': isSoftphone })}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            label="Date"
            value={scheduledDateTime}
            onChange={(newValue) => handleDateTimeChange(newValue, 'date')}
            disablePast
            format={getDateFormat()}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            invalidDateMessage={<></>}
            minDateMessage={<></>}
            error={Boolean(invalidDateTimeError)}
            id="create-task-date-input"
          />
          <TimePicker
            label="Time"
            mask="__:__ _M"
            id="time-picker"
            value={scheduledDateTime}
            onChange={(newValue) => handleDateTimeChange(newValue, 'time')}
            invalidDateMessage={<></>}
            error={Boolean(invalidDateTimeError)}
          />
        </MuiPickersUtilsProvider>
      </div>
      {invalidDateTimeError && (
        <Text color="danger" type="helper" className="mt-10">
          {invalidDateTimeError}
        </Text>
      )}
    </>
  );
}
