import { faHeadset, faUser } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import { getConnectionName, isConnectionOnHold } from 'lib/common/utils/conferenceConnections';
import optional from 'lib/common/utils/optional';
import ConferenceActions from 'lib/common/utils/ConferenceActions';
import { getOrderedConnectionList } from 'lib/common/utils/conference';
import connectGetter from 'lib/common/utils/connectGetter';

import InfoPanel from '../../InfoPanel';

export default function ConferenceUserRow({
  isCurrentConference,
  taskId,
  activeThirdPartyConnections,
  activeConnectionsLength,
  connectionTimestamps,
  isMonitoring,
  isAnyConnectionConnecting
}) {
  const {
    actions: { holdConferenceConnection, resumeConferenceConnection, endConferenceConnection }
  } = useContactContext();

  return (
    <>
      {getOrderedConnectionList(activeThirdPartyConnections, connectionTimestamps).map(
        (connection: connect.VoiceConnection) => {
          const connectionStatus = connectGetter(connection, 'getState')?.type;
          const connectionId = connection.connectionId;
          const connectionIcon =
            connectGetter(connection, 'getEndpoint')?.phoneNumber === 'INTERNAL-TRANSFER' ? faHeadset : faUser;

          return (
            <InfoPanel.Row
              key={connectionId}
              testId={connectionId}
              text={getConnectionName(connection)}
              icon={optional(isCurrentConference, connectionIcon)}
              badgeProps={{
                callIsOnHold: isConnectionOnHold(connection),
                isConnecting: connectionStatus === connect.ConnectionStateType.CONNECTING,
                connection
              }}
              actions={optional(
                activeConnectionsLength > 1 && !isMonitoring,
                <ConferenceActions
                  participantName={getConnectionName(connection)}
                  onDisconnect={() => endConferenceConnection({ taskId, connectionId })}
                  isConnectionOnHold={isConnectionOnHold(connection)}
                  holdConnection={() => holdConferenceConnection({ taskId, connectionId })}
                  resumeConnection={() => resumeConferenceConnection({ taskId, connectionId })}
                  isAnyConnectionConnecting={isAnyConnectionConnecting}
                />
              )}
              showBadgeText={!isCurrentConference}
            />
          );
        }
      )}
    </>
  );
}
