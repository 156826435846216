import getUserName from 'lib/common/utils/getUserName';
import Task from 'lib/common/types/Task';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import Avatar from 'lib/common/components/Avatar';
import TaskStatusBadge from 'lib/common/components/atoms/TaskStatusBadge';
import QueueNameAndTimer from '../QueueNameAndTimer';

import styles from './connecting-task.module.scss';

export default function ConnectingTask(props: Task) {
  const { profile, time, taskId, queueName } = props;

  const name = profile && profile.firstName ? getUserName(profile) : '';

  return (
    <>
      <div className={styles['connecting-task']}>
        <QueueNameAndTimer queueName={queueName} time={time} />
        <div className={styles['connecting-task__info']}>
          <Avatar size={Avatar.Sizes.LARGE} name={name} />
          <TaskStatusBadge isConnecting />
        </div>
      </div>
      {<TaskFooter.HangUpTask taskId={taskId} />}
    </>
  );
}
