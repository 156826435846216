import { useEffect, useState } from 'react';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useConfigContext } from 'lib/core/config';
import IEmailMetadata from 'lib/common/types/email/EmailMetadata';
import { LogEvents, logger } from 'lib/common/components/LoggerController';
import { TEmail } from 'lib/common/types/email/TEmail';

import getEmailContent from './api/getEmailContent';

export default function useEmailContent(emailId?: string) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const [emailContent, setEmailContent] = useState<TEmail | undefined>(void 0);
  const [emailMetadata, setEmailMetadata] = useState<undefined | IEmailMetadata>(void 0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(!emailId);

  const fetchData = async () => {
    if (!emailId) {
      return;
    }

    try {
      setEmailContent(void 0);
      setLoading(true);

      const data = await getEmailContent({ fetch_, config, id: emailId });

      setEmailContent(data.email);
      setEmailMetadata(data.metadata);
      setLoading(false);
    } catch (error) {
      logger.error(LogEvents.EMAIL.CONTENT_LOAD_FAILED, { error });
      setError(true);
    }
  };

  useEffect(() => {
    if (!emailId) {
      return void setError(true);
    }

    fetchData();
  }, []);

  return {
    loading,
    error,
    data: emailContent,
    metadata: emailMetadata,
    attachments: emailContent?.attachments || []
  };
}
