import { faHeadset, faUser } from '@fortawesome/pro-regular-svg-icons';
import sizeMe from 'react-sizeme';
import cx from 'classnames';

import getUserName from 'lib/common/utils/getUserName';
import Task from 'lib/common/types/Task';
import isActionableCallTask from 'lib/common/utils/tasks/isActionableCallContact';
import {
  getAgentConnection,
  getAllActiveThirdPartyVoiceConnections,
  getConnectionName,
  isAnyConnectionConnecting,
  isConnectionOnHold,
  isMultiPartyConferenceEnabled
} from 'lib/common/utils/conferenceConnections';
import useTaskConnections from 'lib/common/hooks/useTaskConnections';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import connectGetter from 'lib/common/utils/connectGetter';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import CallRecording from 'lib/common/components/molecules/CallRecording';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import Avatar from 'lib/common/components/Avatar';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import { getOrderedConnectionList } from 'lib/common/utils/conference';
import TUser from 'lib/common/types/User';
import agentIsMonitoring from 'lib/common/utils/connect/agentIsMonitoring';

import QueueNameAndTimer from '../../QueueNameAndTimer';
import UserInformation from './UserInformation';

import '../styles/timed-task.scss';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const SMALL_FOOTER_WIDTH_PX = 250;

interface ITimedTaskProps extends Task {
  size: { width: number; height: number };
  user?: TUser;
}

function TimedTask(props: ITimedTaskProps) {
  const {
    profile,
    connectionValue,
    connectionState,
    time,
    type,
    status,
    taskId,
    contact,
    size,
    queueName,
    user,
    connectionTimestamps
  } = props;
  const {
    actions: { endInitialConnection, endConferenceConnection, holdConferenceConnection, resumeConferenceConnection }
  } = useContactContext();
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const { isNeglected, isCurrentConference, initialConnectionDisconnected } = useTaskConnections(props);

  const isSmallFooterWidth = size?.width < SMALL_FOOTER_WIDTH_PX;
  const isMonitoring = agentIsMonitoring(contact);
  const callMissedOrRejected = status === CONTACT_STATES.MISSED || status === CONTACT_STATES.REJECTED;
  const activeThirdPartyConnections = getAllActiveThirdPartyVoiceConnections(contact);
  const isMultiPartyConference = isMultiPartyConferenceEnabled(contact);
  const agentConnection = getAgentConnection(contact);
  const initialConnection = connectGetter(contact, 'getInitialConnection');

  return (
    <>
      <div className="timed-task__container">
        <QueueNameAndTimer queueName={queueName} time={time} />
        <CallRecording />
        {!callMissedOrRejected && <ACWOutcome />}
        <div className={cx('timed-task', { 'timed-task--conference': isCurrentConference })}>
          {!isCurrentConference && (
            <Avatar size={Avatar.Sizes.LARGE} name={getUserName(profile && profile.firstName ? profile : void 0)} />
          )}
          {isCurrentConference && isMultiPartyConference && !isMonitoring && (
            <UserInformation
              name={getUserName(user)}
              testId="agent-connection"
              isRow
              icon={faHeadset}
              connection={agentConnection}
              isConnectionOnHold={connectGetter(agentConnection, 'getState')?.type === connect.ConnectionStateType.HOLD}
              holdConnection={() => holdConferenceConnection({ taskId, connectionId: agentConnection?.connectionId })}
              resumeConnection={() =>
                resumeConferenceConnection({ taskId, connectionId: agentConnection?.connectionId })
              }
              isAgentConnection
              isAnyConnectionConnecting={isAnyConnectionConnecting(contact)}
            />
          )}
          {!initialConnectionDisconnected && (
            <UserInformation
              testId="initial-connection"
              isNeglected={isNeglected}
              name={name}
              connection={initialConnection}
              isRow={isCurrentConference}
              icon={faUser}
              isConnectionOnHold={
                connectGetter(initialConnection, 'getState')?.type === connect.ConnectionStateType.HOLD
              }
              onDisconnect={() => endInitialConnection(taskId)}
              holdConnection={() => holdConferenceConnection({ taskId, connectionId: initialConnection?.connectionId })}
              resumeConnection={() =>
                resumeConferenceConnection({ taskId, connectionId: initialConnection?.connectionId })
              }
              isMonitoring={isMonitoring}
              isAnyConnectionConnecting={isAnyConnectionConnecting(contact)}
            />
          )}
          {getOrderedConnectionList(activeThirdPartyConnections, connectionTimestamps).map(
            (connection: connect.VoiceConnection) => {
              const connectionId = connection.connectionId;
              const connectionIcon =
                connectGetter(connection, 'getEndpoint')?.phoneNumber === 'INTERNAL-TRANSFER' ? faHeadset : faUser;

              return (
                <UserInformation
                  key={connectionId}
                  testId={connectionId}
                  connection={connection}
                  name={getConnectionName(connection)}
                  isConnecting={connectGetter(connection, 'getState')?.type === connect.ConnectionStateType.CONNECTING}
                  isRow={isCurrentConference}
                  icon={connectionIcon}
                  isConnectionOnHold={isConnectionOnHold(connection)}
                  onDisconnect={() => endConferenceConnection({ taskId, connectionId })}
                  holdConnection={() => holdConferenceConnection({ taskId, connectionId })}
                  resumeConnection={() => resumeConferenceConnection({ taskId, connectionId })}
                  isMonitoring={isMonitoring}
                  isAnyConnectionConnecting={isAnyConnectionConnecting(contact)}
                />
              );
            }
          )}
        </div>
      </div>
      {isActionableCallTask({ type, status }) && (
        <TaskFooter.ConnectedCall
          inCurrentConference={isCurrentConference}
          connectionState={connectionState}
          taskId={taskId}
          connectionValue={connectionValue}
          initialConnectionDisconnected={initialConnectionDisconnected}
          type={type}
          isSmallWidth={isSmallFooterWidth}
          contact={contact}
        />
      )}
      {isNeglected && <TaskFooter.ClearTask taskId={taskId} />}
    </>
  );
}

export default sizeMeHOC(TimedTask);
