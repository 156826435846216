import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';

const AGENT_CLOSE_ERROR_MESSAGE =
  'We tried to end the task but something went wrong. Please try again. If the problem persists, try reloading the page.';

export default async function hangupAgentHelper({ contact }: { contact?: connect.Contact }) {
  const agentConnection = connectGetter(contact, 'getAgentConnection');

  if (!agentConnection) {
    return Promise.resolve();
  }

  return connectAction('destroy', agentConnection, void 0, {
    errorMsg: AGENT_CLOSE_ERROR_MESSAGE
  });
}
