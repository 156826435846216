import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import ClickableIcon from 'lib/common/components/ClickableIcon';

import SoftphoneCreateAgentTask from 'lib/common/components/molecules/SoftphoneCreateAgentTask';
import SoftphoneSendSms from 'lib/common/components/molecules/SoftphoneSendSms';

import CreateTaskPopover from 'lib/common/components/atoms/CreateTaskPopover';

import './softphone-add-agent-task-button.scss';

export default function SoftphoneAddAgentTaskButton() {
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const openCreateAgentTaskModal = () => {
    openModal({
      title: 'Add Agent Task',
      content: <SoftphoneCreateAgentTask onClose={closeModal} />
    });
  };

  const openSendSmsModal = () => {
    openModal({
      title: 'Send a New SMS',
      content: <SoftphoneSendSms onClose={closeModal} />
    });
  };

  return (
    <CreateTaskPopover
      onCreateTask={openCreateAgentTaskModal}
      onSendSms={openSendSmsModal}
      anchor={
        <div className="softphone-add-agent-task-button">
          <ClickableIcon icon={faPlus} tooltip="Create New" tooltipPlacement="right" id="softphone-add-task-button" />
        </div>
      }
    />
  );
}
