import { useCallback, useEffect, useRef, useState } from 'react';
import _debounce from 'lodash.debounce';
import cx from 'classnames';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import ReactSelect from 'lib/common/components/ReactSelect';
import customerProfileSearchTypes from 'lib/common/constants/customerProfileSearchTypes';

import '../styles/search.scss';

const SEARCH_TYPES = {
  NUMBER: {
    value: customerProfileSearchTypes.phoneNumber,
    label: 'Phone Number',
    placeholder: 'Search using a phone number'
  },
  NAME: {
    value: customerProfileSearchTypes.name,
    label: 'Name',
    placeholder: 'Search using a full name, such as Bob Smith'
  },
  ACCOUNT_NUMBER: {
    value: customerProfileSearchTypes.accountNumber,
    label: 'Account Number',
    placeholder: 'Search using an account or membership number'
  },
  EMAIL: {
    value: customerProfileSearchTypes.emailAddress,
    label: 'Email Address',
    placeholder: 'Search using an email address'
  }
};

const SEARCH_DEBOUNCE_MS = 1000;

type TSearchType = { label: string; value: string; placeholder: string };

type TSearchProps = {
  searching: boolean;
  onSearch: (value: string, type: string) => Promise<void>;
  clearResults: () => void;
};

function getNormalisedSearchValue(type: string, value: string) {
  if (type !== SEARCH_TYPES.NUMBER.value || value.charAt(0) !== '0') {
    return value;
  }

  // customer profiles allows us to search local numbers if there is no leading 0
  return value.substring(1);
}

export default function Search({ onSearch, searching, clearResults }: TSearchProps) {
  const [searchValue, setSearchValue] = useState('');
  const [searchType, setSearchType] = useState<TSearchType>(SEARCH_TYPES.NUMBER);

  const searchInput = useRef<HTMLInputElement>(null);

  const handleSearch = useCallback(
    _debounce((value: string) => {
      const type = searchType.value;

      return onSearch(getNormalisedSearchValue(type, value), type);
    }, SEARCH_DEBOUNCE_MS),
    [searchType.value]
  );

  const onChangeSearch = ({ target: { value } }) => {
    setSearchValue(value);

    handleSearch(value);
  };

  useEffect(() => {
    if (!searchInput) {
      return;
    }

    searchInput?.current?.[searching ? 'blur' : 'focus']();
  }, [searching]);

  useEffect(() => {
    clearResults();
    setSearchValue('');
  }, [searchType]);

  return (
    <div
      className={cx('panel--small', 'customer-profiles-search', {
        'customer-profiles-search--disabled': searching
      })}
    >
      <Icon icon={faSearch} />
      <ReactSelect
        id="customer-profiles-search-type"
        className="customer-profiles-search__dropdown"
        options={Object.values(SEARCH_TYPES)}
        onChange={setSearchType as any}
        defaultValue={searchType}
        value={searchType}
        isSearchable={false}
      />
      <input
        ref={searchInput}
        data-testid="customer-profile-search"
        onChange={onChangeSearch}
        value={searchValue}
        placeholder={searchType.placeholder}
        aria-label="Search"
      />
    </div>
  );
}
