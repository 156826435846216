import { forwardRef, ReactNode, useRef } from 'react';
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import cx from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';

import Icon from 'lib/common/components/Icon';
import Tooltip from 'lib/common/components/atoms/Tooltip';
import useActionState from 'lib/common/hooks/useActionState';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';

import './clickable-icon.scss';

interface TClickableIcon {
  className?: string;
  onClick?: (_: any) => void;
  color?: string;
  icon: IconDefinition;
  withHover?: boolean;
  size?: number;
  height?: number;
  width?: number;
  tooltip?: ReactNode;
  tooltipClassName?: string;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  disabled?: boolean;
}

// Intersection types. Cannot create a union type as it is not allowed
interface TClickableIconProps extends TClickableIcon, FontAwesomeIconProps {
  size?: any;
  width?: any;
  height?: any;
  icon: any;
  onClick?: any;
  id?: string;
  onlyConstrain?: IconSizeConstraints;
  asyncAction?: boolean;
}

function ClickableIcon(
  {
    className,
    tooltipClassName,
    onClick,
    icon,
    withHover = true,
    size,
    tooltip,
    tooltipPlacement = 'bottom',
    disabled,
    id,
    asyncAction,
    ...iconProps
  }: TClickableIconProps,
  ref?
) {
  const defaultRef = useRef(null);

  const usedRef = ref || defaultRef;

  const {
    icon: actionIcon,
    handleInteraction,
    busy,
    icons
  } = useActionState({
    onAction: onClick,
    // Move focus back to icon after action finished
    onSuccess: () => {
      usedRef?.current?.focus();
    }
  });

  const onButtonClick = (event) => {
    if (!onClick) {
      return;
    }

    if (asyncAction) {
      return handleInteraction(event);
    }

    onClick(event);
  };

  const iconButton = (
    <button
      ref={usedRef}
      className={cx('clickable-icon', { 'clickable-icon--with-hover': withHover }, className)}
      style={{ fontSize: size && `${size}px` }}
      onClick={onButtonClick}
      data-testid={id || `clickable-icon-${typeof icon === 'string' ? icon : icon.iconName}`}
      aria-label={iconProps['aria-label'] || tooltip?.toString()}
      disabled={disabled || busy}
    >
      {actionIcon?.name === icons.busy.name && <span className="sr-only">Loading</span>}
      <Icon spin={actionIcon?.name === icons.busy.name} icon={actionIcon?.el || icon} {...iconProps} size={size} />
    </button>
  );

  return tooltip ? (
    <Tooltip
      enterDelay={1000}
      title={tooltip}
      placement={tooltipPlacement}
      classes={{
        tooltip: tooltipClassName
      }}
      ariaHidden
    >
      {iconButton}
    </Tooltip>
  ) : (
    iconButton
  );
}

export default forwardRef(ClickableIcon);
