import { cloneElement, forwardRef, ReactNode } from 'react';
import { TPermissions } from '@cloud-wave/neon-common-lib';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { TConnectPermissions, TPermissionTypes } from 'lib/common/types/Permissions';

type AuthorisedRouteProps = {
  permission?: OneOrMany<TPermissions | TConnectPermissions>;
  permissionType?: TPermissionTypes;
  fallback?: ReactNode;
  children?: ReactNode;
  requiresAdmin?: boolean;
};

function getChild(children, ref) {
  if (!ref) {
    return children;
  }

  return cloneElement(children, { ref });
}

function RequiresPermission(
  { permission, permissionType, requiresAdmin, children = null, fallback = null }: AuthorisedRouteProps,
  ref
) {
  const { hasPermission, isAdmin } = usePermissionsContext();

  const hasCorrectPermissions = Array.isArray(permission)
    ? permission.every((permission) => hasPermission({ type: permissionType, permission }))
    : hasPermission({ type: permissionType, permission });

  if (!hasCorrectPermissions || (requiresAdmin && !isAdmin)) {
    return fallback;
  }

  return getChild(children, ref);
}

export default forwardRef(RequiresPermission);
