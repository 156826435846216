import sizeMe from 'react-sizeme';

import QueueNameAndTimer from 'lib/pages/AgentWorkspace/components/TaskDetail/components/QueueNameAndTimer';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import PureEmail from 'lib/common/components/molecules/Email';
import TEmailTask from 'lib/common/types/EmailTask';
import getContactAttributes from 'lib/common/utils/getContactAttributes';
import useEmailContent from 'lib/common/hooks/useEmailContent/useEmailContent';

import TaskDetailHeader from '../TaskDetailHeader';

import './email.scss';

const SMALL_FOOTER_WIDTH_PX = 250;

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

interface IEmailTaskProps extends TEmailTask {
  size: { width: number; height: number };
}

function Email(task: IEmailTaskProps) {
  const { time, queueName, size } = task;

  const {
    neonEmailId,
    neonEmailTo,
    emailAddress,
    neonEmailSubject,
    neonEmailCcRecipients = '',
    neonEmailAllRecipients = ''
  } = getContactAttributes(
    { task },
    'neonEmailId',
    'neonEmailTo',
    'emailAddress',
    'neonEmailSubject',
    'neonEmailCcRecipients',
    'neonEmailAllRecipients'
  );

  const { data, loading, error, attachments, metadata } = useEmailContent(neonEmailId);

  const isSmallFooterWidth = size?.width < SMALL_FOOTER_WIDTH_PX;

  return (
    <div className="email-task-preview">
      <TaskDetailHeader>{neonEmailSubject || 'No Subject'}</TaskDetailHeader>
      <QueueNameAndTimer queueName={queueName} time={time} />
      <div className="email-task-preview__container">
        <ACWOutcome />
        <hr />
        <PureEmail
          metadata={{ from: emailAddress, to: neonEmailTo }}
          content={data}
          neonEmailCcRecipients={neonEmailCcRecipients}
          neonEmailAllRecipients={neonEmailAllRecipients}
          loading={loading}
          error={error}
          attachments={attachments}
          hideDate
        />
      </div>
      <TaskFooter.Email
        task={task}
        content={data}
        attachments={attachments}
        metadata={metadata}
        isSmallWidth={isSmallFooterWidth}
      />
    </div>
  );
}

export default sizeMeHOC(Email);
