import { IModule } from '@cloud-wave/neon-common-lib';

import Frame from 'lib/common/components/atoms/Frame';

import Landing from './Landing';

const PAGE_TYPE = 'PAGE';

interface PageModuleProps {
  pageModuleProps: IModule;
  user: any;
}

export default function PageModule(props: PageModuleProps) {
  const isFrame = props.pageModuleProps.type === PAGE_TYPE;

  if (isFrame) {
    return <Frame source={props.pageModuleProps.source as string} id={props.pageModuleProps.moduleId} />;
  }

  return <Landing {...props.pageModuleProps} />;
}
