/**
 * Get the cryptographic hash of an ArrayBuffer
 *
 * @param ab - ArrayBuffer to digest
 * @param algorithm - Cryptographic hash digest algorithm
 * @returns Hexadecimal hash digest string
 */
export const getUnit8ArrayHash = async (
  algorithm: AlgorithmIdentifier,
  ab: ArrayBuffer | Promise<ArrayBuffer>
): Promise<string> =>
  new Uint8Array(await crypto.subtle.digest(algorithm, await ab)).reduce(
    (memo, i) => memo + i.toString(16).padStart(2, '0'),
    ''
  );
