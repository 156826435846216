import { useEffect, useState } from 'react';
import { faMeteor } from '@fortawesome/pro-regular-svg-icons';
import { useVisibilityState } from 'webrix/hooks';
import cx from 'classnames';
import { usePrevious } from 'webrix/hooks';
import _pickBy from 'lodash.pickby';
import Icon from 'lib/common/components/Icon';
import TaskOverlayBanner from 'lib/common/components/TaskOverlayBanner';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import getMappedContactAttributes from 'lib/common/utils/getMappedContactAttributes';
import CONTACT_TYPE from 'lib/common/constants/contactTypes';

import TTask from 'lib/common/types/Task';

import COLOURS from 'css/export-vars.module.scss';
import hasIncomingContactAttributes from './hasIncomingContactAttributes';
import './incoming-task-overlay.scss';

interface ITaskOverlays {
  filteredTasks: TTask[];
  hasMultipleIncomingTasks: boolean;
  openMultipleTaskList: boolean;
  commonStyles: (string | Record<string, boolean>)[];
  onOverlayOpen: (taskId: string) => void;
  openedTask: string;
}

const TaskOverlays = ({
  filteredTasks,
  hasMultipleIncomingTasks,
  openMultipleTaskList,
  commonStyles,
  onOverlayOpen,
  openedTask
}: ITaskOverlays) => {
  if (!hasMultipleIncomingTasks) {
    const task = filteredTasks[0];
    const attributes = getMappedContactAttributes(task?.contact, task?.type);
    const contactAttributes = attributes ? _pickBy(attributes, (attribute) => Boolean(attribute.isIncoming)) : void 0;
    return (
      <div className={cx(...commonStyles, 'panel--elevated panel--small')}>
        <TaskOverlayBanner
          task={task}
          overlayOpen
          showQueueDetails
          showContactAttributes={hasIncomingContactAttributes(contactAttributes)}
          attributes={contactAttributes}
        />
      </div>
    );
  }

  if (openMultipleTaskList) {
    return (
      <div className={cx(...commonStyles, 'panel--elevated panel--small', 'incoming-task-overlay__content--multiple')}>
        {filteredTasks.map((task) => {
          const attributes = getMappedContactAttributes(task?.contact, task?.type);
          const contactAttributes = attributes
            ? _pickBy(attributes, (attribute) => Boolean(attribute.isIncoming))
            : void 0;

          return (
            <div key={task.taskId}>
              <TaskOverlayBanner
                task={task}
                overlayOpen={openedTask === task.taskId}
                onOverlayOpen={onOverlayOpen}
                showQueueDetails
                showExpandAction
                showContactAttributes={hasIncomingContactAttributes(contactAttributes)}
                attributes={contactAttributes}
              />
              <div className="incoming-task-overlay__content__task-gap" />
            </div>
          );
        })}
      </div>
    );
  }

  return null;
};

export default function IncomingTaskOverlay({ tasks }: { tasks: TTask[] }) {
  const [openedTask, setOpenedTask] = useState('');
  const { visible: openMultipleTaskList, toggle } = useVisibilityState(false);
  const {
    state: { incomingTaskPlacement }
  } = usePreferencesContext();

  const onOverlayOpen = (taskId) => {
    setOpenedTask(taskId);
  };

  const onContentBubbleClick = () => {
    toggle();
    setOpenedTask('');
  };

  const filteredTasks = tasks.filter(
    (task) => task.status === CONTACT_STATES.CONNECTING && task.type !== CONTACT_TYPE.CONFERENCE_CALL
  );

  useEffect(() => {
    if (!filteredTasks?.length) {
      return;
    }

    setOpenedTask(filteredTasks[0].taskId);
  }, [filteredTasks?.length, openMultipleTaskList]);

  const hasMultipleIncomingTasks = filteredTasks.length > 1;
  const show = Boolean(filteredTasks.length);

  const hasPreviousTasks = Boolean(usePrevious(filteredTasks).length);
  const outAnimation = hasPreviousTasks ? 'slideOutRight' : '';

  const top = incomingTaskPlacement === INCOMING_TASK_PLACEMENTS.TOP;

  const commonStyles = [
    'incoming-task-overlay__content',
    'animate__animated',
    `animate__${show ? 'slideInRight' : outAnimation}`,
    'animate__fast',
    { 'incoming-task-overlay__content--top': top }
  ];

  if (!filteredTasks?.length) {
    return null;
  }

  return (
    <div
      className={cx('incoming-task-overlay', { 'incoming-task-overlay--top': top })}
      style={{
        display: !show && !hasPreviousTasks ? 'none' : void 0
      }}
    >
      <TaskOverlays
        filteredTasks={filteredTasks}
        hasMultipleIncomingTasks={hasMultipleIncomingTasks}
        openMultipleTaskList={openMultipleTaskList}
        commonStyles={commonStyles}
        onOverlayOpen={onOverlayOpen}
        openedTask={openedTask}
      />
      {hasMultipleIncomingTasks && (
        <div className={cx(...commonStyles, { 'incoming-task-overlay__content--small': hasMultipleIncomingTasks })}>
          <button
            aria-label="Toggle task overlay"
            className="incoming-task-overlay__content__bubble"
            onClick={onContentBubbleClick}
            data-testid="incoming-task-overlay-bubble"
          >
            <Icon size={30} icon={faMeteor} color="white" />
            <span
              className="incoming-task-overlay__content__bubble__quantity"
              style={{ background: COLOURS.white }}
              data-testid="incoming-task-overlay-quantity"
            >
              {filteredTasks.length}
            </span>
          </button>
        </div>
      )}
    </div>
  );
}
