import cx from 'classnames';
import isURL from 'validator/lib/isURL';
import { FUSION_DATA_BLOCK_TYPES } from '../../../constants/fusionDataBlockTypes';
import Text from '../../Text';
import Badge from '../../Badge';
import { TBadgeType } from '../../Badge/Badge';
import styles from './fusion-data.module.scss';

interface IFusionData {
  id: string;
  data: any;
  label: string;
  callback?: () => void;
  type: 'string' | 'phone-number' | 'badge-list' | 'accordion' | 'dynamic';
}

const ValueDisplay = ({
  attribute: { data, badgeType },
  isSoftphone
}: {
  attribute: { data: unknown; badgeType?: TBadgeType };
  isSoftphone?: boolean;
}) => {
  if (!data || !(typeof data === 'string')) {
    //should never get here but if someone messes up a parameter by setting it to "CW-NEON_"
    return null;
  }

  if (badgeType) {
    return <Badge label={data} type={badgeType} />;
  }

  if (isURL(data)) {
    return (
      <Text
        type="link"
        className={cx(styles['fusion-data__paragraph--link'], {
          [styles['fusion-data__paragraph--softphone']]: isSoftphone
        })}
        rel="noreferrer"
        href={data}
      >
        {data}
      </Text>
    );
  }

  return (
    <Text
      type="body"
      className={cx(styles['fusion-data__paragraph'], {
        [styles['fusion-data__paragraph--softphone']]: isSoftphone
      })}
    >
      {data}
    </Text>
  );
};

export const DynamicAttributesBlock = ({
  fusionData,
  isSoftphone
}: {
  fusionData: IFusionData[];
  isSoftphone?: boolean;
}) => {
  const dynamicAttributeDetails = fusionData.filter(({ type }) => type === FUSION_DATA_BLOCK_TYPES.DYNAMIC);

  if (!dynamicAttributeDetails.length) {
    return null;
  }

  return (
    <>
      {dynamicAttributeDetails.map((attribute, index) => {
        const { label, id } = attribute;
        return (
          <div key={`${id}-${index}`} className={styles['fusion-data__block']}>
            <Text
              inline={isSoftphone}
              color="darkBlue"
              className={cx(styles['fusion-data__label'], {
                [styles['fusion-data__label--softphone']]: isSoftphone
              })}
            >
              {label}
            </Text>
            <ValueDisplay attribute={attribute} isSoftphone={isSoftphone} />
          </div>
        );
      })}
    </>
  );
};
