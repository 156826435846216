export const ATTRIBUTE_FIELDS = {
  PHONE_NUMBER: 'phoneNumber',
  NAME: 'name',
  PROFILE_ID: 'profileId',
  ACCOUNT_NUMBER: 'accountNumber',
  EMAIL: 'emailAddress'
};

export default {
  [ATTRIBUTE_FIELDS.PHONE_NUMBER]: '_phone',
  [ATTRIBUTE_FIELDS.NAME]: '_fullName',
  [ATTRIBUTE_FIELDS.PROFILE_ID]: '_profileId',
  [ATTRIBUTE_FIELDS.ACCOUNT_NUMBER]: '_account',
  [ATTRIBUTE_FIELDS.EMAIL]: '_email'
};
