import cx from 'classnames';
import { faCircleDown, faFile } from '@fortawesome/pro-regular-svg-icons';
import { format } from 'date-fns';
import _startCase from 'lodash.startcase';
import Markdown from 'markdown-to-jsx';

import Text from 'lib/common/components/Text';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import ParticipantRole from 'lib/common/constants/chat/ParticipantRole';
import { TChatMessage } from 'lib/common/types/chat/ChatMessage';
import Icon from 'lib/common/components/Icon';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import MessageStatus from 'lib/common/constants/chat/MessageStatus';
import chatEvents from 'lib/common/constants/chatEvents';
import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';
import Loader from './Loader';
import MessageStatusIcon from './MessageStatusIcon';
import ChatStartMessage from './components/ChatStartMessage';

import styles from './chat-message.module.scss';

interface IMessage {
  currentUserDisplayName?: string;
  message?: TChatMessage;
  customerName: string;
  lastMessageOfRole: boolean;
  isSmall?: boolean;
  invert?: boolean;
  hideDownloadButton?: boolean;
}

const MessageDisplay = ({
  message,
  hideDownloadButton = false
}: {
  message: TChatMessage;
  hideDownloadButton?: boolean;
}) => {
  const { Content, Type, Attachments } = message;
  const {
    actions: { downloadAttachment }
  } = useContactContext();

  if (Type === 'ATTACHMENT') {
    return (
      <div>
        {Attachments?.map((Attachment) => (
          <div className={styles['chat-message--attachment']} key={Attachment.AttachmentId}>
            <div className={styles['chat-message--attachment--preview']}>
              <Icon icon={faFile} size={17} />
              <Text className={styles['chat-message--content']} bold type="extraSmall">
                {Attachment.AttachmentName}
              </Text>
            </div>

            {!hideDownloadButton && (
              <ClickableIcon
                aria-label="Download attachment"
                icon={faCircleDown}
                size={17}
                asyncAction
                onClick={async () => {
                  const blob = await downloadAttachment(Attachment.AttachmentId);

                  if (!blob) {
                    return;
                  }
                  const tempLink = document.createElement('a');
                  const fileURL = window.URL.createObjectURL(blob);

                  tempLink.setAttribute('href', fileURL);
                  tempLink.setAttribute('download', Attachment.AttachmentName);
                  tempLink.click();

                  tempLink.remove();
                  window.URL.revokeObjectURL(fileURL);
                }}
              />
            )}
          </div>
        ))}
      </div>
    );
  }

  //For some reason complains if you dont use it as a child
  // eslint-disable-next-line react/no-children-prop
  return <Markdown target="_blank" className={styles['chat-message--content']} children={Content || ''} />;
};

export default function ChatMessage({
  message,
  customerName,
  lastMessageOfRole,
  isSmall,
  invert = false,
  currentUserDisplayName,
  hideDownloadButton = false
}: IMessage) {
  const { isSoftphone } = useLayout();
  const isSmallSoftphone = useIsSmallSoftphone();

  const isAgent = message?.ParticipantRole === ParticipantRole.AGENT;
  const isCurrentAgent = isAgent && currentUserDisplayName === message?.DisplayName;
  const isSystem = !isAgent && message?.ParticipantRole !== ParticipantRole.CUSTOMER;
  const displayName = message?.DisplayName || customerName;
  const isCustomerInverted = (!message || (!isAgent && !isSystem)) && invert;

  if (message?.Type === 'EVENT' && message?.ContentType === chatEvents.PARTICIPANT_JOINED) {
    return <ChatStartMessage message={message} customerName={customerName} />;
  }

  return (
    <div
      className={cx(styles['chat-message'], {
        [styles['chat-message--small-softphone']]: isSmallSoftphone,
        [styles['chat-message--agent']]: message && (isAgent || isSystem),
        [styles['chat-message--system']]: message && isSystem,
        [styles['chat-message--customer']]: !message || (!isAgent && !isSystem),
        [styles['chat-message--customer--invert']]: isCustomerInverted,
        [styles['chat-message--customer--invert--softphone']]: isCustomerInverted && isSoftphone,
        [styles['chat-message--end']]: lastMessageOfRole,
        [styles['chat-message--small']]: isSmall,
        [styles['chat-message--loader']]: !message,
        [styles['chat-message--failed']]: message && message.messageStatus === MessageStatus.FAILED
      })}
      data-testid={message ? 'chat-message' : 'typing-message'}
    >
      {message && (
        <div className={styles['chat-message__header']}>
          <Text className={styles['chat-message__header__sender']} ellipsis bold>
            {isCurrentAgent ? 'You' : _startCase(displayName.toLowerCase())}
          </Text>
          <div className={styles['chat-message__header__time']}>
            <MessageStatusIcon message={message} />
            <Text className={styles['chat-message__header__time__text']}>
              {format(new Date(message?.AbsoluteTime), 'HH:mm')}
            </Text>
          </div>
        </div>
      )}
      {!message ? <Loader /> : <MessageDisplay message={message} hideDownloadButton={hideDownloadButton} />}
    </div>
  );
}
