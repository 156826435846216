import { faComment } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import styles from './chat-start-message.module.scss';

function getChatStartMessage({ displayName }) {
  if (displayName) {
    return `Chat started with ${displayName}`;
  }

  return 'Chat started';
}

export default function ChatStartMessage({ message, customerName }) {
  const displayName = message?.DisplayName || customerName;

  return (
    <div className={styles['chat-start-message']}>
      <Text type="label">
        <Icon icon={faComment} size={15} color="darkGrey" className="mr-5 mt-5" />
        {getChatStartMessage({ displayName })}
      </Text>
    </div>
  );
}
