import CHAT_EVENTS from 'lib/common/constants/chatEvents';

import throttle from 'lodash.throttle';
import getUserName from 'lib/common/utils/getUserName';
import TCustomerProfile from 'lib/common/contexts/ContactContext/types/CustomerProfile';
import MessageStatus from 'lib/common/constants/chat/MessageStatus';
import ParticipantRole from 'lib/common/constants/chat/ParticipantRole';
import { TFailedChat } from 'lib/common/types/chat/FailedChat';

import THandleContactChange from '../types/HandleContactChange';

type TArgs = {
  session: connect.ChatSession | null;
  taskId: string;
  handleContactChange: THandleContactChange;
  getSelectedTaskId: () => string | undefined;
  profile?: TCustomerProfile | null;
  icon: string;
};

const MESSAGE_DATA_TYPE = 'MESSAGE';
const ATTACHMENT_DATA_TYPE = 'ATTACHMENT';

// we throttle the notification so that the agent isn't spammed if a user is sending multiple messages
const THROTTLE_PERIOD = 3000;

//currently throttling all notifications however if we move this to within the onMessage handler it would be per conversation
const throttledNotifications = throttle(
  ({ name, message, logo }: { name?: string; message?: string; logo: string }) => {
    //we can return the notification object if we want to add it to the contact and close it when users switch items
    //we can also add an on click event to change currently selected task
    const notification = new Notification(`New Message From ${name}`, {
      body: `${message}`,
      icon: logo
    });
    notification.onclick = function () {
      window.focus();
    };
  },
  THROTTLE_PERIOD,
  { leading: true, trailing: true }
);

export default function registerChatHandlers({
  session,
  taskId,
  handleContactChange,
  getSelectedTaskId,
  profile,
  icon
}: TArgs) {
  if (!session) {
    return;
  }

  session.onMessage((event) => {
    const { data } = event;
    const isAttachmentOrMessage = data.Type === MESSAGE_DATA_TYPE || data.Type === ATTACHMENT_DATA_TYPE;
    const isTypingEvent = data.ContentType === CHAT_EVENTS.TYPING;

    if (!isAttachmentOrMessage || isTypingEvent) {
      return;
    }

    if (data.ContentType === 'application/json') {
      const failedChat = JSON.parse(data.Content ?? '') as TFailedChat;
      return handleContactChange({
        taskId,
        newChat: {
          messageStatus: {
            status: MessageStatus.FAILED,
            ...failedChat
          }
        }
      });
    }

    //if the task is not the currently selected one we have a new message
    const unreadMessage = getSelectedTaskId() !== taskId;

    //Only notify user if its not the currently selected task and thottle to 3 seconds so user isnt spammed
    if (unreadMessage && 'Notification' in window && Notification.permission === 'granted') {
      throttledNotifications({
        message: data.Content,
        name: getUserName({ ...profile, fallback: 'Customer' }),
        logo: icon
      });
    }

    handleContactChange({
      taskId,
      newChat: {
        message: data,
        customerTyping: data.ParticipantRole === ParticipantRole.CUSTOMER ? false : void 0,
        unreadMessage,
        messageStatus:
          data.ParticipantRole !== ParticipantRole.CUSTOMER
            ? {
                messageId: data.Id,
                status: MessageStatus.SENT
              }
            : void 0
      }
    });
  });

  session.onTyping((event) => {
    const { data } = event;

    if (data.ParticipantRole !== ParticipantRole.CUSTOMER) {
      return;
    }

    handleContactChange({ taskId, customerTyping: true });

    // Remove typing loader after 10 seconds in case customer stopped typing or erased the message
    const timeout = setTimeout(() => {
      handleContactChange({ taskId, customerTyping: false });
    }, 10000);

    return () => clearTimeout(timeout);
  });

  session.onDeliveredReceipt((event) => {
    const { data } = event;

    if (data.ContentType !== CHAT_EVENTS.MESSAGE_METADATA || !data.MessageMetadata) {
      return;
    }

    handleContactChange({
      taskId,
      newChat: {
        messageStatus: {
          messageId: data.MessageMetadata.MessageId,
          status: MessageStatus.DELIVERED
        }
      }
    });
  });

  session.onReadReceipt((event) => {
    const { data } = event;

    if (data.ContentType !== CHAT_EVENTS.MESSAGE_METADATA || !data.MessageMetadata) {
      return;
    }

    handleContactChange({
      taskId,
      newChat: {
        messageStatus: {
          messageId: data.MessageMetadata.MessageId,
          status: MessageStatus.READ
        }
      }
    });
  });
}
