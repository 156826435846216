import { Component } from 'react';
import { components } from 'react-select';
import { faAngleDown } from '@fortawesome/pro-regular-svg-icons';
import { parsePhoneNumber, getCountryCodeForRegionCode } from 'awesome-phonenumber';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';
import ReactSelect from 'lib/common/components/ReactSelect';
import { Input } from 'lib/common/components/Input';
import COUNTRIES from './constants/countries.json';
import DEFAULT_COUNTRY_CODES from './constants/defaultCountryCodes';

import { IPhoneInput, IPhoneInputState, TSelectedCountry } from './PhoneInputTypes';
import selectStyles from './selectStyles';
import CountryFlag from './components/CountryFlag';

import './phone-input.scss';

interface IPhoneInputProps extends IPhoneInput {
  agent?: connect.Agent | null;
  autoFocus?: boolean;
  width?: number;
  fontSize?: number;
  variant?: 'standard';
  label?: string;
  testId?: string;
  getStorageItem: (key: string) => string | null;
  setStorageItem: (key: string, value: string) => void;
  isSoftphone: boolean;
  deployRegionCode?: string;
  'aria-label'?: string;
}

// Deploy region codes are lower case and come from env/config
const DEFAULT_DEPLOY_REGION_CODE = 'au';

// Country codes used by phone input are upper case, as per the countries.json file
const US_COUNTRY_CODE = 'US';

const CODE_STORAGE_KEY = 'phone-input-code';

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon icon={faAngleDown} size={15} />
    </components.DropdownIndicator>
  );
};

const unsupportedCountry = {
  value: '',
  label: (
    <div className="phone-input__countries__country-option" data-testid="unsupported-country-option">
      <CountryFlag />
    </div>
  )
};

function countryOption({ name, dialCode, countryCode }) {
  return {
    value: countryCode,
    label: `${name} ${dialCode}`,
    name,
    dialCode,
    countryCode
  };
}

const SingleValue = (props) => {
  const { countryCode } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <CountryFlag code={countryCode} />
    </components.SingleValue>
  );
};

const Option = (props) => {
  const { name, countryCode, dialCode } = props.data;

  return (
    <components.Option {...props}>
      <div className="phone-input__countries__country-option" data-testid={countryCode.toLowerCase()}>
        <CountryFlag code={countryCode} />
        <div className="country-details-selected">
          <div className="phone-input__countries__country-details">
            <div>{name}</div>
            <div>{dialCode}</div>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

function generateCountryOptions(countries) {
  const availableCountries = countries
    ? COUNTRIES.filter((country) => countries.includes(country.countryCode.toLowerCase()))
    : COUNTRIES;

  return availableCountries.map((country) => countryOption(country));
}

class PhoneInput extends Component<IPhoneInputProps, IPhoneInputState> {
  defaultRegionCountryCode: string;

  constructor(props: IPhoneInputProps) {
    super(props);

    const { getStorageItem, setStorageItem, deployRegionCode } = props;

    this.defaultRegionCountryCode = DEFAULT_COUNTRY_CODES[deployRegionCode || DEFAULT_DEPLOY_REGION_CODE];

    const availableCountries = generateCountryOptions(props.countries);
    const storedCode = getStorageItem(CODE_STORAGE_KEY);
    const defaultCode = storedCode || this.defaultRegionCountryCode;
    const selectedCountry = this.findCountry(availableCountries, props.country || defaultCode, { useFallback: true });

    if (!storedCode && availableCountries.includes(selectedCountry)) {
      setStorageItem(CODE_STORAGE_KEY, selectedCountry.value);
    }

    this.state = {
      value: props.initialValue || '',
      selectedCountry,
      availableCountries,
      setStorageItem
    };
  }

  componentDidUpdate(_, prevState) {
    const { selectedCountry: { value: countryCode } = {} } = this.state;

    const { selectedCountry: { value: previousCountryCode } = { value: '' } } = prevState;

    if (!countryCode || previousCountryCode === countryCode) {
      return;
    }

    // Whenever the selected country changes, write the value to local storage (becomes default on next fresh load)
    this.state.setStorageItem(CODE_STORAGE_KEY, countryCode);
  }

  findCountry = (countries, countryCode, { useFallback }: { useFallback?: boolean } = {}) => {
    const country = countries.find((countryOption) => countryOption.value.toLowerCase() === countryCode.toLowerCase());

    if (country) {
      return country;
    }

    if (!useFallback) {
      return unsupportedCountry;
    }

    return (
      countries.find(
        (countryOption) => countryOption.value.toLowerCase() === this.defaultRegionCountryCode.toLowerCase()
      ) ||
      countries.find((countryOption) => countryOption.value.toLowerCase() === US_COUNTRY_CODE.toLowerCase()) ||
      countries[0]
    );
  };

  handleCountryChange = () => {
    const { value, selectedCountry, availableCountries } = this.state;
    const { onChange } = this.props;

    if (!value) {
      return;
    }

    const parsedNumber = parsePhoneNumber(value, selectedCountry.value);
    const internationalNumber = parsedNumber.getCountryCode();

    if (internationalNumber) {
      const isCountrySupported = availableCountries.find((country) => country.value === selectedCountry.value);
      const newNumber = isCountrySupported
        ? value.replace(`${parsedNumber.getCountryCode()}`, `${getCountryCodeForRegionCode(selectedCountry.value)}`)
        : value;

      this.setState({ value: newNumber });

      return onChange(parsePhoneNumber(newNumber, selectedCountry.value).getNumber('e164') || value);
    }

    return onChange(parsedNumber.getNumber('e164') || value);
  };

  handleValueChange = (latestValue) => {
    const { availableCountries, selectedCountry } = this.state;
    const { onChange } = this.props;

    this.setState({ value: latestValue });

    if (!latestValue) {
      return onChange('');
    }

    const parsedNumber = parsePhoneNumber(latestValue, selectedCountry.value);
    const numberHasCountryCode = parsedNumber.getCountryCode();

    if (numberHasCountryCode) {
      this.setState(
        {
          selectedCountry: this.findCountry(availableCountries, parsedNumber.getRegionCode())
        },
        this.handleCountryChange
      );
    }

    return onChange(parsedNumber.getNumber('e164') || '');
  };

  render() {
    const {
      onBlur,
      onEnterKeyPress,
      id,
      helperText,
      onFocus,
      disabled,
      error,
      inputRef,
      autoFocus,
      testId,
      endInputAdornment,
      label,
      variant,
      fontSize,
      width,
      isSoftphone
    } = this.props;
    const { value, availableCountries, selectedCountry } = this.state;

    const handleKeyDown = (e) => {
      if (!onEnterKeyPress || e.key !== 'Enter') {
        return;
      }

      onEnterKeyPress();
    };

    return (
      <div style={{ width }} className="phone-input">
        <Input
          aria-label={this.props['aria-label'] || 'Phone input'}
          variant={variant || 'outlined'}
          InputLabelProps={{ shrink: true }}
          label={label}
          autoFocus={autoFocus}
          type="tel"
          autoComplete="off"
          id={id}
          InputProps={{
            className: cx('phone-input__input', { ['phone-input__input--softphone']: isSoftphone }),
            startAdornment: (
              <div className="phone-input__countries-container">
                <ReactSelect
                  aria-label="Country select"
                  name="select-flag"
                  placeholder=""
                  options={availableCountries}
                  styles={selectStyles}
                  classNamePrefix="phone-input__countries"
                  className="phone-input__countries"
                  onChange={(country) => {
                    this.setState({ selectedCountry: country as TSelectedCountry }, this.handleCountryChange);
                  }}
                  tabIndex={0}
                  value={selectedCountry}
                  isDisabled={disabled}
                  onBlur={onBlur}
                  onFocus={onFocus}
                  components={{ DropdownIndicator, Option, SingleValue }}
                  data-testid="country-select"
                />
                <div className="phone-input__countries__separator"></div>
              </div>
            ),
            endAdornment: endInputAdornment,
            style: { fontSize }
          }}
          value={value}
          onChange={(e) => this.handleValueChange(e.target.value)}
          onBlur={onBlur}
          disabled={disabled}
          onFocus={onFocus}
          inputRef={inputRef}
          onKeyDown={handleKeyDown}
          fullWidth
          error={error}
          helperText={helperText}
          inputProps={{
            'data-testid': testId || this.props['data-testid'] || 'phone-input'
          }}
        />
      </div>
    );
  }
}

export default PhoneInput;
