function normaliseKey(key, { reverse }) {
  return key[0][reverse ? 'toUpperCase' : 'toLowerCase']() + key.substring(1);
}

function normaliseValue(value, { reverse, noFallback }) {
  return typeof value === 'string' ? value : normaliseAWSObjectKeys(value, { reverse, noFallback });
}

export default function normaliseAWSObjectKeys(
  object?: Record<string, any>,
  { reverse, noFallback }: { reverse?: boolean; noFallback?: boolean } = {}
) {
  if (!object) {
    return noFallback ? object : {};
  }

  return Object.entries(object).reduce(
    (normalisedObject, [key, value]) => ({
      ...normalisedObject,
      [normaliseKey(key, { reverse })]: normaliseValue(value, { reverse, noFallback: true })
    }),
    {}
  );
}
