import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import isURL from 'validator/lib/isURL';
import cx from 'classnames';

import Button from 'lib/common/components/Button';
import { Input } from 'lib/common/components/Input';
import ClickableIcon from 'lib/common/components/ClickableIcon';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import '../styles/create-agent-task.scss';
import '../styles/task-references.scss';

export default function TaskReferences({ references, onUpdateReference, removeReference, addReference }) {
  const { isSoftphone } = useLayout();

  return (
    <div
      className={cx('create-agent-task', {
        'create-agent-task--padding': isSoftphone
      })}
    >
      {references.map((reference, index) => (
        <div className="task-references__reference" key={reference.id}>
          <div>
            <div className="create-agent-task__text-field">
              <Input
                ariaRequired
                label="Name"
                fullWidth
                value={reference.name}
                type="url"
                onChange={onUpdateReference(index, 'name')}
                data-testid="create-task-reference-name-input"
              />
            </div>
            <div className="create-agent-task__text-field">
              <Input
                id="references-link"
                ariaRequired
                label="Link"
                fullWidth
                value={reference.url}
                error={Boolean(reference.url && !isURL(reference.url))}
                helperText={Boolean(reference.url && !isURL(reference.url)) && 'Reference link should be a valid URL'}
                type="url"
                onChange={onUpdateReference(index, 'url')}
                data-testid="create-task-reference-url-input"
              />
            </div>
          </div>
          <ClickableIcon aria-label="Remove reference" icon={faTrash} onClick={removeReference(index)} />
        </div>
      ))}
      {isSoftphone && (
        <Button
          className={cx('task-references__button', { 'task-references__button--only-child': !references.length })}
          onClick={addReference}
          round={false}
          styleType="SECONDARY"
        >
          Add Reference
        </Button>
      )}
    </div>
  );
}
