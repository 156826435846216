import cx from 'classnames';
import { faPenRuler } from '@fortawesome/pro-regular-svg-icons';

import getUserName from 'lib/common/utils/getUserName';
import TTask from 'lib/common/types/Task';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import Icon from 'lib/common/components/Icon';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';
import TaskActions from 'lib/common/components/TaskActions';
import { getTaskDisplayProps, TASK_COLOURS } from 'lib/common/constants/taskStates';
import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import Text from 'lib/common/components/Text';

import { NotificationDot } from 'lib/common/components/NotificationDot';

import styles from './task.module.scss';

interface TTaskListTask extends TTask {
  selected: boolean;
  onSelect: (contactId: string) => void;
  listIndex: number;
  selectedTaskIndex: number;
  unreadMessage?: boolean;
}

const BASE_TOP_PX = 140;
const TASK_HEIGHT = 50;
const SELECTED_TASK_TOP_PX = 35;

function SubtitleSuffix({ time }: { time?: Date }) {
  const taskTime = useTaskTimer(time);

  return (
    <Text type="inline" color="text">
      {' '}
      | {taskTime}
    </Text>
  );
}

export default function Task(task: TTaskListTask) {
  const {
    status,
    contact,
    selected,
    type,
    profile,
    time,
    taskId,
    connectionValue,
    connectionType,
    onSelect,
    listIndex,
    selectedTaskIndex,
    unreadMessage
  } = task;
  const taskProps = getTaskDisplayProps(task);
  const title = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const subtitleToDisplay = taskProps?.subtitle || '';

  const onSelectTask = () => {
    onSelect(contact.contactId);
  };

  const isIncoming = status === CONTACT_STATES.CONNECTING;
  const isDraft = status === CONTACT_STATES.DRAFT;
  const isNeglected = status === CONTACT_STATES.REJECTED || status === CONTACT_STATES.MISSED;
  const selectedNotIncoming = selected && !isIncoming;

  const actualIndex = listIndex < selectedTaskIndex ? listIndex : listIndex - 1;
  const gapPx = actualIndex ? 10 : 0;
  const totalGapPx = gapPx * actualIndex;

  return (
    <div
      className={cx(styles['task__content'], {
        [styles['task__content--incoming']]: isIncoming,
        [styles['task__content--selected']]: selectedNotIncoming
      })}
      style={{
        position: 'absolute',
        top: selected ? `${SELECTED_TASK_TOP_PX}px` : `${BASE_TOP_PX + totalGapPx + actualIndex * TASK_HEIGHT}px`
      }}
      onClick={!selected && !isIncoming ? onSelectTask : void 0}
    >
      <div
        className={cx(styles['task__content__icon'], {
          [styles['task__content__icon--selected']]: selected,
          [styles['task__content__icon--neglected']]: isNeglected
        })}
      >
        {unreadMessage && <NotificationDot />}
        <Icon
          size={15}
          color={selected || isNeglected ? 'white' : void 0}
          icon={taskProps?.icon}
          onlyConstrain={IconSizeConstraints.HEIGHT}
        />
      </div>
      <div className={styles['task__content__text']} data-testid={title}>
        <Text ellipsis bold>
          {title}
        </Text>
        <Text
          className={styles['task__content__text__subtitle']}
          ellipsis
          color={selectedNotIncoming ? void 0 : TASK_COLOURS[status]}
        >
          {subtitleToDisplay}
          {(!selected || isIncoming) && <SubtitleSuffix time={time} />}
        </Text>
      </div>
      {isDraft ? (
        <Icon icon={faPenRuler} size={17} />
      ) : (
        <TaskActions taskId={taskId} status={status} type={type} connectionType={connectionType} round />
      )}
    </div>
  );
}
