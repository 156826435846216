import getWrapUpCodesPathname from 'lib/common/utils/ACW/getWrapUpCodesPathname';

export default async function getACWWrapUpCodes({ fetch_, config }) {
  try {
    const result = await fetch_(`${config.CALL_SERVICE_URL}/user/wrap-up-codes/?tenantId=${config.TENANT_ID}`, {
      method: 'GET'
    });
    const codes = (await result.json()).data;

    if (!codes) {
      return [];
    }

    return getWrapUpCodesPathname(codes);
  } catch {
    return [];
  }
}
