import { useState } from 'react';
import cx from 'classnames';

import Button from 'lib/common/components/Button';
import Input from './Input';
import styles from './text-area-with-action.module.scss';

export const TextAreaWithAction = ({
  value,
  placeholder,
  onInputChange,
  onActionClick,
  buttonIcon,
  buttonTooltip,
  buttonTestId,
  inputClassName
}: {
  value?: string;
  placeholder: string;
  onInputChange: (any) => any; //replication of MUI lib
  onActionClick: (any) => Promise<any>; //replication of MUI lib
  buttonIcon: string;
  buttonTooltip: string;
  buttonTestId: string;
  inputClassName?: string;
}) => {
  const [isCompletingAsyncAction, setIsCompletingAsyncAction] = useState(false);

  return (
    <div className={styles['text-area__container']}>
      <Input
        multiline
        minRows={4}
        placeholder={placeholder}
        maxRows={8}
        value={value}
        onChange={onInputChange}
        className={cx(styles['text-area__input'], inputClassName)}
        disabled={isCompletingAsyncAction}
      />
      <div className={styles['text-area__action']}>
        <Button
          onClick={(...args) => {
            setIsCompletingAsyncAction(true);
            return onActionClick(...args);
          }}
          asyncAction
          round
          icon={buttonIcon}
          onFinally={() => {
            setIsCompletingAsyncAction(false);
          }}
          size="small"
          styleType="SECONDARY"
          tooltip={buttonTooltip}
          testId={buttonTestId}
        />
      </div>
    </div>
  );
};
