import { Permissions, TPermissions } from '@cloud-wave/neon-common-lib';

import { TConnectPermissions, TPermissionTypes } from 'lib/common/types/Permissions';
import getContactAttributes from 'lib/common/utils/getContactAttributes';

export const getCanUseAttachments = (
  isConnectWebChat: boolean,
  hasPermission: ({
    type,
    permission
  }: {
    type?: TPermissionTypes;
    permission?: TPermissions | TConnectPermissions | null;
  }) => boolean,
  task
) => {
  if (isConnectWebChat) {
    return hasPermission({
      type: 'tenant',
      permission: Permissions.CHAT_ATTACHMENTS
    });
  }

  const contactAttributeEnabled = getContactAttributes(
    { task },
    'neonChatPlatformAttachments'
  ).neonChatPlatformAttachments;

  return contactAttributeEnabled === 'true' || false;
};
