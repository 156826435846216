function padTo2Digits(number) {
  return number.toString().padStart(2, '0');
}

export default function convertMSToReadableTime(milliseconds) {
  if (milliseconds < 0) {
    return '00:00';
  }

  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const hoursString = hours % 24 ? `${padTo2Digits(hours % 24)}:` : '';
  const minutesString = padTo2Digits(minutes % 60);
  const secondsString = padTo2Digits(seconds % 60);

  return `${hoursString}${minutesString}:${secondsString}`;
}
