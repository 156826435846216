import SegmentedControls from 'lib/common/components/SegmentedControls';
import ReactSelect from 'lib/common/components/ReactSelect';

import { Tab } from '../useCreateAgentTask';

import '../styles/create-agent-task.scss';

export default function TaskLocation({
  setSelectedTab,
  loading,
  selectedEntity,
  selectedTab,
  optionsList,
  setSelectedEntity
}) {
  return (
    <>
      <div className="create-agent-task__tabs">
        <SegmentedControls
          ariaLabel="Task location"
          options={Object.values(Tab).map((tab) => ({
            label: tab,
            value: tab
          }))}
          onSelect={setSelectedTab}
          initialIndices={0}
        />
      </div>
      <div role="tabpanel">
        <ReactSelect
          id="create-task-assign-select"
          classNamePrefix="select"
          isLoading={loading}
          value={selectedEntity || ''}
          placeholder={`Select a${selectedTab === Tab.QUEUE ? ' queue' : 'n agent'}`}
          options={optionsList}
          onChange={(newValue) => setSelectedEntity(newValue)}
        />
      </div>
    </>
  );
}
