import { createContext, useContext } from 'react';

import TContactContext from './types/ContactContext';
import defaultContextValues from './constants/defaultContextValues';

const Context = createContext<TContactContext>(defaultContextValues);

export const useContactContext = () => useContext(Context);

export default Context;
