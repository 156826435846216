import Text from 'lib/common/components/Text';
import { ITextProps } from 'lib/common/components/Text/types';

import styles from './highlight-text.module.scss';

const normaliseString = (str: string) => str.trim().toLowerCase().replaceAll(' ', '');

export default function HighlightText({
  text,
  highlight,
  className,
  ...textProps
}: {
  text?: string;
  highlight?: string;
  className?: string;
} & Omit<ITextProps, 'children'>) {
  if (!text) {
    return null;
  }

  if (!highlight?.trim()) {
    return (
      <Text className={className} {...textProps}>
        {text}
      </Text>
    );
  }

  // Split text based on highlight value and returns an array including the split value
  const parts = text.split(new RegExp(`(${highlight.trim().replace(/  +/g, '').split('').join('\\s*')})`, 'gi'));

  return (
    <Text className={className} {...textProps}>
      {parts.map((part: string) => (
        <>
          {normaliseString(part) === normaliseString(highlight) ? (
            <span className={styles['highlight-text__part']}>{part}</span>
          ) : (
            part
          )}
        </>
      ))}
    </Text>
  );
}
