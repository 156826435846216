import { setContext, setUser } from '@sentry/browser';
import { useEffect, useState } from 'react';

import connectGetter from 'lib/common/utils/connectGetter';
import EventEmitter from 'lib/common/utils/EventEmitter';

import registerAgentHandlers from './utils/registerAgentHandlers';
import Context from './Context';

export default function AgentProvider({ children }) {
  const [agent, setAgent] = useState<connect.Agent | null>(null);

  const handleNewAgent = (agent: connect.Agent) => {
    const config = connectGetter(agent, 'getConfiguration') || { username: '' };

    sessionStorage.setItem('c_user', config.username);

    EventEmitter.emit('onInit', config);

    setAgent(agent);

    // Set Sentry contexts for reporting
    setContext('user', config);
    setUser({ username: config.username });

    registerAgentHandlers(agent);
  };

  useEffect(() => {
    const connect = (window as any).getConnect();

    connect.agent(handleNewAgent);
  }, []);

  return (
    <Context.Provider
      value={{
        agent,
        routingProfile: connectGetter(agent, 'getConfiguration')?.routingProfile?.routingProfileId?.split('/')[3],
        states: connectGetter(agent, 'getAgentStates') || [],
        channelConcurrencyMap: connectGetter(agent, 'getConfiguration')?.routingProfile?.channelConcurrencyMap
      }}
    >
      {children}
    </Context.Provider>
  );
}
