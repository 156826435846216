import { forwardRef } from 'react';

import { useConfigContext } from 'lib/core/config';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import PurePhoneInput from '../PhoneInput';

function PhoneInput(props, ref) {
  const { isSoftphone } = useLayout();
  const { getStorageItem, setStorageItem } = useLocalStorage();
  const {
    config: { DEPLOY_REGION_CODE }
  } = useConfigContext();

  return (
    <PurePhoneInput
      {...props}
      deployRegionCode={DEPLOY_REGION_CODE}
      ref={ref}
      isSoftphone={isSoftphone}
      getStorageItem={getStorageItem}
      setStorageItem={setStorageItem}
    />
  );
}

export default forwardRef(PhoneInput);
