import cx from 'classnames';

import { chatPlatformHasCustomIcon, getTaskDisplayProps } from 'lib/common/constants/taskStates';
import TChatTask from 'lib/common/types/ChatTask';
import Icon from 'lib/common/components/Icon';
import { IconSizeConstraints } from 'lib/common/components/Icon/Icon';

import styles from './chat-platform-badge.module.scss';

export interface ChatPlatformBadgeProps {
  task: TChatTask;
}

export default function ChatPlatformBadge({ task }: ChatPlatformBadgeProps) {
  const { icon, tooltip } = getTaskDisplayProps(task);
  const { neonChatPlatform } = task;

  const useFallBack = !chatPlatformHasCustomIcon(neonChatPlatform);

  const iconEl = (
    <Icon
      tooltip={tooltip}
      data-testid={`chat-platform-${tooltip}`}
      icon={icon}
      color={useFallBack ? 'darkBlue' : 'white'}
      onlyConstrain={IconSizeConstraints.HEIGHT}
      size={15}
    />
  );

  const badgeSubClass = `chat-platform-badge__${useFallBack ? 'fallback' : neonChatPlatform.toLowerCase()}`;

  return (
    <div data-testid={badgeSubClass} className={cx(styles['chat-platform-badge'], styles[badgeSubClass])}>
      {iconEl}
    </div>
  );
}
