import { TCreateContactBody } from '@cloud-wave/neon-common-lib';
import { TConfig } from 'lib/common/types/Config';

export default function createDirectoryContact({
  config,
  fetch_,
  contact
}: {
  config: TConfig;
  fetch_: (url, options) => Promise<any>;
  contact: TCreateContactBody;
}) {
  return fetch_(`${config.DIRECTORY_SERVICE_URL}/user/contact`, {
    method: 'POST',
    body: JSON.stringify(contact)
  });
}
