import { useEffect, useRef } from 'react';

function clearOptionalTimeout(timeout) {
  if (!timeout || !timeout.current) {
    return;
  }

  clearTimeout(timeout.current);
}

export default function useTimeout() {
  const timeout = useRef<null | ReturnType<typeof setTimeout>>(null);

  const clearTimeout = () => {
    clearOptionalTimeout(timeout);
  };

  const setTimeoutRef = (...args: Parameters<typeof setTimeout>) => {
    timeout.current = setTimeout(...args);
  };

  // Clean up any pending timeout on unmount
  useEffect(() => clearTimeout, []);

  return { clearTimeout, setTimeoutRef };
}
