import EventEmitter from 'lib/common/utils/EventEmitter';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import TUser from 'lib/common/types/User';
import TFetch from 'lib/common/types/Fetch';
import { TConfig } from 'lib/common/types/Config';

import addInteractionHistoryItem from '../utils/addInteractionHistoryItem';
import THandleContactChange from '../types/HandleContactChange';

interface IHandleConnectedContact {
  contact: connect.Contact;
  handleContactChange: THandleContactChange;
  fetch_: TFetch;
  config: TConfig;
  tokens?: any;
  getUser: () => TUser | null;
}

export default async function handleConnectedContact({
  contact,
  handleContactChange,
  fetch_,
  config,
  tokens,
  getUser
}: IHandleConnectedContact) {
  handleContactChange({ contact, status: CONTACT_STATES.CONNECTED, eventTimestamp: Date.now() });

  EventEmitter.emit('onContactConnected', contact);

  const user = getUser();

  if (!user) {
    return;
  }

  addInteractionHistoryItem({ contact: contact, fetch_, config, tokens, user });
}
