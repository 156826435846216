import { logger } from 'lib/common/components/LoggerController';
import { CONTACT_NOT_FOUND_EXCEPTION, DEFAULT_ERROR_MSG } from '../../constants/connectExceptions';
import parseJSON from '../parseJSON';
import toast from '../toast';
import { getLogEvent } from './getLogEvent';

const OUTBOUND_CALL_NOT_SUPPORTED_MSG = 'Your contact centre does not support dialing to this country yet.';
const BAD_EXCEPTION = 'BadEndpointException';
const UNSUPPORTED_COUNTRIES_EXCEPTIONS = [
  'Cannot dial third party destination: Phone number is not in dialable countries.',
  'Cannot create new connection by phone number: Phone number is not in dialable countries.'
];

const HIDE_TOAST_FOR_EXCEPTIONS = [CONTACT_NOT_FOUND_EXCEPTION];

export default async function connectAction(
  action: string,
  entity?: connect.Contact | connect.BaseConnection | connect.Agent | null,
  payload?: any,
  config?: {
    retries?: number;
    errorMsg?: string;
    optionalPayloads?: any;
    extraCallbacks?: any;
    ignoreError?: (arg0: Error) => boolean | Promise<boolean>;
  }
): Promise<any> {
  if (!entity) {
    return Promise.resolve();
  }

  const { retries = 3, errorMsg, optionalPayloads = [], extraCallbacks = {} } = config || {};

  try {
    const result = await new Promise<void>((resolve, reject) => {
      const callbacks = {
        success: resolve,
        failure: (e) => {
          reject(e);
        },
        ...extraCallbacks
      };

      const firstArgument = payload || callbacks;
      const secondArgument = payload ? callbacks : void 0;

      entity[action](firstArgument, secondArgument, ...optionalPayloads);
    });

    const LOG_EVENT = getLogEvent(entity, action);

    if (LOG_EVENT) {
      logger.info(LOG_EVENT, result!);
    }

    return result;
  } catch (e: any) {
    if (await config?.ignoreError?.(e)) {
      return Promise.resolve();
    }

    if (retries === 0) {
      const LOG_EVENT = getLogEvent(entity, action, false);

      if (LOG_EVENT) {
        logger.error(LOG_EVENT, e);
      }

      const { type, message = '' } = parseJSON(e) || {};

      const connectErrorMsg =
        type === BAD_EXCEPTION && UNSUPPORTED_COUNTRIES_EXCEPTIONS.includes(message)
          ? OUTBOUND_CALL_NOT_SUPPORTED_MSG
          : DEFAULT_ERROR_MSG;

      if (!HIDE_TOAST_FOR_EXCEPTIONS.includes(type)) {
        console.error(action, e);
        toast('error', errorMsg || connectErrorMsg);
      }

      return Promise.reject(e);
    }

    return connectAction(action, entity, payload, { ...config, retries: retries - 1 });
  }
}
