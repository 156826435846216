import { createContext, RefObject, useContext } from 'react';

const SoftphoneLayoutContext = createContext<{
  contentWidth: number;
  contentHeight: number;
  softphoneLayoutRef?: RefObject<HTMLDivElement>;
  taskFooterContainer?: HTMLDivElement | null;
}>({
  contentHeight: 0,
  contentWidth: 0,
  softphoneLayoutRef: undefined,
  taskFooterContainer: undefined
});

export const useSoftphoneLayout = () => useContext(SoftphoneLayoutContext);

export default SoftphoneLayoutContext;
