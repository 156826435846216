import { useState } from 'react';
import WORKSPACE_LAYOUTS from 'lib/common/constants/workspaceLayouts';
import RINGTONE_OPTIONS from 'lib/common/constants/ringtoneOptions';
import INCOMING_TASK_PLACEMENTS from 'lib/common/constants/incomingTaskPlacements';
import { isWindows } from 'lib/common/utils/isWindows';
import { useLocalStorage } from 'lib/common/hooks/useLocalStorage';

import Context from './Context';

const DEFAULT_INCOMING_TASK_PLACEMENT = isWindows() ? INCOMING_TASK_PLACEMENTS.TOP : INCOMING_TASK_PLACEMENTS.BOTTOM;
const DEFAULT_ENHANCED_LOGGING_STATE = false;

const PREFERENCE_IDS = {
  DEFAULT_WORKSPACE_LAYOUT: 'default-workspace-layout',
  RINGTONE: 'ringtone',
  INCOMING_TASK_PLACEMENT: 'incoming-task-placement',
  ENHANCED_LOGGING_ENABLED: 'enhanced-logging-enabled'
};

/** This context is only for global preferences that are needed by multiple components.
 * Other agent preferences can be found in the preferences overlay **/
function PreferencesProvider({ children }) {
  const { getStorageItem, setStorageItem } = useLocalStorage();

  function getInitialPreferenceValue(id, defaultValue) {
    return getStorageItem(`${id}-preference`) || defaultValue;
  }

  function setPreference(id, value) {
    return setStorageItem(`${id}-preference`, value);
  }

  const [defaultWorkspaceLayout, setDefaultWorkspaceLayout] = useState(
    getInitialPreferenceValue(PREFERENCE_IDS.DEFAULT_WORKSPACE_LAYOUT, WORKSPACE_LAYOUTS.THREE_COLUMNS)
  );

  const [incomingTaskPlacement, setIncomingTaskPlacement] = useState(
    getInitialPreferenceValue(PREFERENCE_IDS.INCOMING_TASK_PLACEMENT, DEFAULT_INCOMING_TASK_PLACEMENT)
  );

  const [ringtone, setRingtone] = useState(
    getInitialPreferenceValue(PREFERENCE_IDS.RINGTONE, RINGTONE_OPTIONS[0].value)
  );

  const [enhancedLoggingEnabled, setEnhancedLoggingEnabled] = useState(
    JSON.parse(getInitialPreferenceValue(PREFERENCE_IDS.ENHANCED_LOGGING_ENABLED, DEFAULT_ENHANCED_LOGGING_STATE))
  );

  const onChangePreference = (id, setter) => (value) => {
    setter(value);
    setPreference(id, value);
  };

  return (
    <Context.Provider
      value={{
        state: { defaultWorkspaceLayout, ringtone, incomingTaskPlacement, enhancedLoggingEnabled },
        actions: {
          setDefaultWorkspaceLayout: onChangePreference(
            PREFERENCE_IDS.DEFAULT_WORKSPACE_LAYOUT,
            setDefaultWorkspaceLayout
          ),
          setIncomingTaskPlacement: onChangePreference(
            PREFERENCE_IDS.INCOMING_TASK_PLACEMENT,
            setIncomingTaskPlacement
          ),
          setRingtone: onChangePreference(PREFERENCE_IDS.RINGTONE, setRingtone),
          setEnhancedLoggingEnabled: onChangePreference(
            PREFERENCE_IDS.ENHANCED_LOGGING_ENABLED,
            setEnhancedLoggingEnabled
          )
        }
      }}
    >
      {children}
    </Context.Provider>
  );
}

export default PreferencesProvider;
