import { useState } from 'react';
import ControlledSegmentedControls, { TControlledSegmentedControls } from './ControlledSegmentedControls';

export default function UncontrolledSegmentedControls({
  initialIndices,
  ...props
}: Omit<TControlledSegmentedControls, 'selectedIndices' | 'setSelectedIndices'> & {
  initialIndices?: number[] | number;
}) {
  const [selectedIndices, setSelectedIndices] = useState(
    typeof initialIndices === 'number' ? [initialIndices] : initialIndices
  );

  return (
    <ControlledSegmentedControls {...props} selectedIndices={selectedIndices} setSelectedIndices={setSelectedIndices} />
  );
}
