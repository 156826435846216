import { useEffect, useState } from 'react';
import { useBooleanState } from 'webrix/hooks';
import _merge from 'lodash.merge';
import _omit from 'lodash.omit';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useConfigContext } from 'lib/core/config';
import TProfile from 'lib/common/types/CustomerProfile';

import customerProfileSearchTypes from 'lib/common/constants/customerProfileSearchTypes';
import normaliseAWSObjectKeys from 'lib/common/utils/customerProfiles/normaliseAWSObjectKeys';
import parseJSON from 'lib/common/utils/parseJSON';

import {
  deleteCustomerProfile,
  saveNotes,
  getHistory,
  getNotes,
  searchCustomerProfiles,
  createUpdateCustomerProfile
} from '../api/customerProfiles';
import profileFieldParsers from '../utils/customerProfiles/profileFieldParsers';
import TTask from '../types/Task';
import TChatTask from '../types/ChatTask';
import { LogEvents, logger } from '../components/LoggerController';

interface IParams {
  profileId?: string;
  task?: TTask | TChatTask;
  refetch?: () => void;
  onDeleteProfile?: () => void;
}

const useCustomerProfileActions = ({ profileId, refetch, onDeleteProfile, task }: IParams) => {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const [profile, setProfile] = useState<TProfile | null | undefined>();
  const [error, setError] = useState<boolean>(false);
  const [profileHistory, setProfileHistory] = useState<[] | undefined>();
  const [profileNotes, setProfileNotes] = useState<string | undefined>();

  const { value: showEditModal, setFalse: closeEditModal, setTrue: openEditModal } = useBooleanState();
  const { value: showDeleteModal, setFalse: closeDeleteModal, setTrue: openDeleteModal } = useBooleanState();
  const { value: showCreateModal, setFalse: closeCreateModal, setTrue: openCreateModal } = useBooleanState();

  const deleteProfile = async () => {
    if (!profile || !profileId) {
      return;
    }
    try {
      const result = await deleteCustomerProfile({ config, fetch_, profileId });

      logger.info(LogEvents.CUSTOMER_PROFILE.DELETE.SUCCESS, { profileId });

      return result;
    } catch (error) {
      logger.error(LogEvents.CUSTOMER_PROFILE.DELETE.FAIL, { profileId, error });
      throw error;
    }
  };

  const onEdit = async (content) => {
    try {
      await createUpdateCustomerProfile({ config, fetch_, profile: content, profileId });

      logger.info(LogEvents.CUSTOMER_PROFILE.UPDATE.SUCCESS, { profileId });

      setProfile(_merge({ ...profile }, content));
    } catch (error) {
      logger.error(LogEvents.CUSTOMER_PROFILE.UPDATE.FAIL, { profileId, error });
      throw error;
    }
  };

  const onCreate = async (profileToCreate: TProfile) => {
    try {
      const profileId = await createUpdateCustomerProfile({ config, fetch_, profile: profileToCreate });

      logger.info(LogEvents.CUSTOMER_PROFILE.CREATE.SUCCESS, { profileId });

      return { ...profileToCreate, profileId };
    } catch (error) {
      logger.error(LogEvents.CUSTOMER_PROFILE.CREATE.FAIL, { profileId, error });
      throw error;
    }
  };

  const onSaveNotes = async (notes: string) => {
    await saveNotes({ config, fetch_, profileId, notes });

    setProfileNotes(notes);
  };

  const onDeleteProfileSuccess = () => {
    closeDeleteModal();

    if (onDeleteProfile) {
      onDeleteProfile();
    }
  };

  const getMatchedContact = async () => {
    if (!profileId) {
      return;
    }

    setProfile(void 0);
    setProfileHistory(void 0);
    setProfileNotes(void 0);

    try {
      const profile = await searchCustomerProfiles({
        config,
        fetch_,
        type: customerProfileSearchTypes.profileId,
        value: profileId,
        rethrowError: true
      });

      if (!profile || !profile?.profileId) {
        setProfileHistory([]);
        setProfileNotes('');

        return void setProfile(null);
      }

      refetch?.();

      const normalisedProfile = _omit(normaliseAWSObjectKeys(profile), ['attributes']);

      setProfile({
        ...normalisedProfile,
        address: profileFieldParsers.address(normalisedProfile.address)
      });

      // Use promise.then instead of await, so they can resolve independently
      getHistory({ config, fetch_, profileId }).then(setProfileHistory);
      /**
       * Get the parsed response while retrieving notes
       */
      getNotes({ config, fetch_, profileId }).then((notes) => setProfileNotes(notes ? parseJSON(notes) : ''));
    } catch (e) {
      setError(true);
      setProfileNotes('');
      setProfileHistory([]);
    }
  };

  useEffect(() => {
    getMatchedContact();
  }, [profileId]);

  useEffect(() => {
    // Check if the selected task doesn't have profile on initial load
    if (task?.profile === null && !profileId) {
      setProfileHistory([]);
      setProfileNotes('');

      return void setProfile(null);
    }
  }, [profileId, task]);

  const profileDoesNotExist = profile === null;
  const profileLoading = !profile && profile !== null && !error;

  return {
    profile,
    error,
    profileHistory,
    profileNotes,
    profileDoesNotExist,
    profileLoading,
    showEditModal,
    showDeleteModal,
    showCreateModal,
    actions: {
      onCreate,
      deleteProfile,
      onEdit,
      onSaveNotes,
      onDeleteProfileSuccess,
      getMatchedContact
    },
    modalActions: {
      open: {
        openEditModal,
        openDeleteModal,
        openCreateModal
      },
      close: {
        closeEditModal,
        closeDeleteModal,
        closeCreateModal
      }
    }
  };
};

export default useCustomerProfileActions;
