import { KeyboardTimePicker, KeyboardTimePickerProps } from '@material-ui/pickers';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import useStyles from './styles';

export default function TimePicker({ id, ...props }: KeyboardTimePickerProps) {
  const { isSoftphone } = useLayout();
  const classes = useStyles({ isSoftphone: isSoftphone });

  return (
    <KeyboardTimePicker
      {...props}
      inputVariant="outlined"
      id={id}
      KeyboardButtonProps={{ disabled: true }}
      className={classes.root}
      keyboardIcon={<></>}
      inputProps={{
        'data-testid': id,
        className: classes.input,
        'aria-label': props['aria-label'] || props.label?.toString() || props.placeholder,
        'aria-disabled': props.disabled
      }}
      InputProps={{
        classes: {
          root: classes.inputRoot
        }
      }}
      InputLabelProps={{
        classes: {
          root: classes.inputLabelRoot
        }
      }}
    />
  );
}
