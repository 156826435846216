import getUserName from 'lib/common/utils/getUserName';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import TTask from 'lib/common/types/Task';
import Accordion from 'lib/common/components/Accordion';
import PureEmail from 'lib/common/components/molecules/Email';
import useEmailContent from 'lib/common/hooks/useEmailContent/useEmailContent';
import getContactAttributes from 'lib/common/utils/getContactAttributes';

import InfoPanel from './InfoPanel';
import AttachedData from './AttachedData';

const EmailTask = (emailTask: TTask) => {
  const { profile, queueName, time, connectionValue } = emailTask;
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const {
    neonEmailId,
    neonEmailTo,
    emailAddress,
    neonEmailSubject,
    neonEmailCcRecipients = '',
    neonEmailAllRecipients = ''
  } = getContactAttributes(
    {
      task: emailTask
    },
    'neonEmailId',
    'neonEmailTo',
    'emailAddress',
    'neonEmailSubject',
    'neonEmailCcRecipients',
    'neonEmailAllRecipients'
  );

  const { data, loading, error, attachments, metadata } = useEmailContent(neonEmailId);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={neonEmailSubject || 'No Subject'} testId={name} />
      </InfoPanel>
      <Accordion title="Email Content" className="mt-10 mb-10" defaultOpen>
        <PureEmail
          metadata={{ from: emailAddress, to: neonEmailTo }}
          content={data}
          loading={loading}
          neonEmailCcRecipients={neonEmailCcRecipients}
          neonEmailAllRecipients={neonEmailAllRecipients}
          error={error}
          attachments={attachments}
          isSoftphone
          hideDate
        />
      </Accordion>
      <ACWOutcome />
      <AttachedData task={emailTask} />
      <TaskFooter.Email
        task={emailTask}
        content={data}
        attachments={attachments}
        metadata={metadata}
        isSmallWidth={false}
      />
    </>
  );
};

export default EmailTask;
