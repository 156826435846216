import { makeStyles } from '@material-ui/core';
import VARS from 'css/export-vars.module.scss';

const LARGE_HEIGHT_PX = 35;
const LARGE_PADDING_PX = 13;
const SMALL_PADDING_PX = 8;
const LARGE_LABEL_TOP_PX = -9;
const SMALL_LABEL_TOP_PX = -4;

interface IStyles {
  isSoftphone: boolean;
  multiline?: boolean;
  noBorder?: boolean;
  noPadding?: boolean;
  ignoreRows?: boolean;
}

const flexGrow = {
  display: 'flex',
  flex: 1
};

function getInputPadding({ isSoftphone, noPadding }: IStyles) {
  if (noPadding) {
    return 0;
  }

  if (isSoftphone) {
    return SMALL_PADDING_PX;
  }

  return LARGE_PADDING_PX;
}

export default makeStyles({
  root: ({ isSoftphone, noBorder, multiline, ignoreRows }: IStyles) => ({
    width: '100%',
    ...(multiline && ignoreRows ? flexGrow : {}),
    '&.MuiInputBase-multiline': {
      height: isSoftphone ? 'inherit' : void 0,
      minHeight: isSoftphone ? 'inherit' : void 0
    },
    '& label.Mui-focused': {
      color: VARS.primary,
      borderWidth: 1
    },
    '& .MuiInput-underline:before': {
      borderColor: VARS.midGrey,
      borderWidth: 1
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderColor: VARS.darkGrey,
      borderWidth: 1
    },
    '& .MuiInput-underline:after': {
      borderColor: VARS.primary,
      borderWidth: 1
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: noBorder ? 0 : void 0,
      borderColor: VARS.midGrey
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: VARS.darkGrey
      },

      '&.Mui-focused fieldset': {
        borderColor: VARS.primary,
        borderWidth: noBorder ? 0 : 1
      }
    },
    '& .MuiOutlinedInput-inputMultiline': {
      ...(multiline && ignoreRows
        ? {
            height: '100% !important',
            paddingTop: 5
          }
        : {})
    },
    '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${VARS.midGrey} !important`
    }
  }),
  inputLabelRoot: ({ isSoftphone }: IStyles) => ({
    top: isSoftphone ? LARGE_LABEL_TOP_PX : SMALL_LABEL_TOP_PX,
    color: VARS.darkGrey
  }),
  input: (styleProps: IStyles) => {
    const padding = styleProps.multiline ? 0 : getInputPadding(styleProps);

    return {
      '&::placeholder': {
        color: VARS.darkGrey,
        opacity: 1
      },
      paddingTop: padding,
      paddingBottom: padding,
      paddingLeft: styleProps.noPadding ? 0 : void 0,
      paddingRight: styleProps.noPadding ? 0 : void 0
    };
  },
  inputRoot: ({ isSoftphone, multiline, noPadding, ignoreRows }: IStyles) => {
    const isSingleLineSoftphone = isSoftphone && !multiline;
    const padding = multiline ? getInputPadding({ isSoftphone, noPadding }) : 0;

    return {
      ...(multiline && ignoreRows ? flexGrow : {}),
      height: isSingleLineSoftphone ? LARGE_HEIGHT_PX : void 0,
      minHeight: isSingleLineSoftphone ? LARGE_HEIGHT_PX : void 0,
      paddingTop: padding,
      paddingBottom: padding,
      paddingLeft: noPadding ? 0 : void 0,
      paddingRight: noPadding ? 0 : void 0,
      background: VARS.white,
      fontSize: isSoftphone ? VARS.fontSizeSmall : VARS.fontSizeNormal
    };
  }
});
