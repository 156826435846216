import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import cx from 'classnames';
import { useRef, useState } from 'react';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { Divider } from 'lib/common/components/atoms/Divider';
import { ConstValues, createEnumFromArray } from '@cloud-wave/neon-common-lib';

import { UListButton } from './UListButton';
import { OListButton } from './OListButton';
import { LinkButton } from './LinkButton';
import { BoldButton } from './BoldButton';
import { ItalicButton } from './ItalicButton';

import styles from './text-formatter.module.scss';

const allOptions = [
  'close',
  'history',
  'bold',
  'italics',
  'links',
  'underline',
  'strikethrough',
  'bulletList',
  'numberList',
  'blockStyles',
  'colour',
  'textAlignment'
] as const;

export const ToolbarOptions = createEnumFromArray(allOptions);

type TToolbarOption = ConstValues<typeof ToolbarOptions>;

export interface ToolbarPluginProps {
  className?: string;
  toolbarOptions?: TToolbarOption[];
  inlineOverlay?: boolean;
}

interface ToolbarPluginPropsInternal {
  close: () => void;
}

/**
 * Component will default to showing all text controls if not provided with an array of wanted controls
 *
 * Note this component has started to be refactored but still needs a lot more love,
 * when you have a need for subcomponents.
 *
 * please update and style them, at the bottom of this component you can see the extra items as a comment
 */
export default function TextToolbarPlugin({
  className,
  toolbarOptions,
  inlineOverlay,
  close,
  small
}: ToolbarPluginProps & ToolbarPluginPropsInternal & { small?: boolean }) {
  //Improvement: iterate over this to determine what components to render rather than adding check before each
  const optionsToRender = toolbarOptions || allOptions;

  const toolbarRef = useRef(null);

  const toolbarContainerClassName = inlineOverlay ? 'toolbar--inline' : 'toolbar';

  const iconSize = small ? 13 : 17;

  // Improvement: all text modification buttons work near identically, move to a base component with preconfigured Exports
  return (
    <div className={cx(styles[toolbarContainerClassName], className)} ref={toolbarRef} data-testid="formatting-toolbar">
      {optionsToRender.find((value) => value === ToolbarOptions.close) && (
        <>
          <ClickableIcon size={iconSize} icon={faChevronLeft} onClick={close} tooltip="Close" />
          <Divider {...(small ? { margin: 'auto 0px auto 0px', height: 13 } : {})} />
        </>
      )}

      <div className={cx(styles['toolbar__format-group'], { [styles['toolbar__format-group--small']]: small })}>
        {optionsToRender.find((value) => value === ToolbarOptions.bold) && (
          <BoldButton className={small ? styles['no-horizontal-padding'] : void 0} size={iconSize} />
        )}
        {optionsToRender.find((value) => value === ToolbarOptions.italics) && (
          <ItalicButton className={small ? styles['no-horizontal-padding'] : void 0} size={iconSize} />
        )}
        {optionsToRender.find((value) => value === ToolbarOptions.bulletList) && (
          <UListButton className={small ? styles['no-horizontal-padding'] : void 0} size={iconSize} />
        )}
        {optionsToRender.find((value) => value === ToolbarOptions.numberList) && (
          <OListButton className={small ? styles['no-horizontal-padding'] : void 0} size={iconSize} />
        )}
        {optionsToRender.find((value) => value === ToolbarOptions.links) && (
          <LinkButton className={small ? styles['no-horizontal-padding'] : void 0} size={iconSize} />
        )}
      </div>
    </div>
  );
}

// Removing unused components for now to reduce refactor time as it was taking way too long, add components as needed

// ** add this before the text formating group**
//       {optionsToRender.find((value) => value === ToolbarOptions.history) && (
//         <ToolbarHistoryButtons updateToolbar={updateToolbar} />
//       )}
//
// {optionsToRender.find((value) => value === ToolbarOptions.blockStyles) && (
//         <BlockStylesDropdown
//           value={blockType}
//           onClick={() => setShowBlockOptionsDropDown(!showBlockOptionsDropDown)}
//           showBlockOptionsDropDown={showBlockOptionsDropDown}
//           toolbarRef={toolbarRef}
//           showBlockOptionsDropDown1={setShowBlockOptionsDropDown}
//         />
//       )}

//   **add this back to the text formating group between link and ordered list**
//   {optionsToRender.find((value) => value === ToolbarOptions.underline) && (
//           <UnderlineButton underline={isUnderline} />
//         )}
//         {optionsToRender.find((value) => value === ToolbarOptions.strikethrough) && (
//           <StrikethroughButton strikethrough={isStrikethrough} />
//         )}
//         {optionsToRender.find((value) => value === ToolbarOptions.colour) && (
//           <ColorPicker
//             buttonClassName="toolbar-item color-picker"
//             buttonAriaLabel="Formatting text color"
//             buttonIconClassName="icon font-color"
//             onChange={onFontColorSelect}
//             title="text color"
//           />
//         )}

// **add this after the group**
//      {optionsToRender.find((value) => value === ToolbarOptions.textAlignment) && <TextAlignment />}
