import { useCallback, useState } from 'react';
import cx from 'classnames';

import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useConfigContext } from 'lib/core/config';
import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';
import TProfile from 'lib/common/types/CustomerProfile';
import PhoneInput from 'lib/common/components/PhoneInput';
import { Input } from 'lib/common/components/Input';
import Text from 'lib/common/components/Text';
import Loader from 'lib/common/components/Loader';
import Label from 'lib/common/components/Label';
import SegmentedControls from 'lib/common/components/SegmentedControls';

import { validateProfile } from '../utils/validateProfile';
import HeadingLabel from '../../HeadingLabel';

import { TCustomerProfileForm, TErrors, TValidating } from '../types/CustomerProfileForm';

import '../styles/customer-profile-form.scss';

const inputValueParser = ({ target: { value } }) => value || void 0;
const numberParser = (number) => number || void 0;

export default function CustomerProfileForm({
  onChangeProfile,
  profile: initialProfile = {},
  compact,
  action
}: TCustomerProfileForm) {
  const [profile, setProfile] = useState<TProfile>(initialProfile);
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();

  const [validationErrors, setValidationErrors] = useState<TErrors>({});
  const [fieldValidating, setFieldValidating] = useState<TValidating>({});

  const updateProfileField = useCallback(
    (fieldName: string, valueParser?: (_: any) => any, { propagateImmediately } = {}) =>
      (value: unknown) => {
        const { phoneNumber, emailAddress, accountNumber } = profile;
        const newProfile = { ...profile, [fieldName]: valueParser ? valueParser(value) : value };

        setProfile(newProfile);

        if (!propagateImmediately) {
          return;
        }

        // This is only called while changing party type
        onChangeProfile({
          profile: newProfile,
          profileValid:
            (Boolean(phoneNumber) || Boolean(emailAddress) || Boolean(accountNumber)) &&
            Object.values(validationErrors).every((value) => !value)
        });
      },
    [profile, validationErrors]
  );

  const onBlurTextField = (fieldName: string) => async () => {
    const params = { fetch_, config, fieldName, value: profile[fieldName], action };

    // Triggering it here to disable save till search returns a response
    onChangeProfile({ profile, profileValid: false });
    setFieldValidating({ [fieldName]: true });

    const { errors } = await validateProfile(profile, params);

    setFieldValidating({ [fieldName]: false });

    const updatedErrors = { ...validationErrors, ...errors };

    setValidationErrors(updatedErrors);

    const { phoneNumber, emailAddress, accountNumber } = profile;

    onChangeProfile({
      profile,
      profileValid:
        (Boolean(phoneNumber) || Boolean(emailAddress) || Boolean(accountNumber)) &&
        Object.values(updatedErrors).every((value) => !value)
    });
  };

  const onFocus = (fieldName: string) => () => {
    setValidationErrors({ ...validationErrors, [fieldName]: void 0 });
  };

  const { partyType, firstName, lastName, phoneNumber, emailAddress, accountNumber, address } = profile;

  const {
    phoneNumber: phoneNumberError,
    emailAddress: emailAddressError,
    accountNumber: accountNumberError
  } = validationErrors;

  const {
    phoneNumber: phoneNumberValidating,
    emailAddress: emailAddressValidating,
    accountNumber: accountNumberValidating
  } = fieldValidating;

  return (
    <div
      data-testid="customer-profile-form"
      className={cx('customer-profile-form', { 'customer-profile-form--compact': compact })}
    >
      <div className="customer-profile-form__block__row">
        <Label id="customer-profile-type" text="Type">
          <SegmentedControls
            ariaLabel="Profile type"
            options={[
              { label: 'Individual', value: PROFILE_TYPES.INDIVIDUAL },
              { label: 'Business', value: PROFILE_TYPES.BUSINESS }
            ]}
            onSelect={updateProfileField('partyType', void 0, { propagateImmediately: true })}
            initialIndices={partyType === PROFILE_TYPES.BUSINESS ? 1 : 0}
          />
        </Label>
      </div>
      <div className="customer-profile-form__block">
        <HeadingLabel primary="Profile" secondary="Optional" />
        <div className="customer-profile-form__block__row">
          <Input
            ariaRequired
            autoFocus
            label="First Name"
            autoComplete="off"
            data-testid="form-field-first-name"
            id="firstName"
            value={firstName || ''}
            type="text"
            onChange={updateProfileField('firstName', inputValueParser)}
            onBlur={onBlurTextField('firstName')}
          />
          <Input
            ariaRequired
            label="Last Name"
            autoComplete="off"
            data-testid="form-field-last-name"
            id="lastName"
            value={lastName || ''}
            type="text"
            onChange={updateProfileField('lastName', inputValueParser)}
            onBlur={onBlurTextField('lastName')}
          />
        </div>
        <HeadingLabel
          primary="Identifier"
          secondary={
            <Text>
              <Text.Bold>At least one</Text.Bold> is required
            </Text>
          }
        />
        <div className="customer-profile-form__block__row">
          <Label
            id="phone-number"
            text="Phone Number"
            className="customer-profile-form__block__row__text"
            LabelWrapperComponent={phoneNumberValidating ? <Loader minimised size={20} /> : null}
          >
            <PhoneInput
              aria-label="Phone number"
              initialValue={phoneNumber}
              onChange={updateProfileField('phoneNumber', numberParser)}
              id="phone-number"
              helperText={phoneNumber && Boolean(phoneNumberError) ? phoneNumberError : void 0}
              onBlur={onBlurTextField('phoneNumber')}
              onFocus={onFocus('phoneNumber')}
              disabled={phoneNumber ? phoneNumberValidating : void 0}
              error={phoneNumber ? Boolean(phoneNumberError) : void 0}
              testId="form-field-phone"
            />
          </Label>
          <Label
            id="email-address"
            text="Email Address"
            className="customer-profile-form__block__row__text"
            LabelWrapperComponent={emailAddressValidating ? <Loader minimised size={20} /> : null}
          >
            <Input
              aria-label="Email address"
              autoComplete="off"
              data-testid="form-field-email"
              error={emailAddress ? Boolean(emailAddressError) : void 0}
              disabled={emailAddress ? emailAddressValidating : void 0}
              helperText={emailAddress && Boolean(emailAddressError) ? emailAddressError : void 0}
              id="emailAddress"
              value={emailAddress || ''}
              type="text"
              onChange={updateProfileField('emailAddress', inputValueParser)}
              onBlur={onBlurTextField('emailAddress')}
              onFocus={onFocus('emailAddress')}
              placeholder="john.doe@email.com"
            />
          </Label>
        </div>
        <div className="customer-profile-form__block__row">
          <Label
            id="account-number"
            text="Account Number"
            className="customer-profile-form__block__row__text"
            LabelWrapperComponent={accountNumberValidating ? <Loader minimised size={20} /> : null}
          >
            <Input
              aria-label="Account Number"
              autoComplete="off"
              data-testid="form-field-account-number"
              id="accountNumber"
              error={accountNumber ? Boolean(accountNumberError) : void 0}
              disabled={accountNumber ? accountNumberValidating : void 0}
              helperText={accountNumber ? accountNumberError : void 0}
              value={accountNumber || ''}
              type="text"
              onChange={updateProfileField('accountNumber', inputValueParser)}
              onBlur={onBlurTextField('accountNumber')}
              onFocus={onFocus('accountNumber')}
              placeholder="123456789"
            />
          </Label>
        </div>
      </div>
      <div className="customer-profile-form__block">
        <HeadingLabel primary="Address" secondary="Optional" />
        <Input
          label="Address"
          autoComplete="off"
          data-testid="form-field-address"
          id="address"
          value={address || ''}
          type="text"
          onChange={updateProfileField('address', inputValueParser)}
          onBlur={onBlurTextField('address')}
        />
      </div>
    </div>
  );
}
