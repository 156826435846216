export default async function getHistory({ fetch_, config, profileId }) {
  try {
    const contactHistoryRes = await fetch_(
      `${config.AGENT_SERVICE_URL}/connect/${config.TENANT_ID}/contacts/${profileId}/history`,
      {
        method: 'GET'
      }
    );

    const parsedHistoryRes = await contactHistoryRes.json();

    return parsedHistoryRes.map(({ Object }) => JSON.parse(Object));
  } catch {
    return [];
  }
}
