import connectAction from 'lib/common/utils/connectAction';
import connectGetter from 'lib/common/utils/connectGetter';

import getContactChatSession from './getContactChatSession';
import destroyTask from './destroyTask';

export default async function endInitialConnectionHelper(contact: connect.Contact | undefined, handleContactChange) {
  if (!contact) {
    return Promise.resolve();
  }

  try {
    const activeInitialConnection = connectGetter(contact, 'getActiveInitialConnection');

    if (activeInitialConnection) {
      await connectAction('destroy', activeInitialConnection);
    }

    (await getContactChatSession(contact))?.cleanUpOnParticipantDisconnect();
  } catch {
    destroyTask({ contact, handleContactChange });
  }
}
