import { useVisibilityState } from 'webrix/hooks';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Collapse } from 'react-collapse';
import { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import Icon from '../Icon';
import Text from '../Text';

import './accordion.scss';

export interface AccordionProps {
  title: string | ReactNode;
  children: ReactNode;
  className?: string;
  childClassName?: string;
  small?: boolean;
  icon?: string | IconProp;
  defaultOpen?: boolean;
  forceOpen?: boolean;
  contrast?: boolean;
  ariaLabel?: string;
  onToggle?: ({ open }: { open: boolean }) => void;
}

export default function Accordion({
  title,
  children,
  className,
  childClassName,
  small,
  icon,
  defaultOpen = false,
  forceOpen = undefined,
  contrast = false,
  onToggle,
  ariaLabel
}: AccordionProps) {
  const { isSoftphone } = useLayout();

  const { visible: open, toggle } = useVisibilityState(defaultOpen);

  const isAccordion = forceOpen === undefined ? open : forceOpen;
  const isSmall = isSoftphone || small;

  return (
    <div
      className={cx(
        'accordion',
        {
          'accordion--open': isAccordion,
          'accordion--softphone': isSmall,
          'accordion--contrast': contrast
        },
        className
      )}
      data-testid="accordion-container"
    >
      <button
        className="accordion__header"
        onClick={() => {
          onToggle?.({ open: !open });
          toggle();
        }}
        data-testid="accordion"
        aria-label={ariaLabel || `Toggle ${typeof title === 'string' ? title : ''} accordion`}
        aria-pressed={isAccordion}
      >
        <div className="accordion__title next-to">
          {icon && <Icon icon={icon} className="mr-10" />}
          <Text className={cx({ 'accordion__title__text--softphone': isSmall })} type="heading3">
            {title}
          </Text>
        </div>
        <div
          className={cx('accordion__header__icon', {
            'accordion__header__icon--open': isAccordion,
            'accordion__header__icon--softphone': isSmall
          })}
        >
          <Icon size={isSmall ? 12 : 15} icon={isAccordion ? faChevronUp : faChevronDown} color="primary" />
        </div>
      </button>
      <Collapse
        isOpened={isAccordion}
        theme={{ collapse: 'accordion--transition', content: cx('accordion__child', childClassName) }}
      >
        {children}
      </Collapse>
    </div>
  );
}
