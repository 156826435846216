import cx from 'classnames';

import Text from 'lib/common/components/Text';

import { DIGITS, DIALS } from '../../constants/dials';

import styles from './number-pad.module.scss';

interface INumberPad {
  onDigitPress: (digit: string) => void;
  isSoftphone?: boolean;
  disabled?: boolean;
}

export default function NumberPad({ onDigitPress, isSoftphone, disabled }: INumberPad) {
  return (
    <div className={styles['number-pad__container']}>
      {DIALS.map((dial, key) => (
        <div key={key.toString()} className={styles['number-pad__row']}>
          {dial.map(({ digit, sub }) => (
            <button
              aria-label={digit}
              data-testid={`number-pad-digit-${digit}`}
              className={cx(styles['number-pad__row__digit'], {
                [styles['number-pad__row__digit--softphone']]: isSoftphone,
                [styles['number-pad__row__digit--disabled']]: disabled
              })}
              key={digit}
              onClick={() => onDigitPress(digit)}
              disabled={disabled}
            >
              <Text
                className={cx(styles['number-pad__row__digit__text'], {
                  [styles['number-pad__row__digit__text--asterisk']]: digit === DIGITS.ASTERISK
                })}
              >
                {digit}
              </Text>
              {sub && <Text className={styles['number-pad__row__digit__sub-text']}>{sub}</Text>}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
}
