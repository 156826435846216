import CONNECTION_TYPE from 'lib/common/constants/connectionType';
import { Permissions } from '@cloud-wave/neon-common-lib';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { TAnyTask } from '../contexts/ContactContext/';

export default function useHasOutcomesAccess(selectedTask?: TAnyTask) {
  const { hasAllPermissions } = usePermissionsContext();

  const canAgentAccessOutcomes = hasAllPermissions({
    type: 'tenant',
    permissionsToCheck: [Permissions.AGENT_ACW, Permissions.AGENT_INTERACTION_OUTCOMES]
  });

  // We cannot complete outcomes when monitoring as the contact isn't a real one we can write attributes to. Connect
  // will fail on the api side.
  return selectedTask && selectedTask.connectionType !== CONNECTION_TYPE.MONITORING && canAgentAccessOutcomes;
}
