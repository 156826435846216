import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import getTaskContentType from 'lib/common/utils/getTaskContentType';
import TASK_CONTENT_TYPES from 'lib/common/constants/tasks/taskContentTypes';
import TOutboundPreviewTask from 'lib/common/types/OutboundPreviewTask';
import TChatTask from 'lib/common/types/ChatTask';
import TEmailTask from 'lib/common/types/EmailTask';

import NeglectedTask from './components/NeglectedTask';
import ChatTask from './components/ChatTask';
import TimedTask from './components/TimedTask';
import AgentTask from './components/AgentTask';
import EmailTask from './components/EmailTask';
import AfterContactWork from './components/AfterContactWork';
import CreateEmail from './components/CreateEmail';
import IncomingTask from './components/IncomingTask';
import OutboundPreviewTask from './components/OutboundPreviewTask';
import ConnectingTask from './components/ConnectingTask';

export default function Task({ user }) {
  const {
    actions: { setSelectedTaskId },
    state: { selectedTaskId, tasks }
  } = useContactContext();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (selectedTaskId && tasks.some((task) => task.taskId === selectedTaskId)) {
      return;
    }

    setSelectedTaskId(tasks[0]?.taskId);
  }, [tasks]);

  useEffect(() => {
    // Only show the landing page when we're on the last task and it's removed
    if (tasks.length > 0 || location.pathname !== SOFTPHONE_PAGE_ROUTES.TASK) {
      return;
    }

    history.replace(SOFTPHONE_PAGE_ROUTES.LANDING);
  }, [tasks?.length]);

  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);

  if (!selectedTask) {
    return null;
  }

  const taskContentType = getTaskContentType({ selectedTask, isSoftphone: true });

  const getTaskContent = () => {
    if (taskContentType === TASK_CONTENT_TYPES.CONNECTING_TASK) {
      return <ConnectingTask {...selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.INCOMING_TASK) {
      return <IncomingTask selectedTask={selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.AFTER_CONTACT_WORK) {
      return <AfterContactWork {...selectedTask} user={user} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.NEGLECTED_TASK) {
      return <NeglectedTask selectedTask={selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.EMAIL_TASK && selectedTask.status === CONTACT_STATES.CONNECTED) {
      return <EmailTask {...(selectedTask as TEmailTask)} key={selectedTask.taskId} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.EMAIL_TASK && selectedTask.status === CONTACT_STATES.DRAFT) {
      return <CreateEmail selectedTask={selectedTask} key={selectedTask.taskId} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.AGENT_TASK) {
      return <AgentTask {...selectedTask} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.OUTBOUND_PREVIEW_TASK) {
      return <OutboundPreviewTask {...(selectedTask as TOutboundPreviewTask)} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.CHAT_TASK) {
      return <ChatTask {...(selectedTask as TChatTask)} key={selectedTask.taskId} />;
    }

    if (taskContentType === TASK_CONTENT_TYPES.TIMED_TASK) {
      return <TimedTask {...selectedTask} user={user} />;
    }

    return null;
  };

  return getTaskContent();
}
