import cx from 'classnames';
import _isEmpty from 'lodash.isempty';
import blankFlag from './assets/blank.gif';

import './country-flag.scss';

const ALL_FLAGS = {
  ad: {
    backgroundPosition: '-24px 0'
  },
  ae: {
    backgroundPosition: '-48px 0'
  },
  af: {
    backgroundPosition: '-72px 0'
  },
  ag: {
    backgroundPosition: '-96px 0'
  },
  ai: {
    backgroundPosition: '-120px 0'
  },
  al: {
    backgroundPosition: '-144px 0'
  },
  am: {
    backgroundPosition: '-168px 0'
  },
  an: {
    backgroundPosition: '-192px 0'
  },
  ao: {
    backgroundPosition: '-216px 0'
  },
  ar: {
    backgroundPosition: '-240px 0'
  },
  as: {
    backgroundPosition: '-264px 0'
  },
  at: {
    backgroundPosition: '-288px 0'
  },
  au: {
    backgroundPosition: '-312px 0'
  },
  aw: {
    backgroundPosition: '-336px 0'
  },
  ax: {
    backgroundPosition: '-360px 0'
  },
  az: {
    backgroundPosition: '0 -24px'
  },
  ba: {
    backgroundPosition: '-24px -24px'
  },
  bb: {
    backgroundPosition: '-48px -24px'
  },
  bd: {
    backgroundPosition: '-72px -24px'
  },
  be: {
    backgroundPosition: '-96px -24px'
  },
  bf: {
    backgroundPosition: '-120px -24px'
  },
  bg: {
    backgroundPosition: '-144px -24px'
  },
  bh: {
    backgroundPosition: '-168px -24px'
  },
  bi: {
    backgroundPosition: '-192px -24px'
  },
  bj: {
    backgroundPosition: '-216px -24px'
  },
  bl: {
    backgroundPosition: '-240px -24px'
  },
  bm: {
    backgroundPosition: '-264px -24px'
  },
  bn: {
    backgroundPosition: '-288px -24px'
  },
  bo: {
    backgroundPosition: '-312px -24px'
  },
  br: {
    backgroundPosition: '-336px -24px'
  },
  bs: {
    backgroundPosition: '-360px -24px'
  },
  bt: {
    backgroundPosition: '0 -48px'
  },
  bw: {
    backgroundPosition: '-24px -48px'
  },
  by: {
    backgroundPosition: '-48px -48px'
  },
  bz: {
    backgroundPosition: '-72px -48px'
  },
  ca: {
    backgroundPosition: '-96px -48px'
  },
  cd: {
    backgroundPosition: '-120px -48px'
  },
  cf: {
    backgroundPosition: '-144px -48px'
  },
  cg: {
    backgroundPosition: '-168px -48px'
  },
  ch: {
    backgroundPosition: '-192px -48px'
  },
  ci: {
    backgroundPosition: '-216px -48px'
  },
  ck: {
    backgroundPosition: '-240px -48px'
  },
  cl: {
    backgroundPosition: '-264px -48px'
  },
  cm: {
    backgroundPosition: '-288px -48px'
  },
  cn: {
    backgroundPosition: '-312px -48px'
  },
  co: {
    backgroundPosition: '-336px -48px'
  },
  cr: {
    backgroundPosition: '-360px -48px'
  },
  cu: {
    backgroundPosition: '0 -72px'
  },
  cv: {
    backgroundPosition: '-24px -72px'
  },
  cw: {
    backgroundPosition: '-48px -72px'
  },
  cy: {
    backgroundPosition: '-72px -72px'
  },
  cz: {
    backgroundPosition: '-96px -72px'
  },
  de: {
    backgroundPosition: '-120px -72px'
  },
  dj: {
    backgroundPosition: '-144px -72px'
  },
  dk: {
    backgroundPosition: '-168px -72px'
  },
  dm: {
    backgroundPosition: '-192px -72px'
  },
  do: {
    backgroundPosition: '-216px -72px'
  },
  dz: {
    backgroundPosition: '-240px -72px'
  },
  ec: {
    backgroundPosition: '-264px -72px'
  },
  ee: {
    backgroundPosition: '-288px -72px'
  },
  eg: {
    backgroundPosition: '-312px -72px'
  },
  eh: {
    backgroundPosition: '-336px -72px'
  },
  er: {
    backgroundPosition: '-360px -72px'
  },
  es: {
    backgroundPosition: '0 -96px'
  },
  et: {
    backgroundPosition: '-24px -96px'
  },
  eu: {
    backgroundPosition: '-48px -96px'
  },
  fi: {
    backgroundPosition: '-72px -96px'
  },
  fj: {
    backgroundPosition: '-96px -96px'
  },
  fk: {
    backgroundPosition: '-120px -96px'
  },
  fm: {
    backgroundPosition: '-144px -96px'
  },
  fo: {
    backgroundPosition: '-168px -96px'
  },
  fr: {
    backgroundPosition: '-192px -96px'
  },
  ga: {
    backgroundPosition: '-216px -96px'
  },
  gb: {
    backgroundPosition: '-240px -96px'
  },
  gd: {
    backgroundPosition: '-264px -96px'
  },
  ge: {
    backgroundPosition: '-288px -96px'
  },
  gg: {
    backgroundPosition: '-312px -96px'
  },
  gh: {
    backgroundPosition: '-336px -96px'
  },
  gi: {
    backgroundPosition: '-360px -96px'
  },
  gl: {
    backgroundPosition: '0 -120px'
  },
  gm: {
    backgroundPosition: '-24px -120px'
  },
  gn: {
    backgroundPosition: '-48px -120px'
  },
  gq: {
    backgroundPosition: '-72px -120px'
  },
  gr: {
    backgroundPosition: '-96px -120px'
  },
  gs: {
    backgroundPosition: '-120px -120px'
  },
  gt: {
    backgroundPosition: '-144px -120px'
  },
  gu: {
    backgroundPosition: '-168px -120px'
  },
  gw: {
    backgroundPosition: '-192px -120px'
  },
  gy: {
    backgroundPosition: '-216px -120px'
  },
  hk: {
    backgroundPosition: '-240px -120px'
  },
  hn: {
    backgroundPosition: '-264px -120px'
  },
  hr: {
    backgroundPosition: '-288px -120px'
  },
  ht: {
    backgroundPosition: '-312px -120px'
  },
  hu: {
    backgroundPosition: '-336px -120px'
  },
  ic: {
    backgroundPosition: '-360px -120px'
  },
  id: {
    backgroundPosition: '0 -144px'
  },
  ie: {
    backgroundPosition: '-24px -144px'
  },
  il: {
    backgroundPosition: '-48px -144px'
  },
  im: {
    backgroundPosition: '-72px -144px'
  },
  in: {
    backgroundPosition: '-96px -144px'
  },
  iq: {
    backgroundPosition: '-120px -144px'
  },
  ir: {
    backgroundPosition: '-144px -144px'
  },
  is: {
    backgroundPosition: '-168px -144px'
  },
  it: {
    backgroundPosition: '-192px -144px'
  },
  je: {
    backgroundPosition: '-216px -144px'
  },
  jm: {
    backgroundPosition: '-240px -144px'
  },
  jo: {
    backgroundPosition: '-264px -144px'
  },
  jp: {
    backgroundPosition: '-288px -144px'
  },
  ke: {
    backgroundPosition: '-312px -144px'
  },
  kg: {
    backgroundPosition: '-336px -144px'
  },
  kh: {
    backgroundPosition: '-360px -144px'
  },
  ki: {
    backgroundPosition: '0 -168px'
  },
  km: {
    backgroundPosition: '-24px -168px'
  },
  kn: {
    backgroundPosition: '-48px -168px'
  },
  kp: {
    backgroundPosition: '-72px -168px'
  },
  kr: {
    backgroundPosition: '-96px -168px'
  },
  kw: {
    backgroundPosition: '-120px -168px'
  },
  ky: {
    backgroundPosition: '-144px -168px'
  },
  kz: {
    backgroundPosition: '-168px -168px'
  },
  la: {
    backgroundPosition: '-192px -168px'
  },
  lb: {
    backgroundPosition: '-216px -168px'
  },
  lc: {
    backgroundPosition: '-240px -168px'
  },
  li: {
    backgroundPosition: '-264px -168px'
  },
  lk: {
    backgroundPosition: '-288px -168px'
  },
  lr: {
    backgroundPosition: '-312px -168px'
  },
  ls: {
    backgroundPosition: '-336px -168px'
  },
  lt: {
    backgroundPosition: '-360px -168px'
  },
  lu: {
    backgroundPosition: '0 -192px'
  },
  lv: {
    backgroundPosition: '-24px -192px'
  },
  ly: {
    backgroundPosition: '-48px -192px'
  },
  ma: {
    backgroundPosition: '-72px -192px'
  },
  mc: {
    backgroundPosition: '-96px -192px'
  },
  md: {
    backgroundPosition: '-120px -192px'
  },
  me: {
    backgroundPosition: '-144px -192px'
  },
  mf: {
    backgroundPosition: '-168px -192px'
  },
  mg: {
    backgroundPosition: '-192px -192px'
  },
  mh: {
    backgroundPosition: '-216px -192px'
  },
  mk: {
    backgroundPosition: '-240px -192px'
  },
  ml: {
    backgroundPosition: '-264px -192px'
  },
  mm: {
    backgroundPosition: '-288px -192px'
  },
  mn: {
    backgroundPosition: '-312px -192px'
  },
  mo: {
    backgroundPosition: '-336px -192px'
  },
  mp: {
    backgroundPosition: '-360px -192px'
  },
  mq: {
    backgroundPosition: '0 -216px'
  },
  mr: {
    backgroundPosition: '-24px -216px'
  },
  ms: {
    backgroundPosition: '-48px -216px'
  },
  mt: {
    backgroundPosition: '-72px -216px'
  },
  mu: {
    backgroundPosition: '-96px -216px'
  },
  mv: {
    backgroundPosition: '-120px -216px'
  },
  mw: {
    backgroundPosition: '-144px -216px'
  },
  mx: {
    backgroundPosition: '-168px -216px'
  },
  my: {
    backgroundPosition: '-192px -216px'
  },
  mz: {
    backgroundPosition: '-216px -216px'
  },
  na: {
    backgroundPosition: '-240px -216px'
  },
  nc: {
    backgroundPosition: '-264px -216px'
  },
  ne: {
    backgroundPosition: '-288px -216px'
  },
  nf: {
    backgroundPosition: '-312px -216px'
  },
  ng: {
    backgroundPosition: '-336px -216px'
  },
  ni: {
    backgroundPosition: '-360px -216px'
  },
  nl: {
    backgroundPosition: '0 -240px'
  },
  no: {
    backgroundPosition: '-24px -240px'
  },
  np: {
    backgroundPosition: '-48px -240px'
  },
  nr: {
    backgroundPosition: '-72px -240px'
  },
  nu: {
    backgroundPosition: '-96px -240px'
  },
  nz: {
    backgroundPosition: '-120px -240px'
  },
  om: {
    backgroundPosition: '-144px -240px'
  },
  pa: {
    backgroundPosition: '-168px -240px'
  },
  pe: {
    backgroundPosition: '-192px -240px'
  },
  pf: {
    backgroundPosition: '-216px -240px'
  },
  pg: {
    backgroundPosition: '-240px -240px'
  },
  ph: {
    backgroundPosition: '-264px -240px'
  },
  pk: {
    backgroundPosition: '-288px -240px'
  },
  pl: {
    backgroundPosition: '-312px -240px'
  },
  pn: {
    backgroundPosition: '-336px -240px'
  },
  pr: {
    backgroundPosition: '-360px -240px'
  },
  ps: {
    backgroundPosition: '0 -264px'
  },
  pt: {
    backgroundPosition: '-24px -264px'
  },
  pw: {
    backgroundPosition: '-48px -264px'
  },
  py: {
    backgroundPosition: '-72px -264px'
  },
  qa: {
    backgroundPosition: '-96px -264px'
  },
  ro: {
    backgroundPosition: '-120px -264px'
  },
  rs: {
    backgroundPosition: '-144px -264px'
  },
  ru: {
    backgroundPosition: '-168px -264px'
  },
  rw: {
    backgroundPosition: '-192px -264px'
  },
  sa: {
    backgroundPosition: '-216px -264px'
  },
  sb: {
    backgroundPosition: '-240px -264px'
  },
  sc: {
    backgroundPosition: '-264px -264px'
  },
  sd: {
    backgroundPosition: '-288px -264px'
  },
  se: {
    backgroundPosition: '-312px -264px'
  },
  sg: {
    backgroundPosition: '-336px -264px'
  },
  sh: {
    backgroundPosition: '-360px -264px'
  },
  si: {
    backgroundPosition: '0 -288px'
  },
  sk: {
    backgroundPosition: '-24px -288px'
  },
  sl: {
    backgroundPosition: '-48px -288px'
  },
  sm: {
    backgroundPosition: '-72px -288px'
  },
  sn: {
    backgroundPosition: '-96px -288px'
  },
  so: {
    backgroundPosition: '-120px -288px'
  },
  sr: {
    backgroundPosition: '-144px -288px'
  },
  ss: {
    backgroundPosition: '-168px -288px'
  },
  st: {
    backgroundPosition: '-192px -288px'
  },
  sv: {
    backgroundPosition: '-216px -288px'
  },
  sy: {
    backgroundPosition: '-240px -288px'
  },
  sz: {
    backgroundPosition: '-264px -288px'
  },
  tc: {
    backgroundPosition: '-288px -288px'
  },
  td: {
    backgroundPosition: '-312px -288px'
  },
  tf: {
    backgroundPosition: '-336px -288px'
  },
  tg: {
    backgroundPosition: '-360px -288px'
  },
  th: {
    backgroundPosition: '0 -312px'
  },
  tj: {
    backgroundPosition: '-24px -312px'
  },
  tk: {
    backgroundPosition: '-48px -312px'
  },
  tl: {
    backgroundPosition: '-72px -312px'
  },
  tm: {
    backgroundPosition: '-96px -312px'
  },
  tn: {
    backgroundPosition: '-120px -312px'
  },
  to: {
    backgroundPosition: '-144px -312px'
  },
  tr: {
    backgroundPosition: '-168px -312px'
  },
  tt: {
    backgroundPosition: '-192px -312px'
  },
  tv: {
    backgroundPosition: '-216px -312px'
  },
  tw: {
    backgroundPosition: '-240px -312px'
  },
  tz: {
    backgroundPosition: '-264px -312px'
  },
  ua: {
    backgroundPosition: '-288px -312px'
  },
  ug: {
    backgroundPosition: '-312px -312px'
  },
  us: {
    backgroundPosition: '-336px -312px'
  },
  uy: {
    backgroundPosition: '-360px -312px'
  },
  uz: {
    backgroundPosition: '0 -336px'
  },
  va: {
    backgroundPosition: '-24px -336px'
  },
  vc: {
    backgroundPosition: '-48px -336px'
  },
  ve: {
    backgroundPosition: '-72px -336px'
  },
  vg: {
    backgroundPosition: '-96px -336px'
  },
  vi: {
    backgroundPosition: '-120px -336px'
  },
  vn: {
    backgroundPosition: '-144px -336px'
  },
  vu: {
    backgroundPosition: '-168px -336px'
  },
  wf: {
    backgroundPosition: '-192px -336px'
  },
  ws: {
    backgroundPosition: '-216px -336px'
  },
  ye: {
    backgroundPosition: '-240px -336px'
  },
  yt: {
    backgroundPosition: '-264px -336px'
  },
  za: {
    backgroundPosition: '-288px -336px'
  },
  zm: {
    backgroundPosition: '-312px -336px'
  },
  zw: {
    backgroundPosition: '-336px -336px'
  }
};

function CountryFlag({ code }: { code?: string }) {
  return (
    <img
      alt={code ? `country-code-${code.split('').join('-')}` : 'country-code-placeholder'}
      className={cx('country-flag', { 'country-flag--missing': !code })}
      src={!_isEmpty(blankFlag) ? blankFlag : ''}
      style={{
        backgroundPosition:
          code && ALL_FLAGS[code.toLowerCase()] ? ALL_FLAGS[code.toLowerCase()]?.backgroundPosition : void 0
      }}
    />
  );
}

export default CountryFlag;
