import { faCog } from '@fortawesome/pro-regular-svg-icons';
import Checkbox from 'lib/common/components/atoms/Checkbox';
import PreferenceSectionContainer from 'lib/common/components/molecules/Preferences/components/PreferenceSectionContainer';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';

const PreferencesGeneral = () => {
  const {
    state: { enhancedLoggingEnabled },
    actions: { setEnhancedLoggingEnabled }
  } = usePreferencesContext();

  return (
    <PreferenceSectionContainer icon={faCog} title={'General'}>
      <Checkbox
        checked={enhancedLoggingEnabled}
        label="Enhanced Logging"
        onChange={setEnhancedLoggingEnabled}
        subtitle="Stores additional logs and performance metrics in your log file which can be useful when trying to resolve issues."
      />
    </PreferenceSectionContainer>
  );
};

export default PreferencesGeneral;
