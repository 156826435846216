import { useEffect, useState } from 'react';
import _debounce from 'lodash.debounce';
import cx from 'classnames';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';

import './styles/search.scss';

type TSearchProps = {
  searching?: boolean;
  onSearch: (value: string) => void;
  size?: 'REGULAR' | 'SMALL';
  debounce?: number;
};

export default function Search({ onSearch, searching, size = 'REGULAR' }: TSearchProps) {
  const [searchValue, setSearchValue] = useState('');

  const onChangeSearch = ({ target: { value } }) => {
    setSearchValue(value);

    onSearch(value);
  };

  const onKeyDown = (e: any) => {
    e.stopPropagation();
  };

  useEffect(() => {
    onSearch(searchValue);
  }, []);

  return (
    <div
      className={cx(
        'search',
        {
          'search--disabled': searching
        },
        { 'panel--small': size === 'SMALL' }
      )}
    >
      <Icon icon={faSearch} />
      <input
        onKeyDown={onKeyDown}
        data-testid="search"
        onChange={onChangeSearch}
        value={searchValue}
        placeholder="Search"
        aria-label="Search"
      />
    </div>
  );
}
