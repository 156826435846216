import styles from './chat-message.module.scss';

export default function Loader() {
  return (
    <div className={styles['chat-message-loader']}>
      <div className={styles['chat-message-loader__dot']}></div>
      <div className={styles['chat-message-loader__dot']}></div>
      <div className={styles['chat-message-loader__dot']}></div>
    </div>
  );
}
