import { createContext, useContext } from 'react';
import { TConfig, TConfigContext } from '../../common/types/Config';
import DEFAULT_CONFIG from './defaultConfig';

export const configObj: TConfig = {
  COGNITO_USER_POOL_ARN: '',
  COGNITO_CLIENT_ID: '',
  CONNECT_HOST: '',
  ...DEFAULT_CONFIG
};

const ConfigContext = createContext<TConfigContext>({ config: configObj, configLoaded: false });

export const useConfigContext = () => useContext(ConfigContext);

export default ConfigContext;
