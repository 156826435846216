import { useEffect, useState } from 'react';
import { far, faPage } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { IModule } from '@cloud-wave/neon-common-lib';

import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { SOFTPHONE_DEFAULT_NAV_OPTIONS } from 'lib/common/constants/softphoneDefaultNavOptions';
import UserProfile from 'lib/common/components/UserProfile';

import TUser from 'lib/common/types/User';
import { useConfigContext } from 'lib/core/config';
import { SoftphoneNavOption } from 'lib/common/types/SoftphoneNavOption';
import { ConnectPermissions } from 'lib/common/types/Permissions';

import SoftphoneAddAgentTaskButton from './components/SoftphoneAddAgentTaskButton';
import SoftphoneNavMenuItem from './components/SoftphoneNavMenuItem';
import SoftphoneTaskList from './components/SoftphoneTaskList';
import SoftphoneNavMenuFolder from './components/SoftphoneNavMenuFolder';

import './softphone-sidebar.scss';

interface ISoftphoneSidebar {
  user: TUser;
  isReversedLayout: boolean;
}

export default function SoftphoneSidebar({ user, isReversedLayout }: ISoftphoneSidebar) {
  const {
    state: { pageModules }
  } = useModulesContext();
  const { config } = useConfigContext();
  const { hasPermission } = usePermissionsContext();
  const [navOptions, setNavOptions] = useState<any>(SOFTPHONE_DEFAULT_NAV_OPTIONS(config));
  const [navLoaded, setNavLoaded] = useState(false);

  useEffect(() => {
    if (navLoaded) {
      return;
    }

    setNavOptions([
      ...navOptions,
      ...pageModules.map((pageModule: IModule) => ({
        link: `/${pageModule.pathName}`,
        name: pageModule.name,
        icon: pageModule.icon ? far[pageModule.icon] || faPage : faPage
      }))
    ]);

    setNavLoaded(true);
  }, []);

  const isCreateTaskDisabled = hasPermission({ type: 'connect', permission: ConnectPermissions.CREATE_TASK_DISABLED });

  return (
    <nav className={cx('softphone-sidebar', { 'softphone-sidebar--reversed': isReversedLayout })}>
      <div className="softphone-sidebar__top">
        <SoftphoneAddAgentTaskButton />
        <SoftphoneTaskList isCreateTaskDisabled={isCreateTaskDisabled} isReversedLayout={isReversedLayout} />
      </div>
      <div className="softphone-sidebar__nav-menu">
        {navOptions.map((item: SoftphoneNavOption) =>
          item.childOptions ? (
            <SoftphoneNavMenuFolder key={item.name} {...item} isReversedLayout={isReversedLayout} />
          ) : (
            <SoftphoneNavMenuItem key={item.link} {...item} />
          )
        )}
      </div>
      <UserProfile user={user} isReversedPopover={isReversedLayout} />
    </nav>
  );
}
