export const NONE = 'none';

export default [
  {
    value: 'https://cw-neon-public.s3.ap-southeast-2.amazonaws.com/assets/audio/ringtones/urban_tea.mp3',
    label: 'Urban Tea'
  },
  {
    value: 'https://cw-neon-public.s3.ap-southeast-2.amazonaws.com/assets/audio/ringtones/blue_agave.mp3',
    label: 'Blue Agave'
  },
  {
    value: 'https://cw-neon-public.s3.ap-southeast-2.amazonaws.com/assets/audio/ringtones/lean_forward.mp3',
    label: 'Lean Forward'
  },
  {
    value: 'https://cw-neon-public.s3.ap-southeast-2.amazonaws.com/assets/audio/ringtones/ringing_gently.mp3',
    label: 'Gentle'
  },
  {
    value: 'https://cw-neon-public.s3.ap-southeast-2.amazonaws.com/assets/audio/ringtones/smooth.mp3',
    label: 'Smooth'
  },
  {
    value: 'https://cw-neon-public.s3.ap-southeast-2.amazonaws.com/assets/audio/ringtones/woolly_ring.mp3',
    label: 'Woolly Ring'
  },
  {
    value: NONE,
    label: 'None'
  }
];
