export default {
  CONNECTING: 'CONNECTING',
  CONNECTED: 'CONNECTED',
  MISSED: 'MISSED',
  ACW: 'ACW',
  ACCEPTED: 'ACCEPTED',
  HOLD: 'HOLD',
  REJECTED: 'REJECTED',
  DRAFT: 'DRAFT'
} as const;
