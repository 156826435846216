import { RecordingState } from '@cloud-wave/neon-common-lib';
import { TAttachment } from 'lib/common/types/email/TAttachment';
import { TEmail } from 'lib/common/types/email/TEmail';
import { TTagInputState } from '../components/TagInput/useTagInputReducer';
import { EmailSendTypes } from '../hooks/useEmail/api/sendEmail';
import IEmailMetadata from './email/EmailMetadata';
import IWrapUpCode from './WrapUpCode';

interface ACW {
  wrapUpCodeName?: IWrapUpCode;
  notes?: string;
}

export const transferToAnyQueue = 'ANY' as const;
type TTransferToAnyQueue = typeof transferToAnyQueue;

interface Email {
  originalEmail?: { emailSubject: string; content?: TEmail | null; neonEmailTo: string; emailAddress: string };
  type: EmailSendTypes;
  fromAddressId: string | null;
  subject?: string;
  to?: TTagInputState;
  cc?: TTagInputState;
  bcc?: TTagInputState;
  content?: string;
  originalEmailHtml?: string;
  originalAttachments?: TAttachment[];
  metadata?: IEmailMetadata | null;
}

interface CallRecording {
  state: keyof typeof RecordingState;
  startPauseTime?: Date;
}

type TChatDraftMessage = {
  content?: string | null;
  attachment?: File | null;
};

export type { ACW, Email, CallRecording, TChatDraftMessage, TTransferToAnyQueue };
