import { faColumns3, faDownload, faFilter, faPrint, faSearch } from '@fortawesome/pro-regular-svg-icons';
import MUIDataTable from 'mui-datatables';

import Icon from 'lib/common/components/Icon';

export default function DataTable(props) {
  return (
    <MUIDataTable
      className="mui-table"
      components={{
        ...(props.components || {}),
        icons: {
          SearchIcon: () => <Icon icon={faSearch} />,
          DownloadIcon: () => <Icon icon={faDownload} />,
          PrintIcon: () => <Icon icon={faPrint} />,
          ViewColumnIcon: () => <Icon icon={faColumns3} size={25} />,
          FilterIcon: () => <Icon icon={faFilter} />
        }
      }}
      {...props}
    />
  );
}
