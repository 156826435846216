import { faSquareList, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { useEffect, useState } from 'react';
import { Permissions, TemplateType, TTemplateType, TTemplatedResponse } from '@cloud-wave/neon-common-lib';
import cx from 'classnames';
import { createHeadlessEditor } from '@lexical/headless';
import { $getRoot } from 'lexical';

import ClickableIcon from 'lib/common/components/ClickableIcon';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import { useConfigContext } from 'lib/core/config';
import requestErrorToast from 'lib/common/utils/toast/requestErrorToast';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import Text from 'lib/common/components/Text';
import Search from 'lib/common/components/atoms/Search';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import Loader from 'lib/common/components/Loader';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';

import Popover from '../Popover';
import { getTemplatedResponses } from './api/getTemplatedResponses';

import './styles/overrides.scss';

import styles from './styles/templated-response.module.scss';

const ICON_SIZE = 17;
const DEFAULT_POPOVER_OFFSET = 10;

type TemplatedResponseProps = {
  queueIds?: string[];
  type: TTemplateType;
  onClick: (content: string) => void;
  contentOnly?: boolean;
};

export default function TemplatedResponse({ type, queueIds = [], onClick, contentOnly }: TemplatedResponseProps) {
  const { config } = useConfigContext();
  const { fetch_ } = useAuthContext();
  const { isSoftphone } = useLayout();
  const { hasPermission } = usePermissionsContext();
  const [templates, setTemplates] = useState<TTemplatedResponse[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  // Getting text content from editor state needs a headless editor, but it throws an
  // error because it can't find any nodes because they don't exist because it's a headless
  //  editor. Hence, we're silencing those errors
  const editor = createHeadlessEditor({ onError: () => {} });

  const canUseTemplates = hasPermission({
    type: 'tenant',
    permission: Permissions.TEMPLATED_RESPONSE
  });

  //Fetch on first render
  useEffect(() => {
    if (!canUseTemplates) {
      return;
    }
    (async () => {
      try {
        const templatesRes = await getTemplatedResponses({ config, type, queueIds, fetch_ });
        setTemplates(JSON.parse(templatesRes));
        setLoading(false);
      } catch (e: any) {
        requestErrorToast({ errorReference: e.requestId });
      }
    })();
  }, []);

  if (!canUseTemplates) {
    return null;
  }

  const localSearch = (value: string) => {
    const lowerValue = value.toLowerCase();
    setSearchValue(lowerValue);
  };
  const filterArrayBySearchValue = () => {
    return templates.filter((template) => {
      const content =
        type === TemplateType.EMAIL
          ? editor.parseEditorState(template.content).read(() => $getRoot().getTextContent())
          : template.content;
      return content.toLowerCase().includes(searchValue) || template.name.toLowerCase().includes(searchValue);
    });
  };

  const templatesToDisplay = searchValue ? filterArrayBySearchValue() : templates;

  const TemplatedResponseHeader = ({ onClose }: { onClose?: () => void }) => (
    <div className={cx(styles['template-response-picker__header'])}>
      <Text className="template-response-picker__heading" type="heading3">
        Insert a Response
      </Text>
      {onClose && <ClickableIcon aria-label="Close" icon={faXmark} size={ICON_SIZE} onClick={onClose} />}
    </div>
  );

  const NoTemplates = () => (
    <Text type="extraSmall" className={cx(styles['template-response-picker__empty'])}>
      There aren’t any available templates to select.
    </Text>
  );

  const NoMatchingSearch = () => (
    <Text type="extraSmall" className={cx(styles['template-response-picker__empty'])}>
      There aren’t any templates that match the search,{' '}
      <Text bold inline type="extraSmall">
        "{searchValue}"
      </Text>
      .
    </Text>
  );

  const rootStyles = isSoftphone ? styles['template-response-picker--softphone'] : styles['template-response-picker'];
  const offsets = isSoftphone ? { offset: 0, offsetLeft: 0 } : { offset: 20, offsetLeft: 0 };

  const content = ({ onClose, withHeader = true }: { onClose?: () => void; withHeader?: boolean }) => (
    <div
      className={cx('templated-response-container', rootStyles, {
        'templated-response-container--softphone': isSoftphone
      })}
    >
      {withHeader && <TemplatedResponseHeader onClose={onClose} />}
      <Search onSearch={localSearch} size="SMALL" />
      <div className={cx(styles['template-response-picker__templates'])}>
        {(loading && <Loader size={30} relative />) ||
          (!templates.length && <NoTemplates />) ||
          (!templatesToDisplay.length && <NoMatchingSearch />) ||
          templatesToDisplay.map((template) => (
            <button
              onClick={() => {
                onClick(template.content);
                onClose?.();
              }}
              key={template.id}
              className="no-styles-button full-width"
            >
              <PopoverMenuItem
                text={template.name}
                subtitle={
                  type === TemplateType.EMAIL
                    ? editor.parseEditorState(template.content).read(() => $getRoot().getTextContent())
                    : template.content
                }
              />
            </button>
          ))}
      </div>
    </div>
  );

  if (contentOnly) {
    return content({});
  }

  if (isSoftphone) {
    return (
      <ClickableIcon
        icon={faSquareList}
        onClick={() => {
          openModal({
            content: content({ withHeader: false, onClose: closeModal }),
            title: 'Insert A Response'
          });
        }}
        size={ICON_SIZE}
        data-testid="templated-response-item"
        tooltip="Templates"
      />
    );
  }

  return (
    <Popover
      anchor={
        <ClickableIcon
          icon={faSquareList}
          onClick={() => {}}
          size={ICON_SIZE}
          data-testid="templated-response-item"
          tooltip="Templates"
        />
      }
      reverse
      transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      offset={-1 * (offsets.offset + DEFAULT_POPOVER_OFFSET)}
      offsetLeft={offsets.offsetLeft}
    >
      {content}
    </Popover>
  );
}
