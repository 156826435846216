import { ReactComponent as ErrorImage } from 'assets/vectors/error.svg';
import Text from 'lib/common/components/Text';

type TError = {
  show: boolean;
};

export default function Error({ show }: TError) {
  if (!show) {
    return null;
  }

  return (
    <div className="customer-profile__error">
      <ErrorImage />
      <div className="customer-profile__error__text">
        <Text type="heading2">Something went wrong.</Text>
        <Text>Try reloading the page.</Text>
      </div>
    </div>
  );
}
