import { useParams } from 'react-router-dom';
import CustomerProfile from '../components/CustomerProfile';

const CustomerProfileContainer = () => {
  const { profileId } = useParams<{ profileId: string }>();

  return <CustomerProfile profileId={profileId} />;
};

export default CustomerProfileContainer;
