import IWrapUpCode from '../../types/WrapUpCode';

export default function getWrapUpCodesPathname(wrapUpCodes: IWrapUpCode[]) {
  const getPathname = (wrapUpCode: IWrapUpCode, pathname = ''): string => {
    if (!wrapUpCode.parentCodeId) {
      return pathname ? pathname.trimEnd().slice(0, -1) : '';
    }

    const wrapUpParent = wrapUpCodes.find((code) => code.id === wrapUpCode.parentCodeId);

    return getPathname(wrapUpParent as IWrapUpCode, `${wrapUpParent?.name} / ${pathname}`);
  };

  return wrapUpCodes.map((wrapUpCode: IWrapUpCode) => ({
    ...wrapUpCode,
    pathname: getPathname(wrapUpCode)
  }));
}
