import { useEffect, useState } from 'react';

const DEFAULT_MAX_RESULTS = 20;

function getNumberOfPages(data, maxResults) {
  return Math.ceil(data.length / maxResults) || 1;
}

function generatePageNumbers(data, maxResults) {
  return [...new Array(getNumberOfPages(data, maxResults))].map((_, index) => index + 1);
}

function getPaginatedData(data, pageNumber, maxResults) {
  const startIndex = maxResults * (pageNumber - 1);

  return data.slice(startIndex, startIndex + maxResults);
}

export default function usePagination({ data = [], maxResults = DEFAULT_MAX_RESULTS, defaultPage = 1 }) {
  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [pages, setPages] = useState(generatePageNumbers(data, maxResults));
  const [paginatedData, setPaginatedData] = useState(getPaginatedData(data, currentPage, maxResults));

  const onSelectPage = (pageNumber) => {
    if (pageNumber > pages.length) {
      return;
    }

    setCurrentPage(pageNumber);
  };

  const onNextPage = () => {
    if (currentPage + 1 > pages.length) {
      return;
    }

    setCurrentPage(currentPage + 1);
  };

  const onPrevPage = () => {
    if (currentPage === 1) {
      return;
    }

    setCurrentPage(currentPage - 1);
  };

  useEffect(() => {
    const numberOfPages = getNumberOfPages(data, maxResults);
    const isCurrentPageValid = currentPage <= numberOfPages;

    if (!isCurrentPageValid) {
      setCurrentPage(numberOfPages);
    }

    setPages(generatePageNumbers(data, maxResults));
    setPaginatedData(getPaginatedData(data, currentPage, maxResults));
  }, [data]);

  useEffect(() => {
    setPaginatedData(getPaginatedData(data, currentPage, maxResults));
  }, [currentPage]);

  return {
    onNextPage,
    onPrevPage,
    onSelectPage,
    currentPage,
    pages,
    data: paginatedData
  };
}
