import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';

interface SideBarHotkeyProps {
  link: string;
  hotkey: string;
  pageHasSubMenu?: boolean;
  items: { link: string; external?: string }[];
}

const SidebarHotkey = ({ link, hotkey, pageHasSubMenu, items }: SideBarHotkeyProps) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [subMenuIndex, setSubMenuIndex] = useState(0);

  useEffect(() => {
    if (!pageHasSubMenu || !subMenuIndex) {
      return;
    }

    const subMenuPaths = items.map(({ link }) => link);

    if (subMenuPaths.includes(pathname)) {
      return;
    }

    setSubMenuIndex(0);
  }, [pathname, setSubMenuIndex, subMenuIndex, items, pageHasSubMenu]);

  const goToPage = () => {
    if (!pageHasSubMenu) {
      return history.push(link);
    }

    if (items[subMenuIndex].external) {
      return void window.open(items[subMenuIndex].link, '_blank');
    }

    history.push(items[subMenuIndex].link);
    setSubMenuIndex(subMenuIndex < items.length - 1 ? subMenuIndex + 1 : 0);
  };

  useHotkeys(hotkey, goToPage, [history, link, subMenuIndex, setSubMenuIndex]);

  return null;
};

export default SidebarHotkey;
