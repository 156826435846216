import { ConstValues, TPermissions } from '@cloud-wave/neon-common-lib';

export const PermissionTypes = {
  ORGANISATION: 'organisation',
  TENANT: 'tenant',
  USER: 'user',
  CONNECT: 'connect'
} as const;

export const ConnectPermissions = {
  CREATE_TASK_DISABLED: 'restrictTaskCreation'
} as const;

export type TConnectPermissions = ConstValues<typeof ConnectPermissions>;

export type TPermissionTypes = ConstValues<typeof PermissionTypes>;

export type TPermissionsConfig = {
  organisation: TPermissions[];
  tenant: TPermissions[];
  user: TPermissions[];
  connect: string[];
};
