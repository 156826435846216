import { IFetch } from 'lib/core/context/AuthProvider/Context';

import { TConfig } from '../types/Config';

export default async function getInstanceDirectories({ fetch_, config }: { fetch_: IFetch; config: TConfig }) {
  try {
    const response = await fetch_(`${config.DIRECTORY_SERVICE_URL}/user/directory`);

    const directoryList = (await response.json()).data as any[];
    return directoryList.reduce((dict, { directoryId, ...directory }) => ({ ...dict, [directoryId]: directory }), {});
  } catch {
    return {};
  }
}
