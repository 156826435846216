const TEXT_TYPES = {
  // text classes are defined in typography scss file until we move all text to this component
  heading1: { element: 'h1', className: 'header1' },
  heading2: { element: 'h2', className: 'header2' },
  heading3: { element: 'h3', className: 'header3' },
  heading4: { element: 'h4', className: 'header4' },
  body: { element: 'p', className: 'paragraph' },
  label: { element: 'label', className: 'label' },
  link: { element: 'a', className: 'anchor' },
  helper: { element: 'p', className: 'paragraph text--helper' },
  small: { element: 'p', className: 'paragraph text--small' },
  extraSmall: { element: 'p', className: 'paragraph text--extra-small' },
  tooltip: { element: 'p', className: 'paragraph text--tooltip' },
  hero: { element: 'h1', className: 'header1 text--hero' },
  underline: { element: 'u' },
  inline: { element: 'span' }
} as const;

export type TTextTypeKeys = keyof typeof TEXT_TYPES;

export default TEXT_TYPES as {
  [key in TTextTypeKeys]: {
    element: string;
    className?: string;
  };
};
