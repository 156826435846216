import cx from 'classnames';

import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import PopoverMenu from 'lib/common/components/PopoverMenu';
import Text from 'lib/common/components/Text';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import { getTaskDisplayProps } from 'lib/common/constants/taskStates';
import getUserName from 'lib/common/utils/getUserName';

import TChatTask from 'lib/common/types/ChatTask';
import TTask from 'lib/common/types/Task';
import { NotificationDot } from 'lib/common/components/NotificationDot';
import TaskMenuItem from './TaskMenuItem';

import styles from './tasks-popover.module.scss';

const MORE_TASKS_MENU_OFFSET = 30;

type TTasksPopover = {
  tasks: (TChatTask | TTask)[];
  selectedTask?: TChatTask | TTask;
  isReversedLayout: boolean;
  onTaskClick?: (taskId: string) => void;
};

const TasksPopover = ({ tasks, selectedTask, isReversedLayout, onTaskClick }: TTasksPopover) => {
  const extendedMenuHasMessage = Boolean(tasks.find((task) => (task as TChatTask)?.unreadMessage));
  const hasActiveItem = Boolean(tasks.find(({ taskId }) => taskId === selectedTask?.taskId));
  if (!tasks.length) {
    return null;
  }

  const handleTaskClick = (taskId: string) => {
    if (!onTaskClick) {
      return;
    }

    onTaskClick(taskId);
  };

  return (
    <PopoverMenu
      containerClassName={styles['tasks-popover__container']}
      anchor={
        <button
          aria-label="More tasks"
          className={cx(styles['tasks-popover__anchor-button'], {
            [styles['tasks-popover__anchor-button--active']]:
              hasActiveItem && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK
          })}
        >
          {extendedMenuHasMessage && <NotificationDot />}
          <Text type="body" data-testid="tasks-popover__anchor-item">{`+${tasks.length}`}</Text>
        </button>
      }
      closeOnClick
      offset={0}
      offsetLeft={isReversedLayout ? -MORE_TASKS_MENU_OFFSET : MORE_TASKS_MENU_OFFSET}
    >
      {tasks.map(({ taskId, ...otherTaskProps }) => (
        <PopoverMenuItem
          key={taskId}
          data-testid={`tasks-popover__task${
            selectedTask?.taskId === taskId && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK ? '--active' : ''
          }`}
          className={cx(styles['tasks-popover__container__task'], {
            [styles['tasks-popover__container__task--active']]:
              selectedTask?.taskId === taskId && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK
          })}
          onClick={() => handleTaskClick(taskId)}
          ariaLabel={`${getTaskDisplayProps({ taskId, ...otherTaskProps }).subtitle} for ${
            otherTaskProps.profile?.firstName ? getUserName(otherTaskProps?.profile) : otherTaskProps?.connectionValue
          }${otherTaskProps.queueName ? `in ${otherTaskProps.queueName}` : ''}`}
          customContent={
            <TaskMenuItem
              taskId={taskId}
              {...otherTaskProps}
              onSelect={handleTaskClick}
              selected={selectedTask?.taskId === taskId && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK}
            />
          }
        />
      ))}
    </PopoverMenu>
  );
};

export default TasksPopover;
