import _sortBy from 'lodash/sortBy';

import IWrapUpCode from 'lib/common/types/WrapUpCode';

/**
 * Alphabetises an array of wrap codes case-insensitively
 * @param codes
 */
export default function alphabeticallySortCodes(codes: IWrapUpCode[]) {
  return _sortBy(codes, (code: IWrapUpCode) => code.name.toLowerCase());
}
