import { useEffect } from 'react';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import { isConference as isConferenceContact } from 'lib/common/utils/conferenceConnections';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import TTask from 'lib/common/types/Task';
import connectGetter from 'lib/common/utils/connectGetter';

import handleConnectedContact from '../utils/handleConnectedContact';
import handleMissedContact from '../utils/handleMissedContact';
import handleCallRefresh from '../utils/handleCallRefresh';
import destroyTask from '../utils/destroyTask';
import buildConnectionTimestamps from '../utils/buildConnectionTimestamps';
import useMoveTaskToACW from './useMoveTaskToACW';

export default function useContactHandlers({
  matchNewContact,
  handleContactChange,
  fetch_,
  config,
  tokens,
  hasMissedCallPermission,
  getTasks
}) {
  const { getUser } = useAuthContext();
  const moveTaskToACW = useMoveTaskToACW({ handleContactChange });

  useEffect(() => {
    const connect = (window as any).getConnect();

    const contactSubscription = connect.contact((contact: connect.Contact) => {
      contact.onConnecting(matchNewContact);

      contact.onIncoming(matchNewContact);

      contact.onAccepted(() => {
        handleContactChange({ contact, status: CONTACT_STATES.ACCEPTED, eventTimestamp: Date.now() });
      });

      contact.onConnected(async (latestContact: connect.Contact) =>
        handleConnectedContact({ contact: latestContact, handleContactChange, fetch_, config, tokens, getUser })
      );

      /**
       * We're double handling ACW here, because we cannot differentiate ACW triggered by us vs. customer disconnecting.
       * If we just rely on us moving the task to acw, when the customer disconnects nothing will happen
       */
      contact.onACW((latestContact: connect.Contact) => moveTaskToACW(latestContact));

      contact.onMissed((latestContact: connect.Contact) =>
        handleMissedContact({ contact: latestContact, handleContactChange, hasMissedCallPermission })
      );

      /**
       * NEONNOW should have destroyed the contact already, before waiting for the connect event. Handle them here as well, just in case
       */
      contact.onDestroy((contact: connect.Contact) => destroyTask({ contact, handleContactChange }));

      contact.onRefresh((latestContact: connect.Contact) => {
        const existingTask = getTasks().find((existingTask: TTask) => existingTask.taskId === contact.contactId);

        // If task is in ACW, we bail out
        if (existingTask?.status === CONTACT_STATES.ACW) {
          return;
        }

        const contactType = connectGetter(latestContact, 'getType');
        const contactState = connectGetter(latestContact, 'getState')?.type;

        const isConference = isConferenceContact(latestContact);
        const isCall = contactType === connect.ContactType.VOICE || contactType === connect.ContactType.QUEUE_CALLBACK;
        const isConnectedCall = contactState === connect.ContactStateType.CONNECTED && isCall;

        // If task is not conference or connected, we bail out
        if (!isConference && !isConnectedCall) {
          return;
        }

        // Manually move to ACW if the conference call has ended
        if (isConference && connectGetter(latestContact, 'getState')?.type === connect.ContactStateType.ENDED) {
          return moveTaskToACW(latestContact);
        }

        // Building connection timestamps for conference to keep them in order
        // because we only show (agent) as connection name for internal transfers
        const connectionTimestamps = buildConnectionTimestamps({ contact: latestContact, task: existingTask });

        handleCallRefresh({ handleContactChange, contact: latestContact, connectionTimestamps, existingTask });
      });
    });

    return () => contactSubscription.unsubscribe();
  }, []);
}
