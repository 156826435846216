import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import cx from 'classnames';
import style from './notification-dot.module.scss';
export const NotificationDot = () => {
  const { isSoftphone } = useLayout();
  return (
    <div
      className={cx(style['notification-dot'], { [style['notification-dot--softphone']]: isSoftphone })}
      data-testid="notification-dot"
    />
  );
};
