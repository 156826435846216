import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { ReactNode } from 'react';
import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';

import './descriptive-pill.scss';

export interface DescriptivePillProps {
  heading: string;
  icon?: IconDefinition;
  description?: string | ReactNode;
  selected?: boolean;
  onClick: () => void;
  disabled?: boolean;
  maxWidth?: number;
}

const DescriptivePill = ({
  heading,
  icon,
  description,
  selected,
  onClick,
  disabled,
  maxWidth
}: DescriptivePillProps) => {
  return (
    <button
      role="tab"
      aria-selected={selected}
      aria-label={heading}
      data-testid={`descriptive-pill-option-${heading}`}
      className={cx('descriptive-pill', 'no-styles-button', {
        'descriptive-pill--selected': selected,
        'descriptive-pill--disabled': selected || disabled
      })}
      onClick={disabled || selected ? void 0 : onClick}
      style={{ maxWidth }}
    >
      <div className="descriptive-pill__heading">
        <div className="next-to">
          {icon && <Icon className="descriptive-pill__heading__right__icon" icon={icon} size={15} />}
          <Text type="heading3">{heading}</Text>
        </div>
        <span className="descriptive-pill__heading__selected-icon" />
      </div>
      {description && (typeof description === 'string' ? <Text type="extraSmall">{description}</Text> : description)}
    </button>
  );
};

export default DescriptivePill;
