import Text from 'lib/common/components/Text';
import convertMSToReadableTime from 'lib/common/utils/convertMSToReadableTime';

import styles from './queue.module.scss';

export default function Queue({ id, name, contactsInQueue, oldestContactInQueue, agentsAvailable, agentsOnline }) {
  return (
    <div className={styles['queue']} key={id}>
      <div className={styles['queue__container']} key={id}>
        <Text ellipsis className={styles['queue__container__queue-name']}>
          {name}
        </Text>
        <div className={styles['queue__container__details']}>
          <Text>{contactsInQueue}</Text>
          <Text>{oldestContactInQueue ? convertMSToReadableTime(oldestContactInQueue * 1000) : `00:00`}</Text>
          <Text>
            {agentsAvailable}/{agentsOnline}
          </Text>
        </div>
      </div>
    </div>
  );
}
