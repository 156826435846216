import queryString from 'query-string';

type LocationParams = {
  url?;
  param: string;
};

type UrlParams = {
  url: string;
  param: string;
};

function getValueFromQuery(query, param) {
  if (!query) {
    return null;
  }

  return Array.isArray(query[param]) ? query[param]?.[0] : query[param];
}

export default function getQueryParam({ url, param }: LocationParams | UrlParams) {
  const { location } = window;

  if (url) {
    const parsedQuery = queryString.parseUrl(url)?.query;

    return getValueFromQuery(parsedQuery, param);
  }

  const parsedQuery = queryString.parse(location.search);

  return getValueFromQuery(parsedQuery, param);
}
