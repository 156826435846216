import { faHeadset, faUser } from '@fortawesome/pro-regular-svg-icons';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import useTaskConnections from 'lib/common/hooks/useTaskConnections';
import getUserName from 'lib/common/utils/getUserName';
import optional from 'lib/common/utils/optional';
import ConferenceActions from 'lib/common/utils/ConferenceActions';
import isActionableCallTask from 'lib/common/utils/tasks/isActionableCallContact';
import TTask from 'lib/common/types/Task';
import TUser from 'lib/common/types/User';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import {
  getAgentConnection,
  getAllActiveConnections,
  getAllActiveThirdPartyConnections,
  isAnyConnectionConnecting,
  isAnyConnectionOnHold,
  isMultiPartyConferenceEnabled
} from 'lib/common/utils/conferenceConnections';
import connectGetter from 'lib/common/utils/connectGetter';
import ConferenceAgentName from 'lib/common/components/atoms/ConferenceAgentName';
import agentIsMonitoring from 'lib/common/utils/connect/agentIsMonitoring';

import AttachedData from '../AttachedData';
import InfoPanel from '../InfoPanel';
import ConferenceUserRow from './components/ConferenceUserRow';

interface ITimedTaskProps extends TTask {
  user?: TUser;
}

export default function TimedTask(props: ITimedTaskProps) {
  const { isNeglected, isCurrentConference, initialConnectionDisconnected } = useTaskConnections(props);
  const {
    actions: { endInitialConnection, holdConferenceConnection, resumeConferenceConnection }
  } = useContactContext();
  const {
    profile,
    queueName,
    time,
    connectionValue,
    type,
    taskId,
    connectionState,
    contact,
    status,
    user,
    connectionTimestamps
  } = props;
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const activeThirdPartyConnections = getAllActiveThirdPartyConnections(contact);
  const activeConnectionsLength = getAllActiveConnections(contact).length;
  const onDisconnectMainTask = () => endInitialConnection(taskId);
  const isMultiPartyConference = isMultiPartyConferenceEnabled(contact);
  const callMissedOrRejected = status === CONTACT_STATES.MISSED || status === CONTACT_STATES.REJECTED;
  const isMonitoring = agentIsMonitoring(contact);
  const agentConnection = getAgentConnection(contact);
  const initialConnection = connectGetter(contact, 'getInitialConnection');
  const anyConnectionOnHold = isAnyConnectionOnHold(contact);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        {isCurrentConference && isMultiPartyConference && !isMonitoring && (
          <InfoPanel.Row
            text={<ConferenceAgentName agentName={getUserName(user)} isSoftphone />}
            testId="agent-connection"
            icon={faHeadset}
            showBadgeText={!isCurrentConference}
            badgeProps={{
              callIsOnHold: connectGetter(agentConnection, 'getState')?.type === connect.ConnectionStateType.HOLD,
              isNeglected,
              status,
              connection: agentConnection
            }}
            actions={
              <ConferenceActions
                participantName={getUserName(user)}
                isConnectionOnHold={
                  connectGetter(agentConnection, 'getState')?.type === connect.ConnectionStateType.HOLD
                }
                holdConnection={() => holdConferenceConnection({ taskId, connectionId: agentConnection?.connectionId })}
                resumeConnection={() =>
                  resumeConferenceConnection({ taskId, connectionId: agentConnection?.connectionId })
                }
                isAnyConnectionConnecting={isAnyConnectionConnecting(contact)}
              />
            }
          />
        )}
        {!initialConnectionDisconnected && (
          <InfoPanel.Row
            text={name}
            testId="initial-connection"
            icon={optional(isCurrentConference, faUser)}
            badgeProps={{
              callIsOnHold: connectionState === CONTACT_STATES.HOLD,
              isNeglected,
              status,
              connection: initialConnection
            }}
            actions={optional(
              activeConnectionsLength > 1 && !isMonitoring,
              <ConferenceActions
                participantName={name}
                onDisconnect={onDisconnectMainTask}
                isConnectionOnHold={connectionState === CONTACT_STATES.HOLD}
                holdConnection={() =>
                  holdConferenceConnection({ taskId, connectionId: initialConnection?.connectionId })
                }
                resumeConnection={() =>
                  resumeConferenceConnection({ taskId, connectionId: initialConnection?.connectionId })
                }
                isAnyConnectionConnecting={isAnyConnectionConnecting(contact)}
              />
            )}
            showBadgeText={!isCurrentConference}
          />
        )}
        <ConferenceUserRow
          isCurrentConference={isCurrentConference}
          taskId={taskId}
          activeThirdPartyConnections={activeThirdPartyConnections}
          activeConnectionsLength={activeConnectionsLength}
          connectionTimestamps={connectionTimestamps}
          isMonitoring={isMonitoring}
          isAnyConnectionConnecting={isAnyConnectionConnecting(contact)}
        />
      </InfoPanel>
      {!callMissedOrRejected && <ACWOutcome />}
      <AttachedData task={props} />
      {isCurrentConference && anyConnectionOnHold && !isMonitoring && (
        <TaskFooter.ConferenceSubFooter taskId={taskId} contact={contact} />
      )}
      {optional(
        isActionableCallTask({ type, status }),
        <TaskFooter.ConnectedCall
          inCurrentConference={isCurrentConference}
          connectionState={connectionState}
          taskId={taskId}
          connectionValue={connectionValue}
          initialConnectionDisconnected={initialConnectionDisconnected}
          type={type}
          contact={contact}
          isSoftphone
        />
      )}
      {optional(isNeglected, <TaskFooter.ClearTask taskId={taskId} />)}
    </>
  );
}
