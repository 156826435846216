import toast from 'lib/common/utils/toast';

import type { TConfig } from 'lib/common/types/Config';

interface IArgs {
  config: TConfig;
  fetch_: (url: string, options: any) => Promise<any>;
  prompt: string;
}

export default async function generateNotesAISummary({ config, fetch_, prompt }: IArgs) {
  try {
    const endpoint = `${config.AGENT_SERVICE_V2_URL}/user/generate-notes-ai-summary`;

    const response = await fetch_(endpoint, {
      body: JSON.stringify({ prompt }),
      method: 'POST'
    });

    const summary = (await response.json()).data.summary;

    return summary || '';
  } catch {
    toast('error', `Something went wrong generating the summary. Please try again.`);
  }
}
