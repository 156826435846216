import { ContactType } from '@cloud-wave/neon-common-lib';

export default function getAllowedType(isAdmin: boolean, hasPersonalContactPermission: boolean) {
  if (!isAdmin && hasPersonalContactPermission) {
    return ContactType.PERSONAL;
  }

  if (isAdmin && !hasPersonalContactPermission) {
    return ContactType.ORGANISATION;
  }

  return null;
}
