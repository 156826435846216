import { Auth } from 'aws-amplify';

import { LogEvents, logger } from 'lib/common/components/LoggerController';

export const TOKEN_REVOKED_ERROR = 'Access Token has been revoked';

export const handleSignout = async ({ global }: { global?: boolean }) => {
  try {
    await Auth.signOut({ global });
  } catch (error: any) {
    // Fixes NEON-3820 - https://github.com/aws-amplify/amplify-js/issues/3540#issuecomment-506442759
    if (error?.message === TOKEN_REVOKED_ERROR) {
      return await handleSignout({ global: false });
    }

    logger.error(LogEvents.AUTH.SIGN_OUT.COMPLETED.FAIL, { error });
    throw error;
  }
};
