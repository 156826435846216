export const DIGITS = {
  ASTERISK: '*'
};

export const DIALS = [
  [
    {
      digit: '1',
      sub: '-'
    },
    {
      digit: '2',
      sub: 'ABC'
    },
    {
      digit: '3',
      sub: 'DEF'
    }
  ],
  [
    {
      digit: '4',
      sub: 'GHI'
    },
    {
      digit: '5',
      sub: 'JKL'
    },
    {
      digit: '6',
      sub: 'MNO'
    }
  ],
  [
    {
      digit: '7',
      sub: 'PQRS'
    },
    {
      digit: '8',
      sub: 'TUV'
    },
    {
      digit: '9',
      sub: 'WXYZ'
    }
  ],
  [
    {
      digit: DIGITS.ASTERISK,
      sub: ''
    },
    {
      digit: '0',
      sub: '+'
    },
    {
      digit: '#',
      sub: ''
    }
  ]
];
