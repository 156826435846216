import TProfile from '../../types/CustomerProfile';

type TDisplayNameOptions = {
  capitalised?: boolean;
};

const getContactDisplayName = (profile?: TProfile | null, { capitalised }: TDisplayNameOptions = {}) =>
  profile?.firstName || `${capitalised ? 'T' : 't'}his person`;

export default getContactDisplayName;
