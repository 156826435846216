import { faFolder } from '@fortawesome/pro-regular-svg-icons';
import { TreeNode } from 'primereact/treenode';
import _sortBy from 'lodash/sortBy';

import Icon from 'lib/common/components/Icon';
import IWrapUpCode from 'lib/common/types/WrapUpCode';
import WRAP_UP_CODE_TYPES from 'lib/common/constants/ACW/wrapUpCodeTypes';

import alphabeticallySortCodes from './alphabeticallySortCodes';

function getNodeFromCode(code: IWrapUpCode): TreeNode {
  return {
    id: code.id,
    key: code.id,
    label: code.name,
    data: code,
    selectable: code.type === WRAP_UP_CODE_TYPES.CODE,
    leaf: code.type === WRAP_UP_CODE_TYPES.CODE,
    icon: code.type === WRAP_UP_CODE_TYPES.GROUP ? <Icon icon={faFolder} size={10} /> : undefined,
    style: code.type === WRAP_UP_CODE_TYPES.CODE ? { fontWeight: 500 } : undefined
  };
}

function createTreeFromCodes(codes: IWrapUpCode[], allCodes: IWrapUpCode[]) {
  return (
    alphabeticallySortCodes(codes)
      .map((code) => {
        const newCode = getNodeFromCode(code);

        // If code is not a group, no need to do recursive tree logic
        if (code.type === WRAP_UP_CODE_TYPES.CODE) {
          return newCode;
        }

        // Alphabetise the codes/groups
        const children = alphabeticallySortCodes(
          allCodes.filter((nestedCode: IWrapUpCode) => nestedCode.parentCodeId === code.id)
        );

        // Don't include any groups that have no children. These null items are filtered out later
        if (!children.length) {
          return null;
        }

        // Recursive tree building on children
        const childTree = createTreeFromCodes(children, allCodes);

        // In some cases we may have one or more children, but they could also be empty groups, so check for their children length as well
        if (childTree.every((childNode: TreeNode) => childNode.children && !childNode.children.length)) {
          return null;
        }

        return { ...newCode, children: childTree };
      })
      // Get rid of any empty groups
      .filter(Boolean)
  );
}

export default function generateTree(wrapCodes: IWrapUpCode[]): TreeNode[] {
  // We only want to start by traversing codes at the root, so we can build recursive trees
  const codesAtRoot = wrapCodes.filter((code) => !code.parentCodeId);

  return createTreeFromCodes(codesAtRoot, wrapCodes);
}
