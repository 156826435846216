import TChatTask from 'lib/common/types/ChatTask';
import cx from 'classnames';
import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';
import { NotificationDot } from 'lib/common/components/NotificationDot';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { getTaskDisplayProps } from 'lib/common/constants/taskStates';
import TaskMenuItem from 'lib/common/layouts/SoftphoneLayout/components/SoftphoneSidebar/components/SoftphoneTaskList/components/TaskMenuItem';
import TTask from 'lib/common/types/Task';

export const SoftPhoneTaskItem = ({
  task,
  incomingTask,
  handleTaskClick,
  isActiveTask,
  highlightPopover = true
}: {
  task: TChatTask | TTask;
  incomingTask?: TChatTask | TTask;
  handleTaskClick: (taskId: string) => void;
  isActiveTask: boolean;
  highlightPopover?: boolean;
}) => {
  const hasNewMessage = (task as TChatTask)?.unreadMessage || false;

  const wrappedHandelTaskClick = () => {
    handleTaskClick(task.taskId);
  };

  const taskDisplayProps = getTaskDisplayProps(task);

  return (
    <div
      className={cx('softphone-task-list__item', {
        'softphone-task-list__item--active': isActiveTask && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK,
        'softphone-task-list__item--incoming': incomingTask?.taskId === task.taskId
      })}
    >
      {hasNewMessage && <NotificationDot />}
      <ClickableIcon
        aria-label={`${taskDisplayProps.subtitle} with ${
          task.profile?.firstName ? `${task.profile?.firstName} ${task.profile?.lastName || ''}` : task.connectionValue
        }`}
        icon={task.status === CONTACT_STATES.ACCEPTED ? faSpinnerThird : taskDisplayProps.icon}
        withHover
        tooltip={<TaskMenuItem {...task} selected={isActiveTask && highlightPopover} onSelect={() => {}} />}
        tooltipPlacement="right"
        onClick={wrappedHandelTaskClick}
        size={17}
        spin={task.status === CONTACT_STATES.ACCEPTED}
        tooltipClassName={cx('softphone-task-list__item__tooltip', {
          'softphone-task-list__item--active__tooltip':
            isActiveTask && highlightPopover && location.pathname === SOFTPHONE_PAGE_ROUTES.TASK
        })}
      />
    </div>
  );
};
