import { format } from 'date-fns';
import cx from 'classnames';
import { TAttachment } from '../../../types/email/TAttachment';
import IEmailMetadata from '../../../types/email/EmailMetadata';
import { TEmail } from '../../../types/email/TEmail';
import getDateFormat from '../../../utils/getDateFormat';
import Text from '../../Text';
import EmailRecipients from './EmailRecipients';

export function LetterHead({
  neonEmailAllRecipients,
  neonEmailCcRecipients,
  attachments,
  AttachmentsEl,
  softphoneAttachments,
  hideDate,
  loading,
  metadata,
  showAllFields,
  isSoftphone,
  content
}: {
  neonEmailAllRecipients: string;
  neonEmailCcRecipients: string;
  attachments: TAttachment[];
  AttachmentsEl: () => null | JSX.Element;
  softphoneAttachments: React.ReactPortal | null;
  hideDate?: boolean;
  loading?: boolean;
  metadata?: IEmailMetadata;
  showAllFields?: boolean;
  isSoftphone?: boolean;
  content?: TEmail | null;
}) {
  const emailDate = content?.date ? format(new Date(content.date), `H:mm - ${getDateFormat()}`) : null;
  const extraTos = [
    ...new Set(
      neonEmailAllRecipients?.split(',').filter((value) => !value.includes(metadata?.to || '') && value !== '')
    )
  ];
  const extraCcs = [...new Set(neonEmailCcRecipients?.split(',').filter((value) => value !== ''))];

  if (!showAllFields) {
    return (
      <>
        <div className={cx('email__header', 'email__header__title-row')}>
          <Text tooltip={metadata?.from} type="heading3" className="mr-auto">
            {metadata?.from}
          </Text>
          {!isSoftphone && attachments.length ? <AttachmentsEl /> : softphoneAttachments}
          {!isSoftphone && emailDate && !hideDate && <Text inline>{emailDate}</Text>}
        </div>
        {!loading && <EmailRecipients metadata={metadata} extraCcs={extraCcs} extraTos={extraTos} extraMargin />}
      </>
    );
  }

  return (
    <div className={cx('email__header', { 'email__header--softphone': isSoftphone })}>
      <div className="email__header__title-row">
        <Text type="heading2" className="email__header__title">
          {content?.subject}
        </Text>
        {attachments.length ? <AttachmentsEl /> : softphoneAttachments}
        {!isSoftphone && emailDate && !hideDate && <Text inline>{emailDate}</Text>}
      </div>

      <div className="email__header__subtitle-row">
        <Text type="heading3">{metadata?.from}</Text>
      </div>

      {!loading && (
        <>
          <div className="email__header__recipients">
            <EmailRecipients metadata={metadata} extraCcs={extraCcs} extraTos={extraTos} />
          </div>
          {isSoftphone && emailDate && !hideDate && (
            <Text className={cx('email__header__date', { 'email__header__date--softphone': isSoftphone })} inline>
              {emailDate}
            </Text>
          )}
          {isSoftphone && <hr />}
        </>
      )}
    </div>
  );
}
