import { faDownload } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Text from 'lib/common/components/Text';
import Icon from 'lib/common/components/Icon';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';

import styles from './download-logs.module.scss';

const handleClick = () => (window as any).getConnect().getLog().download();

export default function DownloadLogs({
  hidden = false,
  alwaysShown = false
}: {
  hidden?: boolean;
  alwaysShown?: boolean;
}) {
  const {
    state: { enhancedLoggingEnabled }
  } = usePreferencesContext();

  if (!alwaysShown && !enhancedLoggingEnabled) {
    return null;
  }

  return (
    <div className={cx(styles['download-logs'], { [styles['download-logs--hidden']]: hidden })}>
      <Text inline>Having Issues?</Text>
      <Text onClick={handleClick} className={styles['download-logs__label']}>
        Download logs
        <Icon icon={faDownload} onClick={handleClick} color="primary" />
      </Text>
    </div>
  );
}
