import { useEffect, useRef } from 'react';
import { useMediaQuery } from 'react-responsive';
import cx from 'classnames';
import { ModuleType, Permissions } from '@cloud-wave/neon-common-lib';
import { faWindowFlip, faMinus, faTimes, faMinusSquare } from '@fortawesome/pro-regular-svg-icons';
import { P, match } from 'ts-pattern';
import { SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';
import { useLocation } from 'react-router-dom';

import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { useDesktopLayoutContext } from 'lib/common/contexts/layout/DesktopLayoutContext';
import SoftphoneLayoutProvider from 'lib/common/contexts/layout/SoftphoneLayoutContext';
import SoftphoneModalContext from 'lib/common/contexts/SoftphoneModalContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import WrapUpCodeContext from 'lib/common/contexts/WrapUpCodeContext';
import PresenceContext from 'lib/common/contexts/PresenceContext';
import LayoutProvider from 'lib/common/contexts/layout/LayoutContext';
import { WidgetsPlacement } from 'lib/common/contexts/ModulesContext/constants/WidgetsPlacement';
import { WidgetsState } from 'lib/common/contexts/ModulesContext/constants/WidgetsState';
import { useMonitorLayoutState } from 'lib/common/hooks/useMonitorLayoutChange';
import useModules from 'lib/common/hooks/useModules/useModules';
import ResizableGroup from 'lib/common/components/molecules/ResizableGroup';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import DraggableWidgetsColumn from 'lib/common/components/atoms/DraggableWidgetsColumn';
import WarningBanner from 'lib/common/components/WarningBanner';
import contactStates from 'lib/common/constants/contactStates';
import VARS from 'css/export-vars.module.scss';
import { useAgentPreferencesContext } from '../../../common/contexts/AgentPreferenceContext';
import EmailSignatureModal from '../../../common/contexts/AgentPreferenceContext/EmailSignatureModal';

import SoftphoneRoutes from '../SoftphoneRoutes';
import DesktopRoutes from '../DesktopRoutes';
import layoutSwitcherColumnIds from './constants/layoutSwitcherColumnIds';

import styles from './layout-switcher.module.scss';

const DEFAULT_MODULES_COLUMN_MIN_WIDTH = 160;
const DEFAULT_SOFTPHONE_MODULES_COLUMN_MIN_WIDTH = 200;
const DEFAULT_APP_CONTENT_MIN_WIDTH = 650;
const DEFAULT_SOFTPHONE_APP_CONTENT_MIN_WIDTH = 200;
const DESKTOP_SIDEBAR_OFFSET = 200;

export default function LayoutSwitcher({ user, isReversedLayout }) {
  const { pathname } = useLocation();
  const { isSidebarOpen } = useDesktopLayoutContext();
  const { hasPermission } = usePermissionsContext();
  const {
    state: { preferenceModalOpen },
    actions: { setPreferenceModalOpen }
  } = useAgentPreferencesContext();
  const {
    actions: { getSelectedTask }
  } = useContactContext();
  const isForcedSoftphone = hasPermission({ permission: Permissions.SOFTPHONE_ONLY });
  const isSoftphoneSize = useMediaQuery({ query: `(max-width: ${VARS.softphoneWidth})` });
  const isSoftphone = isForcedSoftphone || isSoftphoneSize;
  const isDesktop = !isSoftphone;

  useMonitorLayoutState(isSoftphone);

  useEffect(() => {
    if (isDesktop || widgetsPlacement === WidgetsPlacement.PINNED) {
      return;
    }

    handleWidgetsConfigChange({ widgetsPlacement: WidgetsPlacement.PINNED });
  }, [isSoftphone]);

  const {
    state: {
      widgetsConfig: { widgetsPlacement, widgetsState }
    },
    actions: { handleWidgetsConfigChange }
  } = useModulesContext();

  const contentRef = useRef<HTMLDivElement | null>(null);
  const minimiseWidgetsColumnRef = useRef({ onMinimise: (_: { minimiseSize: number }) => void 0 });

  const isWidgetsColumnFloating = widgetsPlacement === WidgetsPlacement.FLOATING;
  const isWidgetsColumnClosed = widgetsState === WidgetsState.CLOSED;
  const desktopAppContentMinWidth = !isSidebarOpen
    ? DEFAULT_APP_CONTENT_MIN_WIDTH
    : DEFAULT_APP_CONTENT_MIN_WIDTH + DESKTOP_SIDEBAR_OFFSET;
  const softphoneModulesColumnMinWidth = isWidgetsColumnClosed ? 35 : DEFAULT_SOFTPHONE_MODULES_COLUMN_MIN_WIDTH;

  const onTabChange = () => {
    if (isDesktop || widgetsState === WidgetsState.OPEN) {
      return;
    }

    handleWidgetsConfigChange({ widgetsState: WidgetsState.OPEN });
  };

  const selectedTask = getSelectedTask();
  const { tabs, content, modules } = useModules({
    moduleType: isSoftphone
      ? match({ pathname, taskStatus: selectedTask?.status })
          .returnType<'WIDGET' | 'TASK' | undefined>()
          .with({ pathname: SOFTPHONE_PAGE_ROUTES.TASK, taskStatus: contactStates.DRAFT }, () => ModuleType.WIDGET)
          .with({ pathname: SOFTPHONE_PAGE_ROUTES.TASK, taskStatus: P._ }, () => void 0)
          .with({ pathname: P._ }, () => ModuleType.WIDGET)
          .exhaustive()
      : ModuleType.WIDGET,
    selectedTask,
    isSoftphone,
    tabsClassName: cx({
      [styles['layout-switcher__modules-column__tabs--softphone']]: isSoftphone && !isWidgetsColumnClosed
    }),
    onTabChange
  });

  const layout = isSoftphone ? (
    <SoftphoneModalContext isReversedLayout={isReversedLayout}>
      <SoftphoneRoutes user={user} isReversedLayout={isReversedLayout} contentRef={contentRef} />
    </SoftphoneModalContext>
  ) : (
    <DesktopRoutes user={user} />
  );

  return (
    <LayoutProvider isForcedSoftphone={isForcedSoftphone}>
      <EmailSignatureModal
        open={preferenceModalOpen}
        closeModal={() => {
          setPreferenceModalOpen(false);
        }}
      />
      <WrapUpCodeContext>
        <PresenceContext>
          <SoftphoneLayoutProvider
            className={cx(styles['layout-switcher'], {
              [styles['layout-switcher--softphone']]: isSoftphone
            })}
            contentRef={contentRef}
            footerClassName={styles['layout-switcher__softphone__footer']}
          >
            {isDesktop && <WarningBanner className={cx([styles['layout-switcher__desktop__warning-banner']])} />}
            <div className="layout-switcher-container">
              <ResizableGroup
                containerClassName="layout-switcher-container"
                resizerClassname={cx({ [styles['layout-switcher__softphone__resizer']]: isSoftphone })}
                vertical={isSoftphone}
                additionalResizerContent={
                  isSoftphone && (
                    <ClickableIcon
                      tooltip="Minimise"
                      icon={faMinusSquare}
                      onClick={(e) => {
                        handleWidgetsConfigChange({ widgetsState: WidgetsState.CLOSED });
                        e.stopPropagation();
                      }}
                      size={15}
                      className={cx([styles['layout-switcher__softphone__resizer__icon']])}
                    />
                  )
                }
                layoutOptions={[
                  {
                    defaultSizePercent: isSoftphone ? 50 : 70,
                    id: layoutSwitcherColumnIds.APP_CONTENT,
                    minSize: isSoftphone ? DEFAULT_SOFTPHONE_APP_CONTENT_MIN_WIDTH : desktopAppContentMinWidth,
                    maximised: isWidgetsColumnClosed || isWidgetsColumnFloating || !modules.length,
                    preventResize: isSoftphone && isWidgetsColumnClosed
                  },
                  {
                    defaultSizePercent: isSoftphone ? 50 : 30,
                    id: layoutSwitcherColumnIds.MODULES,
                    minSize: isSoftphone ? softphoneModulesColumnMinWidth : DEFAULT_MODULES_COLUMN_MIN_WIDTH,
                    className: cx({ [styles['layout-switcher__modules-column--softphone']]: isSoftphone }),
                    minimised: isWidgetsColumnClosed,
                    preventResize: isWidgetsColumnClosed,
                    hide: !modules.length || (isDesktop && (isWidgetsColumnClosed || isWidgetsColumnFloating))
                  }
                ]}
                ref={minimiseWidgetsColumnRef}
              >
                <div className={styles['layout-switcher__main-column']} key={layoutSwitcherColumnIds.APP_CONTENT}>
                  {layout}
                </div>
                <DraggableWidgetsColumn
                  open={!isWidgetsColumnClosed}
                  key={layoutSwitcherColumnIds.MODULES}
                  title={isSoftphone ? 'Modules' : 'Widgets'}
                >
                  {isDesktop && (
                    <div
                      className={cx(styles['layout-switcher__modules-column__header'], {
                        [styles['layout-switcher__modules-column__header--floating']]: isWidgetsColumnFloating
                      })}
                    >
                      <div className={styles['layout-switcher__modules-column__header__tabs']}>{tabs}</div>

                      {!isWidgetsColumnFloating && (
                        <div className={styles['layout-switcher__modules-column__header__actions']}>
                          <ClickableIcon
                            icon={faWindowFlip}
                            tooltip="Move out of page"
                            size={17}
                            onClick={() => handleWidgetsConfigChange({ widgetsPlacement: WidgetsPlacement.FLOATING })}
                          />
                          <ClickableIcon
                            icon={faMinus}
                            size={17}
                            tooltip="Reset window"
                            onClick={() =>
                              minimiseWidgetsColumnRef?.current?.onMinimise({
                                minimiseSize: DEFAULT_MODULES_COLUMN_MIN_WIDTH
                              })
                            }
                          />
                          <ClickableIcon
                            icon={faTimes}
                            tooltip="Close"
                            size={17}
                            onClick={() => handleWidgetsConfigChange({ widgetsState: WidgetsState.CLOSED })}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={cx(styles['layout-switcher__modules-column__content'], {
                      [styles['layout-switcher__modules-column__content--floating']]: isWidgetsColumnFloating,
                      [styles['layout-switcher__modules-column__content--softphone']]: isSoftphone,
                      [styles['layout-switcher__modules-column__content--closed']]: isWidgetsColumnClosed
                    })}
                  >
                    {content}
                  </div>
                  {isSoftphone && <>{tabs}</>}
                </DraggableWidgetsColumn>
              </ResizableGroup>
            </div>
          </SoftphoneLayoutProvider>
        </PresenceContext>
      </WrapUpCodeContext>
    </LayoutProvider>
  );
}
