import { ReactChild } from 'react';

import Text from 'lib/common/components/Text';

import './information-field.scss';

interface IInformationField {
  label: string;
  value?: any;
  parseValue?: (_: any) => ReactChild;
}

export default function InformationField({ label, value, parseValue }: IInformationField) {
  if (!value) {
    return null;
  }

  return (
    <div className="information-field">
      <span>
        <strong>{label}:</strong>
      </span>
      <Text ellipsis>{parseValue ? parseValue(value) : value}</Text>
    </div>
  );
}
