import { faGear, faUsers } from '@fortawesome/pro-regular-svg-icons';

import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

export const ADMIN_NAV_OPTIONS = {
  icon: faGear,
  name: 'Settings',
  requiresAdmin: true,
  pageHasSubMenu: true,
  link: PAGE_ROUTES.USER_SETTINGS,
  items: [
    {
      link: PAGE_ROUTES.USER_SETTINGS,
      icon: faUsers,
      name: 'Manage Users',
      activeClassName: 'nav-active'
    }
  ]
};
