import { useEffect } from 'react';

import getUserName from 'lib/common/utils/getUserName';
import { useScrollManagedContainer } from 'lib/common/hooks/useScrollManagedContainer';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import ChatMessages from 'lib/common/components/molecules/ChatMessages';
import TChatTask from 'lib/common/types/ChatTask';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import ChatSendFooter from 'lib/common/components/atoms/ChatSendFooter';
import { useSoftphoneLayout } from 'lib/common/contexts/layout/SoftphoneLayoutContext';
import ChatPlatformBadge from 'lib/common/components/ChatPlatformBadge';

import InfoPanel from '../InfoPanel';
import AttachedData from '../AttachedData';

import styles from './chat-task.module.scss';

type TChat = TChatTask & {
  minimised?: boolean;
};

const ChatTask = (task: TChat) => {
  const { profile, queueName, time, connectionValue, taskId, messages, customerTyping, minimised, nextToken, status } =
    task;
  const customerName = profile && profile.firstName ? getUserName(profile) : connectionValue;

  const {
    actions: { sendMessage, sendTypingEvent, sendAttachment }
  } = useContactContext();
  const { softphoneLayoutRef } = useSoftphoneLayout();

  const { scrollToBottom, saveScrollPosition } = useScrollManagedContainer(softphoneLayoutRef);

  useEffect(() => scrollToBottom(), [messages.length]);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={customerName} testId={customerName} suffix={<ChatPlatformBadge task={task} />} />
      </InfoPanel>
      <AttachedData task={task} />

      <div className={styles['chat-task']}>
        <ChatMessages
          messages={messages}
          customerName={customerName}
          customerTyping={customerTyping}
          saveScrollPosition={saveScrollPosition}
          nextToken={nextToken}
          status={status}
        />
      </div>
      {!minimised && (
        <ChatSendFooter
          sendMessage={(message, isMarkdown) => {
            sendMessage(message, isMarkdown);
            scrollToBottom({ force: true });
          }}
          sendTypingEvent={sendTypingEvent}
          task={task}
          sendAttachment={sendAttachment}
        />
      )}
      {/*uses a portal so trying to group it with other components is useless*/}
      <TaskFooter.Chat taskId={taskId} connectionValue={connectionValue} />
    </>
  );
};

export default ChatTask;
