import cx from 'classnames';

import Text from 'lib/common/components/Text';
import { ReactNode } from 'react';
import ConnectionTimer from './components/ConnectionTimer';

import styles from './queue-name-and-timer.module.scss';

interface IQueueNameProps {
  queueName?: string;
  time?: Date;
  className?: string;
  icon?: ReactNode;
}

const QueueNameAndTimer = ({ queueName, time, className, icon }: IQueueNameProps) => (
  <div className={cx(styles['queue-name-and-timer-container'])}>
    <Text className={cx(styles['queue-name-and-timer'], className)}>
      {queueName && (
        <Text type="inline" medium>
          {queueName}
        </Text>
      )}
      {queueName && time && ' | '}
      {time && <ConnectionTimer time={time} />}
    </Text>
    {icon}
  </div>
);

export default QueueNameAndTimer;
