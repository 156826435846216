import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';

export default async function deleteAttachment(fetch_: TFetch, config: TConfig, key: string) {
  try {
    await fetch_(`${config.EMAIL_URL}/user/attachment/${encodeURIComponent(key)}`, {
      method: 'DELETE'
    });
  } catch {
    // Silently fail as we clear locally anyway
  }
}
