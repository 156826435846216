export default {
  INCOMING_TASK: 'INCOMING_TASK',
  AFTER_CONTACT_WORK: 'AFTER_CONTACT_WORK',
  AGENT_TASK: 'AGENT_TASK',
  CHAT_TASK: 'CHAT_TASK',
  NEGLECTED_TASK: 'NEGLECTED_TASK',
  TIMED_TASK: 'TIMED_TASK',
  EMAIL_TASK: 'EMAIL_TASK',
  OUTBOUND_PREVIEW_TASK: 'OUTBOUND_PREVIEW_TASK',
  CONNECTING_TASK: 'CONNECTING_TASK'
} as const;
