import { useAuthContext } from 'lib/core/context/AuthProvider';

export default function useIsolatedNavigate() {
  const { connectUserId, user } = useAuthContext();

  return (link: string = window.location.pathname) => {
    if (!user) {
      return () => {};
    }

    const isolatedUrlParams = `?standalone=true&user=${user.username}&token=${connectUserId}`;

    window.open(`${window.location.origin}${link}${isolatedUrlParams}`, '_blank');
  };
}
