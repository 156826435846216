import { formatDistanceStrict } from 'date-fns';

import connectGetter from '../connectGetter';

type TContact = connect.Contact;

export const getTaskTimeInfo = ({ contact, connectedAtTime }: { contact?: TContact; connectedAtTime?: Date }) => {
  const now = new Date();

  // means we have either refreshed or had a complete state refresh and we will not display the information as we cant get it
  // but we still need to set started at in case of callback
  if (!contact || !connectedAtTime) {
    return { endedAt: now, startedAt: now, activeDuration: undefined };
  }

  // we assume the call ended when the state changed so the start time of acw is the end time of the active task
  const endedAt = connectGetter(contact, 'getState')?.timestamp;

  if (!endedAt) {
    return { endedAt: now, startedAt: now, activeDuration: undefined };
  }

  const activeDuration = formatDistanceStrict(connectedAtTime, endedAt);

  return {
    endedAt,
    startedAt: connectedAtTime,
    activeDuration
  };
};
