export const ConnectFileTypes = [
  '.csv',
  '.doc',
  '.docx',
  '.heic',
  '.jfif',
  '.jpeg',
  '.jpg',
  '.mov',
  '.mp4',
  '.pdf',
  '.png',
  '.ppt',
  '.pptx',
  '.rtf',
  '.txt',
  '.wav',
  '.xls',
  '.xlsx'
];

export const MAX_MESSAGE_LENGTH = 1024;

export const MaxConnectAttachmentFileSize = 20 * 1024 * 1024; // 20MB
