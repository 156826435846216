import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';

export default async function getSendableEmailAddresses(fetch_: TFetch, config: TConfig) {
  try {
    const res = await fetch_(`${config.EMAIL_URL}/user/sendable-email-addresses`);

    return (await res.json()).data;
  } catch (e) {
    return [];
  }
}
