import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { IModule } from '@cloud-wave/neon-common-lib';

import Error from 'lib/common/components/Error';
import Button from 'lib/common/components/Button';
import Text from 'lib/common/components/Text';
import Icon from 'lib/common/components/Icon';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import '../styles/landing.scss';

const BULLETS_SEPARATOR = ';';

function getSourceContent(source: string | object) {
  try {
    // @ts-expect-error expect this to fail if object is passed
    return JSON.parse(source);
  } catch {
    return typeof source === 'object' ? source : null;
  }
}

export default function Landing(props: IModule) {
  const { isSoftphone } = useLayout();

  const sourceContent = getSourceContent(props.source);

  if (!sourceContent) {
    return <Error />;
  }

  const { vectorUrl, bulletPoints, learnMoreLink, subtitle, launchLink, logoUrl } = sourceContent;

  return (
    <div className={cx('panel', 'landing', { 'landing--softphone': isSoftphone })}>
      <div className={cx('landing__left-column', { 'landing__left-column--softphone': isSoftphone })}>
        {logoUrl && (
          <div className={cx('landing__left-column__logos', { 'landing__left-column__logos--softphone': isSoftphone })}>
            <img alt="product-logo" src={logoUrl} />
          </div>
        )}
        <Text type="hero">{props.name}</Text>
        {subtitle ? (
          <Text type="heading2" htmlElement="h3">
            {subtitle}
          </Text>
        ) : null}
        <div
          className={cx('landing__left-column__checklist', {
            'landing__left-column__checklist--softphone': isSoftphone
          })}
        >
          {bulletPoints && bulletPoints.length
            ? bulletPoints.split(BULLETS_SEPARATOR).map((point) => (
                <div key={point} className="landing__left-column__checklist__item">
                  <Icon icon={faCheckCircle} className="mr-20" />
                  <span>{point}</span>
                </div>
              ))
            : null}
        </div>
        <div className={cx('landing__left-column__footer', { 'landing__left-column__footer--softphone': isSoftphone })}>
          {learnMoreLink ? (
            <Text type="link" href={learnMoreLink} rel="noreferrer">
              Learn More
            </Text>
          ) : null}
          <Button
            icon="faExternalLinkAlt"
            onClick={() => {
              window.open(launchLink, '_blank');
            }}
          >
            Launch
          </Button>
        </div>
      </div>
      {vectorUrl ? <img alt="product-promo-image" src={vectorUrl} /> : null}
    </div>
  );
}
