import Icon from 'lib/common/components/Icon';
import { faHeadSideHeadphones } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import styles from './agent-icon.module.scss';

export default function AgentIcon({ small }: { small?: boolean }) {
  const { isSoftphone } = useLayout();

  return (
    <div className={cx(styles['agent-icon'], { [styles['agent-icon--softphone']]: isSoftphone })}>
      <Icon
        className={styles['agent-icon__icon']}
        icon={faHeadSideHeadphones}
        size={small ? 15 : 20}
        tooltip="Agents available / agents online"
      />
      <span
        className={cx(styles['agent-icon__dot'], {
          [styles['agent-icon__dot--small']]: small
        })}
      ></span>
    </div>
  );
}
