import { RefObject, useEffect, useState } from 'react';

// Timeout needed to prevent cancelled or incomplete scrolling on effect triggers
const DEFAULT_SCROLL_TIMEOUT = 300;

// Pixel threshold from bottom of container to trigger scroll (approximately a 10 line message)
const DEFAULT_SCROLL_THRESHOLD = 200;

// adding a buffer as sometimes there is a delay before accordions auto open
const SCROLL_BUFFER_PIXELS = 10000;

export const useScrollManagedContainer = (
  containerRef: RefObject<HTMLDivElement> | undefined,
  { scrollTimeout = DEFAULT_SCROLL_TIMEOUT, scrollThreshold = DEFAULT_SCROLL_THRESHOLD } = {}
) => {
  const [lastScrollHeight, setLastScrollHeight] = useState<number | null>(null);

  useEffect(() => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    const observer = new MutationObserver((entries) => {
      const entry = entries.pop()!;
      if (lastScrollHeight) {
        const newHeight = (entry.target as HTMLDivElement).scrollHeight;
        (entry.target as HTMLDivElement).scrollTo({ top: newHeight - lastScrollHeight });

        setTimeout(() => setLastScrollHeight(null), scrollTimeout);
      }
    });

    observer.observe(containerRef.current, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, [containerRef?.current, lastScrollHeight]);

  const saveScrollPosition = () => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    setLastScrollHeight(containerRef.current.scrollHeight);
  };

  const scrollToBottom = ({ force = false } = {}) => {
    if (!containerRef || !containerRef.current) {
      return;
    }

    const {
      current: { scrollHeight, clientHeight, scrollTop }
    } = containerRef;

    const shouldScroll = force || Math.abs(scrollHeight - clientHeight - scrollTop) <= scrollThreshold;

    if (!shouldScroll) {
      return;
    }

    setTimeout(
      () =>
        containerRef.current?.scrollTo({
          top: scrollHeight + SCROLL_BUFFER_PIXELS,
          behavior: 'smooth'
        }),
      scrollTimeout
    );
  };

  return { scrollToBottom, saveScrollPosition };
};
