import { Backdrop, Fade, Modal as MUIModal } from '@material-ui/core';

import Loader from 'lib/common/components/Loader';
import Text from 'lib/common/components/Text';
import { useConfigContext } from 'lib/core/config';

import SIGN_OUT_TYPES from '../../constants/signOutTypes';

import './signing-out-overlay.scss';

interface ISigningOutOverlay {
  type: ValueOf<typeof SIGN_OUT_TYPES>;
}

function Subtitle({ subtitle, showHelp }) {
  if (subtitle && !showHelp) {
    return <Text className="mt-10">{subtitle}</Text>;
  }

  return (
    <Text className="mt-10">
      {subtitle || ''} Keep seeing this? Try manually{' '}
      <strong>
        <Text type="link" href={window.location.origin}>
          reloading the page
        </Text>
      </strong>
      .
    </Text>
  );
}

export default function SigningOutOverlay({ type }: ISigningOutOverlay) {
  const { config } = useConfigContext();

  const SIGN_OUT_PROPS = {
    [SIGN_OUT_TYPES.MANUAL_SIGN_OUT]: {
      showHelp: false,
      title: "We're Signing You Out",
      subtitle: "It'll only take a few seconds."
    },
    [SIGN_OUT_TYPES.AUTH_FAIL]: {
      showHelp: true,
      title: "Your session has expired. We'll get you to sign in again.",
      subtitle: `We recommend logging out or closing ${config.BRAND.productName} whenever you're finished for the day.`
    }
  };

  const { title, subtitle, showHelp } = SIGN_OUT_PROPS[type];

  return (
    <MUIModal open className="cw-modal" BackdropProps={{ timeout: 500 }} BackdropComponent={Backdrop}>
      <Fade in={Boolean(open)}>
        <div className="signing-out">
          <Text type="heading1">{title}</Text>
          {subtitle && <Subtitle showHelp={showHelp} subtitle={subtitle} />}
          <Loader relative />
        </div>
      </Fade>
    </MUIModal>
  );
}
