import { TAgentContact, TContact } from '@cloud-wave/neon-common-lib';

function getNameWithoutSpaces(name?: string) {
  if (!name) {
    return '';
  }

  return name.replaceAll(' ', '');
}

export default function getAvatarNameFromContact(contact?: TContact | TAgentContact) {
  return `${getNameWithoutSpaces(contact?.firstName)} ${getNameWithoutSpaces(contact?.lastName)}`.trim();
}
