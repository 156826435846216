import { useState } from 'react';
import Context, { TOverlayState, TOverlayType } from './Context';

export const OverlayProvider = ({ children }) => {
  const [overlayOpen, setOverlayOpen] = useState(false);
  const [overlayState, setOverlayState] = useState<TOverlayState>({ currentOverlay: null, overlayProps: null });

  const openOverlay = <T extends TOverlayType>(
    type: T,
    // this is somewhat arcane, but this is constraining the context type to whatever is on
    // overlayOpen: T, so that only the expected props can be passed in. this means at the
    // overlay end, once we check that state.type === T, ts gives us the correct props on the
    // context object.
    props?: Extract<TOverlayState, { currentOverlay: T }>['overlayProps']
  ) => {
    //@ts-expect-error this typing works, but has a tricky-to-untangle knot
    setOverlayState({ currentOverlay: type, overlayProps: props ?? null });
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
  };

  return (
    <Context.Provider
      value={{
        //@ts-expect-error this typing works, but has a tricky-to-untangle knot
        openOverlay,
        closeOverlay,
        // for the type narrowing to work correctly, this object should not be destructured
        // in the same statement that calls useOverlayProvider
        overlayOpen,
        ...overlayState
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default OverlayProvider;
