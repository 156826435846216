const BASE_EVENT_STRING = 'application/vnd.amazonaws.connect.event';

export default {
  TYPING: `${BASE_EVENT_STRING}.typing` as connect.ChatEventContentType,
  ENDED: `${BASE_EVENT_STRING}.chat.ended` as connect.ChatEventContentType,
  TRANSFER_SUCCEEDED: `${BASE_EVENT_STRING}.transfer.succeeded` as connect.ChatEventContentType,
  TRANSFER_FAILED: `${BASE_EVENT_STRING}.transfer.failed` as connect.ChatEventContentType,
  MESSAGE_METADATA: `${BASE_EVENT_STRING}.message.metadata` as connect.ChatEventContentType,
  PARTICIPANT_JOINED: `${BASE_EVENT_STRING}.participant.joined` as connect.ChatEventContentType
};
