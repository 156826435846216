const isTextMessage = ({ Content, ContentType }: connect.ChatTranscriptItem) =>
  Boolean(Content) && ContentType !== 'application/json';

// Filter out events and JSON messages from transcript
export default function filterChatTranscript(transcript?: connect.ChatTranscriptItem[]): connect.ChatTranscriptItem[] {
  if (!transcript) {
    return [];
  }

  return transcript.filter((transcript) => isTextMessage(transcript) || transcript.Type === 'ATTACHMENT');
}
