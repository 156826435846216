import { CSSProperties } from 'react';
import cx from 'classnames';
import VARS from 'css/export-vars.module.scss';

import { Tooltip } from '@material-ui/core';
import COLOUR_MAP from './constants/colourMap';
import TEXT_TYPES from './constants/textTypes';

import { IStyles, ITextProps } from './types';
import LearnMore from './components/LearnMore';

const optionalLinkProps = ({ onClick, href }: { onClick?: () => void; href?: string }) => ({
  onClick,
  href: href || (onClick ? '#' : void 0),
  target: href && !onClick ? '_blank' : void 0,
  rel: href && !onClick ? 'noreferrer' : void 0
});

const styles = ({
  color,
  ellipsis,
  bold,
  inline,
  disabled,
  noSelect,
  noWrap,
  medium,
  onClick,
  width
}: IStyles): CSSProperties => ({
  color: !onClick && color ? COLOUR_MAP[color] || color : void 0,
  textOverflow: ellipsis ? 'ellipsis' : void 0,
  overflow: ellipsis ? 'hidden' : void 0,
  whiteSpace: ellipsis || noWrap ? 'nowrap' : void 0,
  fontWeight: (bold && VARS.fontWeightBold) || (medium && VARS.fontWeightMedium) || void 0,
  display: inline ? 'inline' : void 0,
  opacity: disabled ? 0.5 : void 0,
  userSelect: noSelect ? 'none' : void 0,
  width
});

const Text = ({
  type = 'body',
  color,
  tooltip,
  className,
  ellipsis,
  bold,
  inline,
  medium,
  children,
  onClick,
  href,
  disabled,
  testId,
  noSelect,
  noWrap,
  suffix,
  ariaTitle,
  htmlElement,
  width,
  htmlFor,
  rel,
  id,
  ...props
}: ITextProps) => {
  const TextElement =
    htmlElement || (TEXT_TYPES[href || onClick ? 'link' : type].element as keyof JSX.IntrinsicElements);
  const computedClassName = cx(
    'text',
    href || onClick ? TEXT_TYPES['link'].className : '',
    TEXT_TYPES[type]?.className,
    className
  );

  const textElement = (
    <TextElement
      {...optionalLinkProps({ onClick, href })}
      htmlFor={htmlFor}
      id={id}
      rel={rel}
      title={ariaTitle}
      style={styles({ color, ellipsis, bold, inline, disabled, noSelect, noWrap, medium, onClick, width })}
      className={computedClassName}
      data-testid={props['data-testid'] || testId}
    >
      {children}
      {suffix}
    </TextElement>
  );

  return tooltip ? (
    <Tooltip enterDelay={500} title={tooltip}>
      {textElement}
    </Tooltip>
  ) : (
    textElement
  );
};

Text.LearnMore = LearnMore;

Text.Bold = ({ children, ...props }: ITextProps) => (
  <Text {...props} type="inline" bold>
    {children}
  </Text>
);

export default Text;
