import { faPlus } from '@fortawesome/pro-regular-svg-icons';

import CreateTaskPopover from 'lib/common/components/atoms/CreateTaskPopover';
import Icon from 'lib/common/components/Icon';
import useHover from 'lib/common/hooks/useHover';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import './create-task-button.scss';

type AddAgentTaskButtonProps = {
  setCreateAgentTaskOpen: (open: boolean) => void;
  setSendSmsModalOpen: (open: boolean) => void;
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

export default function CreateTaskButton({
  setCreateAgentTaskOpen,
  setSendSmsModalOpen,
  tooltipPlacement = 'left'
}: AddAgentTaskButtonProps) {
  const [hoverRef] = useHover();

  return (
    <CreateTaskPopover
      onCreateTask={() => setCreateAgentTaskOpen(true)}
      onSendSms={() => setSendSmsModalOpen(true)}
      anchor={
        <Tooltip enterDelay={1000} title="Create A New" placement={tooltipPlacement} ariaHidden>
          <button
            aria-label="Create task button"
            // @ts-ignore
            ref={hoverRef}
            className="button--primary create-task-button panel--small"
            data-testid="create-task-button"
          >
            <Icon color="white" icon={faPlus} size={25} />
          </button>
        </Tooltip>
      }
    />
  );
}
