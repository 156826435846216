import { PAGE_ROUTES, SOFTPHONE_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

export const LAYOUT_SWITCH_REDIRECTION_ROUTES = [
  { from: SOFTPHONE_PAGE_ROUTES.LANDING, to: PAGE_ROUTES.WORKSPACE },
  { from: SOFTPHONE_PAGE_ROUTES.TASK, to: PAGE_ROUTES.WORKSPACE },
  { from: SOFTPHONE_PAGE_ROUTES.AGENT_STATS, to: '/' },
  { from: SOFTPHONE_PAGE_ROUTES.DIALPAD, to: '/' },
  { from: SOFTPHONE_PAGE_ROUTES.DIRECTORY, to: '/' },
  { from: SOFTPHONE_PAGE_ROUTES.PREFERENCES, to: '/' }
];
