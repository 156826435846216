import { Component, ContextType } from 'react';
import { withScope, captureException } from '@sentry/browser';
import { Context as ContactContext } from 'lib/common/contexts/ContactContext';
import Error from './lib/common/components/Error';

type TErrorBoundaryState = {
  hasError: boolean;
};

type TErrorBoundaryProps = {
  children: JSX.Element;
};

export default class ErrorBoundary extends Component<TErrorBoundaryProps, TErrorBoundaryState> {
  static contextType = ContactContext;

  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <Error />;
    }

    return children;
  }
}
