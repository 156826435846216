import { usePreferencesContext } from '../../contexts/PreferencesContext';
import { TLogEventValue } from './LogEvents';
import usePerformanceHeartbeat from './hooks/usePerformanceHeartbeat';

const logLevels = ['debug', 'info', 'warn', 'error'] as const;

type LogObject = { error?: any; [k: string]: any };

export type TLogger = Record<typeof logLevels[number], (logMessage: TLogEventValue, logObject?: LogObject) => void>;

const dummyLogger = { ...logLevels.reduce((acc, level) => ({ ...acc, [level]: (..._) => {} }), {} as TLogger) };

const actualLogger = logLevels.reduce(
  (acc, level) => ({
    ...acc,
    [level]: (logMessage: TLogEventValue, logObject?: LogObject) => {
      if (!connect) {
        return;
      }

      const log = connect.getLog()[level](`[NEONNOW] %s`, logMessage);

      if (logObject) {
        const { error, ...rest } = logObject;

        if (error) {
          log.withException(error);
        }

        log.withObject(rest);
        console[level](logMessage, logObject);
      }
    }
  }),
  {} as TLogger
);

let logger = dummyLogger;

function LoggerController({ children }) {
  const {
    state: { enhancedLoggingEnabled }
  } = usePreferencesContext();

  usePerformanceHeartbeat({ enhancedLoggingEnabled });

  logger = enhancedLoggingEnabled ? actualLogger : dummyLogger;

  return <>{children}</>;
}

export default LoggerController;
export { logger };
