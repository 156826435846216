import Modal from 'lib/common/components/Modal';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import TEmailTask from 'lib/common/types/EmailTask';
import { TModal } from 'lib/common/components/Modal/Modal';
import { ISoftphoneModalOptionsProps } from 'lib/common/contexts/SoftphoneModalContext';
import EmailModalChildren from './EmailModalChildren';
import { getModalProps } from './utils';

export enum EmailModalTypes {
  WARNING,
  CUSTOMER_PROFILE,
  ORIGINAL_EMAIL,
  WRAP_UP_CODES_AND_NOTES,
  ATTACHED_DATA
}

interface IEmailModal {
  selectedTask: TEmailTask;
  warningProps: Partial<TModal>;
  type: EmailModalTypes | null;
  onClose: () => void;
  warningMessage: string | null;
}

export default function EmailModal({ selectedTask, warningProps, type, onClose, warningMessage }: IEmailModal) {
  const { isSoftphone } = useLayout();

  const {
    state: { isModalOpen },
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const hasType = type !== null;

  if (isSoftphone && (!hasType || isModalOpen)) {
    return null;
  }

  const modalProps: Partial<ISoftphoneModalOptionsProps> = hasType
    ? getModalProps(warningProps, selectedTask)[type]
    : {};

  const modalChildren = hasType
    ? warningMessage || <EmailModalChildren type={type} task={selectedTask} isSoftphone={isSoftphone} />
    : null;

  if (isSoftphone) {
    openModal({
      onClose,
      ...modalProps,
      onSuccess: async () => {
        await modalProps.onSuccess?.();

        closeModal();
      },
      content: modalChildren
    });

    return null;
  }

  return (
    <Modal
      primaryButtonText="Close"
      open={hasType}
      onClose={onClose}
      onSave={onClose}
      hideSecondaryButton
      {...modalProps}
    >
      {modalChildren}
    </Modal>
  );
}
