import connectAction from 'lib/common/utils/connectAction';

const sendDigitsOverConnection = async (connection: connect.VoiceConnection | undefined, digit: string) => {
  try {
    await connectAction('sendDigits', connection, digit);
  } catch {
    throw new Error('Failed to send digits to connection');
  }
};

export default sendDigitsOverConnection;
