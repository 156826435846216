import WIDGETS_OVERLAY_MIN_CONSTRAINTS from '../constants/WidgetsOverlayMinConstrains';
import TWidgetsOverlayDimensions from '../types/WidgetsOverlayDimensions';

const getWidgetsOverlayDefaultDimensions = (widgetsOverlaySavedData?: TWidgetsOverlayDimensions) => {
  const SCREEN_SIZE = { width: window.innerWidth, height: window.innerHeight };

  const widgetsOverlayData: TWidgetsOverlayDimensions = {
    position: {
      x: (SCREEN_SIZE.width - WIDGETS_OVERLAY_MIN_CONSTRAINTS.width) / 2,
      y: 75
    },
    size: WIDGETS_OVERLAY_MIN_CONSTRAINTS,
    maxConstraints: [window.innerWidth * 0.8, window.innerHeight * 0.8],
    ...widgetsOverlaySavedData
  };

  const overlayHorizontalPosition = widgetsOverlayData.position.x;
  const overlayVerticalPosition = widgetsOverlayData.position.y;
  const overlayWidth = widgetsOverlayData.size.width > SCREEN_SIZE.width ? 350 : widgetsOverlayData.size.width;
  const overlayHeight = widgetsOverlayData.size.height > SCREEN_SIZE.height ? 350 : widgetsOverlayData.size.height;
  const isOverlayWidthOutsideScreen = Boolean(overlayHorizontalPosition + overlayWidth > SCREEN_SIZE.width);
  const isOverlayHeightOutsideScreen = Boolean(overlayVerticalPosition + overlayHeight > SCREEN_SIZE.height);

  return {
    ...widgetsOverlayData,
    position: {
      x: isOverlayWidthOutsideScreen ? SCREEN_SIZE.width - overlayWidth : overlayHorizontalPosition,
      y: isOverlayHeightOutsideScreen ? SCREEN_SIZE.height - overlayHeight : overlayVerticalPosition
    },
    size: {
      width: overlayWidth,
      height: overlayHeight
    }
  };
};

export default getWidgetsOverlayDefaultDimensions;
