const DEFAULT_BROWSER_LANGUAGE = 'default';

const DATE_FORMAT_LITERAL = 'literal';

const DATE_FORMAT_MAPPED_BLOCKS = {
  day: 'dd',
  month: 'MM',
  year: 'yyyy'
};

export default function getDateFormat() {
  const dateFormat = new Intl.DateTimeFormat(window.navigator?.language || DEFAULT_BROWSER_LANGUAGE);
  const dateFormatParts = dateFormat.formatToParts(new Date());

  return dateFormatParts
    .map((formatPart) =>
      formatPart.type === DATE_FORMAT_LITERAL ? formatPart.value : DATE_FORMAT_MAPPED_BLOCKS[formatPart.type]
    )
    .filter(Boolean)
    .join('');
}
