import { isLocalhost } from './isLocalHost';

export const getTenantId = () => {
  const hostname = window.location.hostname;

  const DEPLOYMENT_ENVIRONMENT = process.env.VITE_DEPLOYMENT_ENVIRONMENT!;

  //Seems safer to check with a specific if statement
  if (isLocalhost()) {
    return DEPLOYMENT_ENVIRONMENT === 'dev' ? 'forge' : 'stagingrem';
  }

  return hostname.split('.')?.[0];
};
