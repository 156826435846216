import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_ORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useState } from 'react';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import cx from 'classnames';
import { faListOl } from '@fortawesome/pro-regular-svg-icons';
import styles from './/text-formatter.module.scss';

export function OListButton({ size, className }: { size?: number; className?: string }) {
  const [editor] = useLexicalComposerContext();
  const [ol, setOl] = useState(false);

  const toggleUl = () => {
    if (!ol) {
      editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, void 0);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
    }
    setOl(!ol);
  };

  return (
    <ClickableIcon
      className={cx(styles['toolbar__action'], className, { [styles['toolbar__action--active']]: ol })}
      onClick={toggleUl}
      aria-label="Format Bold"
      icon={faListOl}
      color="grey"
      size={size || 17}
    />
  );
}
