const ENTER_KEY = 'Enter';

export default function onEnterKeyPressed(
  onPress,
  { requireMeta, ignoreNewLines }: { requireMeta?: boolean; ignoreNewLines?: boolean } = {}
) {
  return (event) => {
    const { key, metaKey, shiftKey, ctrlKey, altKey } = event;
    const enterPressed = key === ENTER_KEY;

    if (enterPressed && ignoreNewLines && (ctrlKey || altKey)) {
      return;
    }

    if (!enterPressed) {
      return;
    }

    if (!shiftKey && (metaKey || !requireMeta)) {
      event.preventDefault();
      onPress();
    }
  };
}
