export const GLOBAL_HOTKEYS = {
  DIAL_PAD: 'c',
  DIRECTORY_AGENTS: 'a',
  DIRECTORY_ORGANISATION: 'x',
  DIRECTORY_PERSONAL: 'z'
};

export const AGENT_WORKSPACE_HOTKEYS = {
  IN_CALL_TRANSFER_CONTACT: 'e',
  IN_CALL_TRANSFER_DIAL_PAD: 'w',
  IN_CALL_TRANSFER_QUEUE: 'q',
  IN_CALL_HOLD: 'h',
  IN_CALL_MUTE: 'm',
  AFTER_CALL_SCHEDULE_CALLBACK: 'k',
  ADD_TASK: 't',
  SEND_MESSAGE: 'm'
};
