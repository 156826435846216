export default async function getNotes({ fetch_, config, profileId }) {
  try {
    const notesRes = await fetch_(
      `${config.AGENT_SERVICE_URL}/connect/${config.TENANT_ID}/contacts/${profileId}/notes`,
      {
        method: 'GET'
      }
    );

    return notesRes.json();
  } catch {
    return '';
  }
}
