import { faCheckCircle, faCircleMinus, faCircleXmark, faLoader } from '@fortawesome/pro-regular-svg-icons';

export enum PRESENCE_STATES {
  AVAILABLE = 'Available',
  BUSY = 'Busy',
  OFFLINE = 'Offline'
}

const presenceStates: {
  [key: string]: string;
} = Object.keys(PRESENCE_STATES).reduce((states, key) => ({ ...states, [key]: PRESENCE_STATES[key] }), {});

export const STATUS_TO_COLOUR_MAP = {
  [PRESENCE_STATES.OFFLINE]: 'midGrey',
  [PRESENCE_STATES.AVAILABLE]: 'pastelGreen',
  [PRESENCE_STATES.BUSY]: 'pastelRed',
  'Status Unknown': 'white'
};
export const STATUS_TO_ICON_COLOUR_MAP = {
  [PRESENCE_STATES.OFFLINE]: 'darkGrey',
  [PRESENCE_STATES.AVAILABLE]: 'success',
  [PRESENCE_STATES.BUSY]: 'danger',
  'Status Unknown': 'white'
};

export const STATUS_TO_ICON_MAP = {
  [PRESENCE_STATES.OFFLINE]: faCircleXmark,
  [PRESENCE_STATES.AVAILABLE]: faCheckCircle,
  [PRESENCE_STATES.BUSY]: faCircleMinus,
  'Status Unknown': faLoader
};

export default presenceStates;
