import CONTACT_TYPES from 'lib/common/constants/contactTypes';

import TTask from '../types/Task';

export default function isVoice(task: Pick<TTask, 'type'> | undefined) {
  return task
    ? task.type === CONTACT_TYPES.CALL ||
        task.type === CONTACT_TYPES.QUEUE_CALLBACK ||
        task.type === CONTACT_TYPES.CONFERENCE_CALL
    : false;
}
