import _isEqual from 'lodash.isequal';
import _omit from 'lodash.omit';

import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import connectGetter from 'lib/common/utils/connectGetter';
import TTask from 'lib/common/types/Task';

import THandleContactChange from '../types/HandleContactChange';

export default function handleCallRefresh({
  handleContactChange,
  contact,
  connectionTimestamps,
  existingTask
}: {
  handleContactChange: THandleContactChange;
  contact: connect.Contact;
  connectionTimestamps: Record<string, Date>;
  existingTask: TTask;
}) {
  const initialConnection = connectGetter(contact, 'getInitialConnection');
  const thirdPartyConnection = connectGetter(contact, 'getSingleActiveThirdPartyConnection');
  const relevantConnection = connectGetter(initialConnection, 'isActive') ? initialConnection : thirdPartyConnection;
  const relevantConnectionState = connectGetter(relevantConnection, 'isOnHold')
    ? CONNECTION_STATES.HOLD
    : CONNECTION_STATES.LIVE;

  // This logic is to check if contact connections have changed rather than just connection state timestamp changes to reduce unnecessary state update.
  const latestContactConnections = contact
    .toSnapshot()
    // @ts-expect-error - contact snapshot is not typed correctly by connect
    .contactData?.connections.map((connection) => ({
      ...connection,
      state: _omit(connection.state, 'timestamp')
    }));
  // @ts-expect-error - contact snapshot is not typed correctly by connect
  const voiceTaskConnections = existingTask?.contactSnapshot?.contactData?.connections.map((connection) => ({
    ...connection,
    state: _omit(connection.state, 'timestamp')
  }));

  // If no change in connections, do not update tasks state
  if (_isEqual(voiceTaskConnections, latestContactConnections)) {
    return;
  }

  handleContactChange({
    contact,
    status: CONTACT_STATES.CONNECTED,
    connectionState: relevantConnectionState,
    connectionTimestamps
  });
}
