import localforage from 'localforage';
import _omitBy from 'lodash.omitby';
import hasDraftContact from 'lib/common/utils/tasks/hasDraftContact';
import DRAFT_TASKS_STORAGE_KEY from '../constants/draftTaskStorageKey';
import CONTACT_STATES from '../../../constants/contactStates';
import { TAnyTask } from '../types/ContactContext';

export type LocalStorageTask = TAnyTask & {
  hasContact: boolean;
  contact: never;
};

export function setDraftTasks(tasks: TAnyTask[]) {
  localforage.setItem(
    DRAFT_TASKS_STORAGE_KEY,
    tasks
      .filter((task) => task.status === CONTACT_STATES.DRAFT)

      // todo come up with a better way of differentiating draft/normal tasks with contacts
      .map((task) => ({ ..._omitBy(task, 'contact'), hasContact: !hasDraftContact(task) }))
  );
}

export async function getDraftTasks() {
  try {
    const tasks = ((await localforage.getItem(DRAFT_TASKS_STORAGE_KEY)) || []) as LocalStorageTask[];

    return {
      withContact: tasks.filter((tasks) => tasks.hasContact),
      withoutContact: tasks.filter((tasks) => !tasks.hasContact)
    };
  } catch {
    return {
      withContact: [],
      withoutContact: []
    };
  }
}
