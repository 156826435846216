import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import getQueryParam from 'lib/common/utils/getQueryParam';
import Button from 'lib/common/components/Button';
import CustomerProfileForm, { ProfileActions } from 'lib/common/components/CustomerProfileForm';
import TProfile from 'lib/common/types/CustomerProfile';
import PROFILE_TYPES from 'lib/common/constants/customerProfileTypes';
import useCustomerProfileActions from 'lib/common/hooks/useCustomerProfileActions';

import './create-customer-profile.scss';

type TProfileState = {
  profile: TProfile;
  profileValid: boolean;
};

const INITIAL_PROFILE = (phoneNumber) => ({
  phoneNumber: phoneNumber || void 0,
  partyType: PROFILE_TYPES.INDIVIDUAL
});

export default function CreateCustomerProfile() {
  const history = useHistory();
  const {
    actions: { onCreate }
  } = useCustomerProfileActions({});

  const initialNumber = getQueryParam({ param: 'number' });

  const [{ profile, profileValid }, setProfileValues] = useState<TProfileState>({
    profile: INITIAL_PROFILE(initialNumber),
    profileValid: Boolean(initialNumber)
  });

  const onSave = () => {
    return onCreate(profile);
  };

  const onSaveComplete = ({ profileId }: { profileId: string }) => {
    history.push(`/profiles/${profileId}`);
  };

  return (
    <div className="panel">
      <CustomerProfileForm onChangeProfile={setProfileValues} profile={profile} action={ProfileActions.CREATE} />
      <div className="create-customer-profile__footer">
        <Button
          onClick={() => {
            history.push('/profiles');
          }}
          styleType="SECONDARY"
        >
          Cancel
        </Button>
        <Button
          onClick={profileValid ? onSave : void 0}
          onSuccess={onSaveComplete}
          disabled={!profileValid}
          data-testid="save-profile-btn"
          asyncAction
        >
          Save
        </Button>
      </div>
    </div>
  );
}
