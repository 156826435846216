import { createContext, useContext } from 'react';

import TWrapUpCodeContext from './types/WrapUpCodeContext';
import defaultContextValues from './constants/defaultContextValues';

export const Context = createContext<TWrapUpCodeContext>(defaultContextValues);

export default Context;

export const useWrapUpCodeContext = () => useContext(Context);
