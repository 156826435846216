import { faClock } from '@fortawesome/pro-regular-svg-icons';

import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import Icon from 'lib/common/components/Icon';
import cx from 'classnames';
import Text from 'lib/common/components/Text';
import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';

import Popover from '../../../Popover';
import styles from './pause-timer.module.scss';

export default function PauseTimer({ time, isSoftphone }): JSX.Element {
  const taskTime = useTaskTimer(time);
  const isSmallSoftphone = useIsSmallSoftphone();

  return !isSoftphone || isSmallSoftphone ? (
    <Text type="extraSmall"> - {taskTime}</Text>
  ) : (
    <Popover
      anchor={
        <button
          aria-label="Pause timer"
          className={cx('no-styles-button', styles['pause-timer'])}
          data-testid="paused-call-timer"
        >
          <Icon icon={faClock} size={15} />
        </button>
      }
    >
      <Text medium type="extraSmall" className={styles['pause-timer__text']}>
        {taskTime}
      </Text>
    </Popover>
  );
}
