import { faBuilding, faHeadset, faMobileButton } from '@fortawesome/pro-regular-svg-icons';
import { DIRECTORY_TAB } from './directoryTabs';

const TabsPlaceholderDetails = {
  [DIRECTORY_TAB.AGENTS]: { text: "There aren't any agents", icon: faHeadset },
  [DIRECTORY_TAB.PERSONAL]: { text: "You don't have any personal contacts", icon: faMobileButton },
  [DIRECTORY_TAB.ORGANISATION]: { text: "There aren't any organisation contacts", icon: faBuilding }
};

export default TabsPlaceholderDetails;
