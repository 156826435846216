import { LinkNode } from '@lexical/link';
import utils from '@lexical/utils';
import { DOMConversionMap, LexicalNode, $applyNodeReplacement } from 'lexical';

export default class ExtendedLinkNode extends LinkNode {
  static importDOM(): DOMConversionMap | null {
    return {
      a: (_) => ({
        conversion: convertAnchorElement,
        priority: 1
      })
    };
  }

  static getType(): string {
    return 'extended-link';
  }
}

function convertAnchorElement(domNode) {
  let node: LexicalNode | null = null;

  if (utils.isHTMLAnchorElement(domNode)) {
    const content = domNode.textContent;

    if (content !== null) {
      node = $createLinkNode(domNode.getAttribute('href') || '', {
        rel: domNode.getAttribute('rel'),
        target: domNode.getAttribute('target')
      });
    }
  }

  return {
    node
  };
}

function $createLinkNode(url, attributes) {
  return $applyNodeReplacement(new LinkNode(url, attributes));
}
