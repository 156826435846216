import getTransferEndpoint from 'lib/common/api/getTransferEndpoint';
import quickConnectTypes from 'lib/common/constants/quickConnectTypes';
import connectAction from 'lib/common/utils/connectAction';
import prependHttp from 'prepend-http';
import { Tab } from '../useCreateAgentTask';

export default async function createTask({
  fetch_,
  config,
  selectedTab,
  selectedEntity,
  references,
  agent,
  name,
  description,
  scheduledDateTime
}) {
  const endpoint = await getTransferEndpoint({
    fetch_,
    config,
    type: selectedTab === Tab.QUEUE ? quickConnectTypes.QUEUE : quickConnectTypes.AGENT,
    identifier: selectedEntity.value,
    asEndpointObject: true
  });

  if (!endpoint) {
    return Promise.reject();
  }

  const optionalURLPayload = references.length
    ? {
        references: references.reduce(
          (allReferences, reference) => ({
            ...allReferences,
            [reference.name]: { type: 'URL', value: prependHttp(reference.url) }
          }),
          {}
        )
      }
    : {};

  return connectAction('createTask', agent, {
    name,
    description,
    endpoint,
    scheduledTime: scheduledDateTime ? scheduledDateTime?.getTime() / 1000 : undefined,
    ...optionalURLPayload
  });
}
