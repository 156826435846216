import { ContactAttributesValue } from 'lib/common/utils/getMappedContactAttributes';

const hasIncomingContactAttributes = (attributes: Record<string, ContactAttributesValue> | undefined) => {
  if (!attributes) {
    return false;
  }

  return Object.values(attributes).some((attr) =>
    // Transcripts and Intents are arrays but all other dynamic attributes are strings
    Array.isArray(attr.value) ? Boolean(attr?.value?.length) : Boolean(attr?.value)
  );
};

export default hasIncomingContactAttributes;
