enum PanelContent {
  MICROPHONE_ACCESS_ERROR = 'MICROPHONE_ACCESS_ERROR',
  INCOMING_VOICE_TASK_ERROR = 'INCOMING_VOICE_TASK_ERROR',
  CONFERENCE_TRANSFER_CONNECTION_ERROR = 'CONFERENCE_TRANSFER_CONNECTION_ERROR',
  CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR = 'CONFERENCE_TRANSFER_MAX_ATTENDEES_ERROR',
  CALL_HANGUP_INFO = 'CALL_HANGUP_INFO',
  CONFERENCE_HANGUP_INFO = 'CONFERENCE_HANGUP_INFO',
  CURRENT_TASK_ENDED = 'CURRENT_TASK_ENDED',
  CALL_IN_ACW = 'CALL_IN_ACW'
}

export default PanelContent;
