import validator from 'validator';

import TagInput from './TagInput';
import { TagInputProps } from './useTagInputReducer';

const validateTag = (text: string) => validator.isEmail(text);

const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>, addTag: (tag: string) => Promise<void>) => {
  const pastedText = event.clipboardData.getData('Text');

  if (validateTag(pastedText)) {
    event.preventDefault();
    addTag(pastedText);
  }

  const tagRegex = /<(.*?)>/g;
  if (!tagRegex.test(pastedText)) {
    return;
  }

  const matchedEmails = pastedText.match(tagRegex);
  if (!matchedEmails) {
    return;
  }

  const emails = matchedEmails.map((text) => text.substring(1, text.length - 1)).filter((email) => validateTag(email));

  if (emails.length) {
    event.preventDefault();
    emails.forEach((email) => addTag(email));
  }
  return;
};

const EmailTagInput = (props: TagInputProps) => {
  return (
    <TagInput
      {...props}
      errorHidden
      errorMessage="Invalid email"
      label="Email addresses"
      handlePaste={handlePaste}
      validateTag={validateTag}
    />
  );
};

export default EmailTagInput;
