import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND } from '@lexical/list';
import { useState } from 'react';
import ClickableIcon from 'lib/common/components/ClickableIcon';
import cx from 'classnames';
import { faListUl } from '@fortawesome/pro-regular-svg-icons';
import styles from './/text-formatter.module.scss';

export function UListButton({ size, className }: { size?: number; className?: string }) {
  const [editor] = useLexicalComposerContext();
  const [ul, setUl] = useState(false);

  const toggleUl = () => {
    if (!ul) {
      editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, void 0);
    } else {
      editor.dispatchCommand(REMOVE_LIST_COMMAND, void 0);
    }
    setUl(!ul);
  };

  return (
    <ClickableIcon
      className={cx(styles['toolbar__action'], className, { [styles['toolbar__action--active']]: ul })}
      onClick={toggleUl}
      aria-label="Format Bold"
      icon={faListUl}
      color="grey"
      size={size || 17}
    />
  );
}
