import { createElement, useEffect, useRef, useState } from 'react';

type TInfiniteScrollReturn<TRootElement> = {
  rootRef: React.RefObject<TRootElement>;
  sentryElement: React.ReactHTMLElement<HTMLDivElement>;
  updateTrigger: unknown;
};

const useInfiniteScroll = <TRootElement extends HTMLElement = HTMLDivElement>({
  rootMargin = 0
}: {
  rootMargin?: number;
}): TInfiniteScrollReturn<TRootElement> => {
  const [updateTrigger, setUpdateTrigger] = useState<number>(0);

  const sentryRef = useRef<HTMLDivElement>(null);
  const rootRef = useRef<TRootElement>(null);

  useEffect(() => {
    if (!sentryRef.current) {
      return;
    }

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setUpdateTrigger(Math.random());
        }
      },
      { root: rootRef.current, threshold: 0, rootMargin: `0px 0px ${rootMargin}px 0px` }
    );

    observer.observe(sentryRef.current);

    return () => {
      if (sentryRef.current) {
        observer.unobserve(sentryRef.current);
      }
    };
  }, [sentryRef.current, rootRef.current]);

  const sentryElement = createElement('div', { ref: sentryRef });

  return { rootRef, sentryElement, updateTrigger };
};

export default useInfiniteScroll;
