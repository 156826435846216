import cx from 'classnames';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import Text from 'lib/common/components/Text';
import TaskStatusBadge from 'lib/common/components/atoms/TaskStatusBadge';
import ConferenceActions from 'lib/common/utils/ConferenceActions';
import Avatar from 'lib/common/components/Avatar';
import ConferenceAgentName from 'lib/common/components/atoms/ConferenceAgentName';

import '../styles/user-information.scss';

interface IUserInformation {
  isRow: boolean;
  icon: IconDefinition;
  name: string;
  connection: connect.BaseConnection | undefined;
  isNeglected?: boolean;
  isConnecting?: boolean;
  isConnectionOnHold: boolean;
  onDisconnect?: () => void;
  holdConnection: () => void;
  resumeConnection: () => void;
  isAgentConnection?: boolean;
  isMonitoring?: boolean;
  isAnyConnectionConnecting: boolean;
  testId: string;
}

export default function UserInformation({
  isRow,
  icon,
  name,
  connection,
  isNeglected,
  isConnecting,
  isConnectionOnHold,
  onDisconnect,
  holdConnection,
  resumeConnection,
  isAgentConnection,
  isMonitoring,
  isAnyConnectionConnecting,
  testId
}: IUserInformation) {
  const getName = () => {
    if (isAgentConnection) {
      return <ConferenceAgentName agentName={name} />;
    }

    return (
      <Text ellipsis color="darkBlue" medium>
        {name}
      </Text>
    );
  };

  return (
    <>
      {!isRow && <Text type="heading2">{name}</Text>}
      <div
        className={cx('user-information', {
          'user-information--on-hold': isConnectionOnHold,
          'user-information--neglected': isNeglected,
          'user-information--row': isRow
        })}
        data-testid={testId}
      >
        {isRow && <Avatar fallbackIcon={icon} size={30} className="user-information__avatar" iconHashString={name} />}
        {isRow && getName()}
        <TaskStatusBadge
          connection={connection}
          isNeglected={isNeglected}
          callIsOnHold={isConnectionOnHold}
          isConnecting={isConnecting}
          isRow={isRow}
          className="user-information__status-badge"
          showBadgeText={!isRow}
        />
        {isRow && !isMonitoring && (
          <ConferenceActions
            participantName={name}
            onDisconnect={onDisconnect}
            isConnectionOnHold={isConnectionOnHold}
            holdConnection={holdConnection}
            resumeConnection={resumeConnection}
            isAnyConnectionConnecting={isAnyConnectionConnecting}
          />
        )}
      </div>
    </>
  );
}
