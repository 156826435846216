import ClickableIcon from 'lib/common/components/ClickableIcon';
import { faFont } from '@fortawesome/pro-regular-svg-icons';
import TextToolbarPlugin, { ToolbarPluginProps } from './TextToolbarPlugin';

export const TextFormatter = ({
  toolbarVisible,
  setToolbarVisible,
  ...props
}: ToolbarPluginProps & { small?: boolean; toolbarVisible: boolean; setToolbarVisible: (arg0: boolean) => void }) => {
  const closeToolbar = () => setToolbarVisible(false);
  return (
    <>
      {!toolbarVisible && (
        <ClickableIcon
          size={17}
          icon={faFont}
          onClick={() => setToolbarVisible(!toolbarVisible)}
          tooltip="Text Formatting"
        />
      )}
      {toolbarVisible && <TextToolbarPlugin {...props} close={closeToolbar} />}
    </>
  );
};
