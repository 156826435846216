import WIDGETS_OVERLAY_MIN_CONSTRAINTS from '../constants/WidgetsOverlayMinConstrains';
import TWidgetsOverlayDimensions from '../types/WidgetsOverlayDimensions';

export default function getWidgetsOverlayDimensions(
  prevDimensions: TWidgetsOverlayDimensions
): TWidgetsOverlayDimensions {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const cursorHorizontalPosition = prevDimensions.position.x;
  const cursorVerticalPosition = prevDimensions.position.y;
  const overlayWidth = prevDimensions.size.width;
  const overlayHeight = prevDimensions.size.height;

  const isOverlayWidthOutsideScreen = cursorHorizontalPosition + overlayWidth > screenWidth;
  const isOverlayHeightOutsideScreen = cursorVerticalPosition + overlayHeight > screenHeight;

  const overlayVerticalPosition = isOverlayHeightOutsideScreen ? screenHeight - overlayHeight : cursorVerticalPosition;
  const overlayHorizontalPosition = isOverlayWidthOutsideScreen ? screenWidth - overlayWidth : cursorHorizontalPosition;

  const overlayReducedHeight =
    screenHeight / 1.3 < WIDGETS_OVERLAY_MIN_CONSTRAINTS.height
      ? WIDGETS_OVERLAY_MIN_CONSTRAINTS.height
      : screenHeight / 1.3;

  const newDimensions: TWidgetsOverlayDimensions = {
    ...prevDimensions,
    position: {
      x: overlayHorizontalPosition,
      y: overlayVerticalPosition > 0 ? overlayVerticalPosition : 0
    },
    size: {
      width: overlayWidth > screenWidth ? screenWidth / 1.3 : overlayWidth,
      height: overlayHeight > screenHeight ? overlayReducedHeight : overlayHeight
    },
    maxConstraints: [screenWidth * 0.8, screenHeight * 0.8]
  };

  return newDimensions;
}
