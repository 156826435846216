import { TypeOptions } from 'react-toastify/dist/types';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle
} from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import { ReactNode } from 'react';
import styles from './toast-alert.module.scss';

export interface IToastAlertProps {
  title?: string;
  message?: string;
  type?: TypeOptions;
  children?: ReactNode;
}

const TOAST_ICONS = {
  info: faInfoCircle,
  success: faCheckCircle,
  error: faExclamationCircle,
  warning: faExclamationTriangle
};

const ToastAlert = ({ title, message, type = 'info', children }: IToastAlertProps) => {
  const { isSoftphone } = useLayout();

  return (
    <div className={cx(styles['toast-alert'], { [styles['toast-alert--softphone']]: isSoftphone })}>
      <Icon size={17} icon={TOAST_ICONS[type]} />
      <div className={styles['toast-alert__text']}>
        {title && (
          <Text
            type="heading3"
            className={cx(styles['toast-alert__text__title'], {
              [styles['toast-alert__text__title--softphone']]: isSoftphone
            })}
          >
            {title}
          </Text>
        )}
        <Text type="body" className={styles['toast-alert__text__message']}>
          {children || message}
        </Text>
      </div>
    </div>
  );
};

export default ToastAlert;
