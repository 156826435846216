import { ConstValues } from '@cloud-wave/neon-common-lib';

const ContactTypes = {
  CALL: 'CALL',
  CHAT: 'CHAT',
  TASK: 'TASK',
  EMAIL: 'EMAIL',
  OUTBOUND_PREVIEW: 'OUTBOUND_PREVIEW',
  CONFERENCE_CALL: 'CONFERENCE_CALL',
  QUEUE_CALLBACK: 'QUEUE_CALLBACK'
} as const;

export type TContactTypes = ConstValues<typeof ContactTypes>;
export default ContactTypes;
