import { Tooltip as MUITooltip, TooltipProps } from '@material-ui/core';

export default function Tooltip({
  children,
  ariaHidden,
  ...props
}: { children: React.ReactNode; ariaHidden: boolean } & TooltipProps) {
  return (
    <MUITooltip
      {...props}
      {...(ariaHidden
        ? {
            'aria-describedby': void 0,
            PopperProps: {
              'aria-hidden': true
            }
          }
        : {})}
    >
      {children}
    </MUITooltip>
  );
}
