import { ReactNode, RefObject, useCallback, useState } from 'react';
import sizeMe from 'react-sizeme';

import SoftphoneLayoutContext from './Context';

const sizeMeHOC = sizeMe({ monitorWidth: true, monitorHeight: true, refreshRate: 16 });

const CONTENT_PADDING = 15;

const SoftphoneLayoutProvider = sizeMeHOC(
  ({
    children,
    size,
    className,
    footerClassName,
    contentRef
  }: {
    children: ReactNode;
    size: { height: number; width: number };
    className?: string;
    footerClassName?: string;
    contentRef: RefObject<HTMLDivElement>;
  }) => {
    const [footerContainer, setFooterContainer] = useState<HTMLDivElement | null>(null);

    // useRef doesn't trigger a re-render, and we need container element changes to trigger children to update
    const onFooterContainerRef = useCallback((node: HTMLDivElement) => {
      if (node === null) {
        return;
      }

      setFooterContainer(node);
    }, []);

    return (
      <SoftphoneLayoutContext.Provider
        value={{
          contentHeight: size.height - CONTENT_PADDING * 2,
          contentWidth: size.width - CONTENT_PADDING * 2,
          softphoneLayoutRef: contentRef,
          taskFooterContainer: footerContainer
        }}
      >
        <div className={className}>
          {children}
          <section aria-label="footer" ref={onFooterContainerRef} className={footerClassName} />
        </div>
      </SoftphoneLayoutContext.Provider>
    );
  }
);

export default SoftphoneLayoutProvider;
