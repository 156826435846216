import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import Directory from 'lib/common/components/molecules/Directory';
import Dialpad from 'lib/common/components/molecules/Dialpad';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';
import { useOverlayContext } from 'lib/common/contexts/OverlayContext';
import { OverlayType, TransferDestination } from 'lib/common/contexts/OverlayContext/Context';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';

export default function useTransferModal() {
  const { isSoftphone } = useLayout();
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();
  const { openOverlay } = useOverlayContext();

  const {
    actions: { getSelectedTask }
  } = useContactContext();

  const taskType = getSelectedTask()?.type;

  const transferTo =
    taskType === CONTACT_TYPES.CALL ||
    taskType === CONTACT_TYPES.QUEUE_CALLBACK ||
    taskType === CONTACT_TYPES.CONFERENCE_CALL
      ? TransferDestination.ALL
      : TransferDestination.AGENTS;

  const onTransferToAgent = () => {
    if (isSoftphone) {
      return openModal({
        title: 'Transfer',
        content: <Directory onClose={closeModal} transferTo={transferTo} />
      });
    }

    openOverlay(OverlayType.DIRECTORY, {
      transferTo
    });
  };

  const onTransferToDialpad = () => {
    if (isSoftphone) {
      return openModal({
        title: 'Dialpad',
        content: <Dialpad closeModal={closeModal} isDialpadTransfer />
      });
    }

    openOverlay(OverlayType.DIALPAD, { isDialpadTransfer: true });
  };

  return { onTransferToDialpad, onTransferToAgent };
}
