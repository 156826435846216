import { LogEvents, logger } from '../components/LoggerController';

export default async function getInstanceQueues({ fetch_, config }) {
  const apiEndpoint = `${config.QUEUES_SERVICE_URL}/user/queues?tenantId=${config.TENANT_ID}`;

  try {
    const allQueueResponse = await fetch_(apiEndpoint, {
      method: 'GET'
    });

    return (await allQueueResponse.json()).data;
  } catch (error) {
    logger.error(LogEvents.QUEUES_LOAD_FAILED, { apiEndpoint, error });
    return [];
  }
}
