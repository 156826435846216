import { LogEvents, TLogEventValue } from 'lib/common/components/LoggerController';

const types = [connect.Contact, connect.BaseConnection, connect.Agent];

export function getLogEvent(
  entity: connect.Contact | connect.BaseConnection | connect.Agent | null | undefined,
  action: string,
  success = true
): TLogEventValue | null {
  const entityType = types.find((type: typeof types[number]) => entity instanceof type);

  if (entity === null || entity == undefined || entityType == undefined) {
    return null;
  }

  const logEventMap = {
    [typeof connect.Agent]: {
      createTask: {
        success: LogEvents.AGENT_TASK.CREATE.SUCCESS,
        fail: LogEvents.AGENT_TASK.CREATE.FAIL
      }
    },
    [typeof connect.BaseConnection]: {
      connect: {
        success: LogEvents.TASK.CONNECT.SUCCESS,
        fail: LogEvents.TASK.CONNECT.FAIL
      },
      destroy: {
        success: LogEvents.TASK.DESTROY.SUCCESS,
        fail: LogEvents.TASK.DESTROY.FAIL
      },
      clear: {
        success: LogEvents.TASK.CLEAR.SUCCESS,
        fail: LogEvents.TASK.CLEAR.FAIL
      },
      addConnection: {
        success: LogEvents.TASK.TRANSFER_TO_QUEUE.SUCCESS,
        fail: LogEvents.TASK.TRANSFER_TO_QUEUE.FAIL
      }
    }
  };

  return logEventMap[typeof entityType]?.[action]?.[success ? 'success' : 'fail'] || null;
}
