import { useEffect } from 'react';

import connectGetter from 'lib/common/utils/connectGetter';
import TTask from 'lib/common/types/Task';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { useAgentContext } from 'lib/common/contexts/AgentContext';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';

import handleConnectedContact from '../utils/handleConnectedContact';
import handleMissedContact from '../utils/handleMissedContact';
import THandleContactChange from '../types/HandleContactChange';
import destroyTask from '../utils/destroyTask';
import useMoveTaskToACW from './useMoveTaskToACW';

type TArgs = {
  getTasks: () => TTask[];
  handleContactChange: THandleContactChange;
  fetch_: TFetch;
  config: TConfig;
  tokens?: any;
  hasMissedCallPermission: boolean;
};

export default function useTaskRefresh({
  getTasks,
  handleContactChange,
  fetch_,
  config,
  tokens,
  hasMissedCallPermission
}: TArgs) {
  const { getUser } = useAuthContext();
  const { agent } = useAgentContext();
  const moveTaskToACW = useMoveTaskToACW({ handleContactChange });

  useEffect(() => {
    if (!agent) {
      return;
    }

    agent.onRefresh(() => {
      // Task refresh only needs to handle chats + agent tasks
      const tasks = getTasks().filter((task) => task.type === CONTACT_TYPES.TASK || task.type === CONTACT_TYPES.CHAT);
      const existingContacts = connectGetter(agent, 'getContacts') || [];

      tasks.forEach(({ contact: taskContact, taskId, status }) => {
        const existingContact = existingContacts.find((contact) => contact.contactId === taskId);

        if (!existingContact) {
          return destroyTask({ contact: taskContact, handleContactChange });
        }

        const existingContactState = connectGetter(existingContact, 'getState')?.type;
        const taskIsConnecting = status === CONTACT_STATES.CONNECTING;

        if (taskIsConnecting && existingContactState === connect.ContactStateType.CONNECTED) {
          return handleConnectedContact({ contact: taskContact, handleContactChange, fetch_, config, tokens, getUser });
        }

        if (taskIsConnecting && existingContactState === connect.ContactStateType.REJECTED) {
          return handleContactChange({ contact: taskContact, status: CONTACT_STATES.REJECTED });
        }

        if (taskIsConnecting && existingContactState === connect.ContactStateType.MISSED) {
          return handleMissedContact({ contact: taskContact, handleContactChange, hasMissedCallPermission });
        }

        if (!(status === CONTACT_STATES.CONNECTED && existingContactState === connect.ContactStateType.ENDED)) {
          return;
        }

        moveTaskToACW(taskContact);
      });
    });
  }, [agent]);
}
