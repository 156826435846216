import { EditorState, convertFromRaw } from 'draft-js';

export default {
  address: (address) => address?.address1 || address || '',
  notes: (notes: string) => {
    if (!notes) {
      return '';
    }

    try {
      /**
       * Parsed as while saving notes convertToRaw saves stringified value
       */
      return EditorState.createWithContent(convertFromRaw(JSON.parse(notes)));
    } catch (e) {
      return '';
    }
  }
};
