import { faChevronDown, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';

import Icon from 'lib/common/components/Icon';

import styles from './tree-dropdown-indicator.module.scss';

export default function TreeDropdownIndicator({ loading }: { loading?: boolean }) {
  if (loading) {
    return (
      <div className={styles['tree-dropdown-indicator']}>
        <Icon
          icon={faCircleNotch}
          spin
          size={13}
          color="darkGrey"
          className={styles['tree-dropdown-indicator__loading-icon']}
        />
        <Icon icon={faChevronDown} size={13} color="darkGrey" />
      </div>
    );
  }

  return <Icon icon={faChevronDown} size={13} color="darkGrey" />;
}
