import CONTACT_TYPES from 'lib/common/constants/contactTypes';

export default {
  [connect.ContactType.VOICE]: CONTACT_TYPES.CALL,
  [connect.ContactType.QUEUE_CALLBACK]: CONTACT_TYPES.QUEUE_CALLBACK,
  [connect.ContactType.CHAT]: CONTACT_TYPES.CHAT,
  [connect.ContactType.TASK]: CONTACT_TYPES.TASK,
  [CONTACT_TYPES.CONFERENCE_CALL]: CONTACT_TYPES.CONFERENCE_CALL,
  [CONTACT_TYPES.EMAIL]: CONTACT_TYPES.EMAIL,
  [CONTACT_TYPES.OUTBOUND_PREVIEW]: CONTACT_TYPES.OUTBOUND_PREVIEW
} as const;
