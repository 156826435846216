import cloneDeep from 'lodash.clonedeep';
import _omit from 'lodash.omit';

import TTask from 'lib/common/types/Task';

const redactTask = (task?: TTask) => {
  return cloneDeep(_omit(task, ['chatSession', 'messages', 'ACW.notes', 'email', 'contact']));
};

export default redactTask;
