import { ReactElement } from 'react';
import { Letter } from 'react-letter';
import cx from 'classnames';
import { faDownload, faPaperclip } from '@fortawesome/pro-regular-svg-icons';
import { saveAs } from 'file-saver';
import { createPortal } from 'react-dom';
import { filesize } from 'filesize';

import { TEmail } from 'lib/common/types/email/TEmail';
import { TAttachment } from 'lib/common/types/email/TAttachment';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import Icon from 'lib/common/components/Icon';
import Loader from 'lib/common/components/Loader';
import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';
import Text from 'lib/common/components/Text';
import PORTAL_IDS from 'lib/common/constants/portalIds';
import getAttachmentIcon from 'lib/common/utils/email/getAttachmentIcon';
import IEmailMetadata from 'lib/common/types/email/EmailMetadata';
import getAttachmentsTotalSize from 'lib/common/utils/email/getAttachmentsTotalSize';
import { PopoverMenuItemType } from 'lib/common/components/PopoverMenuItem/PopoverMenuItem';

import { LetterHead } from './LetterHead';

import './email.scss';

function getContent({
  error,
  loading,
  content,
  isSoftphone
}: {
  error: boolean;
  loading: boolean;
  content?: TEmail | null;
  isSoftphone?: boolean;
}) {
  if (error) {
    return (
      <EmptyPlaceholder
        className={isSoftphone ? 'mb-10' : ''}
        isError
        text={
          !isSoftphone
            ? 'There was a problem loading the content of this email. Please try again.'
            : "We couldn't load the email content."
        }
      />
    );
  }

  if (loading) {
    return <Loader relative small={!isSoftphone} className="email__loader" size={isSoftphone ? 30 : void 0} />;
  }

  return (
    <Letter
      html={content?.html || ''}
      text={content?.text}
      className={cx('email__content', { 'email__content--softphone': isSoftphone })}
    />
  );
}

export default function Email({
  content,
  metadata,
  loading,
  error,
  attachments,
  isSoftphone,
  showAllFields,
  neonEmailCcRecipients,
  neonEmailAllRecipients,
  hideDate
}: {
  content?: TEmail | null;
  metadata?: IEmailMetadata;
  loading: boolean;
  error: boolean;
  attachments: TAttachment[];
  isSoftphone?: boolean;
  showAllFields?: boolean;
  hideDate?: boolean;
  neonEmailCcRecipients: string;
  neonEmailAllRecipients: string;
}) {
  const handleDownloadAttachment = ({ content, filename, mimeType }: TAttachment) => {
    if (!content) {
      return;
    }

    saveAs(new Blob([content], { type: mimeType }), filename);
  };

  const downloadAllAttachments = () => {
    attachments.forEach(handleDownloadAttachment);
  };

  const AttachmentsEl = () => {
    if (!attachments.length) {
      return null;
    }

    return (
      <PopoverMenu
        anchor={
          <div className="email__header__attachments" data-testid="email-attachments">
            <Text className={cx({ 'mr-5': !isSoftphone })}>{attachments.length}</Text>
            <Icon icon={faPaperclip} />
          </div>
        }
      >
        {
          attachments.map((attachment: TAttachment) => (
            <PopoverMenuItem
              key={attachment.hash}
              icon={getAttachmentIcon(attachment.mimeType)}
              text={`${attachment.filename}`}
              subtitle={`(${filesize(attachment.content.byteLength, { round: 0 })})`}
              onClick={() => handleDownloadAttachment(attachment)}
            />
          )) as unknown as ReactElement
        }
        {attachments.length > 1 ? <PopoverMenuItem type={PopoverMenuItemType.SEPARATOR} /> : void 0}
        {attachments.length > 1 ? (
          <PopoverMenuItem
            key="download-all"
            icon={faDownload}
            text="Download All"
            onClick={downloadAllAttachments}
            subtitle={`(${filesize(getAttachmentsTotalSize(attachments), { round: 0 })})`}
          />
        ) : (
          void 0
        )}
      </PopoverMenu>
    );
  };

  // Softphone attachments are rendered in a different place in the DOM tree so we need to use createPortal to render them in Info Panel
  const softphoneAttachments =
    document.getElementById(PORTAL_IDS.SOFTPHONE_ATTACHMENT_PORTAL_ID) &&
    createPortal(<AttachmentsEl />, document.getElementById(PORTAL_IDS.SOFTPHONE_ATTACHMENT_PORTAL_ID) as Element);

  const Letterhead = LetterHead({
    content,
    neonEmailAllRecipients,
    neonEmailCcRecipients,
    metadata,
    showAllFields,
    isSoftphone,
    attachments,
    AttachmentsEl,
    softphoneAttachments,
    hideDate,
    loading
  });

  return (
    <div className="email">
      {Letterhead}
      {getContent({ error, isSoftphone, content, loading })}
    </div>
  );
}
