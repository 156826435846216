import { MutableRefObject, useState } from 'react';

import RichTextEditor from 'lib/common/components/molecules/RichTextEditor';
import { EditorUseCase } from 'lib/common/components/molecules/RichTextEditor/Editor';
import { Input } from 'lib/common/components/Input';
import onEnterKeyPressed from 'lib/common/utils/onEnterKeyPressed';
import useIsSmallSoftphone from 'lib/common/hooks/useIsSmallSoftphone';
import useTextAreaNewLine, { NewLineShortCutMap } from 'lib/common/hooks/useTextAreaNewLine';

export const ChatBoxInput = ({
  message,
  onChangeInputMessage,
  messageInputRef,
  sending,
  submitButtonRef,
  canUseRichText,
  editorFocusRef
}: {
  message: string;
  onChangeInputMessage: ({ target: { value } }: { target: { value: string } }) => void;
  messageInputRef: MutableRefObject<HTMLTextAreaElement | null>;
  sending: boolean;
  submitButtonRef: MutableRefObject<HTMLButtonElement | null>;
  canUseRichText: boolean;
  editorFocusRef?: MutableRefObject<(() => void) | null>;
}) => {
  const isSmallSoftphone = useIsSmallSoftphone();

  const onKeyDown = useTextAreaNewLine({
    shortcuts: [NewLineShortCutMap.ALT, NewLineShortCutMap.CTRL],
    inputValue: message,
    inputRef: messageInputRef,
    setInputValue: (value) => onChangeInputMessage({ target: { value } })
  });

  //Used to force rerender seems dodgy but email was doing it this way too
  const [_htmlContent, setHtmlContent] = useState('');
  if (canUseRichText) {
    return (
      <RichTextEditor.Editor
        className={'chat-rich-text'}
        content={message}
        editorUseCase={EditorUseCase.DirectMessage}
        onEditorStateChange={(editorState) => {
          onChangeInputMessage({ target: { value: editorState } });
        }}
        onSubmit={() => submitButtonRef?.current?.click()}
        onHtmlChange={setHtmlContent}
        editorFocusRef={editorFocusRef}
      />
    );
  }

  return (
    <Input
      multiline
      ariaRequired
      autoFocus
      minRows={1}
      maxRows={isSmallSoftphone ? 1 : 4}
      placeholder="Say something ..."
      value={message}
      onChange={onChangeInputMessage}
      inputRef={messageInputRef}
      disabled={sending}
      // We use the ref click here to get the full button action states
      onKeyDown={onEnterKeyPressed(() => submitButtonRef?.current?.click(), {
        requireMeta: false,
        ignoreNewLines: true
      })}
      inputProps={{ 'data-testid': 'chat-message-input', onKeyDown }}
    />
  );
};
