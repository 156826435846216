export default async function getInstanceQuickConnects({ fetch_, config }) {
  try {
    const allQuickConnectsResponse = await fetch_(
      `${config.AGENT_SERVICE_URL}/connect/${config.TENANT_ID}/quick-connects`,
      { method: 'GET' }
    );

    return allQuickConnectsResponse.json();
  } catch {
    return [];
  }
}
