import CONNECT_TYPE_MAP from '../contexts/ContactContext/constants/connectTypeMap';

import getMappedContactAttributes from './getMappedContactAttributes';
import { getFusionDataFromMap } from './fusionData/getFusionDataFromMap';

const getAttachedDataContactAttributes = (contact?: connect.Contact, taskType?: ValueOf<typeof CONNECT_TYPE_MAP>) => {
  // Draft task contacts are objects only containing contactId - we don't want to try and pull out attributes from them
  if (!contact || !contact.getAttributes || !taskType) {
    return;
  }

  const attributes = getMappedContactAttributes(contact, taskType);

  if (!attributes) {
    return;
  }

  const validAttribute = ([, attribute]) => {
    if (!Boolean(attribute?.isActive)) {
      return false;
    }

    return Array.isArray(attribute?.value) ? attribute?.value.length > 0 : Boolean(attribute?.value);
  };

  return Object.entries(attributes)
    .filter(validAttribute)
    .map(([key, attribute]) => getFusionDataFromMap(key, attribute?.value));
};

export default getAttachedDataContactAttributes;
