import { TUpdateContactBody } from '@cloud-wave/neon-common-lib';
import { TConfig } from 'lib/common/types/Config';

export default function updateDirectoryContact({
  config,
  fetch_,
  contact
}: {
  config: TConfig;
  fetch_: (url, options) => Promise<any>;
  contact: TUpdateContactBody & { contactId: string };
}) {
  const { contactId, ...restContact } = contact;

  return fetch_(`${config.DIRECTORY_SERVICE_URL}/user/contact/${contactId}`, {
    method: 'PATCH',
    body: JSON.stringify(restContact)
  });
}
