import { isConference } from 'lib/common/utils/conferenceConnections';
import getCallContactType from 'lib/common/contexts/ContactContext/utils/getCallContactType';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPE from 'lib/common/constants/contactTypes';
import taskBuilder from 'lib/common/contexts/ContactContext/utils/taskBuilder/index';
import ChatTask from 'lib/common/types/ChatTask';
import TCustomerProfile from 'lib/common/contexts/ContactContext/types/CustomerProfile';
import THandleContactChange from 'lib/common/contexts/ContactContext/types/HandleContactChange';
import { TChatChanges } from 'lib/common/contexts/ContactContext/types/ChatChanges';
import { THydratedChat } from 'lib/common/types/chat/HydratedChat';
import CONNECTION_STATES from 'lib/common/constants/connectionStates';
import TTask from 'lib/common/types/Task';

export default async function updatedTask({
  taskIndex,
  tasks,
  status,
  connectionState,
  contact,
  profile,
  handleContactChange,
  icon,
  getTasks,
  getSelectedTaskId,
  newChat,
  hydratedChat,
  taskProps
}: {
  taskIndex: number;
  tasks: TTask[];
  status?: ValueOf<typeof CONTACT_STATES>;
  connectionState?: ValueOf<typeof CONNECTION_STATES>;
  contact?: connect.Contact;
  profile?: TCustomerProfile;
  handleContactChange: THandleContactChange;
  icon: string;
  getTasks: () => TTask[];
  getSelectedTaskId: () => string | undefined;
  newChat?: TChatChanges;
  hydratedChat?: THydratedChat;
  taskProps: any;
}) {
  const existingTask = tasks[taskIndex];
  const updatedContact = contact || existingTask.contact;
  const type = isConference(updatedContact) ? getCallContactType(updatedContact) : existingTask.type;

  /**
   * Scenario - 1 for queue callbacks when we get two new contacts with the same id
   * Scenario - 2 bypass conference call because the parent call is connected but status of the call has changed to connecting
   * The above scenario shouldn't be considered as a new task.
   */
  if (status === CONTACT_STATES.CONNECTING && type !== CONTACT_TYPE.CONFERENCE_CALL) {
    const newTasks = [...getTasks()];

    newTasks.splice(taskIndex, 1, { ...existingTask, contact: updatedContact });

    return newTasks;
  }

  // We set a task to rejected state manually, but in just the call scenario, connect calls onMissed
  if (status && status === CONTACT_STATES.MISSED && existingTask.status === CONTACT_STATES.REJECTED) {
    return null;
  }

  if (newChat || hydratedChat || existingTask.type === CONTACT_TYPE.CHAT) {
    const newTasks = [...getTasks()];

    const updatedChatTask = await taskBuilder.updatedChatTask({
      newChat,
      hydratedChat,
      existingTask: existingTask as ChatTask,
      handleContactChange,
      icon,
      getSelectedTaskId,
      updatedContact,
      taskProps,
      type,
      status,
      connectionState,
      profile
    });

    newTasks.splice(taskIndex, 1, updatedChatTask);

    return newTasks;
  }

  // Base Task
  const updatedTask = taskBuilder.updatedBaseTask({
    existingTask,
    updatedContact,
    taskProps,
    type,
    status,
    connectionState,
    profile
  });

  const newTasks = [...getTasks()];

  newTasks.splice(taskIndex, 1, updatedTask);

  return newTasks;
}
