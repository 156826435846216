import { IconDefinition, IconName, IconPrefix } from '@fortawesome/fontawesome-common-types';

const faCalendarPhone: IconDefinition = {
  prefix: 'far' as IconPrefix,
  iconName: 'calendar-phone' as IconName,
  icon: [
    18,
    16,
    [],
    '101E47',
    'M8.89746 14.0938H2.4375C2.17969 14.0938 1.96875 13.8828 1.96875 13.625V6.125H13.6875V4.25C13.6875 3.21582 12.8467 2.375 11.8125 2.375H10.6406V1.20312C10.6406 0.813477 10.3271 0.5 9.9375 0.5C9.54785 0.5 9.23437 0.813477 9.23437 1.20312V2.375H5.01562V1.20312C5.01562 0.813477 4.70215 0.5 4.3125 0.5C3.92285 0.5 3.60937 0.813477 3.60937 1.20312V2.375H2.4375C1.40332 2.375 0.5625 3.21582 0.5625 4.25V13.625C0.5625 14.6592 1.40332 15.5 2.4375 15.5H10.2539C9.71777 15.1221 9.25781 14.6445 8.89746 14.0938ZM13.2187 7.0625C10.8896 7.0625 9 8.95215 9 11.2812C9 13.6104 10.8896 15.5 13.2187 15.5C15.5478 15.5 17.4375 13.6104 17.4375 11.2812C17.4375 8.95215 15.5478 7.0625 13.2187 7.0625ZM11.6367 13.3906C11.4873 13.3906 11.3584 13.291 11.3174 13.1475L11.1182 12.4209C11.0742 12.2598 11.1562 12.0957 11.3086 12.0312L12.0996 11.7002C12.2344 11.6445 12.3896 11.6826 12.4834 11.7969L12.8174 12.2041C13.3975 11.9316 13.8662 11.46 14.1416 10.8799L13.7373 10.5488C13.626 10.4551 13.585 10.3027 13.6406 10.165L13.9717 9.37402C14.0332 9.22168 14.2031 9.13965 14.3613 9.18359L15.0879 9.38281C15.2314 9.42383 15.3311 9.55273 15.3311 9.70215C15.3311 11.7412 13.6787 13.3936 11.6396 13.3936L11.6367 13.3906Z'
  ]
};

export default faCalendarPhone;
