import { ImageType } from 'lib/common/constants/imageBlockFields';
import { useConfigContext } from 'lib/core/config';
import { getDefaultBrandImageUrl, getFileUrl } from 'lib/core/utils';

import Tooltip from 'lib/common/components/atoms/Tooltip';

const BrandImage = ({
  type,
  className,
  tooltip,
  altText
}: {
  type: ImageType;
  className?: string;
  tooltip?: string;
  altText?: string;
}) => {
  const { config } = useConfigContext();

  function getBrandImage() {
    const image = type === ImageType.LOGO ? config.BRAND?.logo : config.BRAND?.favicon;

    if (!image?.fileKey) {
      return <img className={className} src={getDefaultBrandImageUrl(type)} alt={altText} />;
    }

    const fileUrl = getFileUrl(image.fileKey, config);

    return <img className={className} src={fileUrl} alt={altText} />;
  }

  if (tooltip) {
    return (
      <Tooltip title={tooltip} enterDelay={1000} ariaHidden={false}>
        {getBrandImage()}
      </Tooltip>
    );
  }

  return getBrandImage();
};

export default BrandImage;
