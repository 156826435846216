import { useEffect, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import Icon from 'lib/common/components/Icon';
import { Input } from 'lib/common/components/Input';

export default function Searchbar({ searchTerm, onChange }) {
  const [search, setSearch] = useState(searchTerm);

  // Update the search if it changes externally. Hence (Clear search button) and onSearch will update the search internally
  useEffect(() => {
    if (searchTerm === search) {
      return;
    }

    setSearch(searchTerm);
  }, [searchTerm]);

  const onSearch = ({ target: { value: newSearchValue } }) => {
    setSearch(newSearchValue);
    onChange(newSearchValue);
  };

  return (
    <Input
      autoFocus
      data-testid="directory-search-input"
      small
      onFocus={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
      onChange={onSearch}
      placeholder="Search"
      inputProps={{ 'aria-label': 'search' }}
      value={search}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Icon icon={faSearch} color="midGrey" />
          </InputAdornment>
        )
      }}
    />
  );
}
