import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import Text from 'lib/common/components/Text';
import TOutboundPreviewTask from 'lib/common/types/OutboundPreviewTask';

import ButtonPreviewIcon from 'lib/common/components/atoms/ButtonPreviewIcon';
import OutboundPreviewClickToCall from 'lib/common/components/molecules/OutboundPreviewClickToCall';
import InfoPanel from '../InfoPanel';
import AttachedData from '../AttachedData';

import styles from './outbound-preview-task.module.scss';

const OutboundPreviewTask = (props: TOutboundPreviewTask) => {
  const { queueName, time, connectionValue, taskId, description, campaign, contact } = props;

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time} />
      <AttachedData task={props} />
      <div className={styles['outbound-preview-task__container']}>
        <div>
          <Text.LearnMore
            learnMoreText={
              <>
                Your contact centre admin has added this number to a call list for you to dial. Click the{' '}
                <ButtonPreviewIcon>Call</ButtonPreviewIcon> button below to make the outbound call.
              </>
            }
            type="heading3"
          >
            Campaign
          </Text.LearnMore>
          <Text className={styles['outbound-preview-task__container__text']}>{connectionValue}</Text>
        </div>
        <div>
          <Text type="heading3">Campaign Description</Text>
          <Text className={styles['outbound-preview-task__container__text']}>
            {description || "This outbound preview doesn't have a description."}
          </Text>
        </div>
        <OutboundPreviewClickToCall campaign={campaign} contact={contact} />
      </div>
      <TaskFooter.OutboundPreviewTask taskId={taskId} connectionValue={connectionValue} />
    </>
  );
};

export default OutboundPreviewTask;
