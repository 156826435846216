import { faPause, faPlay } from '@fortawesome/pro-regular-svg-icons';
import { RecordingState } from '@cloud-wave/neon-common-lib';

const callRecordingStates = {
  [RecordingState.RESUME]: {
    text: 'Recording',
    icon: faPause,
    iconAriaLabel: 'Pause recording',
    classNameSuffix: 'recording'
  },
  [RecordingState.SUSPEND]: {
    text: 'Paused',
    icon: faPlay,
    iconAriaLabel: 'Start recording',
    classNameSuffix: 'paused'
  },
  [RecordingState.STOP]: {
    text: 'Recording Stopped',
    icon: null,
    classNameSuffix: 'stopped'
  }
};

export default callRecordingStates;
