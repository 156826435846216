import { Redirect } from 'react-router-dom';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

export default function RedirectIfSoftphone({ to }) {
  const { isSoftphone } = useLayout();

  if (isSoftphone) {
    return <Redirect to={to} />;
  }

  return null;
}
