export default function shouldShowSkipButton({
  ACWIsSkippable,
  hasAgentOutcomesPermission,
  taskIsMonitoring
}: {
  ACWIsSkippable: boolean;
  hasAgentOutcomesPermission: boolean;
  taskIsMonitoring: boolean;
}) {
  // We cannot complete outcomes when monitoring as the contact isn't a real one we can write attributes to. Connect
  // will fail on the api side.
  if (taskIsMonitoring || !hasAgentOutcomesPermission) {
    return false;
  }

  return ACWIsSkippable;
}
