import Task from 'lib/common/types/Task';
import agentIsMonitoring from 'lib/common/utils/connect/agentIsMonitoring';
import { isAnyConnectionOnHold } from 'lib/common/utils/conferenceConnections';

import TaskFooter from '../../TaskFooter';
import ConferenceSubFooter from '../../ConferenceSubFooter';
import Call from './Call';
import Conference from './Conference';

interface IConnectedCallFooter extends Pick<Task, 'connectionState' | 'taskId' | 'connectionValue' | 'type'> {
  inCurrentConference?: boolean;
  initialConnectionDisconnected: boolean;
  isSmallWidth?: boolean;
  contact: connect.Contact;
  isSoftphone?: boolean;
}

export default function ConnectedCallFooter({
  inCurrentConference = false,
  connectionState,
  taskId,
  connectionValue,
  initialConnectionDisconnected,
  type,
  isSmallWidth = false,
  contact,
  isSoftphone = false
}: IConnectedCallFooter) {
  const showCallControls = !inCurrentConference;
  const isMonitoring = agentIsMonitoring(contact);
  const anyConnectionOnHold = isAnyConnectionOnHold(contact);
  const showConferenceSubFooter = inCurrentConference && anyConnectionOnHold && !isSoftphone && !isMonitoring;

  const footers = (
    <>
      {showConferenceSubFooter && <ConferenceSubFooter taskId={taskId} contact={contact} />}
      <TaskFooter isSmallWidth={isSmallWidth}>
        {showCallControls ? (
          <Call
            connectionState={connectionState}
            connectionValue={connectionValue}
            taskId={taskId}
            type={type}
            isSmallWidth={isSmallWidth}
          />
        ) : (
          <Conference
            taskId={taskId}
            initialConnectionDisconnected={initialConnectionDisconnected}
            isSmallWidth={isSmallWidth}
            connectionValue={connectionValue}
            contact={contact}
          />
        )}
      </TaskFooter>
    </>
  );

  return !isSoftphone ? <div className="task-footer">{footers}</div> : footers;
}
