import Button from 'lib/common/components/Button';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import { useHotkeys } from 'react-hotkeys-hook';
import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import { isConnectionMuted } from 'lib/common/utils/conferenceConnections';

export default function MuteButton({
  agentConnection,
  taskId,
  disabled = false
}: {
  taskId: string;
  agentConnection?: connect.VoiceConnection;
  disabled?: boolean;
}) {
  const {
    actions: { muteConnection, unmuteConnection }
  } = useContactContext();

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.IN_CALL_MUTE, () => {
    (async () => {
      await onButtonClick();
    })();
  });

  if (!agentConnection) {
    return null;
  }

  const isAgentMuted = isConnectionMuted(agentConnection);
  const onButtonClick = isAgentMuted
    ? () => unmuteConnection({ taskId, connectionId: agentConnection.connectionId })
    : () => muteConnection({ taskId, connectionId: agentConnection.connectionId });

  return (
    <Button
      size="small"
      icon={isAgentMuted ? 'faMicrophoneSlash' : 'faMicrophone'}
      onClick={onButtonClick}
      tooltip={
        isAgentMuted
          ? `${disabled ? 'You are unable to unmute while on hold' : 'Unmute microphone'}`
          : `${disabled ? 'You are unable to mute while on hold' : 'Mute microphone'}`
      }
      styleType={isAgentMuted ? 'WARNING' : 'NEUTRAL'}
      round
      disabled={disabled}
    />
  );
}
