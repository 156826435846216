import { format } from 'date-fns';
import { faClock, faComments, faPlug } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';

import { AGENT_WORKSPACE_HOTKEYS } from 'lib/common/utils/hotkeys/hotkeyMap';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import getUserName from 'lib/common/utils/getUserName';
import { useSoftphoneModalContext } from 'lib/common/contexts/SoftphoneModalContext';
import Text from 'lib/common/components/Text';
import Icon from 'lib/common/components/Icon';
import TaskFooter from 'lib/common/components/molecules/TaskFooter';
import ACWOutcome from 'lib/common/components/molecules/ACW/components/ACWOutcome';
import ChatMessages from 'lib/common/components/molecules/ChatMessages';
import SoftphoneCreateAgentTask from 'lib/common/components/molecules/SoftphoneCreateAgentTask';
import TTask from 'lib/common/types/Task';
import TChatTask from 'lib/common/types/ChatTask';
import getScheduleCallbackDescription from 'lib/common/utils/getScheduleCallbackDescription';

import { getTaskTimeInfo } from 'lib/common/utils/ACW/getTaskTimeInfo';
import InfoPanel from '../InfoPanel';
import AttachedData from '../AttachedData';

import styles from './after-contact-work.module.scss';

const AfterContactWork = (props: TTask & { user: any }) => {
  const {
    actions: { openModal, closeModal }
  } = useSoftphoneModalContext();

  const { profile, queueName, time, connectionValue, contact, connectedAtTime, type, user } = props;
  const name = profile && profile.firstName ? getUserName(profile) : connectionValue;
  const { startedAt, activeDuration } = getTaskTimeInfo({ contact, connectedAtTime });

  const handleOpenChatHistoryModal = () => {
    openModal({
      title: 'Chat History',
      content: (
        <ChatMessages
          showEmptyPlaceholder
          messages={(props as unknown as TChatTask).messages}
          customerName={name}
          nextToken={(props as unknown as TChatTask).nextToken}
          status={props.status}
        />
      )
    });
  };

  const scheduleCallback = () => {
    openModal({
      title: 'Add A New Agent Task',
      content: (
        <SoftphoneCreateAgentTask
          name="Call A Customer Back"
          description={
            type === CONTACT_TYPES.TASK ? '' : getScheduleCallbackDescription(connectionValue, profile, startedAt, user)
          }
          onClose={closeModal}
        />
      )
    });
  };

  useHotkeys(AGENT_WORKSPACE_HOTKEYS.AFTER_CALL_SCHEDULE_CALLBACK, scheduleCallback, []);

  return (
    <>
      <InfoPanel queue={queueName} timestamp={time}>
        <InfoPanel.Row text={name} testId={name} />
      </InfoPanel>
      <div className={styles['after-contact-work__info']}>
        <Text type="heading3">Summary:</Text>
        {activeDuration && (
          <>
            <div className={styles['after-contact-work__info__row']}>
              <Icon size={17} icon={faPlug} tooltip="Time interaction started" />
              <Text>{format(startedAt, 'h:mm aa')}</Text>
            </div>
            <div className={styles['after-contact-work__info__row']}>
              <Icon size={17} icon={faClock} tooltip="Interaction duration" />
              <Text>{activeDuration}</Text>
            </div>
          </>
        )}
        {type === CONTACT_TYPES.CHAT && (
          <div className={cx(styles['after-contact-work__info__row'], styles['after-contact-work__info__row-chat'])}>
            <Icon icon={faComments} />
            <Text onClick={handleOpenChatHistoryModal}>View Chat History</Text>
          </div>
        )}
      </div>
      <ACWOutcome accordionDefaultOpen />
      <AttachedData task={props} />
      <TaskFooter.ACW scheduleCallback={scheduleCallback} />
    </>
  );
};

export default AfterContactWork;
