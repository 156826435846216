import { ReactNode } from 'react';
import cx from 'classnames';

import useTaskTimer from 'lib/common/hooks/useTaskTimer';
import Text from 'lib/common/components/Text';
import CallRecording from 'lib/common/components/molecules/CallRecording';
import Row from './components/Row';
import Separator from './components/Separator';

import styles from './info-panel.module.scss';

interface InfoPanelProps {
  timestamp: Date;
  queue?: string;
  children?: ReactNode;
}

function InfoPanel({ timestamp, queue, children }: InfoPanelProps) {
  const time = useTaskTimer(timestamp);

  return (
    <div className={styles['softphone-task-info-panel']}>
      <div
        className={cx(styles['softphone-task-info-panel__header'], {
          [styles['softphone-task-info-panel__header--empty']]: !children
        })}
      >
        <div className={styles['softphone-task-info-panel__header__content']}>
          {queue && (
            <Text className={styles['softphone-task-info-panel__header__content__queue']} ellipsis>
              {queue}
            </Text>
          )}
          {timestamp && <Text className={styles['softphone-task-info-panel__header__content__timestamp']}>{time}</Text>}
        </div>
        <CallRecording className={styles['softphone-task-info-panel__header__call-recording']} />
      </div>
      {children && <div className={styles['softphone-task-info-panel__content']}>{children}</div>}
    </div>
  );
}

InfoPanel.Row = Row;
InfoPanel.Separator = Separator;

export default InfoPanel;
