import connectGetter from 'lib/common/utils/connectGetter';

export default function doesContactExist(agent: connect.Agent | null, contactId?: string) {
  if (!agent || !contactId) {
    return false;
  }

  const contacts = agent ? connectGetter(agent, 'getContacts') || [] : [];

  return contacts.some((activeContact: connect.Contact) => activeContact.contactId === contactId);
}
