import { Redirect, Route, Switch } from 'react-router-dom';
import { Permissions, IModule } from '@cloud-wave/neon-common-lib';
import { PAGE_ROUTES, LEGACY_PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

// pages
// TODO move these pages into /desktop
import InteractionHistoryPage from 'lib/pages/InteractionHistory';
import CustomerProfile from 'lib/pages/CustomerProfile';
import CustomerProfiles from 'lib/pages/CustomerProfiles';
import CreateCustomerProfile from 'lib/pages/CreateCustomerProfile';
import Users from 'lib/pages/Users';
import AgentWorkspace from 'lib/pages/AgentWorkspace';
import Report from 'lib/pages/desktop/Report';
import EmailView from 'lib/pages/EmailView';

// components
import AuthorisedRoute from 'lib/common/components/AuthorisedRoute';
import NotFound from 'lib/common/components/NotFound';
import PageModule from 'lib/common/components/PageModule';

import { CoreLayout } from 'lib/common/layouts';
import { useModulesContext } from 'lib/common/contexts/ModulesContext';
import { useContactContext } from 'lib/common/contexts/ContactContext';
import WithApiContexts from 'lib/common/components/atoms/WithApiContexts';
import PermissionRedirect from 'lib/common/components/PermissionRedirect';
import Playground from 'lib/pages/Playground';
import IncomingTaskOverlay from 'lib/common/contexts/ContactContext/components/IncomingTaskOverlay';

// constants
import { LAYOUT_SWITCH_REDIRECTION_ROUTES } from 'lib/routes/constants';
import REPORT_TYPES from 'lib/common/constants/reportTypes';
import isDevEnvironment from 'lib/common/constants/isDevEnvironment';

function withUser(user, Component) {
  return (props) => <Component {...props} user={user} />;
}

export default function DesktopRoutes({ user }) {
  const {
    state: { pageModules }
  } = useModulesContext();
  const {
    state: { tasks }
  } = useContactContext();

  return (
    <CoreLayout user={user}>
      <IncomingTaskOverlay tasks={tasks} />
      <Switch>
        <Redirect exact from="/home" to={PAGE_ROUTES.WORKSPACE} />
        <Redirect exact from={LEGACY_PAGE_ROUTES.DASHBOARDS} to={PAGE_ROUTES.WORKSPACE} />
        <Redirect exact from={LEGACY_PAGE_ROUTES.WALLBOARD} to={PAGE_ROUTES.WORKSPACE} />
        <Redirect exact from={PAGE_ROUTES.BASE} to={PAGE_ROUTES.WORKSPACE} />
        <Redirect exact from={PAGE_ROUTES.SETTINGS} to={PAGE_ROUTES.USER_SETTINGS} />
        <Route exact render={withUser(user, AgentWorkspace)} path={PAGE_ROUTES.WORKSPACE} />
        <Route exact path={PAGE_ROUTES.EMAIL} component={EmailView} />
        {isDevEnvironment && <Route exact path={PAGE_ROUTES.PLAYGROUND} component={Playground} />}
        <PermissionRedirect
          exact
          from={PAGE_ROUTES.REPORTS}
          requiresAdmin
          redirects={[
            {
              permission: Permissions.REPORTS_PERFORMANCE_TODAY,
              route: PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY
            },
            {
              permission: Permissions.REPORTS_ANALYTICS,
              route: PAGE_ROUTES.REPORTS_ANALYTICS
            }
          ]}
        />
        <AuthorisedRoute
          exact
          permissionType="tenant"
          permission={Permissions.REPORTS_PERFORMANCE_TODAY}
          path={PAGE_ROUTES.REPORTS_PERFORMANCE_TODAY}
          requiresAdmin
        >
          <WithApiContexts
            component={Report}
            props={{ type: REPORT_TYPES.PERFORMANCE_TODAY }}
            user={user}
            key={REPORT_TYPES.PERFORMANCE_TODAY}
          />
        </AuthorisedRoute>
        <AuthorisedRoute
          exact
          permissionType="tenant"
          permission={Permissions.REPORTS_ANALYTICS}
          path={PAGE_ROUTES.REPORTS_ANALYTICS}
          requiresAdmin
        >
          <WithApiContexts
            component={Report}
            props={{ type: REPORT_TYPES.ANALYTICS }}
            user={user}
            key={REPORT_TYPES.ANALYTICS}
          />
        </AuthorisedRoute>
        <AuthorisedRoute
          exact
          permissionType="tenant"
          permission={Permissions.REPORTS_WALLBOARD}
          path={PAGE_ROUTES.REPORTS_WALLBOARD}
        >
          <WithApiContexts
            component={Report}
            props={{ type: REPORT_TYPES.WALLBOARD }}
            user={user}
            key={REPORT_TYPES.WALLBOARD}
          />
        </AuthorisedRoute>
        <AuthorisedRoute
          exact
          permission={Permissions.CALL_HISTORY}
          permissionType="tenant"
          path={PAGE_ROUTES.INTERACTION_HISTORY}
          component={InteractionHistoryPage}
        />
        <AuthorisedRoute
          permissionType="tenant"
          permission={Permissions.CUSTOMER_PROFILES}
          exact
          path={PAGE_ROUTES.CUSTOMER_PROFILES}
          component={CustomerProfiles}
        />
        <AuthorisedRoute
          permission={Permissions.CUSTOMER_PROFILES_WRITE}
          exact
          path={PAGE_ROUTES.CREATE_CUSTOMER_PROFILE}
          component={CreateCustomerProfile}
        />
        <AuthorisedRoute
          permissionType="tenant"
          permission={Permissions.CUSTOMER_PROFILES}
          exact
          path={PAGE_ROUTES.CUSTOMER_PROFILE}
          component={CustomerProfile}
        />
        <AuthorisedRoute requiresAdmin exact path={PAGE_ROUTES.USER_SETTINGS}>
          <WithApiContexts component={Users} user={user} />
        </AuthorisedRoute>
        {pageModules.map((pageModule: IModule, i) => (
          <Route
            render={(props) => <PageModule {...props} pageModuleProps={pageModule} user={user} />}
            path={`/${pageModule.pathName}`}
            key={i}
          />
        ))}
        {LAYOUT_SWITCH_REDIRECTION_ROUTES.map((props, index) => (
          <Redirect key={index} {...props} />
        ))}
        <Route component={NotFound} />
      </Switch>
    </CoreLayout>
  );
}
