import { createContext, useContext } from 'react';

type Context = {
  isSidebarOpen: boolean;
  toggleIsSidebarOpen: () => void;
};

export const defaultLayout: Context = {
  isSidebarOpen: false,
  toggleIsSidebarOpen: () => undefined
};

const DesktopLayoutContext = createContext<Context>(defaultLayout);

export const useDesktopLayoutContext = () => useContext(DesktopLayoutContext);

export default DesktopLayoutContext;
