import cx from 'classnames';
import { useParams } from 'react-router-dom';

import Email from 'lib/common/components/molecules/Email';
import useEmailContent from 'lib/common/hooks/useEmailContent/useEmailContent';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import styles from './email-view.module.scss';

const EmailView = () => {
  const { emailId } = useParams<{ emailId: string }>();
  const { isSoftphone } = useLayout();
  const { data, metadata, loading, error, attachments } = useEmailContent(emailId);
  const Ccs = data?.cc?.map((address) => address.address);
  const Tos = data?.to?.map((address) => address.address);
  const neonEmailAllRecipients = Tos?.join(',') || '';
  const neonEmailCcRecipients = Ccs?.join(',') || '';

  return (
    <div className={cx(styles['email-view'], { [styles['email-view--softphone']]: isSoftphone })}>
      <Email
        content={data}
        metadata={metadata}
        neonEmailAllRecipients={neonEmailAllRecipients}
        neonEmailCcRecipients={neonEmailCcRecipients}
        loading={loading}
        error={error}
        attachments={attachments}
        isSoftphone={isSoftphone}
        showAllFields
      />
    </div>
  );
};

export default EmailView;
