import { Permissions } from '@cloud-wave/neon-common-lib';

import CONTACT_STATES from 'lib/common/constants/contactStates';

import { usePermissionsContext } from '../../PermissionsContext';
import THandleContactChange from '../types/HandleContactChange';
import useClearContact from './useClearContact';

export default function useMoveTaskToACW({ handleContactChange }: { handleContactChange: THandleContactChange }) {
  const clearContact = useClearContact({ handleContactChange });
  const { hasPermission } = usePermissionsContext();

  const hasACWPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.AGENT_ACW
  });

  return async (contact?: connect.Contact) => {
    if (!contact) {
      return;
    }

    if (!hasACWPermission) {
      await clearContact(contact);

      return;
    }

    handleContactChange({
      contact,
      status: CONTACT_STATES.ACW
    });
  };
}
