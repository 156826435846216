import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import { match } from 'ts-pattern';
import getContactConnection from '../getContactConnection';
import connectGetter from '../connectGetter';
import { getAgentVoiceConnection } from '../conferenceConnections';
import getTaskType from '../../contexts/ContactContext/utils/getTaskType';

export default function agentIsMonitoring(contact: connect.Contact | undefined) {
  const contactType = getTaskType(contact);
  const monitoringSupported = match(contactType)
    .with(CONTACT_TYPES.CALL, CONTACT_TYPES.QUEUE_CALLBACK, CONTACT_TYPES.CONFERENCE_CALL, () => true)
    .otherwise(() => false);

  if (!monitoringSupported) {
    return false;
  }

  const agentConnection = getAgentVoiceConnection(contact);
  return (
    // When advanced monitoring is disabled/barge disabled
    getContactConnection(contact)?.getType() === connect.ConnectionType.MONITORING ||
    // When advanced monitoring is enabled
    connectGetter(agentConnection, 'getMonitorStatus') === connect.MonitoringMode.SILENT_MONITOR
  );
}
