import 'amazon-connect-streams';
import 'amazon-connect-chatjs';
import 'amazon-connect-taskjs';
import AgentPreferencesProvider from 'lib/common/contexts/AgentPreferenceContext';

import { BrowserRouter as Router } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { PrimeReactProvider } from 'primereact/api';

import { Routes, IsolatedRoutes } from 'lib/routes';
import AuthProvider from 'lib/core/context/AuthProvider';
import ModulesProvider from 'lib/common/contexts/ModulesContext';
import ConfigProvider from 'lib/core/config';
import AgentProvider from 'lib/common/contexts/AgentContext';
import PreferencesProvider from 'lib/common/contexts/PreferencesContext';
import InstanceProvider from 'lib/common/contexts/InstanceContext';
import RequirementsProvider from 'lib/common/contexts/RequirementsContext';
import EmailUploadProvider from 'lib/common/contexts/EmailUploadContext';
import Offline from 'lib/common/components/Offline';
import DesktopLayoutProvider from 'lib/common/contexts/layout/DesktopLayoutContext';
import IsolatedAuthProvider from 'lib/core/context/IsolatedAuthProvider';
import LoggerController from 'lib/common/components/LoggerController/LoggerController';

import isIsolatedMode from 'lib/common/utils/isIsolatedMode';

import 'lib/common/customIcons';

import OverlayProvider from 'lib/common/contexts/OverlayContext';
import ErrorBoundary from './ErrorBoundary';

function IsolatedApp() {
  return (
    <IsolatedAuthProvider>
      <IsolatedRoutes />
    </IsolatedAuthProvider>
  );
}

function FullApp() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <PreferencesProvider>
        <LoggerController>
          <AgentProvider>
            <AuthProvider>
              <AgentPreferencesProvider>
                <ModulesProvider>
                  <InstanceProvider>
                    <RequirementsProvider>
                      <EmailUploadProvider>
                        <DesktopLayoutProvider>
                          <OverlayProvider>
                            <PrimeReactProvider value={{ unstyled: false }}>
                              <ErrorBoundary>
                                <Routes />
                              </ErrorBoundary>
                            </PrimeReactProvider>
                          </OverlayProvider>
                        </DesktopLayoutProvider>
                      </EmailUploadProvider>
                    </RequirementsProvider>
                  </InstanceProvider>
                </ModulesProvider>
              </AgentPreferencesProvider>
            </AuthProvider>
          </AgentProvider>
        </LoggerController>
      </PreferencesProvider>
    </QueryClientProvider>
  );
}

export default function App() {
  const isIsolatedApp = isIsolatedMode();
  const children = isIsolatedApp ? <IsolatedApp /> : <FullApp />;

  return (
    <ErrorBoundary>
      <Router>
        <Offline>
          <ConfigProvider>{children}</ConfigProvider>
        </Offline>
      </Router>
    </ErrorBoundary>
  );
}
