import TProfile from 'lib/common/types/CustomerProfile';

export enum ProfileActions {
  CREATE = 'create',
  EDIT = 'edit'
}

export type TCustomerProfileForm = {
  onChangeProfile: ({ profile, profileValid }: { profile: TProfile; profileValid: boolean }) => void;
  profile?: TProfile;
  compact?: boolean;
  action: ProfileActions;
};

export type TParams = {
  config: any;
  fetch_: (url, options) => Promise<any>;
  value: string;
  fieldName: string;
  action: ProfileActions;
};

export enum SEARCHABLE_FIELDS {
  PHONE_NUMBER = 'phoneNumber',
  EMAIL_ADDRESS = 'emailAddress',
  ACCOUNT_NUMBER = 'accountNumber'
}

export type TErrors = {
  [SEARCHABLE_FIELDS.PHONE_NUMBER]?: string;
  [SEARCHABLE_FIELDS.EMAIL_ADDRESS]?: string;
  [SEARCHABLE_FIELDS.ACCOUNT_NUMBER]?: string;
};

export type TValidating = {
  [SEARCHABLE_FIELDS.PHONE_NUMBER]?: boolean;
  [SEARCHABLE_FIELDS.EMAIL_ADDRESS]?: boolean;
  [SEARCHABLE_FIELDS.ACCOUNT_NUMBER]?: boolean;
};
