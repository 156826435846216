import { ContactAttributes } from 'lib/common/constants/contactAttributes';
import { FUSION_DATA_BLOCK_TYPES } from '../../constants/fusionDataBlockTypes';

export const fusionDataMap = {
  [ContactAttributes.INTENTS]: {
    type: FUSION_DATA_BLOCK_TYPES.BADGE_LIST,
    label: 'Intents'
  },
  [ContactAttributes.TRANSCRIPT]: {
    type: FUSION_DATA_BLOCK_TYPES.ACCORDION,
    label: 'Transcript'
  },
  [ContactAttributes.PHONE_NUMBER]: {
    type: FUSION_DATA_BLOCK_TYPES.PHONE_NUMBER,
    label: 'Phone Number'
  },
  [ContactAttributes.OUTBOUND_CAMPAIGN]: {
    type: FUSION_DATA_BLOCK_TYPES.DYNAMIC,
    label: 'Outbound Campaign'
  },
  [ContactAttributes.OUTBOUND_CAMPAIGN_DESCRIPTION]: {
    type: FUSION_DATA_BLOCK_TYPES.DYNAMIC,
    label: 'Outbound Campaign Description'
  }
};
