//could type the group type but this allows for adding any time of email grouping
import cx from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import sizeMe from 'react-sizeme';
import _throttle from 'lodash.throttle';
import Text from '../../Text';
import IEmailMetadata from '../../../types/email/EmailMetadata';
import Icon from '../../Icon';

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });
const OriginalToAddressCount = 1;
const ThrottleMethodTimeout = 300;

const AddressList = ({
  addressList,
  groupType,
  boldFirst
}: {
  addressList: string[];
  groupType: string;
  boldFirst?: boolean;
}) => {
  if (!addressList.length) {
    return null;
  }
  return (
    <>
      {addressList.map((address, index) => (
        <Text
          tooltip={address}
          inline
          key={`${groupType}${address}`}
          testId={`${groupType}${address} ${index}`}
          className={cx('email__header__recipients__item', {
            ['email__header__recipients__start']: !index,
            ['email__header__recipients__end']: index + 1 === addressList.length
          })}
          bold={!index && boldFirst}
          onClick={async () => {
            await navigator.clipboard.writeText(address);
          }}
        >
          {!index && <Text.Bold>{groupType}: </Text.Bold>}
          {address};
        </Text>
      ))}
    </>
  );
};

function EmailRecipients({
  metadata,
  extraTos,
  extraCcs,
  extraMargin = false,
  size: { width: availableWidth }
}: {
  metadata?: IEmailMetadata;
  extraTos: string[];
  extraCcs: string[];
  extraMargin?: boolean;
  size: { width: number };
}) {
  const [hideExtras, setHideExtras] = useState(true);
  const [hiddenItems, setHiddenItems] = useState(0);
  const divContainer = useRef<HTMLDivElement | null>(null);

  const totalNumberOfEmails = OriginalToAddressCount + extraCcs.length + extraTos.length;
  const ToArray = [metadata?.to || 'unknown to address', ...extraTos];
  const CcArray = extraCcs;

  const onWidthChange = _throttle(() => {
    const children = Array.from(divContainer?.current?.children || []);
    const visibleChildren = children.filter((child) => {
      const { offsetLeft, offsetWidth } = child as HTMLElement;

      return offsetLeft + offsetWidth < availableWidth;
    });
    setHiddenItems(Math.abs(totalNumberOfEmails - visibleChildren.length));
  }, ThrottleMethodTimeout);
  const ShowExpander = hideExtras && hiddenItems > 0;

  useEffect(() => {
    onWidthChange();
  }, [availableWidth, hideExtras]);

  const HiddenItemsToggle = () => {
    return (
      <Text
        className={'email__header__recipients__expander'}
        type={'link'}
        testId={'expander'}
        onClick={() => {
          setHideExtras(!hideExtras);
        }}
      >
        {hideExtras && `+${hiddenItems} more`}

        <Icon icon={hideExtras ? faChevronDown : faChevronUp} color={'primary'} size={12} className={'ml-5'} />
      </Text>
    );
  };

  return (
    <div className={cx(extraMargin && 'mb-20', 'email__header__recipients-container')}>
      <div
        ref={divContainer}
        className={cx('email__header__recipients', {
          ['email__header__recipients--open']: !hideExtras,
          ['email__header__recipients--closed']: hideExtras
        })}
      >
        <AddressList addressList={ToArray} groupType="To" boldFirst={true} />
        {!hideExtras && <span className={'email__header__recipients__break'} />}
        <AddressList addressList={CcArray} groupType="Cc" />
        {!hideExtras && <HiddenItemsToggle />}
      </div>
      {ShowExpander && <HiddenItemsToggle />}
    </div>
  );
}

export default sizeMeHOC(EmailRecipients);
