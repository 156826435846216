import { ReactComponent as NotFoundImage } from 'assets/vectors/not-found.svg';
import Error from './Error';

export default function NotFound() {
  return (
    <Error
      title="Hmm"
      Image={NotFoundImage}
      text="We can’t seem to find this page anywhere (even when we called its name several times)."
    />
  );
}
