export function getOrderedConnectionList(
  connections: connect.VoiceConnection[],
  connectionTimestamps: Record<string, Date>
): connect.VoiceConnection[] {
  return connections.sort((a, b) => {
    const aTimestamp = connectionTimestamps[a.connectionId];
    const bTimestamp = connectionTimestamps[b.connectionId];

    return aTimestamp?.getTime() - bTimestamp?.getTime();
  });
}
