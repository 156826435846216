import { P, match } from 'ts-pattern';
import isEmail from 'validator/lib/isEmail';

export default function addProtocolToUrl(link?: string | null) {
  return match(link)
    .with(null, '', undefined, () => '')
    .with(P.string.startsWith('http'), (link) => link)
    .with(P.string.startsWith('https'), (link) => link)
    .with(P.string.startsWith('mailto:'), (link) => link)
    .with(P.when(isEmail), () => `mailto:${link}`)
    .otherwise((link) => `https://${link}`);
}
