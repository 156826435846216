import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import TTask from 'lib/common/types/Task';
import isVoice from './isVoice';

export default function isConnectedVoice({ type, status }: TTask) {
  const isConferenceCall = type === CONTACT_TYPES.CONFERENCE_CALL;

  const isVoiceTask = isVoice({ type });

  return (
    (isVoiceTask && status === CONTACT_STATES.CONNECTED) || (isConferenceCall && status === CONTACT_STATES.CONNECTING)
  );
}
