import { LOCAL_STORAGE_KEYS } from '../constants/localStorageKeys';
import appInIframe from './appInIframe';

export default async function requestNotificationPermission() {
  try {
    if (typeof Notification === void 0 || !Notification) {
      return Promise.resolve();
    }

    if (Notification.permission !== 'granted') {
      await Notification.requestPermission();
    }

    if (!appInIframe()) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.NOTIFICATION_PERMISSION, Notification.permission);
    }

    return Notification.permission;
  } catch {
    return Promise.resolve();
  }
}
