import { faEllipsis } from '@fortawesome/pro-regular-svg-icons';

import PopoverMenu from 'lib/common/components/PopoverMenu';
import PopoverMenuItem from 'lib/common/components/PopoverMenuItem';

import Tab, { ITabProps } from './Tab';

const BOTTOM = 'bottom';
const TOP = 'top';

const BELOW_OFFSET = -10;
const ABOVE_OFFSET = 10;

export default function OverflowPopoverMenu({
  overflowTabs,
  tabProps
}: {
  overflowTabs: ITabProps[];
  tabProps: Pick<ITabProps, 'orientation' | 'size' | 'tooltip'>;
}) {
  return (
    <PopoverMenu
      anchorOrigin={{
        vertical: tabProps.orientation === 'above' ? BOTTOM : TOP,
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: tabProps.orientation === 'above' ? TOP : BOTTOM,
        horizontal: 'right'
      }}
      offset={tabProps.orientation === 'below' ? BELOW_OFFSET : ABOVE_OFFSET}
      anchor={
        <Tab
          {...tabProps}
          isSelected={overflowTabs.some((tab) => tab.isSelected)}
          id="overflow"
          data-testid="tab-overflow-popover-menu"
          icon={faEllipsis}
        />
      }
      closeOnClick
    >
      {overflowTabs.map((tab, index) => (
        <PopoverMenuItem
          ariaLabel={`${tab.title} tab`}
          selected={tab.isSelected}
          color="darkGrey"
          key={index}
          onClick={() => {
            tab.onClick?.();
          }}
          text={tab.title}
          icon={tab.icon}
        />
      ))}
    </PopoverMenu>
  );
}
