import { TInternalContacts } from '../../../types';

export default function getChangedContactValues(
  originalContact: TInternalContacts,
  newContact: Partial<TInternalContacts>
): Partial<TInternalContacts> {
  return Object.keys(newContact).reduce((diff, key) => {
    if (originalContact[key] === newContact[key]) {
      return diff;
    }

    return { ...diff, [key]: newContact[key] };
  }, {});
}
