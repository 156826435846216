import { useEffect, useRef } from 'react';

import useInterval from 'lib/common/hooks/useInterval';

import { LogEvents, logger } from '../';

const HEARTBEAT_INTERVAL_MS = 5000;
const ACK_TIMEOUT_TRAILING_WINDOW_S = 30;
const MEMORY_SIZE_UNITS = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
const KB_BYTES = 1024;

type MemoryLog = {
  jsHeapSizeLimit: number;
  totalJSHeapSize: number;
  usedJSHeapSize: number;
};

/**
 * Connect hasn't typed this or exported it in their types, so do something custom
 */
type ConnectEventSubSubscription = {
  unsubscribe: () => void;
};

/**
 * Connect hasn't typed this or exported it in their types, so do something custom
 */
type ConnectEventBus = {
  subscribe: (event: connect.EventType, callback: () => void) => ConnectEventSubSubscription;
};

type AckTimeoutCount = { count: number; firstEvent: number; lastEvent: number };

/**
 * Convert a byte size value to a human-readable string, e.g., 5 KB or 10 MB.
 *
 * @param {number} bytes - The byte size value to be formatted.
 * @returns {string} - The formatted byte size string.
 */
function formatBytes(bytes: number): string {
  // If bytes is falsy (0 or undefined), return '0 Bytes'
  if (!bytes) {
    return '0 Bytes';
  }

  // Determine the appropriate unit (e.g., KB, MB) for the byte size
  const unitIndex = Math.floor(Math.log(bytes) / Math.log(KB_BYTES));

  // Calculate the value in the appropriate unit and format it to 2 decimal places
  const valueInUnit = parseFloat((bytes / Math.pow(KB_BYTES, unitIndex)).toFixed(2));

  // Concatenate the value and unit to form the formatted string
  return `${valueInUnit} ${MEMORY_SIZE_UNITS[unitIndex]}`;
}

/**
 * Some window objects like navigator and memory return strange objects that can't be parsed to string for logging
 * Do some funky monkey workaround to make them readable
 *
 * Explicitly type this as any because the global types don't include "memory" on performance and __proto__ isn't
 * typed properly
 *
 * @param windowObject
 */
function getReadableWindowObject(windowObject: any) {
  try {
    return JSON.parse(
      JSON.stringify(Object.defineProperties(windowObject, Object.getOwnPropertyDescriptors(windowObject?.__proto__)))
    );
  } catch {
    return {};
  }
}

/**
 * Makes the window.performance.memory object readable in logs by making the byte values readable
 */
function getMemoryObject() {
  try {
    // Better to use https://developer.mozilla.org/en-US/docs/Web/API/Performance/measureUserAgentSpecificMemory moving forward,
    // but requires cross-origin isolation to be set up (https://developer.mozilla.org/en-US/docs/Web/API/Performance/measureUserAgentSpecificMemory#security_requirements)

    // @ts-ignore chrome/edge specific object https://developer.mozilla.org/en-US/docs/Web/API/Performance/memory
    const { memory } = window.performance;

    if (!memory) {
      return null;
    }

    const readableData: MemoryLog = getReadableWindowObject(memory);

    return Object.entries(readableData).reduce(
      (formatted, [key, value]: [key: string, value: number]) => ({
        ...formatted,
        [`${key}Formatted`]: formatBytes(value),
        [key]: value
      }),
      {}
    );
  } catch {
    return null;
  }
}

function wasLastEventInTrailingWindow(lastEvent = 0) {
  return lastEvent && lastEvent >= Date.now() - ACK_TIMEOUT_TRAILING_WINDOW_S * 1000;
}

function getAckTimeoutRate({ count, firstEvent, lastEvent }: AckTimeoutCount) {
  // If we've had no events in the trailing window, return a rate of 0
  if (!wasLastEventInTrailingWindow(lastEvent)) {
    return 0;
  }

  // Rate is based on number of events since the first event we capture in the trailing window
  const rate = count / ((Date.now() - firstEvent) / 1000);

  // Round to 2 decimal places. Can't be higher than a rate of 1 (1 event per second)
  return parseFloat(Math.min(rate, 1).toFixed(2));
}

export default function usePerformanceHeartbeat({ enhancedLoggingEnabled }: { enhancedLoggingEnabled: boolean }) {
  const ackTimeoutCount = useRef<AckTimeoutCount>({
    count: 0,
    firstEvent: 0,
    lastEvent: 0
  });
  const ackTimeoutSubscription = useRef<null | ConnectEventSubSubscription>(null);

  useEffect(() => {
    // @ts-ignore Connect hasn't typed this or exported it in their types, so ignore the type error
    const eventBus = connect.core.getEventBus() as ConnectEventBus;

    if (!enhancedLoggingEnabled) {
      ackTimeoutSubscription?.current?.unsubscribe();

      return;
    }

    // Reset counts when enhanced logging is enabled or on first mount
    ackTimeoutCount.current = { count: 0, firstEvent: 0, lastEvent: 0 };

    ackTimeoutSubscription.current = eventBus.subscribe(connect.EventType.ACK_TIMEOUT, () => {
      logger.info(LogEvents.PERFORMANCE.ACK_TIMEOUT);

      const { lastEvent, firstEvent, count } = ackTimeoutCount.current;
      const lastEventWasInTrailingWindow = wasLastEventInTrailingWindow(lastEvent);

      // If we've had events in the trailing window, just append to the count, otherwise reset
      ackTimeoutCount.current = {
        count: lastEventWasInTrailingWindow ? count + 1 : 1,
        firstEvent: lastEventWasInTrailingWindow ? firstEvent : Date.now(),
        lastEvent: Date.now()
      };
    });

    return () => {
      ackTimeoutSubscription?.current?.unsubscribe();
    };
  }, [enhancedLoggingEnabled]);

  useInterval(async () => {
    if (!enhancedLoggingEnabled) {
      return;
    }

    try {
      logger.info(LogEvents.PERFORMANCE.HEARTBEAT.SUCCESS, {
        memoryUsage: getMemoryObject() || 'Unsupported',
        ackTimeoutRate30s: getAckTimeoutRate(ackTimeoutCount.current),
        cpuCores: navigator.hardwareConcurrency || 'Unknown',
        network: getReadableWindowObject(navigator['connection']),

        // @ts-ignore is supported on our accepted browsers but not all https://developer.mozilla.org/en-US/docs/Web/API/Navigator/userAgentData
        userAgent: window.navigator.userAgentData || window.navigator.userAgent
      });

      // Who knows what weird and wonderful errors the above could cause, so let's handle them just in case
    } catch (error) {
      logger.error(LogEvents.PERFORMANCE.HEARTBEAT.FAIL, { error });
    }
  }, HEARTBEAT_INTERVAL_MS);
}
