import { createContext, useContext } from 'react';

import IQuickConnect from 'lib/common/types/InstanceQuickConnect';
import IQueue from 'lib/common/types/InstanceQueue';
import TRedactedDirectory from 'lib/common/types/RedactedDirectory';

type InstanceContextType = {
  instanceQueues: IQueue[];
  instanceQuickConnects: IQuickConnect[];
  instanceMaxContacts: Record<string, number>;
  instanceDirectories: Record<string, TRedactedDirectory>;
};

const Context = createContext<InstanceContextType>({
  instanceQueues: [],
  instanceQuickConnects: [],
  instanceMaxContacts: {},
  instanceDirectories: {}
});

// export the context hook
export const useInstanceContext = () => useContext(Context);

export default Context;
