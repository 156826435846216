import { PRESENCE_STATES } from 'lib/common/constants/presenceStates';

const PENDING_QUEUE_CALLBACK_STATE = 'Pending';

export default function getMappedConnectState(state: PRESENCE_STATES) {
  const connect = (window as any)?.getConnect();

  if (!connect) {
    return state;
  }

  const availableStates = Object.values(connect.AgentAvailStates);
  const errorStates = Object.values(connect.AgentErrorStates);

  // For queue callbacks, connect sets the agent to Pending which isn't in any of the enums, so add it manually
  const hasBusyState = [...availableStates, ...errorStates, PENDING_QUEUE_CALLBACK_STATE].includes(state);

  if (hasBusyState) {
    return PRESENCE_STATES.BUSY;
  }

  return state;
}
