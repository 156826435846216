import cx from 'classnames';

import styles from './tree.module.scss';

export default ({ width, loading, isSoftphone }: { width?: number; loading?: boolean; isSoftphone?: boolean }) => ({
  root: { className: styles['tree'] },
  labelContainer: { className: styles['tree__label-container'] },
  label: {
    className: cx(styles['tree__label'], {
      [styles['tree__label--softphone']]: isSoftphone
    })
  },
  trigger: {
    className: cx(styles['tree__trigger'], {
      [styles['tree__trigger--loading']]: loading
    })
  },
  triggerIcon: { className: styles['tree__trigger-icon'] },

  panel: { className: styles['tree__panel'], style: { width } },
  wrapper: { className: styles['tree__wrapper'] },
  header: { className: styles['tree__header'] },
  filterContainer: { className: styles['tree__filter-container'] },
  filter: {
    className: cx(styles['tree__filter'], {
      [styles['tree__filter--softphone']]: isSoftphone
    })
  },
  filterIcon: { className: styles['tree__filter-icon'] },
  closeButton: { style: { display: 'none' } },

  tree: {
    node: { className: styles['tree__node'] },
    content: { className: styles['tree__content'] },
    label: {
      className: cx(styles['tree__content__label'], {
        [styles['tree__content__label--softphone']]: isSoftphone
      })
    },
    togglerIcon: { className: styles['tree__toggler-icon'] },
    container: {
      className: cx(styles['tree__container'], {
        [styles['tree__container--softphone']]: isSoftphone
      })
    }
  }
});
