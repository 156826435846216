import { ReactNode, useState } from 'react';

import SoftphoneModal from './components/SoftphoneModal';
import Context, { ISoftphoneModalOptionsProps } from './Context';

interface ISoftphoneModalContextProps {
  children: ReactNode;
  isReversedLayout: boolean;
}

function SoftphoneModalProvider({ children, isReversedLayout }: ISoftphoneModalContextProps) {
  const [modalOptions, setModalOptions] = useState<ISoftphoneModalOptionsProps | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (options) => {
    setIsModalOpen(true);
    setModalOptions(options);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    modalOptions?.onClose?.();
  };

  const handleClearContent = () => {
    setModalOptions(undefined);
  };

  const updateModalOptions = (options: ISoftphoneModalOptionsProps) => {
    // Help prevent modal updates when a different modal is open
    if (options.id && options.id !== modalOptions?.id) {
      return;
    }

    setModalOptions((existingOptions) => ({ ...(existingOptions || {}), ...options }));
  };

  return (
    <Context.Provider
      value={{
        state: {
          isModalOpen
        },
        actions: {
          openModal,
          closeModal,
          updateModalOptions
        }
      }}
    >
      {children}
      {modalOptions && (
        <SoftphoneModal
          isModalOpen={isModalOpen}
          closeModal={closeModal}
          handleClearContent={handleClearContent}
          isReversedLayout={isReversedLayout}
          {...modalOptions}
        >
          {modalOptions.content}
        </SoftphoneModal>
      )}
    </Context.Provider>
  );
}

export default SoftphoneModalProvider;
