import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { ContactType, createContactSchema, Permissions } from '@cloud-wave/neon-common-lib';
import { EditableFieldTypes } from '@cloud-wave/neon-common-lib/app';
import cx from 'classnames';

import useHookForm from 'lib/common/hooks/useHookForm';
import { usePermissionsContext } from 'lib/common/contexts/PermissionsContext';
import { useAuthContext } from 'lib/core/context/AuthProvider';
import { useConfigContext } from 'lib/core/config';
import Button from 'lib/common/components/Button';
import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import { TInternalContacts } from '../../types';
import createDirectoryContact from './api/createDirectoryContact';
import DirectoryContactType from './components/DirectoryContactType';
import transformContactObject from './utils/transformContactObject';
import updateDirectoryContact from './api/updateDirectoryContact';
import getChangedContactValues from './utils/getChangedContactValues';

import styles from './create-update-directory-contact.module.scss';

export default function CreateUpdateDirectoryContact({
  onCancel,
  onSuccess,
  contact,
  defaultType
}: {
  onCancel: () => void;
  onSuccess?: () => void;
  contact?: TInternalContacts;
  defaultType: typeof ContactType.PERSONAL | typeof ContactType.ORGANISATION;
}) {
  const { fetch_ } = useAuthContext();
  const { config } = useConfigContext();
  const { isSoftphone } = useLayout();

  const [loading, setLoading] = useState(false);

  const { hasPermission, isAdmin } = usePermissionsContext();

  const hasPersonalContactPermission = hasPermission({
    type: 'tenant',
    permission: Permissions.PERSONAL_CONTACTS
  });

  const { HookFormInput, useForm } = useHookForm();
  const {
    control,
    setValue,
    formState: { errors, isValid },
    clearErrors,
    getValues,
    trigger
  } = useForm({
    defaultValues: transformContactObject({ contact, defaultType }),
    mode: 'onBlur',
    resolver: yupResolver(createContactSchema)
  });

  const contactId = contact && 'contactId' in contact && contact.contactId;

  return (
    <div
      className={cx(styles['create-update-directory-contact'], {
        [styles['create-update-directory-contact--softphone']]: isSoftphone
      })}
    >
      <div className={styles['create-update-directory-contact__inputs']}>
        <HookFormInput
          autoFocus
          data-testid="create-update-directory-contact-first-name"
          label="First Name"
          InputLabelProps={{ shrink: true }}
          name="firstName"
          validationError={errors['firstName']}
          clearErrors={clearErrors}
          disabled={loading}
          fullWidth
          control={control}
          ariaRequired
        />
        <HookFormInput
          label="Last Name"
          data-testid="create-update-directory-contact-last-name"
          InputLabelProps={{ shrink: true }}
          name="lastName"
          validationError={errors['lastName']}
          clearErrors={clearErrors}
          disabled={loading}
          fullWidth
          control={control}
          ariaRequired
        />
        <HookFormInput
          label="Phone Number"
          data-testid="create-update-directory-contact-phone-number"
          InputLabelProps={{ shrink: true }}
          name="phoneNumber"
          validationError={errors['phoneNumber']}
          clearErrors={clearErrors}
          fullWidth
          control={control}
          disabled={loading}
          type={EditableFieldTypes.PHONE}
          ariaRequired
        />

        <HookFormInput
          label="Email Address  •  Optional "
          InputLabelProps={{ shrink: true }}
          name="email"
          validationError={errors['email']}
          clearErrors={clearErrors}
          disabled={loading}
          fullWidth
          control={control}
        />

        <HookFormInput
          label="Job Title  •  Optional "
          InputLabelProps={{ shrink: true }}
          name="jobTitle"
          validationError={errors['jobTitle']}
          clearErrors={clearErrors}
          disabled={loading}
          fullWidth
          control={control}
        />

        <HookFormInput
          label="Notes  •  Optional"
          InputLabelProps={{ shrink: true }}
          name="notes"
          validationError={errors['notes']}
          clearErrors={clearErrors}
          disabled={loading}
          fullWidth
          control={control}
          multiline
          minRows={6}
          maxRows={6}
        />
        <DirectoryContactType
          type={getValues('type')}
          setType={(type) => {
            setValue('type', type);
            trigger('type');
          }}
          isAdmin={isAdmin}
          hasPersonalContactPermission={hasPersonalContactPermission}
        />
      </div>
      <section aria-label="footer" className={styles['create-update-directory-contact__buttons']}>
        <Button styleType="SECONDARY" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          asyncAction
          disabled={!isValid}
          data-testid="create-update-directory-contact-submit"
          onClick={() => {
            setLoading(true);

            return contactId
              ? updateDirectoryContact({
                  config,
                  fetch_,
                  contact: {
                    ...getChangedContactValues(contact as TInternalContacts, getValues() as Partial<TInternalContacts>),
                    contactId
                  }
                })
              : createDirectoryContact({ config, fetch_, contact: getValues() });
          }}
          onSuccess={onSuccess || onCancel}
          onFinally={() => setLoading(false)}
        >
          Save
        </Button>
      </section>
    </div>
  );
}
