import { useEffect, useState } from 'react';
import sizeMe from 'react-sizeme';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { useHistory } from 'react-router-dom';

import { PAGE_ROUTES } from '@cloud-wave/neon-common-lib/app';

import { useContactContext } from 'lib/common/contexts/ContactContext';
import ResizableGroup from 'lib/common/components/molecules/ResizableGroup';
import Column from 'lib/common/components/atoms/Column';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import getQueryParam from 'lib/common/utils/getQueryParam';
import CONTACT_STATES from 'lib/common/constants/contactStates';
import CONTACT_TYPES from 'lib/common/constants/contactTypes';
import WORKSPACE_LAYOUTS from 'lib/common/constants/workspaceLayouts';
import TUser from 'lib/common/types/User';

import NoTaskView from '../TaskDetail/components/NoTaskView';
import TaskList from '../TaskList';
import TaskDetail from '../TaskDetail';
import TASK_FILTER_OPTIONS from './constants/taskFilterOptions';
import NO_TASK_PLACEHOLDERS from './constants/noTaskPlaceholders';
import COLUMN_IDS from './constants/columnIds';
import get2ColLayoutId from './utils/get2ColLayoutId';
import LAYOUTS from './constants/layouts';
import TasksAndControls from './components/TasksAndControls';
import ChooseLayoutModal from './components/ChooseLayoutModal/ChooseLayoutModal';
import CreateAgentTaskModal from './components/CreateAgentTaskModal';
import SendSmsModal from './components/SendSmsModal';
import TaskModules from './components/TaskModules';

import './styles/agent-workspace.scss';

type TAgentWorkspace = {
  user: TUser;
  size: { height?: number; width?: number };
};

const MINIMUM_TASK_LENGTH = 1;

const sizeMeHOC = sizeMe({ monitorWidth: true, refreshRate: 16 });

const WORKSPACE_SMALL_BREAKPOINT = 1000;

function AgentWorkspace({ user, size: { width } }: TAgentWorkspace) {
  const history = useHistory();

  const urlTaskId = getQueryParam({ param: 'taskId' });

  const {
    actions: { setSelectedTaskId },
    state: { tasks, selectedTaskId }
  } = useContactContext();

  const {
    state: { defaultWorkspaceLayout }
  } = usePreferencesContext();

  const tasksWithoutIncoming = tasks.filter(
    (task) =>
      task.status !== CONTACT_STATES.CONNECTING ||
      (task.status === CONTACT_STATES.CONNECTING && task.type === CONTACT_TYPES.CONFERENCE_CALL)
  );
  const selectedTask = tasks.find((task) => task.taskId === selectedTaskId);
  const isSendingEmail = selectedTask?.type === CONTACT_TYPES.EMAIL && selectedTask?.status === CONTACT_STATES.DRAFT;

  const [filter, setFilter] = useState<{ value: string | null; label: string }>(TASK_FILTER_OPTIONS[0]);
  const [filteredTasks, setFilteredTasks] = useState(tasksWithoutIncoming);
  const [createTaskModalOpen, setCreateAgentTaskOpen] = useState(false);
  const [sendSmsModalOpen, setSendSmsModalOpen] = useState(false);
  const [createTaskDefaults, setCreateTaskDefaults] = useState({});

  const isTwoColumnLayout =
    isSendingEmail ||
    (width && width <= WORKSPACE_SMALL_BREAKPOINT) ||
    defaultWorkspaceLayout === WORKSPACE_LAYOUTS.TWO_COLUMNS;

  const onFilterTasks = ({ value, label }) => {
    setFilteredTasks(
      value
        ? tasksWithoutIncoming.filter(({ status, taskId }) => status === value || taskId === selectedTaskId)
        : tasksWithoutIncoming
    );

    setFilter({ value, label });
  };

  useEffect(() => {
    if (createTaskModalOpen) {
      return;
    }

    setCreateTaskDefaults({});
  }, [createTaskModalOpen]);

  useEffect(() => {
    if (!urlTaskId) {
      return;
    }

    if (tasks.find((task) => task.taskId === urlTaskId)) {
      setSelectedTaskId(urlTaskId);
    }

    history.replace(PAGE_ROUTES.WORKSPACE);
  }, [urlTaskId]);

  useEffect(() => {
    onFilterTasks(filter);

    if (selectedTaskId && tasks.some((task) => task.taskId === selectedTaskId)) {
      return;
    }

    setSelectedTaskId(tasks[0]?.taskId);
  }, [tasks]);

  useEffect(() => {
    onFilterTasks(filter);
  }, [selectedTaskId]);

  if (
    !tasks.length ||
    (tasks.length === MINIMUM_TASK_LENGTH &&
      tasks[0].status === CONTACT_STATES.CONNECTING &&
      tasks[0].type !== CONTACT_TYPES.CONFERENCE_CALL)
  ) {
    return (
      <div className="agent-workspace">
        <ChooseLayoutModal />
        <CreateAgentTaskModal
          {...createTaskDefaults}
          open={createTaskModalOpen}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
        />
        <SendSmsModal open={sendSmsModalOpen} setSendSmsModalOpen={setSendSmsModalOpen} />
        <NoTaskView
          user={user}
          setCreateAgentTaskOpen={setCreateAgentTaskOpen}
          setSendSmsModalOpen={setSendSmsModalOpen}
          width={width}
        />
      </div>
    );
  }

  const taskList = (
    <Column minWidth={250} key={COLUMN_IDS.TASKS}>
      <TaskList
        tasks={filteredTasks}
        selectedFilter={filter}
        selectedTaskId={selectedTaskId}
        setSelectedTaskId={setSelectedTaskId}
        onFilter={onFilterTasks}
        setCreateAgentTaskOpen={setCreateAgentTaskOpen}
        setSendSmsModalOpen={setSendSmsModalOpen}
        filterOptions={TASK_FILTER_OPTIONS}
        noTasksPlaceholder={NO_TASK_PLACEHOLDERS[filter.value || 'default']}
      />
    </Column>
  );

  const taskDetail = (
    <Column key={COLUMN_IDS.CONTROLS} minimisedIcon={faCircleInfo} minWidth={180} isResizable className="pb-0">
      <TaskDetail
        selectedTask={selectedTask}
        setCreateAgentTaskOpen={setCreateAgentTaskOpen}
        setCreateTaskDefaults={setCreateTaskDefaults}
        user={user}
      />
    </Column>
  );

  return (
    <div className="agent-workspace">
      <ChooseLayoutModal />
      <CreateAgentTaskModal
        {...createTaskDefaults}
        setCreateAgentTaskOpen={setCreateAgentTaskOpen}
        open={createTaskModalOpen}
      />
      <SendSmsModal open={sendSmsModalOpen} setSendSmsModalOpen={setSendSmsModalOpen} />
      <ResizableGroup
        containerClassName="agent-workspace"
        layoutOptions={isTwoColumnLayout ? LAYOUTS.TWO_COLUMNS : LAYOUTS.THREE_COLUMNS}
      >
        {isTwoColumnLayout && !isSendingEmail && (
          <TasksAndControls
            tasks={filteredTasks}
            onFilterTasks={onFilterTasks}
            setCreateAgentTaskOpen={setCreateAgentTaskOpen}
            setCreateTaskDefaults={setCreateTaskDefaults}
            setSendSmsModalOpen={setSendSmsModalOpen}
            user={user}
            selectedTaskId={selectedTaskId}
            setSelectedTaskId={setSelectedTaskId}
            filter={filter}
            selectedTask={selectedTask}
          />
        )}

        {(isSendingEmail || !isTwoColumnLayout) && taskList}
        {(isSendingEmail || !isTwoColumnLayout) && taskDetail}

        {!isSendingEmail && (
          <TaskModules
            selectedTask={selectedTask}
            key={isTwoColumnLayout ? get2ColLayoutId(COLUMN_IDS.PROFILE) : COLUMN_IDS.PROFILE}
          />
        )}
      </ResizableGroup>
    </div>
  );
}

export default sizeMeHOC(AgentWorkspace);
