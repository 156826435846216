import Text from 'lib/common/components/Text';

import './Input.css';

type Props = Readonly<{
  'data-test-id'?: string;
  label: string;
  onChange: (val: string) => void;
  placeholder?: string;
  value: string;
}>;

export default function TextInput({
  label,
  value,
  onChange,
  placeholder = '',
  'data-test-id': dataTestId
}: Props): JSX.Element {
  return (
    <div className="Input__wrapper">
      <Text type="label" className="Input__label">
        {label}
      </Text>
      <input
        type="text"
        className="Input__input"
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        data-test-id={dataTestId}
        aria-label={label || placeholder}
      />
    </div>
  );
}
