import CHAT_EVENTS from 'lib/common/constants/chatEvents';
import TChatTask from 'lib/common/types/ChatTask';
import toast from 'lib/common/utils/toast';
import filterChatTranscript from 'lib/common/utils/chat/filterChatTranscript';
import connectGetter from 'lib/common/utils/connectGetter';

import getUniqueAndSortedChatMessages from '../utils/getUniqueAndSortedChatMessages';

const MIN_NUM_OF_MESSAGES = 20;

function getChatTask(tasks, taskId): TChatTask {
  return tasks.find((task) => task.taskId === taskId) as TChatTask;
}

const getTranscripts = async ({
  chatSession,
  nextToken,
  retrievedMessages = []
}: {
  chatSession: connect.ChatSession;
  nextToken?: string | boolean;
  retrievedMessages?: connect.ChatTranscriptItem[];
}) => {
  try {
    const transcriptResponse = await connectGetter(chatSession, 'getTranscript', {
      maxResults: 100,
      sortOrder: 'ASCENDING',
      metadata: undefined,
      ...(nextToken && typeof nextToken === 'string' ? { nextToken } : {})
    });

    // Sometimes this connect API returns duplicates. Ensure there are no duplicates in retrieved history
    const newMessages = getUniqueAndSortedChatMessages([
      // Filtering out events from transcript
      ...filterChatTranscript(transcriptResponse?.data?.Transcript),
      ...retrievedMessages
    ]);

    if (newMessages.length >= MIN_NUM_OF_MESSAGES || !transcriptResponse?.data?.NextToken) {
      return {
        messages: newMessages,
        nextToken: transcriptResponse?.data?.NextToken
      };
    }

    return getTranscripts({
      chatSession,
      retrievedMessages: newMessages,
      nextToken: transcriptResponse?.data?.NextToken
    });
  } catch (e) {
    return toast('error', `Something went wrong fetching older messages. Please try again.`);
  }
};

export default function useChatActions(tasks, handleContactChange, selectedTaskId) {
  const downloadAttachment = async (attachmentId: string) => {
    const { chatSession } = getChatTask(tasks, selectedTaskId);

    if (!chatSession) {
      return;
    }

    return chatSession.downloadAttachment({ attachmentId });
  };

  const sendAttachment = async (attachment: File) => {
    const { chatSession } = getChatTask(tasks, selectedTaskId);

    if (!chatSession) {
      return;
    }

    if (attachment.name.match(/.heic$/i)) {
      const correctedAttachment = new File([attachment], attachment.name, { type: 'image/heic' });
      return chatSession.sendAttachment({ attachment: correctedAttachment });
    }

    return chatSession.sendAttachment({ attachment });
  };

  const sendMessage = async (message, isMarkdown = false) => {
    const { chatSession } = getChatTask(tasks, selectedTaskId);
    const contentType = isMarkdown ? 'text/markdown' : 'text/plain';

    if (!chatSession) {
      return;
    }

    return chatSession.sendMessage({ contentType, message });
  };

  const sendTypingEvent = () => {
    const { chatSession } = getChatTask(tasks, selectedTaskId);

    if (!chatSession) {
      return;
    }

    chatSession.sendEvent({ contentType: CHAT_EVENTS.TYPING });
  };

  const getChatTranscripts = async () => {
    try {
      const { chatSession, nextToken } = getChatTask(tasks, selectedTaskId);

      if (!chatSession) {
        return;
      }

      const transcripts = await getTranscripts({ chatSession, nextToken });

      return handleContactChange({
        taskId: selectedTaskId,
        hydratedChat: transcripts
      });
    } catch {
      return [];
    }
  };

  return {
    sendMessage,
    sendTypingEvent,
    getChatTranscripts,
    sendAttachment,
    downloadAttachment
  };
}
