import { faCopy } from '@fortawesome/pro-regular-svg-icons';
import CopyToClipboard from 'react-copy-to-clipboard';

import ClickableIcon from 'lib/common/components/ClickableIcon';

export default function CopyToClipboardIcon({
  className,
  size = 20,
  text
}: {
  className?: string;
  size?: number;
  text: string;
}) {
  return (
    <CopyToClipboard text={text}>
      <ClickableIcon aria-label="Copy to clipboard" className={className} icon={faCopy} size={size} />
    </CopyToClipboard>
  );
}
