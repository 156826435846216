import VARS from 'css/export-vars.module.scss';

export default function getInputWidthAndFontSize(isSoftphone, isSmallSoftphone) {
  if (isSmallSoftphone) {
    return {
      width: 200,
      fontSize: VARS.fontSizeNormal
    };
  }

  if (isSoftphone) {
    return {
      width: 220,
      fontSize: VARS.fontSizeLarge
    };
  }

  return { width: 300, fontSize: VARS.fontSizeExtraLarge };
}
