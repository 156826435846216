import { useEffect, useState, MouseEvent, ChangeEvent } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TableFooter
} from '@material-ui/core';
import { format, formatDistanceToNow, isToday } from 'date-fns';
import { faClock, faComment, faPhoneArrowDown, faPhoneArrowUp } from '@fortawesome/pro-regular-svg-icons';

import Loader from 'lib/common/components/Loader';
import EmptyPlaceholder from 'lib/common/components/EmptyPlaceholder';
import DateSelector from 'lib/common/components/atoms/DateSelector';
import ClickToCall from 'lib/common/components/ClickToCall';
import Avatar from 'lib/common/components/Avatar';
import useInteractionHistory from 'lib/common/api/hooks/useInteractionHistory';
import getDateFormat from 'lib/common/utils/getDateFormat';
import Icon from 'lib/common/components/Icon';
import ContactTypes from 'lib/common/constants/contactTypes';
import ConnectionType from 'lib/common/constants/connectionType';

import useStyles from './useStyles';
import './interaction-history.scss';

interface IData {
  talkedTo: string;
  phoneNumber: string;
  type: string;
  timestamp: string;
  direction: string;
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

type Order = 'asc' | 'desc';

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K
): (a: { [key in K]: string | boolean }, b: { [key in K]: string | boolean }) => number {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface IHeadRow {
  id: keyof IData;
  label: string;
  numeric: boolean;
}

const headRows: IHeadRow[] = [
  { id: 'talkedTo', numeric: false, label: 'Talked To' },
  { id: 'direction', numeric: false, label: 'Direction' },
  { id: 'phoneNumber', numeric: false, label: 'Phone Number' },
  { id: 'timestamp', numeric: false, label: 'When' },
  { id: 'type', numeric: false, label: 'Type' }
];

interface IEnhancedTableProps {
  // eslint-disable-next-line
  classes: ReturnType<typeof useStyles>;
  onRequestSort: (event: MouseEvent<unknown>, property: keyof IData) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: IEnhancedTableProps) {
  // eslint-disable-next-line
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof IData) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headRows.map((row) => (
          <TableCell key={row.id} align="left" sortDirection={orderBy === row.id ? order : false}>
            <TableSortLabel active={orderBy === row.id} direction={order} onClick={createSortHandler(row.id)}>
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function Timestamp({ timestamp }) {
  const timestampDate = new Date(timestamp);
  const timestampIsToday = isToday(timestampDate);

  return (
    <>
      {timestampIsToday
        ? `${formatDistanceToNow(timestampDate)} ago`
        : format(timestampDate, `${getDateFormat()} - H:mm`)}
    </>
  );
}

function HistoryTypeIcon({ type, direction }) {
  if (type !== ContactTypes.CALL) {
    return <Icon tooltip="Chat" icon={faComment} size={17} />;
  }

  return (
    <Icon
      tooltip="Call"
      icon={direction.toLowerCase() === ConnectionType.INBOUND ? faPhoneArrowDown : faPhoneArrowUp}
      size={17}
    />
  );
}

export default function InteractionHistory() {
  const { onDateChange, loaded, error, historyData } = useInteractionHistory();
  const classes = useStyles();
  const [rows, setRows] = useState<IData[]>(historyData);
  const [order, setOrder] = useState<Order>('desc');
  const [orderBy, setOrderBy] = useState<keyof IData>('timestamp');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    setRows(historyData);
  }, [historyData]);

  function handleRequestSort(event: MouseEvent<unknown>, property: keyof IData) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
    setPage(0);
  }

  const showEmpty = loaded && (!rows || !rows.length);

  return (
    <div className={classes.root}>
      <div className="panel--small interaction-history__date-selector">
        <DateSelector onDateChange={onDateChange} />
      </div>
      <div className="panel position-relative interaction-history__container">
        {showEmpty ? (
          <EmptyPlaceholder
            subText="You'll see interactions in here when you accept some tasks."
            icon={faClock}
            isError={error}
          />
        ) : (
          <>
            {loaded ? (
              <>
                <div className={classes.tableWrapper}>
                  <Table className={classes.table} aria-labelledby="tableTitle" size="medium">
                    <colgroup>
                      <col style={{ width: '22.5%' }} />
                      <col style={{ width: '22.5%' }} />
                      <col style={{ width: '22.5%' }} />
                      <col style={{ width: '22.5%' }} />
                      <col style={{ width: '10%' }} />
                    </colgroup>
                    <EnhancedTableHead
                      classes={classes}
                      order={order}
                      orderBy={orderBy}
                      onRequestSort={handleRequestSort}
                      rowCount={rows.length}
                    />
                    <TableBody>
                      {stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row, index) => (
                          <TableRow hover={false} tabIndex={-1} key={index} classes={{ root: 'tbl-row' }}>
                            <TableCell align="left" classes={{ root: 'tbl-cell-first' }}>
                              <div className="next-to-10">
                                <Avatar size={Avatar.Sizes.SMALL} name={row.talkedTo} />
                                {row.talkedTo}
                              </div>
                            </TableCell>
                            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                              {row.direction.charAt(0).toUpperCase() + row.direction.slice(1)}
                            </TableCell>
                            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                              {row.phoneNumber ? <ClickToCall>{row.phoneNumber}</ClickToCall> : '-'}
                            </TableCell>
                            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                              <Timestamp timestamp={row.timestamp} />
                            </TableCell>
                            <TableCell align="left" classes={{ root: 'tbl-cell' }}>
                              <div className="interaction-history__row__icon">
                                <HistoryTypeIcon {...row} />
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                    <TableFooter
                      classes={{
                        root: 'interaction-history__table-footer'
                      }}
                    >
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          backIconButtonProps={{
                            'aria-label': 'previous page'
                          }}
                          nextIconButtonProps={{
                            'aria-label': 'next page'
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </div>
              </>
            ) : (
              <Loader relative />
            )}
          </>
        )}
      </div>
    </div>
  );
}
