import cx from 'classnames';

import styles from './button-preview-icon.module.scss';

export default function ButtonPreviewIcon({ children, primary }: { children: string; primary?: boolean }) {
  return (
    <div
      className={cx(
        styles['button-preview-icon'],
        primary ? styles['button-preview-icon--primary'] : styles['button-preview-icon--secondary']
      )}
    >
      {children}
    </div>
  );
}
