import connectGetter from './connectGetter';

export default function getPhoneNumberFromContact(contact?: connect.Contact): string {
  if (!contact || !contact.getType || connectGetter(contact, 'getType') !== connect.ContactType.VOICE) {
    return '';
  }

  const activeInitialConnection = connectGetter(contact, 'getInitialConnection');

  return connectGetter(activeInitialConnection, 'getEndpoint')?.phoneNumber || '';
}
