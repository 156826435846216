import { ReactNode, useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleQuestion, faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import cx from 'classnames';

import { useLayout } from 'lib/common/contexts/layout/LayoutContext';

import Icon from 'lib/common/components/Icon';
import Text from 'lib/common/components/Text';
import Tooltip from 'lib/common/components/atoms/Tooltip';

import { Colour } from '../Text/types';

import './popover-menu-item.scss';

export enum PopoverMenuItemType {
  SEPARATOR = 'SEPARATOR',
  DANGER = 'DANGER',
  OPTION = 'OPTION'
}

export type TPopoverMenuItem =
  | {
      text?: ReactNode;
      icon?: IconProp | string;
      onClick?: () => void;
      onSuccess?: () => void;
      confirmActionText?: string;
      actionInProgressText?: string;
      highlighted?: boolean;
      disabled?: boolean;
      tooltip?: string;
      tooltipId?: string;
      tooltipDelay?: number;
      type?: PopoverMenuItemType.DANGER | PopoverMenuItemType.OPTION;
      subtitle?: string;
      customContent?: React.ReactNode;
      color?: Colour;
      selected?: boolean;
      iconColor?: Colour;
      className?: string;
      autoFocus?: boolean;
      ariaLabel?: string;
    }
  | {
      background?: string;
      type: PopoverMenuItemType.SEPARATOR;
    };

export default function PopoverMenuItem(props: TPopoverMenuItem) {
  const [isWaitingForConfirmation, setIsWaitingForConfirmation] = useState(false);
  const [actionInProgress, setActionInProgress] = useState(false);
  const { isSoftphone } = useLayout();

  if (props.type === PopoverMenuItemType.SEPARATOR) {
    return <span role="separator" style={{ background: props.background }} className="popover-menu-item--separator" />;
  }

  const {
    text,
    icon,
    onClick,
    highlighted,
    disabled,
    tooltip,
    tooltipId,
    tooltipDelay = 1000,
    subtitle,
    confirmActionText,
    actionInProgressText,
    customContent,
    color,
    selected,
    className,
    onSuccess,
    iconColor,
    autoFocus,
    ariaLabel
  } = props;
  const requiresConfirmation = Boolean(confirmActionText);
  const type = props.type || PopoverMenuItemType.OPTION;
  const hasSubtitle = Boolean(subtitle);

  const handleOnClick = async () => {
    try {
      if (isWaitingForConfirmation) {
        setIsWaitingForConfirmation(false);
        setActionInProgress(true);

        await onClick?.();

        return void setActionInProgress(false);
      }

      if (requiresConfirmation) {
        return void setIsWaitingForConfirmation(true);
      }

      if (actionInProgressText) {
        setActionInProgress(true);
      }

      await onClick?.();
    } finally {
      setActionInProgress(false);
      onSuccess?.();
    }
  };

  const MenuItemContainer = ({
    icon,
    spinIcon,
    title,
    subtitle,
    customContent,
    autoFocus
  }: {
    title?: ReactNode;
    subtitle?: string;
    icon?: IconProp | string;
    spinIcon?: boolean;
    customContent?: React.ReactNode;
    autoFocus?: boolean;
  }) => {
    const menuItem = (
      <MenuItem
        aria-label={`${ariaLabel || title?.toString() || subtitle?.toString()}${selected ? ' Selected' : ''}`}
        autoFocus={autoFocus}
        button
        onClick={!disabled ? handleOnClick : void 0}
        className={cx(
          'popover-menu-item',
          {
            'popover-menu-item--disabled': disabled || actionInProgress,
            'popover-menu-item--non-interactive': disabled && !tooltipId,
            'popover-menu-item--softphone': isSoftphone,
            'popover-menu-item--danger': type === PopoverMenuItemType.DANGER && !actionInProgress,
            'popover-menu-item--subtitled': hasSubtitle,
            'popover-menu-item--selected': selected
          },
          className
        )}
        disableRipple
        data-testid={`${title}-popover-menu-item`}
      >
        {customContent ? (
          customContent
        ) : (
          <>
            {icon && <Icon color={iconColor || color} size={17} icon={icon} className="mr-10" spin={spinIcon} />}
            <div className="popover-menu-item__text">
              <Text color={color} bold={highlighted || hasSubtitle} className="popover-menu-item__text__primary">
                {title}
              </Text>
              {subtitle && (
                <Text type="small" color="darkGrey" className="popover-menu-item__text__subtitle">
                  {subtitle}
                </Text>
              )}
            </div>
          </>
        )}
      </MenuItem>
    );

    if (!tooltipId) {
      return menuItem;
    }

    return (
      <Tooltip enterDelay={tooltipDelay} title={tooltip || ''} ariaHidden={false}>
        {menuItem}
      </Tooltip>
    );
  };

  if (actionInProgress) {
    return (
      <MenuItemContainer
        autoFocus={autoFocus}
        icon={faCircleNotch}
        spinIcon
        title={actionInProgressText}
        subtitle={subtitle}
        customContent={customContent}
      />
    );
  }

  if (isWaitingForConfirmation && confirmActionText) {
    return (
      <MenuItemContainer
        autoFocus
        icon={faCircleQuestion}
        title={confirmActionText}
        subtitle={subtitle}
        customContent={customContent}
      />
    );
  }

  return (
    <MenuItemContainer
      autoFocus={autoFocus}
      icon={icon}
      title={text}
      subtitle={subtitle}
      customContent={customContent}
    />
  );
}
