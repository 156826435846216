import connectGetter from 'lib/common/utils/connectGetter';

export default async function getContactChatSession(
  contact?: connect.Contact
): Promise<connect.AgentChatSession | null> {
  if (!contact) {
    return null;
  }

  const type = connectGetter(contact, 'getType');

  if (type !== connect.ContactType.CHAT) {
    return null;
  }

  const connection = connectGetter(contact, 'getConnections')?.find(
    (connection) => connectGetter(connection, 'getType') === connect.ConnectionType.AGENT
  ) as connect.ChatConnection | undefined;

  if (!connection) {
    return null;
  }

  const session: connect.AgentChatSession = await (connection as connect.ChatConnection).getMediaController();

  return session;
}
