import CONTACT_STATES from '../constants/contactStates';
import {
  isActiveConference,
  isConference as isConferenceContact,
  isInitialConnectionDisconnected
} from '../utils/conferenceConnections';

export default function useTaskConnections(task) {
  const { contact, status } = task;

  const isNeglected = status === CONTACT_STATES.MISSED || status === CONTACT_STATES.REJECTED;

  /**
   * Explicitly checking for contact connections rather than contact type
   * as there might be multiple connections (inbound/outbound) which are disconnected but there is
   * still a single active inbound/outbound call which corresponds to type CALL and not CONFERENCE_CALL
   */
  const isConference = !isNeglected && isConferenceContact(contact);
  const isCurrentConference = !isNeglected && isActiveConference(contact);
  const initialConnectionDisconnected = isConference && isInitialConnectionDisconnected(contact);

  return {
    isNeglected,
    isConference,
    isCurrentConference,
    initialConnectionDisconnected
  };
}
