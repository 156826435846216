export default {
  SOFTPHONE: {
    LINE_LIMIT: 5,
    CHAR_LIMIT: 500
  },
  DESKTOP: {
    LINE_LIMIT: 10,
    CHAR_LIMIT: 500
  }
};
