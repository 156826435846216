import { useEffect } from 'react';

export default function useUnload({ fetch, tokens, config }) {
  const unload = () => {
    navigator.sendBeacon(
      `${config.AGENT_SERVICE_URL}/agent/${config.TENANT_ID}__${sessionStorage.getItem('c_user')}/cleanup/`,
      'close'
    );
  };

  useEffect(() => {
    window.addEventListener('unload', unload);

    return () => {
      window.removeEventListener('unload', unload);
    };
  }, [fetch, tokens, config]);
}
