import cx from 'classnames';

import usePageProps from '../hooks/usePageProps';

import './isolated-layout.scss';

export default function IsolatedLayout({ children }) {
  const { className } = usePageProps();

  return (
    <div className="isolated-layout">
      <div className={cx('isolated-layout__children', className)}>{children}</div>
    </div>
  );
}
