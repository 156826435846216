const REAL_TIME = 'rt';

// -- Get Metrics -- //

function getAccumulativeMetric(metrics, name) {
  return metrics[REAL_TIME]?.MetricResults?.reduce((totalValue, { Collections }) => {
    const metric = Collections.find((collection) => collection.Metric.Name === name);

    if (!metric) {
      return totalValue;
    }

    const currentValue = parseInt(metric.Value);

    if (isNaN(currentValue)) {
      return totalValue;
    }

    return totalValue + currentValue;
  }, 0);
}

function getHighestMetric(metrics, name) {
  return metrics[REAL_TIME]?.MetricResults?.reduce((highestValue, { Collections }) => {
    const metric = Collections.find((collection) => collection.Metric.Name === name);

    if (!metric) {
      return highestValue;
    }

    const currentValue = parseInt(metric.Value);

    if (isNaN(currentValue)) {
      return highestValue;
    }

    return currentValue > highestValue ? currentValue : highestValue;
  }, 0);
}

function getQueueMetric(queueData, name) {
  const metric = queueData?.Collections?.find((collection) => collection.Metric.Name === name);

  if (!metric) {
    return 0;
  }

  return metric.Value;
}

// ---- //

export function buildQueueStats({ metrics, agentQueues }) {
  return agentQueues.map(({ name, Id }) => {
    const queueData = metrics[REAL_TIME].MetricResults.find(({ Dimensions }) => Dimensions?.Queue?.Id === Id);

    return {
      name,
      Id,
      contactsInQueue: getQueueMetric(queueData, 'CONTACTS_IN_QUEUE'),
      oldestContactInQueue: getQueueMetric(queueData, 'OLDEST_CONTACT_AGE'),
      agentsAvailable: getQueueMetric(queueData, 'AGENTS_AVAILABLE'),
      agentsOnline: getQueueMetric(queueData, 'AGENTS_ONLINE')
    };
  });
}

export function buildAllQueuesStats({ metrics }) {
  return {
    contactsInQueues: getAccumulativeMetric(metrics, 'CONTACTS_IN_QUEUE'),
    oldestContactInQueues: getHighestMetric(metrics, 'OLDEST_CONTACT_AGE')
  };
}
