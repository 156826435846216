export default function getUserName({
  firstName,
  lastName,
  spaceChar = ' ',
  fallback = ''
}: { spaceChar?: string; firstName?: string; lastName?: string; fallback?: string } = {}) {
  if (!firstName && !lastName) {
    return fallback;
  }

  const first = firstName || '';
  const last = lastName || '';
  const space = first && last ? spaceChar : '';

  return `${first}${space}${last}`
    .split(spaceChar)
    .map((word) => (word ? `${word.charAt(0).toUpperCase()}${word.substring(1).toLowerCase()}` : ''))
    .join(spaceChar);
}
