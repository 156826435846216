import { useEffect, useRef } from 'react';
import { usePreferencesContext } from 'lib/common/contexts/PreferencesContext';
import { NONE as NO_RINGTONE } from 'lib/common/constants/ringtoneOptions';
import getInstanceUrl from 'lib/common/utils/getInstanceUrl';
import { TConfig } from 'lib/common/types/Config';

const DEFAULT_CCP_TIMEOUT_MS = 5000;
const LOG_LEVEL = 'ERROR';

export default function ConnectStreams({ config }: { config: TConfig }) {
  const {
    state: { ringtone }
  } = usePreferencesContext();

  const ccpEl = useRef(null);
  const instanceUrl = getInstanceUrl(config);

  const ccpURL = `${instanceUrl}/ccp-v2`;
  const ccpLoginURL = config.SSO_URL || `${instanceUrl}/login`;

  useEffect(() => {
    if (!ccpEl || !ccpEl.current) {
      return;
    }

    try {
      // This is used to ignore all [INFO] messages coming from CCP.
      // @ts-ignore
      connect.getLog().setEchoLevel(LOG_LEVEL);

      connect.core.initCCP(ccpEl.current, {
        ccpUrl: ccpURL,
        loginPopup: true,
        loginUrl: ccpLoginURL,
        softphone: {
          allowFramedSoftphone: true,
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        task: {
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        chat: {
          disableRingtone: ringtone === NO_RINGTONE,
          ringtoneUrl: ringtone
        },
        loginOptions: {
          autoClose: true,
          top: 0,
          left: 0,
          height: 472.867,
          width: 355
        },
        loginPopupAutoClose: true,
        region: config.CONNECT_REGION,
        ccpAckTimeout: DEFAULT_CCP_TIMEOUT_MS,
        ccpSynTimeout: DEFAULT_CCP_TIMEOUT_MS,
        ccpLoadTimeout: DEFAULT_CCP_TIMEOUT_MS
      });
    } catch (e) {
      console.error('Error initialising connect streams', e);
    }
  }, [ccpEl]);

  return <div style={{ display: 'none' }} ref={ccpEl} />;
}
