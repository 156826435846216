import { faCookie, faEnvelope, faEraser, faKey, faSunglasses, faUserPlus } from '@fortawesome/pro-regular-svg-icons';

import Text from 'lib/common/components/Text';
import { EXTERNAL_HELP_LINKS } from 'lib/common/constants/help';

export default (productName: string) => [
  {
    key: 'correct-user',
    icon: faKey,
    content: (
      <Text>
        Making sure you’re signed in with the <Text.Bold>correct user</Text.Bold>. The user above should be the one
        you’re expecting.
      </Text>
    )
  },
  {
    key: 'user-added',
    icon: faUserPlus,
    content: (
      <Text>
        Making sure an admin has <Text.Bold>added you</Text.Bold> to <Text.Bold>{productName}</Text.Bold>.
      </Text>
    )
  },
  {
    key: 'clear-data',
    icon: faEraser,
    content: (
      <Text>
        Clearing site data for {productName} -{' '}
        <Text type="link" href={EXTERNAL_HELP_LINKS.SITE_DATA.EDGE}>
          Microsoft Edge
        </Text>{' '}
        |{' '}
        <Text type="link" href={EXTERNAL_HELP_LINKS.SITE_DATA.CHROME}>
          Google Chrome
        </Text>
        .
      </Text>
    )
  },
  {
    key: 'incognito',
    icon: faSunglasses,
    content: (
      <Text>
        Signing in with a private/incognito window -{' '}
        <Text type="link" href={EXTERNAL_HELP_LINKS.INCOGNITO.EDGE}>
          Microsoft Edge
        </Text>{' '}
        |{' '}
        <Text type="link" href={EXTERNAL_HELP_LINKS.INCOGNITO.CHROME}>
          Google Chrome
        </Text>
        .
      </Text>
    )
  },
  {
    key: 'cookies',
    icon: faCookie,
    content: (
      <Text>
        Making sure <Text.Bold>cookies are enabled</Text.Bold>.
      </Text>
    )
  },
  {
    key: 'admin-contact',
    icon: faEnvelope,
    content: (
      <Text>
        Getting in touch with your <Text.Bold>Administrator</Text.Bold>.
      </Text>
    )
  }
];
