import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';
import toast from 'lib/common/utils/toast';

export default async function createEmailAttachmentUploadUrl(fetch_: TFetch, config: TConfig, files: File[]) {
  try {
    const res = await fetch_(`${config.EMAIL_URL}/user/attachment-upload-url`, {
      method: 'POST',
      body: JSON.stringify(
        files.map((file) => ({
          name: file.name,
          size: file.size,
          type: file.type
        }))
      )
    });

    return (await res.json()).data;
  } catch (e) {
    toast('error', `Something went wrong uploading one or more attachments. Please try again.`);

    return [];
  }
}
