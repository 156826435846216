import connectGetter from 'lib/common/utils/connectGetter';
import TTask from '../../../types/Task';

export default function buildConnectionTimestamps({ task, contact }: { task: TTask; contact: connect.Contact }) {
  const connections = connectGetter(contact, 'getConnections');
  const existingConnectionTimestamps = task?.connectionTimestamps || {};

  const connectionTimestamps =
    connections?.reduce<Record<string, Date>>((timestamps, connection) => {
      if (existingConnectionTimestamps[connection.connectionId]) {
        return timestamps;
      }

      return {
        ...timestamps,
        [connection.connectionId]: connectGetter(connection, 'getState')?.timestamp || new Date()
      };
    }, existingConnectionTimestamps) ?? {};

  if (task && Object.keys(connectionTimestamps).length === Object.keys(existingConnectionTimestamps).length) {
    return existingConnectionTimestamps;
  }

  return connectionTimestamps;
}
