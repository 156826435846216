import { TConfig } from 'lib/common/types/Config';
import TFetch from 'lib/common/types/Fetch';

export const deleteContact = async ({
  config,
  fetch_,
  contactId
}: {
  config: TConfig;
  fetch_: TFetch;
  contactId: string;
}) => {
  return fetch_(`${config.DIRECTORY_SERVICE_URL}/user/contact/${contactId}`, {
    method: 'DELETE'
  });
};
